/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Button, Space, DatePicker, Typography, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { TimePicker } from 'antd';
import { useAuth } from '../../Context';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import { enddatetimeCoverter, startdatetimeCoverter } from '../../datetime-converter';
import { SearchOutlined, FileSearchOutlined, InfoOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const dateFormat = 'YYYY-MM-DD';

const { RangePicker } = DatePicker;
const { Title } = Typography;


export default function AlarmReport() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getAllEvents, eventsData, getChannels, channelData, getUserAllocation ,getUserAllocationData, pidsRegionData, getPidsRegion } = useAuth();

	const lastAllocationId = parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`));
	const lastStartDate = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_START_DATE`);
	const lastEndDate = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_END_DATE`);
	const lastActivity = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ACTIVITY`);
	const lastPids = parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`));
	const lastchannel = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);
	const lastStartTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_START_TIME`);
	const lastEndTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_END_TIME`);
	const lastAnomalyfrequency = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_FREQUENCY`);

	const navigate = useNavigate();
	const [activity, setActivity] = useState();
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [allocation, setAllocation] = useState();
	const [pids, setPids] = useState(lastPids ? lastPids : null);
	const [startTime, setStartTime] = useState('00,00,00');
	const [endTime, setEndTime] = useState('23,59,00');
	const [channel, setChannel] = useState('all');
	const [anomalyFrequency, setAnomalyFrequency] = useState('Random');
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [regionId, setRegionId] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [showEndTime, setShoeEndTime] = useState(dayjs());
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const handleStartTime = (time) => {
		setStartTime(moment(time && time.$d).format('HH,mm,ss'));
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_START_TIME`,moment(time && time.$d).format('HH:mm:ss'));

	};

	const handleEndTime = (time) => {
		setEndTime(moment(time && time.$d).format('HH,mm,ss'));
		setShoeEndTime(moment(time && time.$d).format('HH,mm,ss'));
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_END_TIME`,moment(time && time.$d).format('HH:mm:ss'));

	};

	let regionList = [{value : null,
		label: `${langdata && langdata.ALL ? langdata.ALL :'ALL'}`}];
	let eventsList = [];
	let pidsList = [{
		value : null,
		label: `${langdata && langdata.ALL ? langdata.ALL :'ALL'}`
	}];
	let channelList = [{value : 'all',
		label: `${langdata && langdata.ALL ? langdata.ALL :'ALL'}`}];

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 61);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		let pidsallocation = '';

		if(admin_roles.includes(permission)){
			getRegion(authtoken);
			pidsallocation = regionId;

		}
		else{
			getUserAllocation(authtoken);
			pidsallocation=allocation;

		}
		getAllEvents(authtoken);
		// getPids(authtoken);
		getPidsRegion(authtoken,pidsallocation);

	},[authtoken, regionId, allocation]);

	const handlePids = (value) => {
		getChannels(authtoken,value);
		setPids(value);
		setChannel('all');
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`,value);
		localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);

	};

	const disabledStartHours = () => {
		const hours = [];
		const currentHour = moment().hour();
		let hour = 0;
		
		if(moment.utc(moment()).date() == moment(startDate).date()) {
			hour = currentHour;
		} else {
			hour = 23;
		}
	
		for (let i = hour + 1; i < 24; i++) {
			hours.push(i);
		}
	
		return hours;
	};
	
	const disabledStartMinutes = (selectedHour) => {
		const minutes = [];
		let minute = 0;
		const currentMinute = moment().minute();

		if(moment.utc(moment()).date() == moment(startDate).date()) {
			minute = currentMinute;
		} else {
			minute = 59;
		}

		if (selectedHour === moment().hour()) {
			for (let i = minute + 1; i <= 60; i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	const disabledEndHours = () => {
		const hours = [];
		const currentHour = moment().hour();
		let hour = 0;
		
		if(moment.utc(moment()).date() == moment(endDate).date()) {
			hour = currentHour;
		} else {
			hour = 23;
		}
	
		for (let i = hour + 1; i < 24; i++) {
			hours.push(i);
		}
	
		return hours;
	};
	
	const disabledEndMinutes = (selectedHour) => {
		const minutes = [];
		let minute = 0;
		const currentMinute = moment().minute();

		if(moment.utc(moment()).date() == moment(endDate).date()) {
			minute = currentMinute;
		} else {
			minute = 59;
		}

		if (selectedHour === moment().hour()) {
			for (let i = minute + 1; i <= 60; i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regionList.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regionList.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}
	
	eventsList.push({
		value : 'all',
		label: `${langdata && langdata.ALL ? langdata.ALL :'ALL'}`
	});

	eventsData && eventsData.map((item) => {
		eventsList.push({
			value : item.eventType,
			label: item.eventType
		});
	});

	pidsRegionData && pidsRegionData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});

	if(pids){
		channelData && channelData.map((item) => {
			channelList.push({
				value : item.channelId,
				label : item.name
			});
		});

	}


	pidsList.sort((a,b) => { return a.value - b.value; });
	
	const handleActivity = (e) => {
		setActivity(e);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ACTIVITY`,e);

	};

  
	const onRangeChange = (dates) => {
		if (dates) {
			// console.log('From: ', dates[0], ', to: ', dates[1]);
			setStartDate(new Date(dates[0] && dates[0].$d));
			setEndDate(new Date(dates[1] && dates[1].$d));
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_START_DATE`,new Date(dates[0] && dates[0].$d));
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_END_DATE`,new Date(dates[1] && dates[1].$d));
			if(moment.utc(moment()).date() == moment(new Date(dates[1] && dates[1].$d)).date()) {
				setShoeEndTime(dayjs());
			} else {
				setShoeEndTime(dayjs('23:59:59', 'HH:mm:ss'));
			}
		} else {
			console.log('Clear');
		}
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	// const rangePresets = [
	// 	{
	// 		label: 'Last 7 Days',
	// 		value: [dayjs().add(-7, 'd'), dayjs()],
	// 	},
	// 	{
	// 		label: 'Last 14 Days',
	// 		value: [dayjs().add(-14, 'd'), dayjs()],
	// 	},
	// 	{
	// 		label: 'Last 30 Days',
	// 		value: [dayjs().add(-30, 'd'), dayjs()],
	// 	},
	// 	{
	// 		label: 'Last 90 Days',
	// 		value: [dayjs().add(-90, 'd'), dayjs()],
	// 	},
	// ];

	const handleAllocation = (e, options) => {
		if(admin_roles.includes(permission)) {
			setAllocation(null);
			setRegionId(e);
			setPids(null);
			setChannel('all');
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`,e);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);

		} else {
			setAllocation(e);
			setRegionId(options.region);
			setPids(null);
			setChannel('all');
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`,e);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);
		}
	};

	const handleChannel = (e, options) => {
		setChannel(e);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`,options.label);

	};

	const handleAnomalyFrequence = (e) => {
		setAnomalyFrequency(e);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_FREQUENCY`,e);

	};

	const from_datetimeobj=[];

	if(startDate && startDate !=='1970-01-01' && startTime){
		const starttime = startTime;
		var resultArray = starttime.split(',').map(function(starttime){return Number(starttime);});
		if(resultArray.length > 0) {
			startDate.setHours(resultArray[0],resultArray[1],resultArray[2]);
			let startdatetime=startdatetimeCoverter(startDate);
			from_datetimeobj.push({startdatetime});

		}

	}

	const to_datetimeobj=[];

	if(endDate && endDate !=='1970-01-01' && endTime ){

		const endtime = endTime;

		var resultEndArray = endtime.split(',').map(function(endtime){return Number(endtime);});
		if(resultEndArray.length > 0){
			endDate.setHours(resultEndArray[0],resultEndArray[1],resultEndArray[2]);
			// filterDate.setHours(parseFloat(filterEndTime));
			let enddatetime=enddatetimeCoverter(endDate);
			to_datetimeobj.push({enddatetime});

		}

	}

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.reporteventcontent1 ? langdata.reporteventcontent1 :'This feature allow the user to download events as report by sorting and filtering details by controller and channel for precise analysis. The report can be downloaded as .csv format.'}</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}}
				>
					<Col span={12}>
						<Row>
							<Space>
								<FileSearchOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.ReportEvents ? langdata.ReportEvents :'Report - Events'}</Title>
						</Row>
					</Col>

					<Col span={12}>
						<Row justify="end">
							<Col>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
								<Space>
									<Typography>
										<Popover title={langdata && langdata.ReportEvents ? langdata.ReportEvents :'Report - Events'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>

				</Row>
				
				<Row style={{
					margin: '10px',
					paddingTop:'10px',
					// paddingLeft: '100px',
					// paddingRight: '180px',
					paddingBottom:'30px',
					background: '#FFFFFF',
					justifyContent:'left', display:'flex', alignItems:'left'
				}}>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Allocation ? langdata.Allocation :'Allocation'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{width:'360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.Selectallocation ? langdata.Selectallocation :'Select allocation'}
											optionFilterProp="children"
											onSelect={(e, options)=>handleAllocation(e, options)}
											defaultValue={lastAllocationId ? lastAllocationId : null}
											options={regionList}
										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Date ? langdata.Date :'Date'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<RangePicker allowClear={false} onChange={onRangeChange} disabledDate={disabledDate} format={settingsdateformat}
											defaultValue={[dayjs(moment(lastStartDate ? lastStartDate : new Date()).format('YYYY-MM-DD'), dateFormat), dayjs(moment(lastEndDate ? lastEndDate : new Date()).format('YYYY-MM-DD'), dateFormat)]} 
											style={{width:'360px', justifyContent:'right', display:'flex', alignItems:'right'}}									/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Activity ? langdata.Activity :'Activity'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											mode="multiple"
											style={{width:'360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectEvent ? langdata.SelectEvent :'Select Event'}
											optionFilterProp="children"
											// onChange={onChange}
											// onSearch={onSearch}
											// filterOption={filterOption}
											onChange={handleActivity}
											defaultValue={[lastActivity ? lastActivity : 'all']}
											options={eventsList}
											value={activity}
										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{width:'360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS :'Select PIDS'}
											optionFilterProp="children"
											onSelect={handlePids}
											// defaultValue={lastPids ? lastPids : 'All'}
											value={lastPids ? lastPids : pids}
											options={pidsList}
										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Channel ? langdata.Channel :'Channel'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{ width: '360px'}}
											disabled={channelList.length > 1 || lastchannel ? false : true}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectChannel ? langdata.SelectChannel :'Select Channel'}
											optionFilterProp="children"
											// onChange={onChange}
											// onSearch={onSearch}
											// filterOption={filterOption}
											// defaultValue={lastchannel}
											value={lastchannel ? lastchannel : channel}
											options={channelList}
											onSelect={(e,options)=>{handleChannel(e,options);}}
										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.StartTime ? langdata.StartTime :'Start Time'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<TimePicker allowClear={false} style={{ width: '360px'}} defaultValue={dayjs(lastStartTime ? lastStartTime : '00:00', 'HH:mm')} 
											onChange={handleStartTime}
											disabledHours={disabledStartHours}
											disabledMinutes={disabledStartMinutes} />

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.EndTime ? langdata.EndTime :'End Time'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<TimePicker allowClear={false} style={{ width: '360px'}} 
											// defaultValue={dayjs('23:59:59', 'HH:mm:ss')} 
											value={dayjs(lastEndTime ? lastEndTime : showEndTime, 'HH:mm:ss')}
											onChange={handleEndTime}
											disabledHours={disabledEndHours}
											disabledMinutes={disabledEndMinutes} />

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{textAlign:'right', width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.AnomalyFrequency ? langdata.AnomalyFrequency :'Anomaly Frequency'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{ width: '360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.Selecteventfrequency ? langdata.Selecteventfrequency :'Select event frequency'}
											optionFilterProp="children"
											onSelect={handleAnomalyFrequence}
											defaultValue={lastAnomalyfrequency ? lastAnomalyfrequency : 'Random'}
											options={[
												{
													value: 'Continous',
													label: `${langdata && langdata.Continous ? langdata.Continous :'Continous'}`,
												},
												{
													value: 'Random',
													label: `${langdata && langdata.Random ? langdata.Random :'Random'}`,
												}
											]}
										/>

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>

					{/* <Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									&nbsp;
									</Space>
									<Space>&nbsp;</Space>

									<Space style={{width:'360px', justifyContent:'right', display:'flex', alignItems:'right'}}>
									&nbsp;


									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>

						</Col>	

				

					</Row>



					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									&nbsp;
									</Space>
									<Space>&nbsp;</Space>

									<Space style={{width:'360px', justifyContent:'right', display:'flex', alignItems:'right'}}>
										<Button type ={'primary'} onClick={() => {navigate(( '/events'),{ state: { activity : activity, startDate :from_datetimeobj[0].startdatetime.utcdate, endDate: to_datetimeobj[0].enddatetime.utcdate,
											allocation : allocation, startTime : from_datetimeobj[0].startdatetime.utctime, endTime : to_datetimeobj[0].enddatetime.utctime, channel : channel, anomalyFrequency : anomalyFrequency, pids: pids, regionId : regionId} });}}>
                        Next</Button>

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>

						</Col>	

				

					</Row> */}

					<Col style={{
						justifyContent:'left', display:'left', alignItems:'left'}} span={24}>
					

						<Row style={{ marginTop:'30px', justifyContent:'left', display:'flex', alignItems:'left'}}>
							<Col style={{justifyContent:'left', display:'flex', alignItems:'left'}} span={24}
							>
								<Space>
									<Row  style={{justifyContent:'left', display:'flex', alignItems:'left'}}>

										<Space style={{width:'160px', justifyContent:'left', display:'flex', alignItems:'left'}}>

									&nbsp;
										</Space>
										<Space>&nbsp;</Space>

										<Space style={{justifyContent:'left', display:'flex', alignItems:'left', width:'360px'}}>
											<Button type ={'primary'} onClick={() => {navigate(( '/events'),{ state: { activity : activity, startDate :from_datetimeobj[0].startdatetime.utcdate, endDate: to_datetimeobj[0].enddatetime.utcdate,
												allocation : allocation, startTime : from_datetimeobj[0].startdatetime.utctime, endTime : to_datetimeobj[0].enddatetime.utctime, channel : channel, anomalyFrequency : anomalyFrequency, pids: pids, regionId : regionId} });}}>
												<SearchOutlined />{langdata && langdata.Search ? langdata.Search :'Search'}</Button>

										</Space>
										<Space>&nbsp;</Space>

									</Row>
								</Space>
							</Col>	
						</Row>

					</Col>



				</Row>
				
			</Content>            
		</>
	);
}