/* eslint-disable react/react-in-jsx-scope */
import { Row, Col, Select, Typography, Tooltip, Button, Input, Space, Table, Spin, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../Context';
import axios from 'axios';
import reducer from './reducer';
import { USER_LIST, USER_LIST_ERROR, USER_LIST_SUCCESS } from './constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusOutlined, UserOutlined, InfoOutlined } from '@ant-design/icons';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;
const { Search } = Input;

export default function Allocations() {
	const {getUserTypes, userTypeData} = useAuth();

	const initalState = { pending: false, error: false, users : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }};
	const [data, dispatch] = useReducer(reducer, initalState);

	const navigate = useNavigate();
	const { state } = useLocation();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const [userSearch, setUserSearch] = useState(null);
	const [userRole, setUserRoll] = useState(state && state.data.value ? state.data.value : 'all');

	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 5);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getUserTypes(authtoken);
		loadUsers(1);
	},[authtoken, userSearch, userRole]);
 
	const loadUsers = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		dispatch({ type : USER_LIST});
		let url = '';
		if ( userSearch === null && userRole === 'all') {
			url = `${api}/userallocation-list?isDeleted=false&$limit=${_pagination.limit}&$skip=${_pagination.skip}&usertypeId[$gt][]=2`;
		} if (userSearch != null && userRole === 'all') {
			url = `${api}/userallocation-list?isDeleted=false&$limit=${_pagination.limit}&$skip=${_pagination.skip}&usertypeId[$gt][]=2&email[$iLike]=%${userSearch}%`;
			// eslint-disable-next-line eqeqeq
		} if ( userSearch === null && userRole != 'all'){
			url = `${api}/userallocation-list?isDeleted=false&$limit=${_pagination.limit}&$skip=${_pagination.skip}&usertypeId[]=${userRole}`;
			// eslint-disable-next-line eqeqeq
		} if ( userSearch != null && userRole != 'all'){
			url = `${api}/userallocation-list?isDeleted=false&$limit=${_pagination.limit}&$skip=${_pagination.skip}&usertypeId[]=${userRole}&email[$iLike]=%${userSearch}%`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};
    
		axios.request(config)
			.then((response) => {
				if ( response.status === 200) { 
					dispatch({ type : USER_LIST_SUCCESS, payload : response.data, page:pagination.current, pageSize : pagination.pageSize });
				} else { 
					dispatch({ type : USER_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : USER_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
    
	};

	const columns = [
		{
			title : langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex : 'name',
			key : 'name'
		},
		{
			title : langdata && langdata.Email ? langdata.Email : 'Email',
			dataIndex : 'email',
			key : 'email'
		},
		{
			title : langdata && langdata.Phone ? langdata.Phone :  'Phone',
			dataIndex : 'cellPhone',
			key : 'cellPhone'
		},
		{
			title : langdata && langdata.UserType ? langdata.UserType : 'User Type',
			dataIndex : 'usertype',
			key : 'usertype'
		},
		{
			title :  langdata && langdata.Allocations ? langdata.Allocations : 'Allocations',
			dataIndex : 'allocationsList',
			key : 'allocationsList'
		},{
			title : langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key : 'action',
			render: (_, record) => (
				<Space style={{justifyContent:'',display:'flex'}} size="middle">
					{record.usertypeId == 2 || record.usertypeId == 9 || record.usertypeId == 10 || record.usertypeId == 11 || record.usertypeId == 12 ?
						null :
						record.allocationsList === 'No allocation' ? 
							<Button style={{ display : 'contents', color : '#1FA8FF'}} title="Add Allocation"  onClick={() => {navigate(( '/add-allocation'),{ state: { user: record } });}}>{langdata && langdata.AddAllocation ? langdata.AddAllocation : 'Add Allocation'}</Button>
							: 
							<Button style={{ display : 'contents', color : record.usertype === permission || permission === 'CRO' && record.usertype === 'ROU' ? '' : 'orange'}} title="Edit Allocation" disabled = { record.usertype === permission || permission === 'CRO' && record.usertype === 'ROU' ?  true : false} onClick={() => {navigate(( '/edit-allocation'),{ state: { userId: record.id } });}}> {langdata && langdata.EditAllocation ? langdata.EditAllocation : 'Edit Allocation'}</Button>
					}
					{ record.usertype === 'ROU' && record.allocationsList !== 'No allocation' ?
						<>
							| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/allocation-sub/rou'),{ state: { userId : record.id, usertype : 'CRO' } });}}>{langdata && langdata.ViewCROs ? langdata.ViewCROs :  'View CRO\'s'}</Button>
							| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/allocation-sub/npv'),{ state: { userId : record.id, usertype : 'npv' } });}}>{langdata && langdata.ViewNPVs ? langdata.ViewNPVs : 'View NPV\'s'}</Button>
						</>
						:  record.usertype === 'CRO' && record.allocationsList !== 'No allocation' ?
							<>
							| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/allocation-sub/supervisor'),{ state: { userId : record.id, usertype : 'supervisor' } });}}>{langdata && langdata.ViewSupervisor ? langdata.ViewSupervisor : 'View Supervisor'}</Button>
							</>
							:  record.usertype === 'supervisor' && record.allocationsList !== 'No allocation' ? 
								<>
								| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/allocation-sub/supervisor'),{ state: { userId : record.id, usertype : 'linewalker' } });}}>{langdata && langdata.ViewLinewalker ? langdata.ViewLinewalker : 'View Linewalker'}</Button>
								</>
								: record.usertype === 'linewalker' && record.allocationsList !== 'No allocation' ?
									<>
										| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/edit-allocation-pipeline-path'),{ state: { userId : record.id } });}}>{langdata && langdata.EditPipelinePath ? langdata.EditPipelinePath : 'Edit Pipeline Path'}</Button>
										| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(('/linewalkerstable'), { state: { userId : record.id, usertype : 'linewalker', name : record.name } });}}>{langdata && langdata.ViewInspection ? langdata.ViewInspection : 'View Inspection'}</Button>
									</>
									: null }
				</Space>
			)
		}
	];

	const userType = [];
	userType.push({
		label: langdata && langdata.ALL ? langdata.ALL : 'ALL',
		value: 'all'
	});
	// eslint-disable-next-line array-callback-return
	userTypeData && userTypeData.data.map((item) => {
		
		if (permission === 'superadmin' && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		}else if(permission === 'admin' && item.id !== 2 && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		} else if (permission === 'HQO-HSE' && item.id !== 2 && item.id !==12 && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		} else if(permission === 'Pipeline-HO' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		} else if (permission === 'Pipeline-In-Charge' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		} else if (permission === 'Station-In-Charge' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		} else if ( permission === 'ROU' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 6 && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		} else if(permission === 'CRO' && item.id !== 2 && item.id !== 12 && item.id !== 10 && item.id !== 11 && item.id !== 9 && item.id !== 6 && item.id !== 7 && item.id !== 3 && item.id !== 8) {
			userType.push({
				label : item.userType.toUpperCase(),
				value : item.id
			});
		} 
	});

	const AddAllocation = () => {
		navigate('/add-allocation');
	};

	const handleUserSearch = (value) => {
		setUserSearch(value);
	};

	const handleUserRole = (value) => {
		setUserRoll(value);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.AllocationInfo1 ? langdata.AllocationInfo1 : 'All available users based on the current user role are displayed along with their allocation details. \
				New users can be allocated either by using the Add Allocation button in the Actions column of using the Add New button. \
				Existing allocations can be edited using the Edit Allocation button in the Actions column.'}</p>
			<br />
			<p>{langdata && langdata.AllocationInfo2 ? langdata.AllocationInfo2 : 'Users can be searched by providing their email and filter option available to filter users by user type.'}</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}}>

					<Col span={7}>
						<Row>

							<Space>
								<UserOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
					
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.UserAllocation ? langdata.UserAllocation : 'User Allocation'}</Title>
						</Row>
					</Col>
					
					<Col span={17}>
						<Row justify="end">
							<Col >
								<Space>
									<Search
										style={{margin:'5px', marginTop:'2px', verticalAlign:'middle',paddingRight:'10px'}}
										showSearch
										placeholder={langdata && langdata.Searchbyemail ? langdata.Searchbyemail : 'Search by email'}
										onSearch={handleUserSearch}
										enterButton
									/>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyusertype ? langdata.Filterbyusertype : 'Filter by user type'}>
									<Space>
										<Select
											style={{ minWidth: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectUserType ? langdata.SelectUserType : 'Select User Type'}
											optionFilterProp="children"
											defaultValue={userRole}
											options={userType}
											onSelect={handleUserRole}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.AddAllocation ? langdata.AddAllocation : 'Add allocation'}>
									<Space>
										<Button style={{margin:'5px'}} type="primary" onClick = {AddAllocation}><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.UserAllocation ? langdata.UserAllocation : 'User Allocation'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						<Table 
							scroll={{
								x: 900,
							}}
							columns={columns} dataSource={data.users} pagination = { data.users.length > 0 ? data.pagination : 0 } onChange={loadUsers}/>
					</Col>
				</Row>
				<Spin fullscreen spinning={data.pending} />
			</Content>
		</>
	);
}