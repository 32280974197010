/* eslint-disable react/jsx-key */
import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Empty, Row, Col, Select, Typography, Tooltip, Button, Space, Table, Form, Input, Modal, Spin, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined, EditOutlined, EyeOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';

import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { TLP_RULES_LIST, TLP_RULES_LIST_SUCCESS, TLP_RULES_LIST_ERROR }  from './constants';
import { TLP_RULES_DETAILS, TLP_RULES_DETAILS_SUCCESS, TLP_RULES_DETAILS_ERROR } from './constants';
import { SAVE_TLP_RULE, SAVE_TLP_RULE_SUCCESS, SAVE_TLP_RULE_ERROR } from './constants';
import { UPDATE_TLP_RULE, UPDATE_TLP_RULE_SUCCESS, UPDATE_TLP_RULE_ERROR } from './constants';
import { DELETE_TLP_RULE, DELETE_TLP_RULE_SUCCESS, DELETE_TLP_RULE_ERROR } from './constants';

import tlpRulesReducer from './reducer';

import { useFormik } from 'formik';
import axios from 'axios';
import { ToolOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function TLPRules() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata} = useAuth();
	const [regionId, setRegionId] = useState(null);
	const initalState = { rules: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: null, loading: true, error: false };
	const [data, dispatch] = useReducer(tlpRulesReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [showAddRuleModal, setShowAddRuleModal] = useState(false);
	const [showEditRuleModal, setShowEditRuleModal] = useState(false);
	const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);
	const [ruleCategory, setRuleCategory] = useState(0);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const columns = [
		{
			title: langdata && langdata.RuleName ? langdata.RuleName : 'Rule Name',
			dataIndex: 'ruleName',
			key: 'ruleName',
			render: (text) => <span onClick={openEditRuleModal}>{text}</span>,
		},
		{
			title: langdata && langdata.Region ? langdata.Region : 'Region',
			dataIndex: 'regioninfoId',
			key: 'regioninfoId',
			render: (_, { regioninfoId }) => (
				<>
					{regions.map((region) => {
						if (regioninfoId === region.value) {
							return (
								// eslint-disable-next-line react/jsx-key
								<div>
									{region.label}
								</div>
							); 
						} else if(regioninfoId === 0 && region.value === null) {
							return (
								<div>
									{'ALL'}
								</div>
							);
						}
						return true;
					})}
				</>
			)
		},
		{
			title: langdata && langdata.Chainage ? langdata.Chainage : 'Chainage',
			dataIndex: 'pipelineChainage',
			key: 'pipelineChainage',
		},
		{
			title: langdata && langdata.RuleType ? langdata.RuleType : 'Rule Type',
			dataIndex: 'ruleTypeLabel',
			key: 'ruleTypeLabel',
		},
		{
			title: langdata && langdata.LowerLimit ? langdata.LowerLimit : 'Lower Limit',
			dataIndex: 'lowerLimit',
			key: 'lowerLimit',
		},
		{
			title: langdata && langdata.UpperLimit ? langdata.UpperLimit : 'Upper Limit',
			dataIndex: 'upperLimit',
			key: 'upperLimit',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: () => (
				permission === 'superadmin' || permission === 'admin' ? (
					<Space size="middle">
						<a title={langdata && langdata.EditTLPRule ? langdata.EditTLPRule : 'Edit TLP Rule'} onClick={openEditRuleModal}><EditOutlined/></a>
						<a title={langdata && langdata.DeleteTLPRule ? langdata.DeleteTLPRule : 'Delete TLP Rule'} onClick={openDeleteRuleModal}><DeleteOutlined /></a>
					</Space>
				) : (
					<Space size="middle">
						<a title={langdata && langdata.ViewTLPRule ? langdata.ViewTLPRule : 'View TLP Rule'} onClick={openEditRuleModal}><EyeOutlined/></a>
					</Space>
				)
			)
		}
	];

	const openAddRuleModal = () => {
		setShowAddRuleModal(true);
	};

	const closeAddRuleModal = () => {
		setShowAddRuleModal(false);
	};

	const openEditRuleModal = () => {
		setShowEditRuleModal(true);
	};

	const closeEditRuleModal = () => {
		setShowEditRuleModal(false);
	};

	const openDeleteRuleModal = () => {
		setShowDeleteRuleModal(true);
	};

	const deleteRule = () => {
		let id = data.details ? data.details.id : 0;
    
		if (!id || parseInt(id) <= 0) {
			Modal.warning({ title: langdata && langdata.ThedeviceidisinvalidPleasetryagain ? langdata.ThedeviceidisinvalidPleasetryagain : 'The device id is invalid. Please try again.' });
		} else {
			dispatch( { type: DELETE_TLP_RULE });

			let config = {
				method: 'delete',
				maxBodyLength: Infinity,
				url: `${api}/tlprule/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {}
			};
      
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: DELETE_TLP_RULE_SUCCESS, payload:response});
						Modal.success({ title:  langdata && langdata.Ruledeletedsuccessfully ? langdata.Ruledeletedsuccessfully : 'Rule deleted successfully.' });
						closeDeleteRuleModal(true);
						loadTLPRules({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: DELETE_TLP_RULE_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletodeletetherule ? langdata.Unabletodeletetherule : 'Unable to delete the rule.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: DELETE_TLP_RULE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	};

	const closeDeleteRuleModal = () => {
		setShowDeleteRuleModal(false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 43);
	}, []);

	useEffect(() => {
		getRegion(authtoken);
		loadTLPs();
		loadTLPRules(1);
	}, [ruleCategory, regionId]);

	const loadTLPRules = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		dispatch({ type: TLP_RULES_LIST });

		let param = '';
		if (regionId && regionId !== null) {
			param = `&regioninfoId=${regionId}`;
		}

		let url =  `${api}/tlprule?${param}` ;
		if(ruleCategory !== 0) {
			url =  `${api}/tlprule?ruleCategory=${ruleCategory}${param}`;
		}

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: TLP_RULES_LIST_SUCCESS, payload: response.data , page:pagination.current});
			} else {
				dispatch( { type: TLP_RULES_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLP_RULES_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	// Load TLP rule details starts
	const loadDetails = ((id) =>  {
		dispatch({ type: TLP_RULES_DETAILS });
    
		let details = null;

		dispatch({ type: TLP_RULES_DETAILS });

		if (data && data.rules) {
			data.rules && data.rules.map((item) => {
				if (item.id === id) {
					details = item;
				}

				return true;
			});
      
			dispatch({ type: TLP_RULES_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: TLP_RULES_DETAILS_ERROR });
		}
	});
	// Load TLP rule details ends

	let regions = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}];
	let filteredRegions = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL :  'ALL'}];
	let tlps = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL :  'ALL'}];

	regiondata && regiondata.map((region) => {
		regions.push({ value: region.id, label: region.regionName.toUpperCase()});
		filteredRegions.push({ value: region.id, label: region.regionName.toUpperCase()});

		return true;
	});

	const handleChangeRegion = (e) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`, e);
		setRegionId(e);
	};

	data && data.tlps && data.tlps.map((tlp) => {
		return tlps.push({ value: tlp.id, label: 'CH - ' + tlp.pipelineChainage + ' (TLP)'});
	});

	const loadTLPs = () => {
		dispatch({ type: TLPS_LIST });

		let url = `${api}/smarttlp?$limit=10000&$skip=0&$sort[pipelineChainage]=1`;

		if (regionId && regionId !== null) {
			url = `${api}/smarttlp?$limit=10000&$skip=0&regioninfoId=${regionId}&$sort[pipelineChainage]=1`;
		}

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: TLPS_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: TLPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const saveRuleFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			ruleName: '',
			deviceId: 0,
			ruleCategory: 1,
			lowerLimit: 0,
			upperLimit: 0
		},
		onSubmit: (values) => {
			const ruleName = values.ruleName ? values.ruleName : null;
			const regioninfoId = values.regioninfoId ? values.regioninfoId : null;
			const pipelineChainageId = values.pipelineChainageId ? values.pipelineChainageId : null;
			const ruleCategory = values.ruleCategory ? values.ruleCategory : 1;
			const ruleType = values.ruleType ? values.ruleType : 1;
			const lowerLimit = values.lowerLimit ? values.lowerLimit : 0;
			const upperLimit = values.upperLimit ? values.upperLimit : 0;
      
			let pipelineChainage = null;
			let deviceId = null;

			if (!ruleName || ruleName == null) {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalidrulename ? langdata.Pleaseprovideavalidrulename : 'Please provide a valid rule name.' });
				return false;
			}

			// if (!regioninfoId || parseInt(regioninfoId) <= 0) {
			// 	Modal.warning({ title: 'Please select a valid region.' });
			// 	return false;
			// }

			// if (!pipelineChainageId || parseInt(pipelineChainageId) <= 0) {
			// 	Modal.warning({ title: 'Please select a valid pipeline chainage.' });
			// 	return false;
			// } 
			else {
				// Find the device id from the selected TLP
				let selectedTLP = data.tlps.find((tlp) => {
					return tlp.id === pipelineChainageId;
				});
        
				if (selectedTLP) {
					pipelineChainage = selectedTLP ? selectedTLP.pipelineChainageName : null;
					deviceId = selectedTLP ? selectedTLP.deviceId : null;
				}
			}

			if (!ruleCategory || parseInt(ruleCategory) <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseselectavalidrulecategory ? langdata.Pleaseselectavalidrulecategory : 'Please select a valid rule category.' });
				return false;
			}

			if (!ruleType || parseInt(ruleType) <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseselectavalidruletype ? langdata.Pleaseselectavalidruletype : 'Please select a valid rule type.' });
				return false;
			}

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/tlprule`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					ruleName: ruleName,
					regioninfoId: regioninfoId,
					pipelineChainageId: pipelineChainageId,
					pipelineChainage: pipelineChainage,
					deviceId: deviceId,
					ruleCategory: ruleCategory,
					ruleType: ruleType,
					lowerLimit: lowerLimit,
					upperLimit: upperLimit,
					pipelineId: localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null
				}
			};
      
			dispatch( { type: SAVE_TLP_RULE });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: SAVE_TLP_RULE_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.TLPrulesavedsuccessfully ? langdata.TLPrulesavedsuccessfully : 'TLP rule saved successfully.' });
						closeAddRuleModal();
						loadTLPRules({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: SAVE_TLP_RULE_ERROR });
						Modal.warning({ title: langdata && langdata.UnabletosavetheTLPrule ? langdata.UnabletosavetheTLPrule : 'Unable to save the TLP rule.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: SAVE_TLP_RULE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} 
				});
		}
	});

	const updateRuleFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			ruleName: data.details ? data.details.ruleName : '',
			regioninfoId: data.details ? data.details.regioninfoId === 0 ? null : data.details.regioninfoId : 0,
			pipelineChainageId: data.details ? data.details.pipelineChainageId === 0 ? null :  data.details.pipelineChainageId : 0,
			pipelineChainage: data.details ? data.details.pipelineChainage : '',
			deviceId: data.details ? data.details.deviceId : null,
			ruleCategory: data.details ? data.details.ruleCategory : 1,
			ruleType: data.details ? data.details.ruleType : 1,
			lowerLimit: data.details ? data.details.lowerLimit : 0,
			upperLimit: data.details ? data.details.upperLimit : 0
		},
		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const ruleName = values.ruleName ? values.ruleName : null;
			const regioninfoId = values.regioninfoId ? values.regioninfoId : null;
			const pipelineChainageId = values.pipelineChainageId ? values.pipelineChainageId : null;
			const ruleCategory = values.ruleCategory ? values.ruleCategory : 1;
			const ruleType = values.ruleType ? values.ruleType : 0;
			const lowerLimit = values.lowerLimit ? values.lowerLimit : 0;
			const upperLimit = values.upperLimit ? values.upperLimit : 0;
      
			let pipelineChainage = null;
			let deviceId = null;

			if (!ruleName || ruleName == null) {
				Modal.warning({ title: langdata && langdata.Pleaseprovideavalidrulename ? langdata.Pleaseprovideavalidrulename :  'Please provide a valid rule name.' });
				return false;
			}

			// if (!regioninfoId || parseInt(regioninfoId) <= 0) {
			// 	Modal.warning({ title: 'Please select a valid region.' });
			// 	return false;
			// }

			// if (!pipelineChainageId || parseInt(pipelineChainageId) <= 0) {
			// 	Modal.warning({ title: 'Please select a valid pipeline chainage.' });
			// 	return false;
			// } 
			else {
				// Find the device id from the selected TLP
				let selectedTLP = data.tlps.find((tlp) => {
					return tlp.id === pipelineChainageId;
				});
        
				if (selectedTLP) {
					pipelineChainage = selectedTLP ? selectedTLP.pipelineChainageName : null;
					deviceId = selectedTLP ? selectedTLP.deviceId : null;
				}
			}

			if (!ruleCategory || parseInt(ruleCategory) <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseselectavalidrulecategory ? langdata.Pleaseselectavalidrulecategory : 'Please select a valid rule category.' });
				return false;
			}

			if (!ruleType || parseInt(ruleType) <= 0) {
				Modal.warning({ title: langdata && langdata.Pleaseselectavalidruletype ? langdata.Pleaseselectavalidruletype :  'Please select a valid rule type.' });
				return false;
			}

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/tlprule/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					ruleName: ruleName,
					regioninfoId: regioninfoId,
					pipelineChainageId: pipelineChainageId,
					pipelineChainage: pipelineChainage,
					deviceId: deviceId,
					ruleCategory: ruleCategory,
					ruleType: ruleType,
					lowerLimit: lowerLimit,
					upperLimit: upperLimit
				}
			};

			dispatch( { type: UPDATE_TLP_RULE });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_TLP_RULE_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.TLPruleupdatedsuccessfully ? langdata.TLPruleupdatedsuccessfully : 'TLP rule updated successfully.' });
						closeEditRuleModal();
						loadTLPRules({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: UPDATE_TLP_RULE_ERROR });
						Modal.warning({ title: langdata && langdata.UnabletoupdatetheTLPrule ? langdata.UnabletoupdatetheTLPrule : 'Unable to update the TLP rule.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_TLP_RULE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}  
				});
		}
	});

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleSetRuleCategory = (value) => {
		setRuleCategory(value);
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.TlpRuleEngineInfo1 ? langdata.TlpRuleEngineInfo1 : 'The rules that have been added for the Smart TLP devices are displayed in this module.'}</p>
			{ permission === 'superadmin' || permission === 'admin' ?
				<>
					< br/>
					<p>{langdata && langdata.TlpRuleEngineInfo2 ? langdata.TlpRuleEngineInfo2 : 'New rules can be added using the Add New button.'}</p> </> : null }
		</Card>
	);

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<ToolOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.TLPRules ? langdata.TLPRules : 'TLP Rules'}</Title>
						</Row>
					</Col>
				
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Space>
									<Tooltip placement="bottom" title={langdata && langdata.Filterbyrulecategory ? langdata.Filterbyrulecategory : 'Filter by rule category'}>
										<Select
											name="ruleType"
											style={{ width: '200px',margin:'5px'}}
											showSearch
											placeholder={langdata && langdata.Selectrulecategory ? langdata.Selectrulecategory : 'Select rule category'}
											optionFilterProp="children"
											// onChange={(value) => saveRuleFormik.setFieldValue('ruleCategory', value)}
											onChange={handleSetRuleCategory}
											options={[
												{ value: 0, label: langdata && langdata.ALL ? langdata.ALL : 'ALL' },
												{ value: 2, label: langdata && langdata.ANOMALYFILTER ? langdata.ANOMALYFILTER : 'ANOMALY FILTER' },
												{ value: 3, label: langdata && langdata.DEVICETAMPERALERT ? langdata.DEVICETAMPERALERT : 'DEVICE TAMPER ALERT' },
												{ value: 1, label: langdata && langdata.NOTIFICATIONS ? langdata.NOTIFICATIONS : 'NOTIFICATIONS' },
											]}
											defaultValue={0}
										/>
									</Tooltip>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px',margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={'ALL'}
											value={regionId}
											options={regions}
											onSelect={handleChangeRegion}
										/>
									</Space>
								</Tooltip>
								<Space>
									<Space>&nbsp;</Space>
									{ permission === 'superadmin' || permission === 'admin' ? <Button style={{margin:'5px'}} type="primary" onClick={openAddRuleModal} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button> :  null }
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.TLPRules ? langdata.TLPRules : 'TLP Rules'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data && data.rules.length > 0 ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns = { columns } 
								dataSource = { data.rules } 
								pagination = { data.rules.length > 0 ? data.pagination : 0 }
								onRow = { (record) => {
									return {
										onClick: () => { 
											loadDetails(record.id);
										}
									};
								}} />
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>
      
			{/* Add TLP Rule Starts */}
			<Modal
				title={langdata && langdata.AddRule ? langdata.AddRule : 'Add Rule'}
				centered
				open={showAddRuleModal}
				onOk={{closeAddRuleModal}}
				onCancel={closeAddRuleModal}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeAddRuleModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={saveRuleFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={saveRuleFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.RuleName ? langdata.RuleName : 'Rule Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenterrulename ? langdata.Pleaseenterrulename :'Please enter rule name' }]} required>
								<Input name="ruleName" placeholder={langdata && langdata.Pleaseenterrulename ? langdata.Pleaseenterrulename : 'Please enter rule name'} onChange={saveRuleFormik.handleChange} value={saveRuleFormik.values.ruleName} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.RuleCategory ? langdata.RuleCategory : 'Rule Category'} rules={[{ required: true, message: langdata && langdata.Selectrulecategory ? langdata.Selectrulecategory :  'Please select the rule category' }]} required>
								<Select
									name="ruleType"
									showSearch
									placeholder={langdata && langdata.Selectrulecategory ? langdata.Selectrulecategory : 'Select rule category'}
									optionFilterProp="children"
									onChange={(value) => saveRuleFormik.setFieldValue('ruleCategory', value)}
									options={[
										{ value: 2, label: langdata && langdata.ANOMALYFILTER ? langdata.ANOMALYFILTER :  'ANOMALY FILTER' },
										{ value: 3, label: langdata && langdata.DEVICETAMPERALERT ? langdata.DEVICETAMPERALERT : 'DEVICE TAMPER ALERT' },
										{ value: 1, label: langdata && langdata.NOTIFICATIONS ? langdata.NOTIFICATIONS : 'NOTIFICATIONS' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Region ? langdata.Region : 'Region'} rules={[{ required: true, message: langdata && langdata.Selectregion ? langdata.Selectregion : 'Please select the region' }]}>
								<Select
									name="regioninfoId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectregion ? langdata.Selectregion : 'Select region'}
									optionFilterProp="children"
									onChange={(value) => saveRuleFormik.setFieldValue('regioninfoId', value)}
									options={ filteredRegions }
									defaultValue={null}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ChainageName ? langdata.ChainageName :'Chainage Name'} rules={[{ required: true, message: langdata && langdata.Selectchainagename ? langdata.Selectchainagename : 'Please select chainage name' }]}>
								<Select
									name="pipelineChainageId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectchainagename ? langdata.Selectchainagename : 'Pipeline chainage'}
									optionFilterProp="children"
									onChange={(value) => saveRuleFormik.setFieldValue('pipelineChainageId', value)}
									options={tlps}
									defaultValue={null}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						{saveRuleFormik.values.ruleCategory == 1  || saveRuleFormik.values.ruleCategory == 2 ?
							<Col span={12}>
								<Form.Item label={langdata && langdata.RuleType ? langdata.RuleType : 'Rule Type'} rules={[{ required: true, message: langdata && langdata.Selectruletype ? langdata.Selectruletype : 'Please select the rule type' }]} required>
									<Select
										name="ruleType"
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Selectruletype ? langdata.Selectruletype : 'Select rule type'}
										optionFilterProp="children"
										onChange={(value) => saveRuleFormik.setFieldValue('ruleType', value)}
										options={[
											{ value: 1, label: langdata && langdata.CASINGACPICKUP ? langdata.CASINGACPICKUP :  'CASING AC PICKUP' },
											{ value: 2, label: langdata && langdata.ACPICKUP ? langdata.ACPICKUP :  'AC PICKUP' },
											{ value: 3, label: langdata && langdata.CSPDC ? langdata.CSPDC :  'CSP DC' },
											{ value: 4, label: langdata && langdata.PSPDC ? langdata.PSPDC :  'PSP DC' },
											{ value: 5, label: langdata && langdata.BATTERY ? langdata.BATTERY :  'BATTERY' },
											{ value: 6, label: langdata && langdata.TEMPERATURE ? langdata.TEMPERATURE :  'TEMPERATURE' },
											{ value: 7, label:  langdata && langdata.CURRENT ? langdata.CURRENT : 'CURRENT' }
										]}
									/>
								</Form.Item>
							</Col>
							: null }
						{saveRuleFormik.values.ruleCategory == 1 ?
							<Col span={12}>
								<Row gutter={16}>
									<Col span={12}>
										<Form.Item label={langdata && langdata.LowerLimit ? langdata.LowerLimit : 'Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit' }]} required>
											<Input name="lowerLimit" type="number" placeholder={langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit'} onChange={saveRuleFormik.handleChange} value={saveRuleFormik.values.lowerLimit} />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label={langdata && langdata.UpperLimit ? langdata.UpperLimit : 'Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit :  'Please enter the upper limit' }]} required>
											<Input name="upperLimit" type="number" placeholder={langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit : 'Please enter the upper limit'} onChange={saveRuleFormik.handleChange} value={saveRuleFormik.values.upperLimit} />
										</Form.Item>
									</Col>
								</Row>
							</Col>
							: null }
					</Row>
				</Form>
			</Modal>
			{/* Add TLP Rule Ends */}

			{/* Edit TLP Rule Starts */}
			<Modal
				title={langdata && langdata.EditRule ? langdata.EditRule : 'Edit Rule'}
				centered
				open={showEditRuleModal}
				onOk={{closeEditRuleModal}}
				onCancel={closeEditRuleModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeEditRuleModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={updateRuleFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={updateRuleFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.RuleName ? langdata.RuleName : 'Rule Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenterrulename ? langdata.Pleaseenterrulename : 'Please enter rule name' }]} required>
								<Input name="ruleName" placeholder={langdata && langdata.Pleaseenterrulename ? langdata.Pleaseenterrulename : 'Please enter rule name'} onChange={updateRuleFormik.handleChange} value={updateRuleFormik.values.ruleName} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.RuleCategory ? langdata.RuleCategory : 'Rule Category'} rules={[{ required: true, message: langdata && langdata.Selectrulecategory ? langdata.Selectrulecategory : 'Please select the rule category' }]} required>
								<Select
									name="ruleCategory"
									showSearch
									placeholder={langdata && langdata.Selectrulecategory ? langdata.Selectrulecategory : 'Select rule category'}
									optionFilterProp="children"
									initialValue= {updateRuleFormik.values.ruleCategory}
									value= {updateRuleFormik.values.ruleCategory}
									onChange={(value) => updateRuleFormik.setFieldValue('ruleCategory', value)}
									options={[
										{ value: 2, label: langdata && langdata.ANOMALYFILTER ? langdata.ANOMALYFILTER :  'ANOMALY FILTER' },
										{ value: 3, label: langdata && langdata.DEVICETAMPERALERT ? langdata.DEVICETAMPERALERT : 'DEVICE TAMPER ALERT' },
										{ value: 1, label: langdata && langdata.NOTIFICATIONS ? langdata.NOTIFICATIONS : 'NOTIFICATIONS' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Region ? langdata.Region : 'Region'} rules={[{ required: true, message: langdata && langdata.Selectregion ? langdata.Selectregion : 'Please select the region' }]} >
								<Select
									name="regioninfoId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectregion ? langdata.Selectregion : 'Select region'}
									optionFilterProp="children"
									initialValue= {updateRuleFormik.values.regioninfoId}
									value= {updateRuleFormik.values.regioninfoId}
									onChange={(value) => updateRuleFormik.setFieldValue('regioninfoId', value)}
									options={ filteredRegions }
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ChainageName ? langdata.ChainageName : 'Chainage Name'} rules={[{ required: true, message: langdata && langdata.Selectchainagename ? langdata.Selectchainagename : 'Please select chainage name' }]} >
								<Select
									name="pipelineChainageId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectchainagename ? langdata.Selectchainagename : 'Pipeline chainage'}
									optionFilterProp="children"
									initialValue= {updateRuleFormik.values.pipelineChainageId}
									value= {updateRuleFormik.values.pipelineChainageId}
									onChange={(value) => updateRuleFormik.setFieldValue('pipelineChainageId', value)}
									options={tlps}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						{updateRuleFormik.values.ruleCategory  == 1 ||  updateRuleFormik.values.ruleCategory  == 2 ?
							<Col span={12}>
								<Form.Item label={langdata && langdata.RuleType ? langdata.RuleType : 'Rule Type'} rules={[{ required: true, message: langdata && langdata.Selectruletype ? langdata.Selectruletype : 'Please select the rule type' }]} required>
									<Select
										name="ruleType"
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Selectruletype ? langdata.Selectruletype : 'Select rule type'}
										optionFilterProp="children"
										initialValue= {updateRuleFormik.values.ruleType}
										value= {updateRuleFormik.values.ruleType}
										onChange={(value) => updateRuleFormik.setFieldValue('ruleType', value)}
										options={[
											{ value: 1, label: langdata && langdata.CASINGACPICKUP ? langdata.CASINGACPICKUP : 'CASING AC PICKUP' },
											{ value: 2, label: langdata && langdata.ACPICKUP ? langdata.ACPICKUP : 'AC PICKUP' },
											{ value: 3, label: langdata && langdata.CSPDC ? langdata.CSPDC : 'CSP DC' },
											{ value: 4, label: langdata && langdata.PSPDC ? langdata.PSPDC : 'PSP DC' },
											{ value: 5, label: langdata && langdata.BATTERY ? langdata.BATTERY : 'BATTERY' },
											{ value: 6, label: langdata && langdata.TEMPERATURE ? langdata.TEMPERATURE : 'TEMPERATURE' },
											{ value: 7, label: langdata && langdata.CURRENT ? langdata.CURRENT : 'CURRENT' }
										]}
									/>
								</Form.Item>
							</Col>
							: null }
						{updateRuleFormik.values.ruleCategory  == 1 ?
							<Col span={12}>
								<Row gutter={16}>
									<Col span={12}>
										<Form.Item label={langdata && langdata.LowerLimit ? langdata.LowerLimit : 'Lower Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit' }]} required>
											<Input name="lowerLimit" type="number" placeholder={langdata && langdata.Pleaseenterthelowerlimit ? langdata.Pleaseenterthelowerlimit : 'Please enter the lower limit'} onChange={updateRuleFormik.handleChange} value={updateRuleFormik.values.lowerLimit} />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label={langdata && langdata.UpperLimit ? langdata.UpperLimit :'Upper Limit'} rules={[{ required: true, message: langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit : 'Please enter the upper limit' }]} required>
											<Input name="upperLimit" type="number" placeholder={langdata && langdata.Pleaseentertheupperlimit ? langdata.Pleaseentertheupperlimit : 'Please enter the upper limit'} onChange={updateRuleFormik.handleChange} value={updateRuleFormik.values.upperLimit} />
										</Form.Item>
									</Col>
								</Row>
							</Col>
							: null }
					</Row>
					
				</Form>
			</Modal>
			{/* Edit TLP Rule Ends */}

			{/* Delete Modal Starts */}
			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteRuleModal} onOk={deleteRule} onCancel={closeDeleteRuleModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeDeleteRuleModal}>
							{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}
						</Button>
						<Button onClick={deleteRule} type="primary">
							{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}
						</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{langdata && langdata.AreyousureyouwanttodeletethisTLPrule ? langdata.AreyousureyouwanttodeletethisTLPrule : 'Are you sure you want to delete this TLP rule?'}</Col>
				</Row>
			</Modal>
			{/* Delete Modal Ends */}

			<Spin spinning={data.loading} fullscreen />
		</>
	);
}
