import React, { useEffect, useReducer, useState } from 'react';

import { Avatar, Popover, Card, Row, Col, Select, Typography, Button, Space, Modal, Checkbox, Spin, Divider, message} from 'antd';
import {Tooltip as StyledTooltip} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Icon } from 'leaflet';
import '../../../App.css';
import 'leaflet/dist/leaflet.css';
import { Circle, LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useAuth } from '../../Context';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import trackpig from './reducer';
import { PIG_LIST, PIG_LIST_ERROR, PIG_LIST_SUCCESS, PIG_LOCATION, PIG_LOCATION_ERROR, PIG_LOCATION_SUCCESS, PIG_PID_LIST_SUCCESS, PIG_UPDATE, PIG_UPDATE_ERROR, PIG_UPDATE_SUCCESS } from './constants';
import { InfoOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import GoogleMap from '../../Map/map';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';


const { Title } = Typography;


// const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};
  
  
const markericon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../../images/default1.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});
const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});
const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});

const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});
  


export default function TrackPig() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, handleGetMapdata, mapdata, optionalpipedata,handleOptionalPipelinedata, getPids, pidsData, getUserAllocation ,getUserAllocationData} = useAuth();
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const [regionId, setRegionId] = useState(null);
	const [checked, setChecked] = useState([]);
	const [openPidsModal, setPidsModal] = useState(null);
	const navigate = useNavigate();
	const initalState = {pig_data:[],pig_location:[],pid_data:[], details: null, pending: false,  loading: false, error: false };
	const [pig_data, dispatch] = useReducer(trackpig, initalState);

	const pipeline = [];
	const center=[];
	const odmeter=[];
	const optionalpath = [];
	const markers = [];
	// const points = [];
	// const pidsmarker=[];
	// const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [pigdetails, setPigData] = useState({lat:'', long:'', id:''});
	const [pigmodalopen, setPigModal] = useState(false);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.PigTrackcontent1 ? langdata.PigTrackcontent1:'PIGS used for pipeline maintenance and inspection operations can be tracked by enabling the tracker for the particular PIDs.'}</p>
			<br/>
			<p>{langdata && langdata.PigTrackcontent2 ? langdata.PigTrackcontent2:'On identification, the location of the PIG will be displayed on the map for each PIG enabled pids. PIG enabled pids can be identified on the map by a circular marker highlights around the pids marker.'}</p>
			<br/>
		</Card>
	);

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	});
  
	optionalpipedata &&  optionalpipedata.map((item)=>{

		optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	});

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.CH ? langdata.CH :'CH'}`
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.WB ? langdata.WB :'WB'}`
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TLP ? langdata.TLP :'TLP'}`
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.KMP ? langdata.KMP :'KMP'}`
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TP ? langdata.TP :'TP'}`
			},);
		}


	});


	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		if(map_type && map_type === 'leaflet'){
			handleGetMapdata(authtoken,regionId,startCh,endCh);
			handleOptionalPipelinedata(authtoken,pipelineId,regionId,startCh,endCh);
		}

		getPigdata();
		getPids(authtoken);

	},[authtoken, regionId, allocationId, checked]);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 31);
	}, []);

	useEffect(() => {
		// if(pig_data.pig_data.length > 0){
		// 	getPigLocation(pig_data.pig_data);
		// }
		if(pig_data.pig_data.length > 0){
			getPigLocation(pig_data.pig_data);
			const interval = setInterval(() => {
				getPigLocation(pig_data.pig_data);
				// navigate(0);
			}, refreshTime ); 
			return () => clearInterval(interval);
		}
	},[pig_data.pig_data]);



	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL :'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				id:item.value,
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
		
	}


	const handleSetregion = (e, options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	const getPigdata = () =>{

		dispatch({ type : PIG_LIST});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pigtracking?$limit=1000`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : PIG_LIST_SUCCESS, payload : response.data.data});
					dispatch({ type : PIG_PID_LIST_SUCCESS, payload : response.data.data});
          
				}else {
					dispatch({ type : PIG_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : PIG_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const getPigLocation = (response_data) =>{
		const regionparams = `&regioninfoId=${regionId}`;

		dispatch({ type : PIG_LOCATION});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/piglocation?$limit=1000${regionId && regionId ? regionparams:''}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : PIG_LOCATION_SUCCESS, payload : {payload:response.data.data, pigenabled:response_data}});
				}else {
					dispatch({ type : PIG_LOCATION_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : PIG_LOCATION_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const showPidsModal = () => {
		setPidsModal(true);
	};

	const closeshowPidsModal = () => {
		setPidsModal(false);
	};

	const handleUpdatePigData = () =>{
		dispatch({type:PIG_UPDATE});
		setPidsModal(false);

		let config = {
			method: 'POST',
			maxBodyLength: Infinity,
			url: `${api}/pigtrackingbulkupdate`,
			timeout: extendTimeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${authtoken}`
			},
      
			data:JSON.stringify({
				ids:checked && checked.length > 0 ? checked : undefined,
				permIds:pig_data.pid_data
			})
		};

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch( { type: PIG_UPDATE_SUCCESS, payload: response.data.data });
					Modal.success({ title: `${langdata && langdata.PIGtrackingupdatedsuccessfully ? langdata.PIGtrackingupdatedsuccessfully :'PIG tracking updated successfully.'}` });
					getPigdata();
					setPidsModal(false);
					getPigLocation();
				}
			})
			.catch((error) => {
				dispatch({ type: PIG_UPDATE_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});};

	const onChange = (checkedValues) => {
		setChecked(checkedValues);
	};

	const handlePigLocation = (lat,long,id) =>{

		setPigData({lat:lat, long:long, id:id});
		setPigModal(true);

	};

	const handleClosePigModal = () =>{
		setPigModal(false);

	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			<Content>
				<Row
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>
					<Col span={12}>
						<Row>
							<Space>
								<FundProjectionScreenOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>

							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.MyPIG ? langdata.MyPIG :'My PIG'}</Title>
						</Row>
					</Col>

					<Col style={{justifyContent:'right', display:'flex'}} span={12} >
						<Row style={{alignItems:'center', textAlign: 'left'}}>
							<StyledTooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion :'Filter by region'}>
								<Space>
									<Select Item
										style={{ minWidth: '220px', maxWidth:'220px', margin:'5px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion :'Select Region'}
										optionFilterProp="children"
										value={regionId}
										// onSearch={onSearch}
										// filterOption={filterOption}
										// value={regionId}
										options={regions}
										onSelect={(e, options)=>handleSetregion(e, options)}
									/>
								</Space>
							</StyledTooltip>
							<Space>&nbsp;&nbsp;</Space>
							<Space>
								<StyledTooltip placement="bottom" title={langdata && langdata.PIGconfiguration ? langdata.PIGconfiguration :'PIG configuration'}>
									<Button style={{margin:'5px'}} type='primary' onClick={showPidsModal}>{langdata && langdata.Configuration ? langdata.Configuration :'Configuration'}</Button>
								</StyledTooltip>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
							<Popover title={langdata && langdata.MyPIG ? langdata.MyPIG :'My PIG'} content={infoContent} trigger="click">
								<Avatar size="small"><InfoOutlined /></Avatar>
							</Popover>
							<Space>&nbsp;&nbsp;</Space>
							{pig_data && pig_data.pig_data.length > 0 ?
								<>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Typography style={{color:'crimson'}}>
											{langdata && langdata.PIGenabled ? langdata.PIGenabled :'PIG enabled'}
										</Typography>
									</Space>
								</> : null }
						</Row>
					</Col>             
				</Row>



				{map_type && map_type !== 'leaflet' ?

					<GoogleMap pigData = {pig_data.pig_location} regionId={regionId} startCh={startCh} endCh={endCh} pigPids={pig_data.pig_data} onClick={handlePigLocation} /> :

		

					mapdata && mapdata ? 

						<div className="App" style={{textAlign: 'left', margin : '10px'}}>
							<MapContainer center={center} zoom={14} scrollWheelZoom={false}>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

								{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

								<LayersControl position="topright" >

									<MarkerClusterGroup>
										{allPidsDatas && allPidsDatas.map((marker,index)=>
											<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
												<Popup>
													{marker.pop}
												</Popup>										 

											</Marker>

										)}
									</MarkerClusterGroup>
									{allPidsDatas && allPidsDatas.map((marker)=>
										pig_data && pig_data.pig_data.map((innermarker)=>{
											if(innermarker==marker.id){
												return(

													<Circle
														key={`allPidsDatas-${marker.id}`}
														center={marker.geocode}
														pathOptions={{ fillColor: 'red' }}
														radius={300}
														stroke={true}
													/>
												);
											}
										})	  	    
									)}

									<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP :'KMP'}>
										<MarkerClusterGroup>

											{KMP && KMP.map((marker,index)=>
												<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

    
									<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB :'WB'}>
										<MarkerClusterGroup>

											{WB && WB.map((marker,index)=>
												<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>




									<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP :'TP'}>
										<MarkerClusterGroup>

											{Turn && Turn.map((marker,index)=>
												<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

  
									<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP :'TLP'}>
										<MarkerClusterGroup>

											{TLP && TLP.map((marker,index)=>
												<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>


									{/* <LayersControl.Overlay  name="POINTS">
									<MarkerClusterGroup>

										{points && points.map((marker,index)=>
											<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
												<Popup>
													{marker.pop}
												</Popup>
												<Tooltip>{marker.pop}</Tooltip>
											</Marker>

										)}       
									</MarkerClusterGroup>

								</LayersControl.Overlay> */}



									<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH :'CH'}>
										<MarkerClusterGroup>
											{markers && markers.map((marker,index)=>
												<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}

										</MarkerClusterGroup>
									</LayersControl.Overlay>

									{pig_data.pig_data.length > 0 ? 
										<MarkerClusterGroup>

											{pig_data.pig_location && pig_data.pig_location.map((marker,index)=>
											// {pig_data.pig_data.map((inneritem)=>{

											//   console.log("data",inneritem,marker.pidsinfoId);
											//   if(inneritem==marker.pidsinfoId){

												<Marker key={`pig-${index}`} position={marker.geocode} icon={markericon}>
													<Popup>
														{langdata && langdata.Id ? langdata.Id :'Id'}:{' '}{marker.id}<br/>
														{langdata && langdata.Latitude ? langdata.Latitude :'Latitude'}:{' '}{marker.Latitude}<br/>
														{langdata && langdata.Longitude ? langdata.Longitude :'Longitude'}:{' '}{marker.Longitude}<br/>
													</Popup>
													<Tooltip>{langdata && langdata.Location ? langdata.Location :'Location'}:{' '}[{marker.Latitude}{', '}{marker.Longitude}]</Tooltip>
												</Marker>

												// }
												//       })}

    

											)}       
										</MarkerClusterGroup>:null
									}

       
									{/* <LayersControl.Overlay  checked name="LINE">
									<Polyline pathOptions={linecolor} weight={7} positions={line} />
								</LayersControl.Overlay> */}

      
									<LayersControl.Overlay  checked name={langdata && langdata.Pipeline ? langdata.Pipeline :'Pipeline'}>
										<MarkerClusterGroup>
											<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
												{tempMapArray && Object.values(tempMapArray).map((item) => {
													let pipeline = [];
													item.map((data) => {
														pipeline.push({lat : data.latitude,lng : data.longitude});
													});
													return (
														<>
															<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
														</>
													);
												})}
											</Pane>
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder :'Pipeline Border'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let pipelineBorder = [];
												item.map((data) => {
													pipelineBorder.push({lat : data.latitude,lng : data.longitude});
												});
												return (
													<>
														<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber :'Fiber'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let odmeter = [];
												item.map((data) => {
													odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
												});
												return (
													<>
														<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>


									<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath :'Optional Path'}>
										<MarkerClusterGroup>
											{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
												let optionalpath = [];
												item.map((data) => {
													optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
												});
												return (
													<>
														<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>

								</LayersControl>
							</MapContainer>

						</div>  : null
				}


				{/* Fetch and Download Modal Starts */}
				<Modal
					title={langdata && langdata.PIGTracking ? langdata.PIGTracking :'PIG Tracking'}
					centered
					destroyOnClose={true}
					open={openPidsModal}
					onOk={{closeshowPidsModal}}
					onCancel={closeshowPidsModal}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeshowPidsModal}>
								{langdata && langdata.CANCEL ? langdata.CANCEL :'CANCEL'}
							</Button>
							<Button key="submit" type="primary" onClick={handleUpdatePigData}>
								{langdata && langdata.SAVE ? langdata.SAVE :'SAVE'}
							</Button>
						</Space>
					]}
					width={540}
				>
					<Row gutter={16}>
						<Col span={24}>
							<Typography>
								{langdata && langdata.PidsInfo ? langdata.PidsInfo :'Pids Info'}:
							</Typography>
						</Col>
					</Row>

					<>
						<Row gutter={16} style={{
							backgroundColor: '#d3d3d3',
							padding: '5px',
							marginTop: '10px'
						}}>
							<Checkbox.Group
								style={{
									width: '100%',
								}}
								onChange={onChange}
								defaultValue={pig_data.pig_data}
							>
								{ pidsData && pidsData ?
									pidsData && pidsData.map((pid)=>(

										<Checkbox key={pid.id} style={{padding:'10px'}} value={pid.id}>{pid.name.toUpperCase()}</Checkbox>
									))
									:
									<Col span={24}>
										<Typography>{langdata && langdata.Nopidsfound ? langdata.Nopidsfound :'No pids found.'}</Typography>
									</Col>}
							</Checkbox.Group>
						</Row>
						<br/>
					</>
          
  
				</Modal>


				{/* Model Starts */}
				<Modal
					open={pigmodalopen}
					// title="Lin Details"
					// onOk={handleModelOk}
					onCancel={handleClosePigModal}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Button type="primary" onClick={handleClosePigModal}>
							{langdata && langdata.Close ? langdata.Close :'Close'}
						</Button>,
						// <Button type="primary" onClick={()=>handleModelOk(data.realtime_events_details.id)}>
						//   More info
						// </Button>,
						// eslint-disable-next-line react/jsx-key
						// 				<Button type="primary" onClick={() => {navigate(( '/eventDetails'),{ state: { data: data.realtime_events_details, path: 'realtime-events' } });}}>
						//   More info
						// 				</Button>,
					]}
				>
					<Divider/>
					<Row
						gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}>
						<Col span={24}>          
							{langdata && langdata.Id ? langdata.Id :'Id'}:{' '}{pigdetails.id}<br/>
							{langdata && langdata.Latitude ? langdata.Latitude :'Latitude'}:{' '}{pigdetails.lat}<br/>
							{langdata && langdata.Longitude ? langdata.Longitude :'Longitude'}:{' '}{pigdetails.long}<br/>
						</Col>
					</Row>
				</Modal>

			</Content>
			<Spin fullscreen spinning={pig_data.pending}/>
			<Spin fullscreen spinning={pig_data.loading} tip={langdata && langdata.pigloadmsg ? langdata.pigloadmsg :'The PIG tracking script will require 2-3 minutes to complete the update. Kindly wait a moment.'}/>
		</>
	);
}