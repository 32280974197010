/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/react-in-jsx-scope */

import { Avatar, Row, Col, Select, Typography, Tooltip, Card, Button, Space, Modal, Popover, Table, Spin, Form, TimePicker, Tag, Switch, Input, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, } from '@ant-design/icons';
import {  DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import alarmRuleEngineReducer from './reducer';
import { ADD_ALARM_RULE, ADD_ALARM_RULE_ERROR, ADD_ALARM_RULE_SUCCESS, ALARM_RULE, ALARM_RULE_ERROR, ALARM_RULE_SUCCESS, DELETE_ALARM_RULE, DELETE_ALARM_RULE_ERROR, DELETE_ALARM_RULE_SUCCESS, UPDATE_ALARM_RULE, UPDATE_ALARM_RULE_ERROR, UPDATE_ALARM_RULE_SUCESS } from './constants';
import { useAuth } from '../../Context';
import TextArea from 'antd/es/input/TextArea';
import { useFormik } from 'formik';
import { InfoOutlined, ToolOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function AlarmRuleEngine() {

	const navigate = useNavigate();

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const initalState = { loading: false, error: false, alarmRules : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, ruleDetails : null};
	const [data, dispatch] = useReducer(alarmRuleEngineReducer, initalState);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const {getAllEvents, eventsData, getRegion, regiondata} = useAuth();

	const eventData = [];
	const ruleType = [];
	const ruleTypeFilter = [];
	const regions = [];
	const terrain = [];
	const duration = [];
	const timeSpan = [];
	const eventDataFilter = [];
	const [eventType, setEventType] = useState('all');
	const [alarmtype, setAlarmType] = useState('all');
	const [openAddRuleEditDrawer, setOpenAddRuleDrawer] = useState(false);
	const [openEditRuleEditDrawer, setOpenEditRuleDrawer] = useState(false);
	const [terrainInfo , setTerrainInfo] = useState();
	const format = 'HH:mm';
	const [timing, setTiming] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const alarmRuleInfoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
		  <p><b>{langdata && langdata.Critical ? langdata.Critical : 'Critical'}</b>: {langdata && langdata.alarmRuleInfoContent1 ? langdata.alarmRuleInfoContent1 : 'Alarm will be generated right when this event has been identified.'}</p>
		  <br/>
		  <p><b>{langdata && langdata.Conditional ? langdata.Conditional : 'Conditional'}</b>: {langdata && langdata.alarmRuleInfoContent2 ? langdata.alarmRuleInfoContent2 : 'Alarm will be generated right when this event has been identified in between specified timing and duration.'}</p>
		  <br/>
		  <p><b>{langdata && langdata.Aggregate ? langdata.Aggregate : 'Aggregate'}</b>: {langdata && langdata.alarmRuleInfoContent3 ? langdata.alarmRuleInfoContent3 : 'In aggregate rule the activity need not to be continuous but should have the aggregate alarm duration defined in the rule during the predefined time span.'}</p>
		  <br/>
		  <p><b>{langdata && langdata.Suspected ? langdata.Suspected : 'Suspected'}</b>: {langdata && langdata.alarmRuleInfoContent4 ? langdata.alarmRuleInfoContent4 : 'In suspected rule multiple activities can be added with aggregate alarm duration defined in the rule during the predefined time span.'}</p>
		</Card>
	);
	
	const alarmRuleInfoContentForModal = (
		<Space>
			<label>{langdata && langdata.RuleType ? langdata.RuleType : 'Rule Type'}</label>
			<Popover title={langdata && langdata.AlarmTypes ? langdata.AlarmTypes : 'Alarm Types'} content={alarmRuleInfoContent} trigger="click">
				<Avatar size="small" style={{ width : '18px', height : '18px'}}><InfoOutlined /></Avatar>
			</Popover>
		</Space>
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 47);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getAllEvents(authtoken);
		getRegion(authtoken);

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipelineconfiglocationinfo`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					setTerrainInfo(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						  });
				}
			});

	},[authtoken]);
    
	useEffect(() => {
		loadRules(1);
	},[authtoken, eventType, alarmtype]);

	const loadRules = (pagination) => {
		dispatch({ type : ALARM_RULE });
		let url;
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);
        
		if(eventType !== 'all' && alarmtype !== 'all') {
			url = `${api}/rules?&eventTypeId[]=${eventType}&alarmType=${alarmtype}&$limit=${_pagination.limit}&$skip=${_pagination.skip}`;
		} else if (eventType === 'all' && alarmtype !== 'all') {
			url = `${api}/rules?&alarmType=${alarmtype}&$limit=${_pagination.limit}&$skip=${_pagination.skip}`;
		}
		else if (eventType !== 'all' && alarmtype === 'all') {
			url = `${api}/rules?&eventTypeId[]=${eventType}&$limit=${_pagination.limit}&$skip=${_pagination.skip}`;
		}
		else {
			url = `${api}/rules?$limit=${_pagination.limit}&$skip=${_pagination.skip}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : ALARM_RULE_SUCCESS, payload : response.data, page:pagination.current, pageSize : pagination.pageSize  });
				} else {
					dispatch ({ type : ALARM_RULE_ERROR });
				}
			})
			.catch((error) => {
				dispatch ({ type : ALARM_RULE_ERROR });
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						  });
				}
			});

	};

	const columns = [
		{
			title : langdata && langdata.EventType ? langdata.EventType : 'Event Type',
			dataIndex : 'eventType',
			key : 'eventType',
		},
	    {
			title : langdata && langdata.AlarmType ? langdata.AlarmType : 'Alarm Type',
			dataIndex : 'alarmType',
			key : 'alarmType',
		},
		{
			title : langdata && langdata.Description ? langdata.Description : 'Description',
			dataIndex : 'description',
			key : 'description'
		},
		{
			title : langdata && langdata.Isenabled ? langdata.Isenabled : 'Is enabled',
			dataIndex : 'isEnabled',
			key : 'isEnabled',
			render: (isEnabled) => (
				<>
					<Tag color={isEnabled == 'True' ? 'green' : 'red'}>
						{isEnabled}
					</Tag>
				</>
			),
		},
		(permission==='superadmin' ? 
			{
				title: langdata && langdata.Actions ? langdata.Actions :  'Actions',
				key: 'action',
				align: 'center' ,
				render: (_,record) => (
					<Space size="middle" >
						<a title={langdata && langdata.Edit ? langdata.Edit :  'Edit'} onClick={()=>{handleEdit(record);}}><EditOutlined/></a> 
						<a title={langdata && langdata.Delete ? langdata.Delete : 'Delete'} onClick={() => {handleDelete(record.id);}}><DeleteOutlined /></a>
					</Space>
				)
			}
			:{}
		)
	];

	eventDataFilter.push({
		value : 'all',
		label : langdata && langdata.ALL ? langdata.ALL : 'ALL'
	});

	eventsData && eventsData.map((item) => {
		eventData.push({
			value : item.id,
			label : item.eventType
		});
		eventDataFilter.push({
			value : item.id,
			label : item.eventType
		});
	});

	regiondata && regiondata.map((region)=>{
		regions.push({
			value:region.id,
			label:region.regionName.toUpperCase()
		});
	});

	terrain.push({
		value : 'all',
		label : langdata && langdata.AllLocation ? langdata.AllLocation : 'All Location'
	});

	terrainInfo && terrainInfo.map((item) => {
		terrain.push({
			value : item.terrainClassification,
			label : item.terrainClassification
		});
	});

	ruleType.push({
		value : 'Critical',
		label : langdata && langdata.Critical ? langdata.Critical : 'Critical'
	},
	{
		value : 'Conditional',
		label : langdata && langdata.Conditional ? langdata.Conditional :'Conditional'
	},
	{
		value : 'Aggregate',
		label : langdata && langdata.Aggregate ? langdata.Aggregate :'Aggregate'
	},
	{
		value : 'Suspected',
		label : langdata && langdata.Suspected ? langdata.Suspected :'Suspected'
	});

	ruleTypeFilter.push({
		value : 'all',
		label : langdata && langdata.ALL ? langdata.ALL :'ALL'
	},
	{
		value : 'Critical',
		label : langdata && langdata.Critical ? langdata.Critical :'Critical'
	},
	{
		value : 'Conditional',
		label : langdata && langdata.Conditional ? langdata.Conditional :'Conditional'
	},
	{
		value : 'Aggregate',
		label : langdata && langdata.Aggregate ? langdata.Aggregate :'Aggregate'
	},
	{
		value : 'Suspected',
		label : langdata && langdata.Suspected ? langdata.Suspected :'Suspected'
	});

	duration.push({
		value  :'3',
		label : 3
	},
	{
		value : '9',
		label : 9
	},
	{
		value : '15',
		label : 15
	},
	{
		value : '21',
		label : 21
	},
	{
		value : '27',
		label : 21
	},
	{
		value : '33',
		label : 33
	});

	timeSpan.push({
		value : '30',
		label : 30
	},
	{
		value : '45',
		label : 45
	},
	{
		value : '60',
		label : 60
	},
	{
		value : '75',
		label : 75
	});

	const handleEventType = (value) => {
		setEventType(value);
	};

	const handleRuleType = (value) => {
		setAlarmType(value);
	};

	const onCloseDrawer = () => {
		setOpenAddRuleDrawer(false);
		setOpenEditRuleDrawer(false);
	};

	const handleEdit = (record) =>{

		dispatch({ type : UPDATE_ALARM_RULE });

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/rules/${record.id}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : UPDATE_ALARM_RULE_SUCESS, payload : response.data});
				}else {
					dispatch({ type : UPDATE_ALARM_RULE_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : UPDATE_ALARM_RULE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						  });
				}
			}); 
		setOpenEditRuleDrawer(true);
	};

	const saveRuleFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			regions : '',
			ruleType: '',
			terrain: '',
			activity: '',
			timeSpan: '',
			alarmDuration: '',
			startTime: '',
			endTime: '',
			ruleDescription: '',
		},
		onSubmit: (values) => {
			const regions = values.regions ? values.regions : [];
			const ruleType = values.ruleType ? values.ruleType : '';
			const terrain = values.terrain ? values.terrain : '';
			const activity = values.activity ? values.activity : [];
			const timeSpan = values.timeSpan ? values.timeSpan : '';
			const alarmDuration = values.alarmDuration ? values.alarmDuration : 0;
			const startTime = values.startTime ? values.startTime : '00:00:00';
			const endTime = values.endTime ? values.endTime : '23:59:00';
			const ruleDescription = values.ruleDescription ? values.ruleDescription : '';

			if(!regions || regions.length === 0) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheregion ? langdata.Pleasespecifytheregion : 'Please specify the region' });
				return false;
			}

			if(!ruleType) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheruletype ? langdata.Pleasespecifytheruletype : 'Please specify the rule type' });
				return false;
			}

			if(!terrain) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheterrain ? langdata.Pleasespecifytheterrain : 'Please specify the terrain' });
				return false;
			}

			if(!ruleDescription) {
				Modal.warning({ title: langdata && langdata.Pleasespecifytheruledescription ? langdata.Pleasespecifytheruledescription : 'Please specify the rule description' });
				return false;
			}

			if(regions && ruleType && terrain && ruleDescription){
				setOpenAddRuleDrawer(false);
				dispatch({ type : ADD_ALARM_RULE });
				let body = {};
				ruleType === 'Critical' ||  ruleType === 'Conditional' ?
					body = {
						'locationDesc': terrain,
						'ruleDesc': ruleDescription,
						'startTime': startTime,
						'endTime': endTime,
						'minute': alarmDuration,
						'eventTypeId': activity,
						'alarmType': ruleType,
						'regioninfoId': regions,
						'isEnabled' : true
					}
					: null;

				ruleType === 'Aggregate' || ruleType === 'Suspected' ?
					body = {
						'locationDesc': terrain,
						'ruleDesc': ruleDescription,
						'startTime': startTime,
						'endTime': endTime,
						'minute': alarmDuration,
						'eventTypeId': activity,
						'alarmType': ruleType,
						'regioninfoId': regions,
						'aggregate' : timeSpan,
						'isEnabled' : true
					}
					: null;

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/rules`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : body
				};

				axios.request(config)
					.then((response) => {
						if(response.status === 200 || response.status === 201) {
							dispatch({ type : ADD_ALARM_RULE_SUCCESS });
							Modal.success({
								title: langdata && langdata.Alarmrulesavedsuccessfully ? langdata.Alarmrulesavedsuccessfully : 'Alarm rule saved successfully.' ,
						  });
						  loadRules({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						} else {
							dispatch({ type : ADD_ALARM_RULE_ERROR });
							Modal.warning({
								title: langdata && langdata.Unabletosavethealarmrule ? langdata.Unabletosavethealarmrule : 'Unable to save the alarm rule.' ,
						  });
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type : ADD_ALARM_RULE_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			} else {
				Modal.warning({
					title: langdata && langdata.Unabletosavethealarmrule ? langdata.Unabletosavethealarmrule : 'Unable to save the alarm rule.' ,
				  });
			}

		}
	});

	const updateRuleFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id : data.ruleDetails && data.ruleDetails.id,
			regions : data.ruleDetails && data.ruleDetails.regioninfoId,
			ruleType: data.ruleDetails && data.ruleDetails.alarmType,
			terrain: data.ruleDetails && data.ruleDetails.locationDesc,
			activity: data.ruleDetails && data.ruleDetails.eventTypeId,
			timeSpan: data.ruleDetails && data.ruleDetails.aggregate,
			alarmDuration: data.ruleDetails && data.ruleDetails.minute,
			startTime: data.ruleDetails && data.ruleDetails.startTime,
			endTime: data.ruleDetails && data.ruleDetails.endTime,
			ruleDescription: data.ruleDetails && data.ruleDetails.ruleDesc,
			isEnabled : data.ruleDetails && data.ruleDetails.isEnabled
		},
		onSubmit: (values) => {
			const id = parseInt(updateRuleFormik.values.id);
			// const regions = values.regions ? values.regions : [];
			// const ruleType = values.ruleType ? values.ruleType : '';
			// const terrain = values.terrain ? values.terrain : '';
			// const activity = values.activity ? values.activity : [];
			// const timeSpan = values.timeSpan ? values.timeSpan : '';
			// const alarmDuration = values.alarmDuration ? values.alarmDuration : 0;
			// const startTime = values.startTime ? values.startTime : '00:00:00';
			// const endTime = values.endTime ? values.endTime : '23:59:00';
			// const ruleDescription = values.ruleDescription ? values.ruleDescription : '';
			const isEnabled = values.isEnabled ? values.isEnabled : false;

			// if(!regions || regions.length === 0) {
			// 	Modal.warning({ title: 'Please specify the region' });
			// 	return false;
			// }

			// if(!ruleType) {
			// 	Modal.warning({ title: 'Please specify the rule type' });
			// 	return false;
			// }

			// if(!terrain) {
			// 	Modal.warning({ title: 'Please specify the terrain' });
			// 	return false;
			// }

			// if(!ruleDescription) {
			// 	Modal.warning({ title: 'Please specify the rule description' });
			// 	return false;
			// }

			if(id){
				// setOpenAddRuleDrawer(false);
				dispatch({ type : ADD_ALARM_RULE });
				let body = {
					'isEnabled' : isEnabled
				};
				// ruleType == 'Critical' ||  ruleType == 'Conditional' ?
				// 	body = {
				// 		'locationDesc': terrain,
				// 		'ruleDesc': ruleDescription,
				// 		'startTime': startTime,
				// 		'endTime': endTime,
				// 		'minute': alarmDuration,
				// 		'eventTypeId': activity,
				// 		'alarmType': ruleType,
				// 		'regioninfoId': regions,
				// 		'isEnabled' : isEnabled
				// 	}
				// 	: null;

				// ruleType == 'Aggregate' || ruleType == 'Suspected' ?
				// 	body = {
				// 		'locationDesc': terrain,
				// 		'ruleDesc': ruleDescription,
				// 		'startTime': startTime,
				// 		'endTime': endTime,
				// 		'minute': alarmDuration,
				// 		'eventTypeId': activity,
				// 		'alarmType': ruleType,
				// 		'regioninfoId': regions,
				// 		'aggregate' : timeSpan,
				// 		'isEnabled' : isEnabled
				// 	}
				// 	: null;

				let config = {
					method: 'patch',
					maxBodyLength: Infinity,
					url: `${api}/rules/${id}`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : body
				};

				axios.request(config)
					.then((response) => {
						if(response.status === 200 || response.status === 201) {
							dispatch({ type : ADD_ALARM_RULE_SUCCESS });
							Modal.success({
								title: langdata && langdata.Alarmruleupdatedsuccessfully ? langdata.Alarmruleupdatedsuccessfully : 'Alarm rule updated successfully.' ,
						  });
						  loadRules({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						} else {
							dispatch({ type : ADD_ALARM_RULE_ERROR });
							Modal.warning({
								title: langdata && langdata.Unabletoupdatethealarmrule ? langdata.Unabletoupdatethealarmrule :  'Unable to update the alarm rule.' ,
						  });
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type : ADD_ALARM_RULE_ERROR });
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			} else {
				Modal.warning({
					title:  langdata && langdata.Unabletoupdatethealarmrule ? langdata.Unabletoupdatethealarmrule : 'Unable to update the alarm rule.' ,
				  });
			}
			setOpenEditRuleDrawer(false);
		}
	});

	const handleDelete = (value) => {
		setDeleteId(value);
		setDeleteModalOpen(true);
	};

	const handleDeleteConfirm = () => {
		setDeleteModalOpen(false);
		dispatch ({ type : DELETE_ALARM_RULE });
		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/rules/${deleteId}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					dispatch({ type : DELETE_ALARM_RULE_SUCCESS });
					Modal.success({
						title: langdata && langdata.Alarmruledeletedsuccessfully ? langdata.Alarmruledeletedsuccessfully : 'Alarm rule deleted successfully.' ,
				  });
				  loadRules({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
				} else {
					dispatch ({ type : DELETE_ALARM_RULE_ERROR });
					Modal.warning({
						title:  langdata && langdata.Unabletodeletethealarmrule ? langdata.Unabletodeletethealarmrule : 'Unable to delete the alarm rule.' ,
				  });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch ({ type : DELETE_ALARM_RULE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						  });
				}
			});	

	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}}>
					<Col span={8}>
						<Row>
							<Space>
								<ToolOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.AlarmRuleEngine ? langdata.AlarmRuleEngine : 'Alarm Rule Engine'}</Title>
						</Row>
					</Col>
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyeventtype ? langdata.Filterbyeventtype : 'Filter by event type'}>
									<Space>
										<Select
											style={{ minWidth: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectEventType ? langdata.SelectEventType : 'Select Event Type'}
											optionFilterProp="children"
											options={eventDataFilter}
											onSelect={handleEventType}
										    value={eventType}
										/>		
									</Space>
								</Tooltip>	
								<Space>&nbsp;&nbsp;</Space>						
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyruletype ? langdata.Filterbyruletype : 'Filter by rule type'}>
									<Space>
										<Select
											style={{ minWidth: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRuleType ? langdata.SelectRuleType : 'Select Rule Type'}
											optionFilterProp="children"
											options={ruleTypeFilter}
											onSelect={handleRuleType}
											value={alarmtype}
										/>
										{/* <Popover title="Alarm Types" content={alarmRuleInfoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover> */}
									</Space>
								</Tooltip>
								{permission==='superadmin' ?
									<>
										<Space>&nbsp;&nbsp;</Space>		
										<Space>
											<Button style={{margin:'5px'}} type="primary" onClick={() => setOpenAddRuleDrawer(true)}><PlusOutlined/>{langdata && langdata.AddNewRule ? langdata.AddNewRule : 'Add New Rule'}</Button>
										</Space>
									</> : null}
								<Space>&nbsp;&nbsp;</Space>	
								<Space>
									<Popover title={langdata && langdata.AlarmTypes ? langdata.AlarmTypes : 'Alarm Types'} content={alarmRuleInfoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover></Space>	
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						<Table
							scroll={{
								x: 900,
							}}
							columns={columns} dataSource={data.alarmRules} pagination = { data.alarmRules.length > 0 ? data.pagination : 0 } onChange={loadRules} />
					</Col>
				</Row>
			</Content>
			<Modal
				title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'}
				centered
				open={deleteModalOpen}
				onOk={handleDeleteConfirm}
				onCancel={() => {setDeleteModalOpen(false);}}
				footer={[
					<Button key="back" onClick={() => {setDeleteModalOpen(false);}}>
						{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}
					</Button>,
					<Button key="submit" type="primary" onClick={handleDeleteConfirm}>
						{langdata && langdata.Delete ? langdata.Delete : 'Delete'}
					</Button>,
				]}
			>
				<p>{langdata && langdata.Areyousureyouwanttodeletethisrule ? langdata.Areyousureyouwanttodeletethisrule : 'Are you sure you want to delete this rule?'}</p>
			</Modal>

			{/* Add Rule modal Starts */}
			<Modal
				title={langdata && langdata.AddRule ? langdata.AddRule : 'Add Rule'}
				width={720}
				maskClosable={false}
				open={openAddRuleEditDrawer}
				destroyOnClose={true}
				// onOk={onSaveAddMatrixDrawer}
				onCancel={onCloseDrawer}
				styles={{
					body: {
					},
				}}
				footer={[
					<Button key="back" onClick={onCloseDrawer}>
						{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}
					</Button>,
					<Button key="submit" type="primary" onClick={saveRuleFormik.handleSubmit}>
						{langdata && langdata.Save ? langdata.Save : 'Save'}
					</Button>,
				]}
			>
				<Form layout="vertical" onSubmit={saveRuleFormik.handleSubmit}>


					<Row gutter={24}>
						<Col span={24}>
							<Form.Item name="regions" label={langdata && langdata.Regions ? langdata.Regions : 'Regions'} rules={[{ required: true, message: langdata && langdata.SelectRegion ? langdata.SelectRegion :'Select Region' }]} >
								<Select mode="multiple" placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion :'Select Region'} options={regions} onChange={(value) => saveRuleFormik.setFieldValue('regions', value)} showSearch filterOption={filterOption} />
							</Form.Item>
							<Form.Item name="ruleType" label={alarmRuleInfoContentForModal} rules={[{ required: true, message:langdata && langdata.SelectRuleType ? langdata.SelectRuleType :'Select Rule Type' }]} >
								<Select placeholder={langdata && langdata.SelectRuleType ? langdata.SelectRuleType :'Select Rule Type'} options={ruleType} onChange={(value) => saveRuleFormik.setFieldValue('ruleType', value)} showSearch filterOption={filterOption} />
							</Form.Item>
							<Form.Item name="terrain" label={langdata && langdata.Terrain ? langdata.Terrain : 'Terrain'} rules={[{ required: true, message: langdata && langdata.SelectTerrain ? langdata.SelectTerrain :'Select Terrain' }]} >
								<Select placeholder={langdata && langdata.SelectTerrain ? langdata.SelectTerrain :'Select Terrain'} options={terrain} onChange={(value) => saveRuleFormik.setFieldValue('terrain', value)} showSearch filterOption={filterOption} />
							</Form.Item>
							<Form.Item name="activity" label={langdata && langdata.Activity ? langdata.Activity : 'Activity'} required>
								<Select mode="multiple" placeholder={langdata && langdata.SelectActivity ? langdata.SelectActivity :'Select Activity'} options={eventData} onChange={(value) => saveRuleFormik.setFieldValue('activity', value)} showSearch filterOption={filterOption} />
							</Form.Item>
							{saveRuleFormik.values.ruleType == 'Critical' ? 
								<>
									<Form.Item name="alarmDuration" value = {0} label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration :'Alarm Duration'} rules={[{ required: true, message: langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration :'Select Alarm Duration' }]} >
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration :'Select Alarm Duration'} disabled defaultValue={{
											value: '0',
											label: '0',
										}} onChange={(value) => saveRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} />
									</Form.Item>
									{ timing === true ?
										<>
											<Form.Item name="startTime" label={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} rules={[{ required: true, message: langdata && langdata.StartTime ? langdata.StartTime :'Start Time' }]} >
												<TimePicker placeholder={langdata && langdata.StartTime ? langdata.StartTime :'Start Time'} allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => saveRuleFormik.setFieldValue('startTime', dateString)}/>
											</Form.Item>
											<Form.Item name="endTime" label={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} rules={[{ required: true, message: langdata && langdata.EndTime ? langdata.EndTime : 'End Time' }]} >
												<TimePicker placeholder={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => saveRuleFormik.setFieldValue('endTime', dateString)}/>
											</Form.Item>
											<Typography><Tag color="blue">{langdata && langdata.AlarmRuleTag1 ? langdata.AlarmRuleTag1 : 'Alarm will be generated right when this event has been identified in between specified timing'}</Tag></Typography>
										</> : 
										<><Button onClick={() => setTiming(true)}>{langdata && langdata.AddTiming ? langdata.AddTiming : 'Add Timing'}</Button>
											<Typography><Tag color="blue">{langdata && langdata.AlarmRuleTag2 ? langdata.AlarmRuleTag2 : 'Alarm will be generated right when this event has been identified'}</Tag></Typography>

										</> }
								</> : null}

							{saveRuleFormik.values.ruleType == 'Conditional' ?
								<>
									<Form.Item name="alarmDuration" label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration :'Alarm Duration'} rules={[{ required: true, message: langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration : 'Select Alarm Duration' }]} >
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration : 'Select Alarm Duration'} options={duration} onChange={(value) => saveRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} />
									</Form.Item>
									<Form.Item name="startTime" label={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} rules={[{ required: true, message: langdata && langdata.StartTime ? langdata.StartTime :'Start Time' }]} >
										<TimePicker placeholder={langdata && langdata.StartTime ? langdata.StartTime :'Start Time'} allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => saveRuleFormik.setFieldValue('startTime', dateString)}/>
									</Form.Item>
									<Form.Item name="endTime" label={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} rules={[{ required: true, message: langdata && langdata.EndTime ? langdata.EndTime : 'End Time' }]} >
										<TimePicker placeholder={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => saveRuleFormik.setFieldValue('endTime', dateString)}/>
									</Form.Item>
									<Typography><Tag color="blue">{langdata && langdata.AlarmRuleTag3 ? langdata.AlarmRuleTag3 : 'Alarm will be generated right when this event has been identified in between specified timing and duration'}</Tag></Typography>
								</> : null}
							{saveRuleFormik.values.ruleType == 'Aggregate' ?
								<>
									<Form.Item name="timeSpan" label={langdata && langdata.TimeSpan ? langdata.TimeSpan : 'Time Span'} rules={[{ required: true, message: langdata && langdata.SelectTimeSpan ? langdata.SelectTimeSpan :'Select Time Span' }]} >
										<Select placeholder={langdata && langdata.SelectTimeSpan ? langdata.SelectTimeSpan :'Select Time Span'} options={timeSpan} onChange={(value) => saveRuleFormik.setFieldValue('timeSpan', value)} showSearch filterOption={filterOption} />
									</Form.Item>
									<Form.Item name="alarmDuration" label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration : 'Alarm Duration'} rules={[{ required: true, message: langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration :'Select Alarm Duration' }]} >
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration :'Select Alarm Duration'} options={duration} onChange={(value) => saveRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} />
									</Form.Item>
									<Form.Item name="startTime" label={langdata && langdata.StartTime ? langdata.StartTime :'Start Time'} rules={[{ required: true, message: langdata && langdata.StartTime ? langdata.StartTime : 'Start Time' }]} >
										<TimePicker placeholder={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => saveRuleFormik.setFieldValue('startTime', dateString)}/>
									</Form.Item>
									<Form.Item name="endTime" label={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} rules={[{ required: true, message: langdata && langdata.EndTime ? langdata.EndTime : 'End Time' }]} >
										<TimePicker placeholder={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => saveRuleFormik.setFieldValue('endTime', dateString)}/>
									</Form.Item>
								</> : null}
							{saveRuleFormik.values.ruleType == 'Suspected' ?
								<>
									<Form.Item name="timeSpan" label={langdata && langdata.TimeSpan ? langdata.TimeSpan :'Time Span'} rules={[{ required: true, message: langdata && langdata.SelectTimeSpan ? langdata.SelectTimeSpan : 'Select Time Span' }]} >
										<Select placeholder={langdata && langdata.SelectTimeSpan ? langdata.SelectTimeSpan : 'Select Time Span'} options={timeSpan} onChange={(value) => saveRuleFormik.setFieldValue('timeSpan', value)} showSearch filterOption={filterOption} />
									</Form.Item>
									<Form.Item name="alarmDuration" label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration : 'Alarm Duration'} rules={[{ required: true, message: langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration :'Select Alarm Duration' }]} >
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration :'Select Alarm Duration'} options={duration} onChange={(value) => saveRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} />
									</Form.Item>
                            
								</> : null }

							<Form.Item name="ruleDescription" label={langdata && langdata.RuleDescription ? langdata.RuleDescription :'Rule Description'} rules={[{ required: true, message: langdata && langdata.RuleDescription ? langdata.RuleDescription :'Rule Description' }]} >
								<TextArea rows={4} onChange={saveRuleFormik.handleChange}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				
			</Modal>

			<Modal
				title={langdata && langdata.EditRule ? langdata.EditRule : 'Edit Rule'}
				width={720}
				open={openEditRuleEditDrawer}
				destroyOnClose={true}
				// onOk={onSaveAddMatrixDrawer}
				onCancel={onCloseDrawer}
				styles={{
					body: {
					},
				}}
				footer={[
					<Button key="back" onClick={onCloseDrawer}>
						{langdata && langdata.Cancel ? langdata.Cancel : 'Cancel'}
					</Button>,
					<Button key="submit" type="primary" onClick={updateRuleFormik.handleSubmit}>
						{langdata && langdata.Save ? langdata.Save : 'Save' }
					</Button>,
				]}
			>
				<Form layout="vertical" onSubmit={updateRuleFormik.handleSubmit}>


					<Row gutter={24}>
						<Col span={24}>
							<Form.Item  label={langdata && langdata.Regions ? langdata.Regions : 'Regions'} required>
								<Select mode="multiple" placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'} options={regions} onChange={(value) => updateRuleFormik.setFieldValue('regions', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.regions} disabled/>
							</Form.Item>
							<Form.Item label={alarmRuleInfoContentForModal} required>
								<Select placeholder={langdata && langdata.SelectRuleType ? langdata.SelectRuleType : 'Select Rule Type'} options={ruleType} onChange={(value) => updateRuleFormik.setFieldValue('ruleType', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.ruleType} disabled/>
							</Form.Item>
							<Form.Item label={langdata && langdata.Terrain ? langdata.Terrain : 'Terrain'}  required>
								<Select placeholder="Select Terrain" options={terrain} onChange={(value) => updateRuleFormik.setFieldValue('terrain', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.terrain} disabled/>
							</Form.Item>
							<Form.Item label={langdata && langdata.Activity ? langdata.Activity : 'Activity'} required>
								<Select mode="multiple" placeholder={langdata && langdata.SelectActivity ? langdata.SelectActivity : 'Select Activity'} options={eventData} onChange={(value) => updateRuleFormik.setFieldValue('activity', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.activity} disabled/>
							</Form.Item>
							{updateRuleFormik.values.ruleType == 'Critical' ? 
								<>
									<Form.Item value = {0} label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration : 'Alarm Duration'} required>
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration : 'Select Alarm Duration'} disabled defaultValue={{
											value: '0',
											label: '0',
										}} onChange={(value) => updateRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} 
										value={updateRuleFormik.values.alarmDuration} />
									</Form.Item>
									{ timing === true ?
										<>
											<Form.Item  label={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} required>
												{/* <TimePicker placeholder="Start Time" allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => updateRuleFormik.setFieldValue('startTime', dateString)} 
													defaultValue={updateRuleFormik.values.startTime ? dayjs(moment(updateRuleFormik.values.startTime).format(process.env.REACT_APP_DATE_FORMAT), process.env.REACT_APP_DATE_FORMAT) : null}/> */}
												<Input value={updateRuleFormik.values.startTime} disabled />
											</Form.Item>
											<Form.Item label={langdata && langdata.EndTime ? langdata.EndTime :'End Time'} required>
												{/* <TimePicker placeholder="End Time" allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => updateRuleFormik.setFieldValue('endTime', dateString)} /> */}
												<Input value={updateRuleFormik.values.endTime} disabled/>
											</Form.Item>
											{/* <Typography><Tag color="blue">Alarm will be generated right when this event has been identified in between specified timing</Tag></Typography> */}
										</> : 
										<><Button onClick={() => setTiming(true)}> {langdata && langdata.AddTiming ? langdata.AddTiming : 'Add Timing'}</Button>
											{/* <Typography><Tag color="blue">Alarm will be generated right when this event has been identified</Tag></Typography> */}

										</> }
								</> : null}

							{updateRuleFormik.values.ruleType == 'Conditional' ?
								<>
									<Form.Item label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration : 'Alarm Duration'} required>
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration : 'Select Alarm Duration'} options={duration} onChange={(value) => updateRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.alarmDuration} disabled/>
									</Form.Item>
									<Form.Item label={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} required>
										{/* <TimePicker placeholder="Start Time" allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => updateRuleFormik.setFieldValue('startTime', dateString)} 
											value={updateRuleFormik.values.startTime ? dayjs(moment(updateRuleFormik.values.startTime).format(process.env.REACT_APP_DATE_FORMAT), process.env.REACT_APP_DATE_FORMAT) : null}/> */}
										<Input value={updateRuleFormik.values.startTime} disabled/>
									</Form.Item>
									<Form.Item label={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} required>
										{/* <TimePicker placeholder="End Time" allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => updateRuleFormik.setFieldValue('endTime', dateString)} /> */}
										<Input value={updateRuleFormik.values.endTime} disabled/>
									</Form.Item>
									{/* <Typography><Tag color="blue">Alarm will be generated right when this event has been identified in between specified timing and duration</Tag></Typography> */}
								</> : null}
							{updateRuleFormik.values.ruleType == 'Aggregate' ?
								<>
									<Form.Item label={langdata && langdata.TimeSpan ? langdata.TimeSpan : 'Time Span'} required>
										<Select placeholder={langdata && langdata.SelectTimeSpan ? langdata.SelectTimeSpan : 'Select Time Span'} options={timeSpan} onChange={(value) => updateRuleFormik.setFieldValue('timeSpan', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.timeSpan} disabled/>
									</Form.Item>
									<Form.Item label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration : 'Alarm Duration'} required>
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration : 'Select Alarm Duration'} options={duration} onChange={(value) => updateRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.alarmDuration} disabled/>
									</Form.Item>
									<Form.Item  label={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} required>
										{/* <TimePicker placeholder="Start Time" allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => updateRuleFormik.setFieldValue('startTime', dateString)} /> */}
										<Input value={updateRuleFormik.values.startTime} disabled/>
									</Form.Item>
									<Form.Item label={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} required>
										{/* <TimePicker placeholder="End Time" allowClear format={format}  style={{ width: '100%' }} onChange={(value, dateString) => updateRuleFormik.setFieldValue('endTime', dateString)} /> */}
										<Input value={updateRuleFormik.values.endTime} disabled/>
									</Form.Item>
								</> : null}
							{updateRuleFormik.values.ruleType == 'Suspected' ?
								<>
									<Form.Item label={langdata && langdata.TimeSpan ? langdata.TimeSpan : 'Time Span'} required>
										<Select placeholder={langdata && langdata.SelectTimeSpan ? langdata.SelectTimeSpan : 'Select Time Span'} options={timeSpan} onChange={(value) => updateRuleFormik.setFieldValue('timeSpan', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.timeSpan} disabled/>
									</Form.Item>
									<Form.Item label={langdata && langdata.AlarmDuration ? langdata.AlarmDuration : 'Alarm Duration'} required>
										<Select placeholder={langdata && langdata.SelectAlarmDuration ? langdata.SelectAlarmDuration : 'Select Alarm Duration'} options={duration} onChange={(value) => updateRuleFormik.setFieldValue('alarmDuration', value)} showSearch filterOption={filterOption} value={updateRuleFormik.values.alarmDuration} disabled/>
									</Form.Item>
                            
								</> : null }

							<Form.Item label={langdata && langdata.RuleDescription ? langdata.RuleDescription : 'Rule Description'} required>
								<TextArea rows={4} onChange={updateRuleFormik.handleChange} value={updateRuleFormik.values.ruleDescription} disabled/>
							</Form.Item>
							<Form.Item label={langdata && langdata.Isenabled ? langdata.Isenabled : 'Is Enabled'} required>
								<Switch checked={updateRuleFormik.values.isEnabled} onChange={(value) => updateRuleFormik.setFieldValue('isEnabled', value)}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				
			</Modal>

			{/* Add Rule Modal Ends */}
			<Spin spinning={data.loading} fullscreen />
		</>
	);
}