import React, { useState, useEffect, useReducer } from 'react';
import { useAuth } from '../Context';

import { Empty, Row, Col, Select, Typography, Space, Table, Spin, Button, Form, Modal, Input, Tooltip, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { EditOutlined, EyeOutlined, InfoOutlined } from '@ant-design/icons';

import { CHAINAGES_LIST, CHAINAGES_LIST_SUCCESS, CHAINAGES_LIST_ERROR } from './constants';
import { TERRAIN_CLASSIFICATION_LIST, TERRAIN_CLASSIFICATION_LIST_SUCCESS, TERRAIN_CLASSIFICATION_LIST_ERROR } from './constants';
import { TERRAIN_CLASSIFICATION_DETAILS, TERRAIN_CLASSIFICATION_DETAILS_SUCCESS, TERRAIN_CLASSIFICATION_DETAILS_ERROR } from './constants';
import { UPDATE_TERRAIN_CLASSIFICATION, UPDATE_TERRAIN_CLASSIFICATION_SUCCESS, UPDATE_TERRAIN_CLASSIFICATION_ERROR } from './constants';

import terrainClassificationReducer from './reducer.js';

import { useFormik } from 'formik';
import axios from 'axios';
import { FileProtectOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

export default function TerrainClassification() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [regionId, setRegionId] = useState(null);
	const [rouMarker, setRouMarker] = useState(null);
	const [handleTerrainClassification, SethandleTerrainClassification] = useState(null);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const initalState = {chainages: [], terrainClassifications: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: null, loading: false, error: false };
	const [data, dispatch] = useReducer(terrainClassificationReducer, initalState);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [showTerrainClassificationModal, setShowTerrainClassificationModal] = useState(false);
	const [terrainInfo , setTerrainInfo] = useState();

	let regions = [ { value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'} ];
	let roumarkers = [
		{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}, 
		{ value: 'CH', label: langdata && langdata.CH ? langdata.CH : 'CH'},
		{ value: 'KMP', label: langdata && langdata.KMP ? langdata.KMP : 'KMP'},
		{ value: 'TP', label: langdata && langdata.TP ? langdata.TP : 'TP'},
		{ value: 'TLP', label: langdata && langdata.TLP ? langdata.TLP : 'TLP'},
		{ value: 'WB', label: langdata && langdata.WB ? langdata.WB : 'WB'},
	];
	let chainages = [];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const navigate = useNavigate();

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
  
	const columns = [
		{
			title: langdata && langdata.PipelineChainage ? langdata.PipelineChainage : 'Pipeline Chainage',
			dataIndex: 'pipelineChainage',
			key: 'pipelineChainage',
		},
		{
			title: langdata && langdata.ODMeter ? langdata.ODMeter : 'OD Meter',
			dataIndex: 'ODMeter',
			key: 'ODMeter',
		},
		{
			title: langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker',
			dataIndex: 'rouMarker',
			key: 'rouMarker'
		},
		{
			title: langdata && langdata.Latitude ? langdata.Latitude : 'Latitude',
			dataIndex: 'latitude',
			key: 'latitude',
			render: (text) => (
				<text>{text.toFixed(5)}</text>
			)
		},
		{
			title: langdata && langdata.Longitude ? langdata.Longitude : 'Longitude',
			dataIndex: 'longitude',
			key: 'longitude',
			render: (text) => (
				<text>{text.toFixed(5)}</text>
			)
		},
		{
			title: langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification',
			dataIndex: 'terrainClassification',
			key: 'terrainClassification'
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			align:'center',
			render: () => (
				permission === 'superadmin' || permission === 'admin' ? (
					<Space size="middle">
						<a title={langdata && langdata.ViewTerrainClassification ? langdata.ViewTerrainClassification : 'View Terrain Classification'} onClick={openTerrainClassificationModal}><EditOutlined /></a>
					</Space>
				) : (
					<Space size="middle">
						<a title={langdata && langdata.ViewTerrainClassification ? langdata.ViewTerrainClassification : 'View Terrain Classification'} onClick={openTerrainClassificationModal}><EyeOutlined /></a>
					</Space>	
				)
			)
		}
	];

	const openTerrainClassificationModal = () => {
		setShowTerrainClassificationModal(true);
	};

	const closeTerrainClassificationModal = () => {
		setShowTerrainClassificationModal(false);
	};

	const terrain = [];
	const filterTerrain = [];

	filterTerrain.push({
		value : 'all',
		label : langdata && langdata.ALLLOCATIONS ? langdata.ALLLOCATIONS : 'ALL LOCATIONS'
	});

	terrainInfo && terrainInfo.map((item) => {
		terrain.push({
			value : item.name,
			label : item.name.toUpperCase()
		});
		filterTerrain.push({
			value : item.name,
			label : item.name.toUpperCase()
		});
	});

	filterTerrain.sort((a, b) => { return a.label - b.label; });

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 94);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		if (admin_roles.includes(permission)) {
			getRegion(authtoken);
		} else{
			getUserAllocation(authtoken);
		}

		loadChainages();
		loadTerrainClassification(1);

		if(permission !== 'superadmin' || permission !== 'admin'){
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/terrains?$sort[name]=+1`,
				timeout: timeout,
				headers: { 
					'Authorization': `Bearer ${authtoken}`
				}
			};
	
			axios.request(config)
				.then((response) => {
					if(response.status === 200) {
						setTerrainInfo(response.data.data);
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
		}
	}, [allocationId, regionId, rouMarker, handleTerrainClassification]);

	if (admin_roles.includes(permission)) {
		regiondata && regiondata.map((region) => {
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region) => {
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	data && data.chainages.map((chainage) => {
		return chainages.push({ value: chainage.id, label: chainage.pipelineChainage });
	});

	const handleSetRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`, e);
		
		if (admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	const handleSetROUMarker = (e) => {
		setRouMarker(e);
	};

	const loadChainages = () => {
		const limit = 100000;
		const skip = 0;

		let filterByregionIdPart = '';
		let filterVulnerablePart = '';
		let filterHandleTerrainClassification = '';
		let pipelineChainageparams = `&pipelineChainage[$gte]=${startCh}&pipelineChainage[$lte]=${endCh}`;

		if (regionId && regionId !== null) {
			filterByregionIdPart = `&regioninfoId=${regionId}`;
		} else {
			filterByregionIdPart = '';
		}

		if(handleTerrainClassification && handleTerrainClassification != 'all') {
			filterHandleTerrainClassification = `&terrainClassification=${handleTerrainClassification}`;
		} else {
			filterHandleTerrainClassification = '';
		}

		let url = `${api}/pipelineconfig?$limit=${limit}&$skip=${skip}${filterByregionIdPart}${filterVulnerablePart}${ endCh && endCh || endCh == 0 ? pipelineChainageparams:''}&$sort[pipelineChainage]=+1${filterHandleTerrainClassification}`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		dispatch( { type: CHAINAGES_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: CHAINAGES_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: CHAINAGES_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: CHAINAGES_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};
  
	const loadTerrainClassification = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);


		let filterByregionIdPart = '';
		let filterByROUMarkerPart = '';
		let pipelineChainageparams = `&pipelineChainage[$gte]=${startCh}&pipelineChainage[$lte]=${endCh}`;
		let filterHandleTerrainClassification = '';

		if (regionId && regionId !== null) {
			filterByregionIdPart = `&regioninfoId=${regionId}`;
		} else {
			filterByregionIdPart = '';
		}

		if (rouMarker && rouMarker !== null) {
			filterByROUMarkerPart = `&rouMarker=${rouMarker}`;
		} else {
			filterByROUMarkerPart = '';
		}

		if(handleTerrainClassification && handleTerrainClassification != 'all') {
			filterHandleTerrainClassification = `&terrainClassification=${handleTerrainClassification}`;
		} else {
			filterHandleTerrainClassification = '';
		}

		let url = `${api}/pipelineconfig?$limit=${_pagination.limit}&$skip=${_pagination.skip}${filterByregionIdPart}${filterByROUMarkerPart}${ endCh && endCh || endCh == 0 ? pipelineChainageparams:''}&$sort[pipelineChainage]=+1${filterHandleTerrainClassification}`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
    
		dispatch( { type: TERRAIN_CLASSIFICATION_LIST });

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: TERRAIN_CLASSIFICATION_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: TERRAIN_CLASSIFICATION_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TERRAIN_CLASSIFICATION_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadTerrainClassificationDetails = ((id) => {
		let details = null;

		dispatch({ type: TERRAIN_CLASSIFICATION_DETAILS });
    
		if (data && data.terrainClassifications) {
			data.terrainClassifications && data.terrainClassifications.map((item) => {
				if (item.id === id) {
					details = item;
				}
				return true;
			});
      
			dispatch({ type: TERRAIN_CLASSIFICATION_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: TERRAIN_CLASSIFICATION_DETAILS_ERROR });
		}
	});
  
	const updateTerrainClassificationFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: data.details ? data.details.id : null,
			pipelineChainage: data.details ? data.details.pipelineChainage : null,
			rouMarker: data.details ? data.details.rouMarker : '',
			latitude: data.details ? data.details.latitude : null,
			longitude: data.details ? data.details.longitude : null,
			terrainClassification: data.details ? data.details.terrainClassification : '',
			landmarkDescription: data.details ? data.details.landmarkDescription : '',
		},
		onSubmit: (values) => {
			const id = data.details ? data.details.id : 0;
			const terrainClassification = values.terrainClassification ? values.terrainClassification : '';
			const landmarkDescription = values.landmarkDescription ? values.landmarkDescription : '';
      
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/pipelineconfig/${id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization:`Bearer ${authtoken}`
				},
				data: {
					terrainClassification: terrainClassification,
					landmarkDescription: landmarkDescription
				}
			};

			dispatch( { type: UPDATE_TERRAIN_CLASSIFICATION });

			axios
				.request(config)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						dispatch({type: UPDATE_TERRAIN_CLASSIFICATION_SUCCESS, payload: response});
						Modal.success({ title: langdata && langdata.Terrainclassificationdetailsupdatedsuccessfully ? langdata.Terrainclassificationdetailsupdatedsuccessfully : 'Terrain classification details updated successfully.' });
						closeTerrainClassificationModal();
						loadTerrainClassification({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					} else {
						dispatch({ type: UPDATE_TERRAIN_CLASSIFICATION_ERROR });
						Modal.warning({ title: langdata && langdata.Unabletoupdatetheterrainclassificationdetails ? langdata.Unabletoupdatetheterrainclassificationdetails : 'Unable to update the terrain classification details.' });  
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type: UPDATE_TERRAIN_CLASSIFICATION_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}  
				});
		}
	});

	const handleTerrainData = () =>{
		navigate('/terrain-management');
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p> {langdata && langdata.TerrainClassificationContent1 ? langdata.TerrainClassificationContent1 : 'Terrain details of each chainage are displayed. Filter options available to filter by allocation, ROU Marker and Terrain Classification.'} </p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={6}>
						<Row>
							<Space>
								<FileProtectOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification' }</Title>
						</Row>
					</Col>
					
					<Col span={18}>
						<Row  style={{ display : 'flex', justifyContent : 'right'}}>
							<Col>

								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
									<Space>
										<Select
											style={{ minWidth: '150px', maxWidth:'150px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											// value={regionId}
											options={regions}
											onSelect={(e, options)=>handleSetRegion(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyROUmarker ? langdata.FilterbyROUmarker : 'Filter by ROU marker'}>
									<Space>
										<Select
											style={{ width: '150px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectROUmarker ? langdata.SelectROUmarker : 'Select ROU marker'}
											optionFilterProp="children"
											defaultValue={null}
											options={roumarkers}
											onSelect={(e, options)=>handleSetROUMarker(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title= {langdata && langdata.Filterbyterrainclassification ? langdata.Filterbyterrainclassification : 'Filter by terrain classification'}>
									<Space>
										<Select
											style={{ width: '150px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.Selectterrainclassification ? langdata.Selectterrainclassification : 'Select terrain classification'}
											optionFilterProp="children"
											defaultValue={'all'}
											options={filterTerrain}
											onSelect={(e, options)=>SethandleTerrainClassification(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								{permission==='superadmin' ? 
									<Tooltip placement="bottom" title={langdata && langdata.TerrainManagement ? langdata.TerrainManagement : 'Terrain Management'}>
										<Space>&nbsp;</Space>
										<Space>
											<Button 
												style={{ margin:'5px'}}
												type='primary'
												onClick={handleTerrainData}
											>{langdata && langdata.TerrainManagement ? langdata.TerrainManagement : 'Terrain Management'}</Button>
										</Space>
									</Tooltip> : null}
								<Space>&nbsp;&nbsp;</Space>
								<Popover title={langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.terrainClassifications && data.terrainClassifications.length > 0 ? (
							<Table
								scroll={{
									x: 900,
								}} 
								columns={columns} 
								dataSource={data.terrainClassifications} 
								pagination = {data.terrainClassifications.length > 0 ? data.pagination : 0}
								onChange = { loadTerrainClassification }
								onRow = {(record) => {
									return {
										onClick: () => { 
											loadTerrainClassificationDetails(record.id);
										}
									};
								}} 
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.loading} />

			{/* Terrain Classification Details Modal Starts */}
			<Modal
				title= {langdata && langdata.TerrainClassificationDetails ? langdata.TerrainClassificationDetails : 'Terrain Classification Details'}
				centered
				destroyOnClose={true}
				open={showTerrainClassificationModal}
				onOk={{closeTerrainClassificationModal}}
				onCancel={closeTerrainClassificationModal}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeTerrainClassificationModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						{ permission === 'superadmin' || permission === 'admin' ? <Button key="submit" type="primary" onClick={updateTerrainClassificationFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical" onSubmit={updateTerrainClassificationFormik.handleSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} required>
								<Input name="pipelineChainage" placeholder={langdata && langdata.Chainage ? langdata.Chainage : 'Chainage'} defaultValue={ updateTerrainClassificationFormik.values.pipelineChainage } value={ updateTerrainClassificationFormik.values.pipelineChainage } onChange={updateTerrainClassificationFormik.handleChange} disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker'} required>
								<Input name="rouMarker" placeholder={langdata && langdata.ROUMarker ? langdata.ROUMarker : 'ROU Marker'} defaultValue={ updateTerrainClassificationFormik.values.rouMarker } value={ updateTerrainClassificationFormik.values.rouMarker } onChange={updateTerrainClassificationFormik.handleChange} disabled />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} required>
								<Input name="latitude" placeholder={langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'} defaultValue={ updateTerrainClassificationFormik.values.latitude } value={ updateTerrainClassificationFormik.values.latitude } onChange={updateTerrainClassificationFormik.handleChange} disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} required>
								<Input name="longitude" placeholder={langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'} defaultValue={ updateTerrainClassificationFormik.values.longitude } value={ updateTerrainClassificationFormik.values.longitude } onChange={updateTerrainClassificationFormik.handleChange} disabled />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						{permission && permission === 'superadmin' || permission === 'admin' ?
							<Col span={12}>
								<Form.Item label={langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification'} required>
									<Select placeholder={langdata && langdata.SelectTerrain ? langdata.SelectTerrain : 'Select Terrain'} options={terrain} defaultValue={ updateTerrainClassificationFormik.values.terrainClassification } onChange={(value) => updateTerrainClassificationFormik.setFieldValue('terrainClassification', value)} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col> : 
							<Col span={12}>
								<Form.Item name="terrainClassification" label={langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification'} rules={[{ required: true, message: langdata && langdata.TerrainClassification ? langdata.TerrainClassification : 'Terrain Classification' }]} required>
									<Input disabled placeholder={langdata && langdata.SelectTerrain ? langdata.SelectTerrain : 'Select Terrain'} options={terrain} defaultValue={ updateTerrainClassificationFormik.values.terrainClassification } onChange={(value) => updateTerrainClassificationFormik.setFieldValue('terrainClassification', value)} showSearch filterOption={filterOption}/>
								</Form.Item>
							</Col>
						}
						
						{/* } */}
						{permission && permission === 'superadmin' || permission === 'admin' ?

							<Col span={12}>
								<Form.Item label= {langdata && langdata.LandmarkDescription ? langdata.LandmarkDescription : 'Landmark Description'} required>
									<Input name="landmarkDescription" placeholder={langdata && langdata.LandmarkDescription ? langdata.LandmarkDescription : 'Landmark Description'} defaultValue={ updateTerrainClassificationFormik.values.landmarkDescription } value={ updateTerrainClassificationFormik.values.landmarkDescription } onChange={updateTerrainClassificationFormik.handleChange} />
								</Form.Item>
							</Col>
							:
							<Col span={12}>
								<Form.Item label={langdata && langdata.LandmarkDescription ? langdata.LandmarkDescription : 'Landmark Description'} required>
									<Input disabled name="landmarkDescription" placeholder={langdata && langdata.LandmarkDescription ? langdata.LandmarkDescription : 'Landmark Description'} defaultValue={ updateTerrainClassificationFormik.values.landmarkDescription } value={ updateTerrainClassificationFormik.values.landmarkDescription } onChange={updateTerrainClassificationFormik.handleChange} />
								</Form.Item>
							</Col>
						}


					</Row>
				</Form>
			</Modal>
			{/* Terrain Classification Details Modal Ends */}
		</>
	);
}
