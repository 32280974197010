import { GRADE_MANAGEMENT_LIST, GRADE_MANAGEMENT_LIST_SUCCESS, GRADE_MANAGEMENT_LIST_ERROR, GRADE_MANAGEMENT_DETAILS, ADD_GRADE_MANAGEMENT_DETAILS, ADD_GRADE_MANAGEMENT_DETAILS_SUCCESS, ADD_GRADE_MANAGEMENT_DETAILS_ERROR, UPDATE_GRADE_MANAGEMENT, UPDATE_GRADE_MANAGEMENT_SUCCESS, UPDATE_GRADE_MANAGEMENT_ERROR, DELETE_GRADE_MANAGEMENT, DELETE_GRADE_MANAGEMENT_SUCCESS, DELETE_GRADE_MANAGEMENT_ERROR } from './constants';


export default function gradeManagementReducer(state, action) {
	let grademanagement = [];

	switch (action.type) {
	case GRADE_MANAGEMENT_LIST:
		return { ...state, pending: true };
      
	case GRADE_MANAGEMENT_LIST_SUCCESS:
        
		if (action.payload && action.payload.length > 0) {
			action.payload.map( (item) => {
            
				let data = {};

				data.id = item.id ? item.id : 0;
				data.pipelineId = item.pipelineId ? item.pipelineId : 0;
				data.patchPercentage = item.patchPercentage ? item.patchPercentage : 0;
				data.averageSpeed = item.averageSpeed ? item.averageSpeed : 0;
				data.idleTime = item.idleTime ? item.idleTime : 0;
				data.speedViolatioNumber = item.speedViolatioNumber ? item.speedViolatioNumber : 0;
				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				return grademanagement.push(data);
			});
          
			return { ...state, grademanagement: grademanagement, pending: false, error: false };
		} else {
			if (action.payload && action.payload.length === 0) {
				return { ...state, grademanagement: grademanagement, pending: false, error: false };
			} else {
				return { ...state, pending: false, error: true };
			}
		}
      
	case GRADE_MANAGEMENT_LIST_ERROR:
		return { ...state, pending: false, error: true };

	case GRADE_MANAGEMENT_DETAILS:
		return { ...state, details:action.payload, pending: false };

	case ADD_GRADE_MANAGEMENT_DETAILS:
		return { ...state, pending: false };

	case ADD_GRADE_MANAGEMENT_DETAILS_SUCCESS:
		return { ...state, pending: false, error: false };

	case ADD_GRADE_MANAGEMENT_DETAILS_ERROR:
		return { ...state, pending: false, error: true };

	case UPDATE_GRADE_MANAGEMENT:
		return { ...state, pending: false };

	case UPDATE_GRADE_MANAGEMENT_SUCCESS:
		return { ...state, pending: false, error: false };

	case UPDATE_GRADE_MANAGEMENT_ERROR:
		return { ...state, pending: false, error: true };
      
	case DELETE_GRADE_MANAGEMENT:
		return { ...state, pending: false };

	case DELETE_GRADE_MANAGEMENT_SUCCESS:
		return { ...state, pending: false, error: false };

	case DELETE_GRADE_MANAGEMENT_ERROR:
		return { ...state, pending: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}