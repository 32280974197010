/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Button, Col, Row, Select, Space, Spin, Table, Tooltip, Typography } from 'antd';
import Search from 'antd/es/input/Search';
import { Content } from 'antd/es/layout/layout';
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import reducer from './reducer';
import { ALLOCATION_SUB, ALLOCATION_SUB_ERROR, ALLOCATION_SUB_SUCCESS } from './constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusOutlined, UserOutlined, HomeOutlined} from '@ant-design/icons';
import { useAuth } from '../Context';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

export default function AllocationSub () {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);

	const initalState = { pending: false, error: false, allocateSubUser : [], pagination: { total: 0, limit: 10, skip: 0, current:1 }};
	const [data, dispatch] = useReducer(reducer, initalState);
	const navigate = useNavigate();
	const {getUserTypes, userTypeData} = useAuth();

	const { state } = useLocation();
	const currentPage = state && state.usertype.replace(/^./, state.usertype[0].toUpperCase());

	// const [userTypeId, setUserTypeId] = useState();
	const [userSearch, setUserSearch] = useState(null);

	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const previousState = [];
	previousState.push([state.usertype]);

	state && state.previous && state.previous.length == 0 || state.previous == undefined ? '' : previousState.push([state && state.previousSubState]);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const columns = [
		{
			title : langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex : 'name',
			key : 'name'
		},
		{
			title : langdata && langdata.Email ? langdata.Email :  'Email',
			dataIndex : 'email',
			key : 'email'
		},
		{
			title : langdata && langdata.Phone ? langdata.Phone : 'Phone',
			dataIndex : 'cellPhone',
			key : 'cellPhone'
		},
		{
			title : langdata && langdata.UserType ? langdata.UserType : 'User Type',
			dataIndex : 'usertype',
			key : 'usertype'
		},
		{
			title : langdata && langdata.Allocations ? langdata.Allocations : 'Allocations',
			dataIndex : 'allocationsList',
			key : 'allocationsList'
		},{
			title : langdata && langdata.Action ? langdata.Action : 'Action',
			key : 'action',
			render: (_, record) => (
				<Space  size="middle">
					<Button style={{ display : 'contents', color : record.allocationsList === 'No allocation' || record.usertype === permission || permission === 'CRO' && record.usertype === 'ROU'  ? '' : 'orange'}} title="Edit Allocation" disabled = {record.allocationsList === 'No allocation' || record.usertype === permission || permission === 'CRO' && record.usertype === 'ROU' ? true : false} onClick={() => {navigate(( '/edit-allocation'),{ state: { userId: record.id } });}}>{langdata && langdata.EditAllocation ? langdata.EditAllocation : 'Edit Allocation'}</Button>
					{ record.usertype === 'CRO' && record.allocationsList !== 'No allocation' ?
						<>
						| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/allocation-sub/supervisor'),{ state: { userId : record.id, usertype : 'supervisor' , previous : previousState, previousSubState : state.usertype} });}}>{langdata && langdata.ViewSupervisor ? langdata.ViewSupervisor : 'View Supervisor'}</Button>
						</>
						:  record.usertype === 'supervisor' && record.allocationsList !== 'No allocation' ? 
							<>
							| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(( '/allocation-sub/linewalker'),{ state: { userId : record.id, usertype : 'linewalker', previous : previousState, previousSubState : state.usertype} });}}>{langdata && langdata.ViewLinewalker ? langdata.ViewLinewalker : 'View Linewalker'}</Button>
							</>
							: record.usertype === 'linewalker' && record.allocationsList !== 'No allocation' ?
								<>
							|	<Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {navigate(('/linewalkerstable'), { state: { userId : record.id, usertype : 'linewalker', name : record.name } });}}>{langdata && langdata.ViewInspection ? langdata.ViewInspection : 'View Inspection'}</Button>
								</>
								:
								null }
				</Space>
			)
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(() => {
		dispatch({ type : ALLOCATION_SUB });
		getUserTypes(authtoken);
		if(!authtoken){
			navigate('/');
		}
        
		loadAllocation(1);

	},[authtoken, state, userSearch]);

	const loadAllocation = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		let params='';
		if(state && state.usertype === 'linewalker') {
			params+= `supervisorId=${state && state.userId}&usertypeId=4`;
		}  else if(state && state.usertype === 'supervisor') {
			params+= `crmId=${state && state.userId}`;
		} else if(state && state.usertype === 'CRO') {
			params+= `rouId=${state && state.userId}&usertypeId=3`;
		} else if(state && state.usertype === 'npv') {
			params+= `rouId=${state && state.userId}&usertypeId=7`;
		}
		let config1 = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/userallocation?${params}`,
			headers: { 
				'Authorization': `Bearer ${authtoken}`     
			}
		};

		axios.request(config1)
			.then((response) => {
				if (response.status === 200 && response.data.data.length > 0) {
					let inIds='';
					response.data.data.map((item) => {
						inIds+= `&id[$in][]=${item.details.id}`;
					});
					let url = '';
					if (userSearch) {
						url = `${api}/users?isDeleted=false&$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[firstName]=1&email[$iLike]=%${userSearch}%${inIds}`;
					} else {
						url = `${api}/users?isDeleted=false&$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[firstName]=1${inIds}`;
					}
					let config2 = {
						method: 'get',
						maxBodyLength: Infinity,
						url: url,
						headers: { 
							'Authorization': `Bearer ${authtoken}` 
						}
					};

					axios.request(config2)
						.then((response) => {
							if (response.status === 200) {
								dispatch({ type : ALLOCATION_SUB_SUCCESS, payload : response.data ,page:pagination.current });
								// response.data.data.map((item) => {
								// 	setUserTypeId(item.usertypeId);
								// });
							} else {
								dispatch ({ type : ALLOCATION_SUB_ERROR});
							}
						})
						.catch((error) => {
							console.log(error);
							dispatch ({ type : ALLOCATION_SUB_ERROR});
						});

				} else {
					dispatch ({ type : ALLOCATION_SUB_SUCCESS});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : ALLOCATION_SUB_ERROR });
			});
	};

	const userType = [];
	// eslint-disable-next-line array-callback-return
	userTypeData && userTypeData.data.map((item) => {
		userType.push({
			label : item.userType,
			value : item.id
		});
	});

	const handleUserSearch = (value) => {
		setUserSearch(value);
	};

	const AddAllocation = () => {
		navigate('/add-allocation');
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	let bredcrumb = [];
	{state && state.previous && state.previous.length == 2 ?

		bredcrumb = [
			{
				href: '/dashboard',
				title: <HomeOutlined />,
			},
			{
				title:  <a href = '/allocations'>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}</a> 
			},
			{
				title:  <a onClick={()=>{navigate(-2);}}>{state && state.previous[1][0].replace(/^./, state.previous[1][0][0].toUpperCase())}</a> 
			},
			{
				title:  <a onClick={()=>{navigate(-1);}}>{state && state.previous[0][0].replace(/^./, state.previous[0][0][0].toUpperCase())}</a> 
			},
			{
				title: `${currentPage}`,
			},
		] :
		state && state.previous && state.previous.length == 1 ?

			bredcrumb = [
				{
					href: '/dashboard',
					title: <HomeOutlined />,
				},
				{
					title:  <a href = '/allocations'>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}</a> 
				},
				{
					title:  <a onClick={()=>{navigate(-1);}}>{state && state.previous[0][0].replace(/^./, state.previous[0][0][0].toUpperCase())}</a> 
				},
				{
					title: `${currentPage}`,
				},
			] :
			bredcrumb = [
				{
					href: '/dashboard',
					title: <HomeOutlined />,
				},
				{
					title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Allocation ? langdata.Allocation : 'Allocation'}</a> 
				},
				{
					title: `${currentPage}`,
				},
			]; 
	}



	return (
		<Content>
			{state ?
				<>
					<Row 
						style={{
							// marginLeft:'15px',
							// marginTop: '10px'
							margin : '10px'
						}}            
					>
						<Breadcrumb
							items={bredcrumb}
						/>
					</Row>

					<Row
						style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center',
						}}
					>
						<Col span={5}>
							<Row>
								<Space>
									<UserOutlined style={{fontSize:'30px'}}/>
									{/* </Col> */}
								</Space>
								<Space>&nbsp;&nbsp;</Space>
					
								{/* <Col style={{marginLeft:'4px'}} span={19}> */}
								<Title level={4} style={{ paddingTop:'10px'}}> {currentPage}</Title>
							</Row>
						</Col>

						{/* <Col className="gutter-row" span={4}>
						<Title level={3} style={{ lineHeight: '2'}}></Title>
					</Col> */}
						<Col span={19} >
							<Row justify="end">

								<Tooltip placement="bottom" title={langdata && langdata.Searchbyemail ? langdata.Searchbyemail : 'Search by email'}>
									<Space>
										{/* <Search
									style={{ minWidth: '240px', margin : '5px'}}
									showSearch
									placeholder="Search by name"
									onSearch={handleUserSearch}
								/> */}
										<Search
											style={{margin:'5px', marginTop:'5px', verticalAlign:'middle',paddingRight:'10px'}}
											showSearch
											placeholder={langdata && langdata.Searchbyemail ? langdata.Searchbyemail : 'Search by email'}
											onSearch={handleUserSearch}
											enterButton
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.SelectUserType ? langdata.SelectUserType : 'Select User Type'}>
									<Space>
										<Select
											style={{ minWidth: '200px', margin : '5px', display : 'none'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectUserType ? langdata.SelectUserType : 'Select User Type'}
											optionFilterProp="children"
											defaultValue={'ALL'}
											options={userType}
											disabled={true}
											value={currentPage}
										/>
									</Space>
								</Tooltip>  
								<Space>&nbsp;&nbsp;</Space> 

								<Tooltip placement="bottom" title={langdata && langdata.AddAllocation ? langdata.AddAllocation : 'Add allocation'}>
									<Space>
										<Button type="primary" onClick = {AddAllocation} style={{ margin : '5px'}}><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space> 
							</Row>
						</Col>
					</Row>
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24} style={{ margin : '10px'}}>
							<Table scroll={{
								x: 900,
							}} columns={columns} dataSource={data.allocateSubUser} pagination = { data.allocateSubUser.length > 0 ? data.pagination : 0 } onChange={loadAllocation}/>
						</Col>
					</Row>
					<Spin fullscreen spinning={data.pending} />
				</> : '' }
		</Content>
	);
}