/* eslint-disable react/react-in-jsx-scope */
import { Breadcrumb, Card,  Col, Row, Spin, Tabs, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation } from 'react-router-dom';

import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'leaflet';
import TabPane from 'antd/es/tabs/TabPane';
import { useAuth } from '../../Context';
import pidsdata from './reducer';
import { CHANNEL_INFO_LIST, CHANNEL_INFO_LIST_ERROR, CHANNEL_INFO_LIST_SUCCESS } from './constants';
import axios from 'axios';
import { FullscreenOutlined,FullscreenExitOutlined, HomeOutlined } from '@ant-design/icons';
import GoogleMap from '../../Map/map';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';



// const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};
// const pidsicon = new Icon({
// 	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
// 	iconUrl:require('../../../images/refinery.png'),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]});


const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]});
const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});


export default function PidDetails() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const { state } = useLocation();
	const navigate = useNavigate();
	const {handleGetMapdata, mapdata, optionalpipedata,handleOptionalPipelinedata} = useAuth();
	const [size, setSize] = useState('200px');
	const initalState = {channel_data:[],details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(pidsdata, initalState);

	const pipeline = [];
	const center=[];
	// const odmeter=[];
	// const optionalpath = [];
	const markers = [];
	// const points = [];
	const pidsmarker=[{geocode:[state.data.latitude, state.data.longitude],pop:state.data.pidsName.toUpperCase()}];
	const gmappidsmarker=[{latitude:state.data.latitude, longitude: state.data.longitude,label:state.data.pidsName.toUpperCase(),value:state.data.id}];

	// const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			if(state && state.data && state.data.latitude){
				center.push(parseFloat(state && state.data && state.data.latitude), parseFloat(state && state.data && state.data.longitude));
			}else{
				center.push(item.latitude,item.longitude);
			}
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	// mapdata &&  mapdata.map((item)=>{

	// 	odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	// });
  
	// optionalpipedata &&  optionalpipedata.map((item)=>{

	// 	optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	// });

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:'CH'
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:'WB'
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:'TLP'
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:'KMP'
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:'TP'
			},);
		}


	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		if(map_type && map_type === 'leaflet') {
			handleGetMapdata(authtoken,state.data.regioninfoId,null,null);
			handleOptionalPipelinedata(authtoken,state.data.pipelineId,state.data.regioninfoId,null,null);
		}
		dispatch({ type : CHANNEL_INFO_LIST});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/channelinfo?pidsinfoId=${state.data && state.data.id}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : CHANNEL_INFO_LIST_SUCCESS, payload : response.data});
				}else {
					dispatch({ type : CHANNEL_INFO_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : CHANNEL_INFO_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});      

	},[authtoken]);

	return (
		<>
			{/* <Row style={{justifyContent:'right', display:'flex'}}>

				<Button type='primary' onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} style={{marginRight:'20px'}} >View Map</Button>
			</Row> */}
			{size && size === '200px' ? 
				<Content>

					{/* map filter starts  */}
					{/* {map_type && map_type !== 'leaflet' ?

						<Row span={24} style={{ color:'#000000a6', margin : '10px'}}>
							<Col span={24}>
								<Checkbox style={{ color:'#000000a6'}}>FIBER</Checkbox>
								<Checkbox style={{ color:'#000000a6'}} defaultChecked>PIPELINE</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>PIPELINE BORDER</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>KMP</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>CH</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>WB</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>Turning Point</Checkbox>
								<Checkbox style={{ color:'#000000a6'}}>TLP</Checkbox>
							</Col>
						</Row>

						: null } */}

					{/* map filter ends */}

					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}} style={{margin:'10px'}} >

						<Col className="gutter-row" span={24}>
      

							<Card>
								<Breadcrumb
									items={[
										{
											href: '/dashboard',
											title: <HomeOutlined />,
										},
										{
											title: ( state.path === 'pidsmanagement' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.PIDSManagement ? langdata.PIDSManagement : 'PIDS Management'}</a> : <a onClick={()=>{navigate(-1);}}>PIDS</a> )
										},
										{
											title: langdata && langdata.PIDSDetails ? langdata.PIDSDetails :'PIDS Details',
										},
									]}
									style={{ marginBottom : '10px'}}
								/>


								{/* map starts */}
								{map_type && map_type !== 'leaflet' ?

									< GoogleMap pidsData = {pidsmarker && pidsmarker} regionPids = {gmappidsmarker} height = {'30vh'} />

									: 	 mapdata && mapdata ? 
										<div className="App" style={{textAlign: 'left'}}>
        
											<MapContainer center={center} zoom={17} scrollWheelZoom={false} style={{height:size}}>
												<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenOutlined /></button>
												<TileLayer
													attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
													url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
												/>
												{/* {view && view==='view' ? 
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        :
    
        <TileLayer
              url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
              maxZoom= {20}
              subdomains={['mt1','mt2','mt3']}
              
              />
              } */}
    
												{/* <TileLayer
          attribution='Stamen Watercolor'
          url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
        /> */}
    
												<LayersControl position="topright" >
    
													<LayersControl.Overlay  checked name={langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}>
														<MarkerClusterGroup>
															{pidsmarker && pidsmarker.map((marker,index)=>
																<Marker key={`pidsmarker-${index}`} position={marker.geocode} icon={pidsicon}>
																	<Popup>
																		{marker.pop}
																	</Popup>
																	{/* <Tooltip>{marker.pop}</Tooltip> */}
																</Marker>
    
															)}       
														</MarkerClusterGroup>
													</LayersControl.Overlay>
    
    
													<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP : 'KMP'}>
														<MarkerClusterGroup>
    
															{KMP && KMP.map((marker,index)=>
																<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
																	<Popup>
																		{marker.pop}
																	</Popup>
																	{/* <Tooltip>{marker.pop}</Tooltip> */}
																</Marker>
    
															)}       
														</MarkerClusterGroup>
    
													</LayersControl.Overlay>
    
    
													<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB : 'WB'}>
														<MarkerClusterGroup>
    
															{WB && WB.map((marker,index)=>
																<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
																	<Popup>
																		{marker.pop}
																	</Popup>
																	{/* <Tooltip>{marker.pop}</Tooltip> */}
																</Marker>
    
															)}       
														</MarkerClusterGroup>
    
													</LayersControl.Overlay>
    
    
    
    
													<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP : 'TP'}>
														<MarkerClusterGroup>
    
															{Turn && Turn.map((marker,index)=>
																<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
																	<Popup>
																		{marker.pop}
																	</Popup>
																	{/* <Tooltip>{marker.pop}</Tooltip> */}
																</Marker>
    
															)}       
														</MarkerClusterGroup>
    
													</LayersControl.Overlay>
    
    
													<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP : 'TLP'}>
														<MarkerClusterGroup>
    
															{TLP && TLP.map((marker,index)=>
																<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
																	<Popup>
																		{marker.pop}
																	</Popup>
																	{/* <Tooltip>{marker.pop}</Tooltip> */}
																</Marker>
    
															)}       
														</MarkerClusterGroup>
    
													</LayersControl.Overlay>
    
    
													{/* <LayersControl.Overlay  name="POINTS">
										<MarkerClusterGroup>
    
											{points && points.slice(-100).map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
													<Popup>
														{marker.pop}
													</Popup>
													<Tooltip>{marker.pop}</Tooltip>
												</Marker>
    
											)}       
										</MarkerClusterGroup>
    
									</LayersControl.Overlay> */}
    
    
    
													<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH : 'CH'}>
														<MarkerClusterGroup>
															{markers && markers.map((marker,index)=>
																<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
																	<Popup>
																		{marker.pop}
																	</Popup>
																	{/* <Tooltip>{marker.pop}</Tooltip> */}
																</Marker>
    
															)}
    
														</MarkerClusterGroup>
													</LayersControl.Overlay>
    
    
													{/* <LayersControl.Overlay  checked name="LINE">
										<Polyline pathOptions={linecolor} weight={7} positions={line} />
									</LayersControl.Overlay> */}
    
    
													<LayersControl.Overlay  checked name={langdata && langdata.Pipeline ? langdata.Pipeline : 'Pipeline'}>
														<MarkerClusterGroup>
															<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
																{tempMapArray && Object.values(tempMapArray).map((item) => {
																	let pipeline = [];
																	item.map((data) => {
																		pipeline.push({lat : data.latitude,lng : data.longitude});
																	});
																	return (
																		<>
																			<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																		</>
																	);

																})}
															</Pane>
														</MarkerClusterGroup>
													</LayersControl.Overlay>

													<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder : 'Pipeline Border'}>
														<MarkerClusterGroup>
															{tempMapArray && Object.values(tempMapArray).map((item) => {
																let pipelineBorder = [];
																item.map((data) => {
																	pipelineBorder.push({lat : data.latitude,lng : data.longitude});
																});
																return (
																	<>
																		<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																	</>
																);

															})}
														</MarkerClusterGroup>

													</LayersControl.Overlay>
    
													<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber : 'Fiber'}>
														<MarkerClusterGroup>
															{tempMapArray && Object.values(tempMapArray).map((item) => {
																let odmeter = [];
																item.map((data) => {
																	odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
																});
																return (
																	<>
																		<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
																	</>
																);

															})}
														</MarkerClusterGroup>
													</LayersControl.Overlay>
													<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath : 'Optional Path'}>
														<MarkerClusterGroup>
															{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
																let optionalpath = [];
																item.map((data) => {
																	optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
																});
																return (
																	<>
																		<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
																	</>
																);
															})}
														</MarkerClusterGroup>
													</LayersControl.Overlay>
    
    
												</LayersControl>
											</MapContainer>
    
										</div>
										:null}


								{/* map view ends */}

								{/* Tab view starts */}

								<Tabs defaultActiveKey="1">
									<TabPane tab={langdata && langdata.PIDSDetails ? langdata.PIDSDetails : 'PIDS Details'} key="1">
      
										<Row gutter={{
											xs: 8,
											sm: 16,
											md: 24,
											lg: 32,
										}}
										style={{
											// margin: '10px',
											marginTop:'0px', paddingTop:'0px',
											// padding:'10px',
											background: '#FFFFFF',
											alignItems:'center',
										}}
										>
											<Col style={{marginTop:'0px', paddingTop:'0px'}} className="gutter-row" span={24}>    
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.Name ? langdata.Name : 'Name'}</span>: {state.data && state.data && state.data.pidsName}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.IPAddress ? langdata.IPAddress : 'IP Address'}</span>: {state.data && state.data && state.data.ip}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.Port ? langdata.Port :'Port'}</span>:</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'}</span>: {state.data && state.data.latitude}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'}</span>: {state.data && state.data.longitude}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.ParentAlarmType ? langdata.ParentAlarmType : 'Parent Alarm Type'}</span>: <b>{state.data && state.data.parentAlarmType.toUpperCase()}</b></Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.PIDSAlarmTableName ? langdata.PIDSAlarmTableName : 'PIDS Alarm Table Name'}</span>: {state.data && state.data && localStorage.getItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`).toLowerCase().replaceAll('"','')+'_'+(state.data.pidsName.toLowerCase())+'alarm'}</Typography>
												<Typography style={{fontSize:'15px'}}><span style={{color:'grey'}} >{langdata && langdata.PIDSEventTableName ? langdata.PIDSEventTableName : 'PIDS Event Table Name'}</span>: {state.data && state.data && localStorage.getItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`).toLowerCase().replaceAll('"','')+'_'+(state.data.pidsName.toLowerCase())+'eventconfirmed'}</Typography>
											</Col>

										</Row>    
									</TabPane>
								</Tabs>
								<br/>
								<Tabs
									defaultActiveKey="1"
									style={{
										height: 300,
									}}
									items={data.channel_data && data.channel_data.map((item, i) => {
										const id = String(i);
										
										return {
											label: `${langdata && langdata.Channel ? langdata.Channel : 'Channel'}-${item.channelId}`,
											key: id,
											children: (
												<div>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.Name ? langdata.Name : 'Name'}</span>: <b>{item.name}</b></Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.BinSize ? langdata.BinSize : 'Bin Size'}</span>: {item.binSize}</Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.StartingPosition ? langdata.StartingPosition : 'Starting Position'}</span>: {item.startPos}</Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.AmplitudeNo ? langdata.AmplitudeNo : 'Amplitude No'}</span>: {item.amplitudeNumber}</Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.DeployedProfile ? langdata.DeployedProfile : 'Deployed Profile'} - {langdata && langdata.ModelName ? langdata.ModelName : 'Model Name'}</span>: {item.profile.model_name}</Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.DeployedProfile ? langdata.DeployedProfile : 'Deployed Profile'} - {langdata && langdata.Normalisation ? langdata.Normalisation : 'Normalisation'}</span>: {item.profile.normalisation}</Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.DeployedProfile ? langdata.DeployedProfile : 'Deployed Profile'} - {langdata && langdata.AlarmGroupingDistance ? langdata.AlarmGroupingDistance : 'Alarm Grouping Distance'}</span>: {item.profile.alarm_grouping_distance}</Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.DeployedProfile ? langdata.DeployedProfile : 'Deployed Profile'} - {langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold'}</span>: {item.profile.max_threshold}</Typography>
													<Typography style={{fontSize:'15px', marginLeft : '10px'}}><span style={{color:'grey'}} >{langdata && langdata.DeployedProfile ? langdata.DeployedProfile : 'Deployed Profile'} - {langdata && langdata.NormalisationThreshold ? langdata.NormalisationThreshold : 'Normalisation Threshold'}</span>: {item.profile.normalisation_threshold}</Typography>
													<br/>
												</div>
											),
											
										};
									})}
								/>

							</Card>
						</Col>

					</Row>

					


				</Content>
          
				: 
				<div className="App" style={{textAlign: 'left', margin : '10px'}}>
					<MapContainer center={center} zoom={17} scrollWheelZoom={false}>
						<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenExitOutlined /></button>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

						{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

						<LayersControl position="topright" >

							<LayersControl.Overlay  checked name={langdata && langdata.PIDS ? langdata.PIDS : 'PIDS'}>
								<MarkerClusterGroup>
									{pidsmarker && pidsmarker.map((marker,index)=>
										<Marker key={`pidsmarker-${index}`} position={marker.geocode} icon={pidsicon}>
											<Popup>
												{marker.pop}
											</Popup>
											{/* <Tooltip>{marker.pop}</Tooltip> */}
										</Marker>

									)}       
								</MarkerClusterGroup>
							</LayersControl.Overlay>


							<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP : 'KMP'}>
								<MarkerClusterGroup>

									{KMP && KMP.map((marker,index)=>
										<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
											<Popup>
												{marker.pop}
											</Popup>
											{/* <Tooltip>{marker.pop}</Tooltip> */}
										</Marker>

									)}       
								</MarkerClusterGroup>

							</LayersControl.Overlay>

    
							<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB : 'WB'}>
								<MarkerClusterGroup>

									{WB && WB.map((marker,index)=>
										<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
											<Popup>
												{marker.pop}
											</Popup>
											{/* <Tooltip>{marker.pop}</Tooltip> */}
										</Marker>

									)}       
								</MarkerClusterGroup>

							</LayersControl.Overlay>




							<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP : 'TP'}>
								<MarkerClusterGroup>

									{Turn && Turn.map((marker,index)=>
										<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
											<Popup>
												{marker.pop}
											</Popup>
											{/* <Tooltip>{marker.pop}</Tooltip> */}
										</Marker>

									)}       
								</MarkerClusterGroup>

							</LayersControl.Overlay>

  
							<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP : 'TLP' }>
								<MarkerClusterGroup>

									{TLP && TLP.map((marker,index)=>
										<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
											<Popup>
												{marker.pop}
											</Popup>
											{/* <Tooltip>{marker.pop}</Tooltip> */}
										</Marker>

									)}       
								</MarkerClusterGroup>

							</LayersControl.Overlay>


							{/* <LayersControl.Overlay  name="POINTS">
								<MarkerClusterGroup>

									{points && points.map((marker,index)=>
										<Marker key={`points-${index}`} position={marker.geocode} icon={markericon}>
											<Popup>
												{marker.pop}
											</Popup>
											<Tooltip>{marker.pop}</Tooltip>
										</Marker>

									)}       
								</MarkerClusterGroup>

							</LayersControl.Overlay> */}



							<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH : 'CH'}>
								<MarkerClusterGroup>
									{markers && markers.map((marker,index)=>
										<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
											<Popup>
												{marker.pop}
											</Popup>
											{/* <Tooltip>{marker.pop}</Tooltip> */}
										</Marker>

									)}

								</MarkerClusterGroup>
							</LayersControl.Overlay>

       
							{/* <LayersControl.Overlay  checked name="LINE">
								<Polyline pathOptions={linecolor} weight={7} positions={line} />
							</LayersControl.Overlay> */}

      
							<LayersControl.Overlay  checked name={langdata && langdata.Pipeline ? langdata.Pipeline : 'Pipeline'}>
								<MarkerClusterGroup>
									<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
										{tempMapArray && Object.values(tempMapArray).map((item) => {
											let pipeline = [];
											item.map((data) => {
												pipeline.push({lat : data.latitude,lng : data.longitude});
											});
											return (
												<>
													<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
												</>
											);
										})}
									</Pane>
								</MarkerClusterGroup>
							</LayersControl.Overlay>

							<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder :'Pipeline Border'}>
								<MarkerClusterGroup>
									{tempMapArray && Object.values(tempMapArray).map((item) => {
										let pipelineBorder = [];
										item.map((data) => {
											pipelineBorder.push({lat : data.latitude,lng : data.longitude});
										});
										return (
											<>
												<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
											</>
										);
									})}

								</MarkerClusterGroup>
							</LayersControl.Overlay>

							<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber : 'Fiber'}>
								<MarkerClusterGroup>
									{tempMapArray && Object.values(tempMapArray).map((item) => {
										let odmeter = [];
										item.map((data) => {
											odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
										});
										return (
											<>
												<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
											</>
										);
									})}
								</MarkerClusterGroup>
							</LayersControl.Overlay>

							<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath : 'Optional Path'}>
								<MarkerClusterGroup>
									{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
										let optionalpath = [];
										item.map((data) => {
											optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
										});
										return (
											<>
												<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
											</>
										);
									})}
								</MarkerClusterGroup>
							</LayersControl.Overlay>


						</LayersControl>
					</MapContainer>

				</div>
        
        
			}
			<Spin spinning={data.pending} fullscreen />
		</>
	);
}