import moment from 'moment';
import { CREATE_SIMULATION_ERROR, SIMULATION_EDIT, SIMULATION_EDIT_ERROR, SIMULATION_EDIT_SUCCESS, SIMULATION_LIST, SIMULATION_LIST_ERROR, SIMULATION_LIST_SUCCESS } from './constants';
import { UPDATE_SIMULATION_SUCCESS } from './constants';
import { UPDATE_SIMULATION_ERROR } from './constants';
import { UPDATE_SIMULATION } from './constants';
import { CREATE_SIMULATION_SUCCESS } from './constants';
import { CREATE_SIMULATION } from './constants';


export default function simulationdata(state, action) {
	let simulation_data = [];
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	switch (action.type) {
      
	case CREATE_SIMULATION:
		return { ...state, pending: true };
	case CREATE_SIMULATION_SUCCESS:
		return { ...state, simulation_id: action.payload.id, pending: false };
	case CREATE_SIMULATION_ERROR:
		return { ...state, error: true, pending: false };
	case UPDATE_SIMULATION:
		return { ...state, pending: true };
	case UPDATE_SIMULATION_SUCCESS:
		return { ...state, pending: false };
	case UPDATE_SIMULATION_ERROR:
		return { ...state, error: true, pending: false};
	case SIMULATION_LIST:
		return { ...state, pending: true };
	case SIMULATION_LIST_SUCCESS:
		if(action.payload) {
			action.payload.map((item) => {
				const date = moment.utc(item.activityDate).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);

				// const startDate = moment.utc(item.startTime).toDate();
				// startDate.toString();
				// const newStartDate = moment(date, 'YYYY-MM-DD').format(process.env.REACT_APP_DATE_FORMAT);
				// const newStartTime = moment(date, 'HH:mm:ss').format(process.env.REACT_APP_TIME_FORMAT);

				// const endDate = moment.utc(item.endTime).toDate();
				// endDate.toString();
				// const newEndDate = moment(date, 'YYYY-MM-DD').format(process.env.REACT_APP_DATE_FORMAT);
				// const newEndTime = moment(date, 'HH:mm:ss').format(process.env.REACT_APP_TIME_FORMAT);

				console.log('# Status: ', item.status);

				let status = '';
				
				if (item.status == 'Scp Initiated') {
					status = 'Data Copying Initiated';
				} else if (item.status == 'Scp Started') {
					status = 'Data Copying Started';
				} else if(item.status == 'Scp Completed') {
					status = 'Data Copying Completed';
				} else if(item.status == 'Scp Failed') {
					status = 'Data Copying Failed';
				} else {
					status = item.status;
				}

				simulation_data.push({
					id : item.id,
					activityName : item.activityName ? item.activityName : null,
					activityDate : item.activityDate ? newdate + ' ' + newTime : 'Not Started',
					channelinfoName : item.channelinfoName ? item.channelinfoName : null,
					pidsinfoName : item.pidsinfoName ? item.pidsinfoName.toUpperCase() : null,
					duration : item.duration,
					status : status,
					isChannelFreezed : item.isChannelFreezed,
					isWaterfallobserved : item.isWaterfallobserved ,
					isObservedSignalStrong : item.isObservedSignalStrong ,
					activityType : item.activityType ,
					channelId : item.channelId ,
					pidsinfoId : item.pidsinfoId ,
					startChainage : item.startChainage,
					endChainage : item.endChainage,
					startODMeter : item.startODMeter,
					endODMeter : item.endODMeter,
					alarmGenerated : item.alarmGenerated,
					eventsGenerated : item.eventsGenerated,
					activityPerformedContinuously : item.activityPerformedContinuously,
					performedActivityIsSame : item.performedActivityIsSame,
					startTime : item.startTime,
					startDate : item.startTime,
					endTime : item.endTime,
					endDate : item.endTime,
					step : item.step 
				});
			});
		}
		return { ...state, simulation_data: simulation_data, pending: false };
	case SIMULATION_LIST_ERROR:
		return { ...state, error: true, pending: false};
	case SIMULATION_EDIT:
		return { ...state, pending: true };
	case SIMULATION_EDIT_SUCCESS:
		return { ...state, simulation_details: action.payload, pending: false };
	case SIMULATION_EDIT_ERROR:
		return { ...state, error: true, pending: false };
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}