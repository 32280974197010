import React, { useEffect, useReducer, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, message, Modal, Row, Select, Spin, Steps, Switch, TimePicker } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import dayjs from 'dayjs';
import moment from 'moment';
import { useFormik } from 'formik';
import axios from 'axios';

import simulationdata from './reducer';
import { CREATE_SIMULATION, CREATE_SIMULATION_ERROR, CREATE_SIMULATION_SUCCESS, UPDATE_SIMULATION, UPDATE_SIMULATION_ERROR, UPDATE_SIMULATION_SUCCESS } from './constants';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const steps = [
	{
		title: 'Before Simulation',
	},
	{
		title: 'Date & Time',
	},
	{
		title: 'After Simulation',
	},
];

// const { Title } = Typography;

export default function AddSimulations () {
	// const { token } = theme.useToken();
	const [current, setCurrent] = useState(0);
	const navigate = useNavigate();
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const { getAllEvents, eventsData, getPids, pidsData, getChannels, channelData,  getPipelineConfig, pipelineConfig } = useAuth();

	const initalState = {simulation_id : null, pending: false, error: false};
	const [data, dispatch] = useReducer(simulationdata, initalState);

	const yesOrNoOptions = [];
	const pidsList = [];
	const eventsList = [];
	const chainage = [];
	const odmeter = [];
	const channelList = [];

	const [millisecondsElapsed, setMillisecondsElapsed] = useState(0);
	const [intervalId, setIntervalId] = useState(null);
	const [startTime, setStartTime] = useState();
	const [endTime, setEndTime] = useState();
	const [timerButton, setTimerButton] = useState(false);
	const [previousButton, setPreviousButton] = useState(true);
	const [activityStatus, setActivityStatus] = useState(true);
	const [alalrmGeneratedStatus, setAlalrmGeneratedStatus] = useState();
	const [nextButtonStatus, setNextButtonStatus] = useState(true);
	const [timepickerStatus, setTimepickerStatus] = useState(false);
	const [minimumDuration, setMinimumDuration] = useState(false);
	const [maximumDuration, setMaximumDuration] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [maximumDefaultDuration, setMaximumDefaultDuration] = useState(false);
	const [minimumDefaultDuration, setMinimumDefaultDuration] = useState(false);
	const [invalidDateModal, setInvalidDateModal] = useState(false);
	const simulation_id = parseInt(data.simulation_id);
	const [pidsId, setPidsId] = useState(null);
	const [channelId, setChannelId] = useState(null);
	const [startStopButton, setStartStopButton] = useState(true);
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const [form] = Form.useForm();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getAllEvents(authtoken);
		getPids(authtoken);
	},[authtoken]);

	useEffect(() => {
		getPipelineConfig(authtoken, pidsId, channelId);
	},[authtoken, pidsId, channelId]);

	useEffect(() => {
		if(startTime && endTime && startDate && endDate) {
			var resultStartArray = startTime.split(':').map(function(startTime){return Number(startTime);});
			var resultEndArray = endTime.split(':').map(function(startTime){return Number(startTime);});
			const minutes = (new Date(moment.utc(endDate.setHours(resultEndArray[0],resultEndArray[1],resultEndArray[2],0)).format('YYYY-MM-DD HH:mm:ss')).getTime() - new Date(moment.utc(startDate.setHours(resultStartArray[0],resultStartArray[1],resultStartArray[2],0)).format('YYYY-MM-DD HH:mm:ss')).getTime())/ (60000);
			if(parseInt(minutes)  > 60) {
				setNextButtonStatus(false);
				setMaximumDefaultDuration(true);
			} else if(parseInt(minutes) < 3 && parseInt(minutes) >= 0) {
				setNextButtonStatus(false);
				setMinimumDefaultDuration(true);
			} else if(parseInt(minutes) < 0) {
				setInvalidDateModal(true);
				setNextButtonStatus(false);
			}
			else {
				setNextButtonStatus(true);
				setMaximumDefaultDuration(false);
				setMinimumDefaultDuration(false);
				setInvalidDateModal(false);
			}
		} 
	},[startTime, endTime, startDate, endDate ]);

	const next = () => {
		setCurrent(current + 1);
		if(steps[current].title == 'Date & Time'){
			setPreviousButton(false);
		}
		if(steps[current].title == 'Before Simulation') {
			setNextButtonStatus(false);
		}
	};
	const prev = () => {
		if(current == 1) {
			setStartTime(null);
			setStartDate(null);
			setEndTime(null);
			setEndDate(null);			
		}
		setCurrent(current - 1);
		setNextButtonStatus(true);
	};
	const items = steps.map((item) => {

		let translatedtitle = '';
		if(item.title=='After Simulation'){
			translatedtitle = langdata && langdata.AfterSimulation ? langdata.AfterSimulation : 'After Simulation';
		}
		else if(item.title=='Before Simulation'){
			translatedtitle = langdata && langdata.BeforeSimulation ? langdata.BeforeSimulation : 'Before Simulation';
		}
		else if(item.title=='Date & Time'){
			translatedtitle = langdata && langdata.DateandTime ? langdata.DateandTime : 'Date and Time';
		}
		return({
			key: item.title,
			title: translatedtitle,
		});
	}
	);
	const contentStyle = {
		lineHeight: '460px',
		marginTop: 16,
		fontSize : '30px'
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handlePids = (value) => {
		getChannels(authtoken,value);
		setPidsId(value);
		setChannelId(null);
	};

	const handleChannel = (value) => {
		setChannelId(value);
	};

	yesOrNoOptions.push({
		value : true,
		label : `${langdata && langdata.YES ? langdata.YES:'YES'}`
	},
	{
		value : false,
		label : `${langdata && langdata.NO ? langdata.NO:'NO'}`
	});

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
	});

	pidsList.sort((a,b) => { return a.label - b.label; });

	eventsData && eventsData.map((item) => {
		eventsList.push({
			value : item.id,
			label: item.eventType
		});
	});

	pipelineConfig && pipelineConfig.map((item) => {
		chainage.push({
			value : item.pipelineChainage,
			label : `${item.pipelineChainage} ( ${item.rouMarker} )`
		});
		odmeter.push({
			value:item.ODMeter,
			label:item.ODMeter.toString(),
			chainage:item.pipelineChainage
		});
	});

	channelData && channelData.map((item) => {
		channelList.push({
			value : item.channelId,
			label : item.name
		});
	});

	const startTimer = () => {
		
		if (!intervalId) {
			setIntervalId(
				setInterval(() => {
					setMillisecondsElapsed((prevMilliseconds) => prevMilliseconds + 15);
				}, 1)
			);
		}
		setStartDate(new Date());
		setStartTime(moment().format('HH:mm:ss'));
		setPreviousButton(false);
		setNextButtonStatus(false);
		setTimepickerStatus(true);

		let time = moment().format('HH:mm:ss');
		let date = new Date();
		var resultArray = time.split(':').map(function(startTime){return Number(startTime);});
		let newDate;
		if(resultArray.length > 0) {
			// date.setHours(resultArray[0],resultArray[1],resultArray[2]);
			newDate = moment.utc(date.setHours(resultArray[0],resultArray[1],resultArray[2],0)).toISOString();
		}

		if(newDate && simulation_id) {
			dispatch({ type : UPDATE_SIMULATION });
			let data = JSON.stringify({
				'step': 2,
				'activityDate': newDate,
				'startTime': newDate,
				'status' : 'Inprogress'
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/simulation/${simulation_id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status === 200 || response.status === 201) {
						dispatch({ type : UPDATE_SIMULATION_SUCCESS });
					} else {
						dispatch({ type : UPDATE_SIMULATION_SUCCESS });
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : UPDATE_SIMULATION_SUCCESS });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});

		}

	};

	// const formatValue = (value) => {
	// 	const hours = Math.floor(value / 3600000);
	// 	const minutes = Math.floor((value % 3600000) / 60000);
	// 	const seconds = Math.floor((value % 60000) / 1000);
			
	// 	return <Title style={{ color : 'red'}}>{hours.toString().padStart(2, '0')}:{minutes
	// 		.toString()
	// 		.padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</Title>;
	// };

	const stopTimer = () => {
		const minutes = Math.floor((millisecondsElapsed % 3600000) / 60000);
		const hours = Math.floor(millisecondsElapsed / 3600000);
		if(minutes < 3) {
			setMinimumDuration(true);
			setTimerButton(false);
			setStartStopButton(true);
		}else if(hours >= 1){
			setMaximumDuration(true);
		} 
		else {
			setEndTime(moment().format('HH:mm:ss'));
			clearInterval(intervalId);
			setIntervalId(null);
			setNextButtonStatus(true);
			setTimepickerStatus(false);
			setEndDate(new Date());
			setTimerButton(true);
			setStartStopButton(false);
		}
	};

	const handleChangeActivityStatus = (value) => {
		setActivityStatus(value);
	};

	const handlesetAlalrmGenerated = (value) => {
		setAlalrmGeneratedStatus(value);
	};

	const handleCloseMinimumDuration = () => {
		setMinimumDuration(false);
		setEndTime(moment().format('HH:mm:ss'));
		clearInterval(intervalId);
		setIntervalId(null);
		setNextButtonStatus(true);
		setTimepickerStatus(false);
		setEndDate(new Date());
		setStartStopButton(false);
	};

	const handleCloseMinimumModal = () => {
		setMinimumDuration(false);
	};

	const handleCloseMaximumDuration = () => {
		setMaximumDuration(false);
		setEndTime(moment().format('HH:mm:ss'));
		clearInterval(intervalId);
		setIntervalId(null);
		setNextButtonStatus(true);
		setTimepickerStatus(false);
		setEndDate(new Date());
	};

	const handleCloseMaximumModal = () => {
		setMaximumDuration(false);
	};

	const handleCloseMaximumDefaultDuration = () => {
		setMaximumDefaultDuration(false);
	};

	const handleCloseMinimumDefaultDuration = () => {
		setMinimumDefaultDuration(false);
	};

	const handleCloseInvalidDateModal = () => {
		setInvalidDateModal(false);
	};

	const handleStartTime = (e)=>{
		setStartTime(moment(e && e.$d).format('HH:mm:ss'));
	};

	const handleStartDate = (e)=>{
		setStartDate(new Date(e && e.$d));
	};

	const handleEndDate = (e) => {
		setEndDate(new Date(e && e.$d));
	};

	const handleEndTime = (e) => {
		setEndTime(moment(e && e.$d).format('HH:mm:ss'));
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		let previous = current && current < dayjs().subtract(2, 'days');
		let next  = current && current > dayjs();
		return previous + next;
	};

	const formik = useFormik({
		initialValues : {
			isChannelFreezed : null,
			isWaterfallobserved : null,
			isObservedSignalStrong : null,
			activityType : null,
			activityName : null,
			channelId : null,
			channelinfoName : null,
			pidsinfoId : null,
			pidsinfoName : null,
			startChainage : null,
			endChainage : null,
			startODMeter : null,
			endODMeter : null,
			alarmGenerated : null,
			eventsGenerated : null,
			activityPerformedContinuously : null,
			performedActivityIsSame : null,

		},
		onSubmit : (values) => {
			const {isChannelFreezed, isWaterfallobserved, isObservedSignalStrong, activityPerformedContinuously, performedActivityIsSame} = values;
			const points = (isChannelFreezed ? 0.05 : 0) + (isWaterfallobserved ? 0.05 : 0) + (isObservedSignalStrong ? 0.05 : 0) + (activityPerformedContinuously ? 0.05 : 0) + (performedActivityIsSame ? 0.05 : performedActivityIsSame == false ? 0.01 : 0);

			if(!data.simulation_id) {
				const {isChannelFreezed, isWaterfallobserved, isObservedSignalStrong, activityType, activityName, channelId, channelinfoName, pidsinfoId, pidsinfoName, startChainage, endChainage, startODMeter, endODMeter} = values;

				if(isChannelFreezed === null || isWaterfallobserved === null || activityType === null || channelId === null || pidsinfoId === null || startChainage === null || endChainage === null || startODMeter === null || endODMeter === null){
					Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
				} else if (startODMeter > endODMeter) {
					Modal.warning({title : langdata && langdata.EndODMetershouldbegreaterthanStartODMeter ? langdata.EndODMetershouldbegreaterthanStartODMeter : 'End OD Meter should be greater than Start OD Meter'});
				} else {
					let data = JSON.stringify({
						'isChannelFreezed': isChannelFreezed,
						'isWaterfallobserved': isWaterfallobserved,
						'isObservedSignalStrong': isObservedSignalStrong,
						'activityType': activityType,
						'activityName' : activityName,
						'channelId': channelId,
						'channelinfoName':channelinfoName,
						'pidsinfoId': pidsinfoId,
						'pidsinfoName' : pidsinfoName,
						'startChainage': startChainage,
						'endChainage': endChainage,
						'startODMeter': startODMeter,
						'endODMeter': endODMeter,
						'activityDate': null,
						'startTime': null,
						'endTime': null,
						'duration': 0,
						'step': 2,
						'pipelineId': pipelineId,
						'status' : 'New'
					});

					dispatch({ type : CREATE_SIMULATION });

					let config = {
						method: 'post',
						maxBodyLength: Infinity,
						url: `${api}/simulation`,
						timeout: timeout,
						headers: { 
							'Content-Type': 'application/json', 
							'Authorization': `Bearer ${authtoken}`
						},
						data : data
					};

					axios.request(config)
						.then((response) => {
							if(response.status === 200 || response.status === 201) {
								dispatch({ type : CREATE_SIMULATION_SUCCESS, payload : response.data});
								next();

							} else {
								dispatch({ type : CREATE_SIMULATION_ERROR });
							}
						})
						.catch((error) => {
							console.log(error);
							dispatch({ type : CREATE_SIMULATION_ERROR });
							if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
								message.config({
									duration : 5,
									maxCount : 1,
								});
								message.open({
									type: 'error',
									content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
								});
							}
						});
				}
			}
			else {

				if(steps[current].title == 'Before Simulation') {
					const {isChannelFreezed, isWaterfallobserved, isObservedSignalStrong, activityType, channelId, pidsinfoId, startChainage, endChainage, startODMeter, endODMeter} = values;
					if (startODMeter >= endODMeter) {
						Modal.warning({title : langdata && langdata.EndODMetershouldbegreaterthanStartODMeter ? langdata.EndODMetershouldbegreaterthanStartODMeter : 'End OD Meter should be greater than Start OD Meter'});
					} else {
						let data = JSON.stringify({
							'isChannelFreezed': isChannelFreezed,
							'isWaterfallobserved': isWaterfallobserved,
							'isObservedSignalStrong': isObservedSignalStrong,
							'activityType': activityType,
							'channelId': channelId,
							'pidsinfoId': pidsinfoId,
							'startChainage': startChainage,
							'endChainage': endChainage,
							'startODMeter': startODMeter,
							'endODMeter': endODMeter,
							'step': 2,
						});

						dispatch({ type : UPDATE_SIMULATION });

						let config = {
							method: 'patch',
							maxBodyLength: Infinity,
							url: `${api}/simulation/${simulation_id}`,
							timeout: timeout,
							headers: { 
								'Content-Type': 'application/json', 
								'Authorization': `Bearer ${authtoken}`
							},
							data : data
						};

						axios.request(config)
							.then((response) => {
								if(response.status === 200 || response.status === 201) {
									dispatch({ type : UPDATE_SIMULATION_SUCCESS, payload : response.data});
									next();

								} else {
									dispatch({ type : UPDATE_SIMULATION_ERROR });
								}
							})
							.catch((error) => {
								console.log(error);
								dispatch({ type : UPDATE_SIMULATION_ERROR });
								if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
									message.config({
										duration : 5,
										maxCount : 1,
									});
									message.open({
										type: 'error',
										content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
									});
								}
							});
					}
				} else if (steps[current].title == 'Date & Time') {
					// let time = moment().format('HH:mm:ss');
					// let date = new Date();
					if(startTime && startDate && endTime && endDate) {

						var resultStartArray = startTime.split(':').map(function(startTime){return Number(startTime);});
						let newStartDate;
						if(resultStartArray.length > 0) {
							newStartDate = moment.utc(startDate.setHours(resultStartArray[0],resultStartArray[1],resultStartArray[2],0)).toISOString();
						}

						var resultEndArray = endTime.split(':').map(function(startTime){return Number(startTime);});
						let newEndDate;
						if(resultEndArray.length > 0) {
							newEndDate = moment.utc(endDate.setHours(resultEndArray[0],resultEndArray[1],resultEndArray[2],0)).toISOString();
						}
						if(newStartDate && newEndDate) {
							dispatch({ type : UPDATE_SIMULATION });
							const minutes = (new Date(moment.utc(endDate.setHours(resultEndArray[0],resultEndArray[1],resultEndArray[2],0)).format('YYYY-MM-DD HH:mm:ss')).getTime() - new Date(moment.utc(startDate.setHours(resultStartArray[0],resultStartArray[1],resultStartArray[2],0)).format('YYYY-MM-DD HH:mm:ss')).getTime())/ (60000);
							let data = JSON.stringify({
								'activityDate': newStartDate,
								'startTime': newStartDate,
								'endTime': newEndDate,
								'duration': parseInt(minutes),
								'step': 3,
								'status' : 'Inprogress'
							});

							let config = {
								method: 'patch',
								maxBodyLength: Infinity,
								url: `${api}/simulation/${simulation_id}`,
								timeout: timeout,
								headers: { 
									'Content-Type': 'application/json', 
									'Authorization': `Bearer ${authtoken}`
								},
								data : data
							};

							axios.request(config)
								.then((response) => {
									if(response.status === 200 || response.status === 201) {
										dispatch({ type : UPDATE_SIMULATION_SUCCESS });
										next();
									} else {
										dispatch({ type : UPDATE_SIMULATION_SUCCESS });
									}
								})
								.catch((error) => {
									console.log(error);
									dispatch({ type : UPDATE_SIMULATION_SUCCESS });
									if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
										message.config({
											duration : 5,
											maxCount : 1,
										});
										message.open({
											type: 'error',
											content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
										});
									}
								});
						}

					}
				}else if(steps[current].title == 'After Simulation') {
					const {alarmGenerated, eventsGenerated, activityPerformedContinuously, performedActivityIsSame} = values;
					if(alarmGenerated == null || activityPerformedContinuously == null) {
						Modal.warning({title :  langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
					} else {

						let data = JSON.stringify({
							'alarmGenerated': alarmGenerated,
							'eventsGenerated': alalrmGeneratedStatus === true ? true : eventsGenerated,
							'activityPerformedContinuously': activityPerformedContinuously,
							'performedActivityIsSame': performedActivityIsSame,
							'step': 4,
							'status' : 'Submitted',
							'weightageScore' : points.toFixed(3)
						});

						let config = {
							method: 'patch',
							maxBodyLength: Infinity,
							url: `${api}/simulation/${simulation_id}`,
							timeout: timeout,
							headers: { 
								'Content-Type': 'application/json', 
								'Authorization': `Bearer ${authtoken}`
							},
							data : data
						};

						axios.request(config)
							.then((response) => {
								if(response.status === 200 || response.status === 201) {
									message.success(langdata && langdata.Successfullycompletedsimulationdata ? langdata.Successfullycompletedsimulationdata : 'Successfully completed simulation data');
									dispatch({ type : UPDATE_SIMULATION_SUCCESS });
									setTimeout(() => {
										navigate(-1);
									},1000);
								} else {
									dispatch({ type : UPDATE_SIMULATION_SUCCESS });
								}
							})
							.catch((error) => {
								console.log(error);
								dispatch({ type : UPDATE_SIMULATION_SUCCESS });
								if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
									message.config({
										duration : 5,
										maxCount : 1,
									});
									message.open({
										type: 'error',
										content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
									});
								}
							});
					}
				}
			}

		}
	});

	const handleFormValuesChange = (changedValues) => {
		const formFieldName = Object.keys(changedValues)[0];
		if (formFieldName === 'pidsinfoId') {
		//   setSelectedCompanyId(changedValues[formFieldName]);  // perform setState here
			form.setFieldsValue({channelId: undefined});
			form.setFieldsValue({startODMeter: undefined});
			form.setFieldsValue({endODMeter: undefined}); //reset product selection
		}

		if (formFieldName === 'channelId') {
			//   setSelectedCompanyId(changedValues[formFieldName]);  // perform setState here
			form.setFieldsValue({startODMeter: undefined});
			form.setFieldsValue({endODMeter: undefined}); //reset product selection
		}
	};

	const disabledStartHours = () => {
		const hours = [];
		const currentHour = moment().hour();
		let hour = 0;
		
		if(moment.utc(moment()).date() == moment(startDate).date()) {
			hour = currentHour;
		} else {
			hour = 23;
		}
	
		for (let i = hour + 1; i < 24; i++) {
			hours.push(i);
		}
	
		return hours;
	};
	
	const disabledStartMinutes = (selectedHour) => {
		const minutes = [];
		let minute = 0;
		const currentMinute = moment().minute();

		if(moment.utc(moment()).date() == moment(startDate).date()) {
			minute = currentMinute;
		} else {
			minute = 59;
		}

		if (selectedHour === moment().hour()) {
			for (let i = minute + 1; i <= 60; i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	const disabledEndHours = () => {
		const hours = [];
		const currentHour = moment().hour();
		let hour = 0;
		
		if(moment.utc(moment()).date() == moment(endDate).date()) {
			hour = currentHour;
		} else {
			hour = 23;
		}
	
		for (let i = hour + 1; i < 24; i++) {
			hours.push(i);
		}
	
		return hours;
	};
	
	const disabledEndMinutes = (selectedHour) => {
		const minutes = [];
		let minute = 0;
		const currentMinute = moment().minute();

		if(moment.utc(moment()).date() == moment(endDate).date()) {
			minute = currentMinute;
		} else {
			minute = 59;
		}

		if (selectedHour === moment().hour()) {
			for (let i = minute + 1; i <= 60; i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	return (
		<>
			<Content>
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ display : 'flex', justifyContent : 'center'}}>
						<Card style={{ width : '1400px', margin : '50px'}}>
							<Steps current={current} items={items} />
							<div style={contentStyle}>
								{steps[current].title == 'Before Simulation' ? 
									<Form layout="vertical" onSubmit={formik.handleSubmit} form={form} onValuesChange={handleFormValuesChange}>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item  label={langdata && langdata.Channelfreezed ? langdata.Channelfreezed: 'Channel freezed?'} name="isChannelFreezed" rules={[{ required: true }]}>
													<Select
														name="isChannelFreezed" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Isthechannelfreezed ? langdata.Isthechannelfreezed: 'Is the channel freezed?'}
														optionFilterProp="children"
														options= {yesOrNoOptions}
														onChange={(value) => formik.setFieldValue('isChannelFreezed', value)}
														value={formik.values.isChannelFreezed}
														required
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.Signalobservedinwaterfalldisplaypoststamping ? langdata.Signalobservedinwaterfalldisplaypoststamping: 'Signal observed in waterfall display post-stamping'} name="isWaterfallobserved" rules={[{ required: true }]}>
													<Select
														name="isWaterfallobserved" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Signalobservedinwaterfalldisplaypoststamping ? langdata.Signalobservedinwaterfalldisplaypoststamping: 'Signal observed in waterfall display post-stamping'}
														optionFilterProp="children"
														options= {yesOrNoOptions}
														onChange={(value) => formik.setFieldValue('isWaterfallobserved', value)}
														value={formik.values.isWaterfallobserved}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												{formik.values.isWaterfallobserved !== false ?
													<Form.Item label={langdata && langdata.Doestheobservedsignalpossessadequatesignalstrength ? langdata.Doestheobservedsignalpossessadequatesignalstrength: 'Does the observed signal possess adequate signal strength?'} name="isObservedSignalStrong" rules={[{ required: true }]}>
														<Select
															name="isObservedSignalStrong" 
															showSearch
															filterOption={filterOption}
															placeholder={langdata && langdata.Doestheobservedsignalpossessadequatesignalstrength ? langdata.Doestheobservedsignalpossessadequatesignalstrength: 'Does the observed signal possess adequate signal strength?'}
															optionFilterProp="children"
															options= {yesOrNoOptions}
															onChange={(value) => formik.setFieldValue('isObservedSignalStrong', value)}
															value={formik.values.isObservedSignalStrong}
														/>												
													</Form.Item>
													: null }
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.Activitytype ? langdata.Activitytype:'Activity type'} name="activityType" rules={[{ required: true }]}>
													<Select
														name="activityType" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Activitytype ? langdata.Activitytype: 'Activity type'}
														optionFilterProp="children"
														options={eventsList}
														onChange={(value, item) => {formik.setFieldValue('activityType', value); formik.setFieldValue('activityName', item.label);}}
														value={formik.values.activityType}
													/>												
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label={langdata && langdata.PIDS ? langdata.PIDS: 'PIDS'} name="pidsinfoId" rules={[{ required: true }]}>
													<Select
														name="pidsinfoId" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.PIDS ? langdata.PIDS: 'PIDS'}
														optionFilterProp="children"
														options={pidsList}
														onSelect={handlePids}
														onChange={(value, item) => {formik.setFieldValue('pidsinfoId', value); formik.setFieldValue('pidsinfoName', item.label);}}
														value={formik.values.pidsinfoId}
													/>												
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.Channeldetails ? langdata.Channeldetails: 'Channel details'} name="channelId" rules={[{ required: true }]}>
													<Select
														name="channelId" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.Channeldetails ? langdata.Channeldetails: 'Channel details'}
														optionFilterProp="children"
														onSelect={handleChannel}
														options={channelList}
														onChange={(value, item) => {formik.setFieldValue('channelId', value); formik.setFieldValue('channelinfoName', item.label);}}
														value={channelId}
													/>												
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label={langdata && langdata.ODMeterFiberlengthStart ? langdata.ODMeterFiberlengthStart: 'OD Meter (Fiber length) Start'} name="startODMeter" rules={[{ required: true }]}>
													<Select
														name="startODMeter" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.ODMeterFiberlengthStart ? langdata.ODMeterFiberlengthStart: 'OD Meter (Fiber length) Start'}
														optionFilterProp="children"
														options={odmeter}
														onChange={(value, item) => {formik.setFieldValue('startODMeter', value);formik.setFieldValue('startChainage', item.chainage);}}
														value={formik.values.startODMeter}
													/>					
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={langdata && langdata.ODMeterFiberlengthEnd ? langdata.ODMeterFiberlengthEnd:'OD Meter (Fiber length) End'} name="endODMeter" rules={[{ required: true }]}>
													<Select
														name="endODMeter" 
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.ODMeterFiberlengthEnd ? langdata.ODMeterFiberlengthEnd: 'OD Meter (Fiber length) End'}
														optionFilterProp="children"
														options={odmeter}
														onChange={(value, item) => {formik.setFieldValue('endODMeter', value);formik.setFieldValue('endChainage', item.chainage);}}
														value={formik.values.endODMeter}
													/>												
												</Form.Item>
											</Col>
										</Row>
										{/* <Row gutter={16}>
											<Col span={12}>
												<Form.Item label="Start Chainage">
													<Input
														disabled={true}
														placeholder="Start chainage"
														defaultValue={formik.values.startChainage}
														value={startChainage}
													/>												
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Ending Chainage">
													<Input
														disabled={true}
														placeholder="Ending Chainage"
														defaultValue={formik.values.endChainage}
														value={endChainage}
													/>												
												</Form.Item>
											</Col>
										</Row> */}
										
										<Row gutter={16}>
											{/* <Col span={12}>
												<Form.Item label="Activity date" name='activityDate' >
													<DatePicker style={{
														width: '100%',
													}}
													name='activityDate' 
													onChange={(value) => formik.setFieldValue('activityDate', value)}
													/>									
												</Form.Item>
											</Col> */}
											<Col span={12}>
												<Form.Item label=" " >
													{langdata && langdata.Completedactivity ? langdata.Completedactivity: 'Completed activity'}<Switch value={activityStatus} onChange={handleChangeActivityStatus} defaultChecked style={{
														marginBottom : '20px',
														margin: '0 8px',
													}}/>{langdata && langdata.Liveactivity ? langdata.Liveactivity: 'Live activity'}
												</Form.Item>
											</Col>
										</Row>
									</Form>
									: 
									steps[current].title == 'Date & Time' ?
										<>
											<Form layout="vertical" style={{ marginTop : '10px'}} onSubmit={formik.handleSubmit}>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item  label={langdata && langdata.StartDate ? langdata.StartDate: 'Start Date'} rules={[{ required: true }]}>
															<DatePicker
																allowClear={false} format={settingsdateformat}
																// defaultValue={dayjs(moment().format('YYYY-MM-DD'), dateFormat)} 
																value={startDate ? dayjs(moment(startDate).format(settingsdateformat), settingsdateformat) : null}
																onChange={handleStartDate}
																disabled={timepickerStatus}
																disabledDate={disabledDate}
																placeholder={langdata && langdata.StartDate ? langdata.StartDate:'Start Date'}
																style={{
																	width: '100%',
																}} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label={langdata && langdata.StartTime ? langdata.StartTime: 'Start Time'} >
															<TimePicker value={startTime ? dayjs(startTime, 'HH:mm:ss') : null} 
																onChange={handleStartTime}
																disabled={timepickerStatus}
																disabledHours={disabledStartHours}
																disabledMinutes={disabledStartMinutes}
																changeOnScroll 
																needConfirm={false}
																placeholder={langdata && langdata.StartTime ? langdata.StartTime:'Start Time'}
																style={{
																	width: '100%',
																}}  />
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item label={langdata && langdata.EndDate ? langdata.EndDate: 'End Date'} rules={[{ required: true }]}>
															<DatePicker
																allowClear={false} format={settingsdateformat}
																disabled={timepickerStatus}
																value={endDate ? dayjs(moment(endDate).format(settingsdateformat), settingsdateformat) : null}
																onChange={handleEndDate}
																disabledDate={disabledDate}
																placeholder={langdata && langdata.EndDate ? langdata.EndDate:'End Date'}
																style={{
																	width: '100%',
																}} />										
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label={langdata && langdata.EndTime ? langdata.EndTime: 'End Time'} rules={[{ required: true }]} >
															<TimePicker defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
																value={endTime ? dayjs(endTime, 'HH:mm:ss') : null} 
																disabled={timepickerStatus}
																onChange={handleEndTime}
																disabledHours={disabledEndHours}
																disabledMinutes={disabledEndMinutes}
																changeOnScroll 
																needConfirm={false}
																placeholder={langdata && langdata.EndTime ? langdata.EndTime:'End Time'}
																style={{
																	width: '100%',
																}}  />										
														</Form.Item>
													</Col>
												</Row>
											</Form>
											{activityStatus === true ?

												
												<Row gutter={16} justify="center">
													<Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
														{/* <Statistic
															value={millisecondsElapsed}
															formatter={formatValue}
															style={{ marginRight: 16 }}
														/> */}
														{startStopButton === true ?
															!intervalId && timerButton === false ? (
																<Button style={{ marginTop: 8 }} onClick={startTimer} type='primary'>{langdata && langdata.Start ? langdata.Start: 'Start'}</Button>
															) : (
																<Button style={{ marginTop: 8 }} onClick={stopTimer} type="primary" danger disabled={timerButton}>{langdata && langdata.Stop ? langdata.Stop: 'Stop'}</Button>
															)
															: null }
													</Col>
												</Row>
												: null }
										</>
										:
										steps[current].title == 'After Simulation' ?
											<Form layout="vertical" onSubmit={formik.handleSubmit}>
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item  label={langdata && langdata.Alarmgeneratedornot ? langdata.Alarmgeneratedornot: 'Alarm generated or not?'} name="alarmGenerated" rules={[{ required: true }]}>
															<Select
																name="alarmGenerated" 
																showSearch
																filterOption={filterOption}
																placeholder={langdata && langdata.Alarmgeneratedornot ? langdata.Alarmgeneratedornot: 'Alarm generated or not?'}
																optionFilterProp="children"
																options= {yesOrNoOptions}
																onSelect={handlesetAlalrmGenerated}
																onChange={(value) => formik.setFieldValue('alarmGenerated', value)}
																value={formik.values.alarmGenerated}
															/>
														</Form.Item>
													</Col>
													{alalrmGeneratedStatus !== true ?
														<Col span={12}>
															<Form.Item label={langdata && langdata.Eventsgeneratedornot ? langdata.Eventsgeneratedornot: 'Events generated or not?'} name="eventsGenerated" rules={[{ required: true }]}>
																<Select
																	name="eventsGenerated" 
																	showSearch
																	filterOption={filterOption}
																	placeholder={langdata && langdata.Eventsgeneratedornot ? langdata.Eventsgeneratedornot: 'Events generated or not?'}
																	optionFilterProp="children"
																	options= {yesOrNoOptions}
																	onChange={(value) => formik.setFieldValue('eventsGenerated', value)}
																	value={formik.values.eventsGenerated}
																/>
															</Form.Item>
														</Col>
														: null }
												</Row>
												<Row gutter={16}>
													{formik.values.alarmGenerated === true ||  formik.values.eventsGenerated === true ?
														<Col span={12}>
															<Form.Item label={langdata && langdata.Generatedactivityissameasselected ? langdata.Generatedactivityissameasselected:'Generated activity is same as selected '} name="performedActivityIsSame" rules={[{ required: true }]}>
																<Select
																	name="performedActivityIsSame" 
																	showSearch
																	filterOption={filterOption}
																	placeholder={langdata && langdata.Generatedactivityissameasselected ? langdata.Generatedactivityissameasselected: 'Generated activity is same as selected '}
																	optionFilterProp="children"
																	options= {yesOrNoOptions}
																	onChange={(value) => formik.setFieldValue('performedActivityIsSame', value)}
																	value={formik.values.performedActivityIsSame}
																/>												
															</Form.Item>
														</Col>
														: null }
													<Col span={12}>
														<Form.Item label={langdata && langdata.Activitycontinuouslyperformedornot ? langdata.Activitycontinuouslyperformedornot: 'Activity continuously performed or not?'} name="activityPerformedContinuously" rules={[{ required: true }]}>
															<Select
																name="activityPerformedContinuously" 
																showSearch
																filterOption={filterOption}
																placeholder={langdata && langdata.Activitycontinuouslyperformedornot ? langdata.Activitycontinuouslyperformedornot: 'Activity continuously performed or not?'}
																optionFilterProp="children"
																options= {yesOrNoOptions}
																onChange={(value) => formik.setFieldValue('activityPerformedContinuously', value)}
																value={formik.values.activityPerformedContinuously}
															/>												
														</Form.Item>
														
													</Col>
												</Row>
											</Form>
											: null
								}
                            
							</div>
							<div
								style={{
									marginTop: 24,
									display:'flex',
									justifyContent : 'center'
								}}
							>
								<Button style={{
									marginBottom : '20px',
									margin: '0 8px',
								}} onClick={() => navigate(-1)}>
									{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
								</Button>
								{current > 0 && previousButton === true && (
									<Button
										style={{
											margin: '0 8px',
											marginBottom : '20px'
										}}
										onClick={() => prev()}
									>
										{langdata && langdata.Previous ? langdata.Previous:'Previous'}

									</Button>
								)}
								{current < steps.length - 1  && nextButtonStatus === true && (
									<Button style={{
										margin: '0 8px',
										marginBottom : '20px'
									}} type="primary" 
									// onClick={() => next()}
									onClick={formik.handleSubmit}
									>
										{langdata && langdata.Next ? langdata.Next:'Next'}
									</Button>
								)}
								{current === steps.length - 1 && (
									<Button style={{
										margin: '0 8px',
										marginBottom : '20px'
									}} type="primary" 
									// onClick={() => message.success('Successfully completed simulation data')}
									onClick={formik.handleSubmit}>
										{langdata && langdata.Submit ? langdata.Submit:'Submit'}

									</Button>
								)}
								
							</div>
						</Card>
					</Col>
				</Row>
				<Spin fullscreen spinning={data.pending} />
			</Content>
			<Modal 
				open={minimumDuration} 
				onOk={handleCloseMinimumDuration} 
				onCancel={handleCloseMinimumModal}
				footer={[

					<Button key={'ok'} onClick={handleCloseMinimumDuration} type="primary">
						{langdata && langdata.OK ? langdata.OK:'OK'}
					</Button>
				]}
			>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={23}>
						{langdata && langdata.Minimumsimulationactivitydurationis3minutesDoyoureallywanttostopthissimulation ? langdata.Minimumsimulationactivitydurationis3minutesDoyoureallywanttostopthissimulation: 'Minimum simulation activity duration is 3 minutes. Do you really want to stop this simulation?'}
					</Col>
				</Row>
			</Modal>
			<Modal 
				open={maximumDuration} 
				onOk={handleCloseMaximumDuration} 
				onCancel={handleCloseMaximumModal}
				footer={[

					<Button key={'ok'} onClick={handleCloseMaximumDuration} type="primary">
						{langdata && langdata.OK ? langdata.OK:'OK'}
					</Button>
				]}
			>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={23}>
						{langdata && langdata.Maximumsimulationactivitydurationis60minutesPleaseadjustthestartandstoptime ? langdata.Maximumsimulationactivitydurationis60minutesPleaseadjustthestartandstoptime:'Maximum simulation activity duration is 60 minutes. Please adjust the start and stop time.'}
					</Col>
				</Row>
			</Modal>
			<Modal 
				open={maximumDefaultDuration} 
				// onOk={handleCloseMaximumDefaultDuration} 
				onCancel={handleCloseMaximumDefaultDuration}
				footer={[

					<Button key={'ok'} onClick={handleCloseMaximumDefaultDuration} type="primary">
						{langdata && langdata.OK ? langdata.OK:'OK'}

					</Button>
				]}
			>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={23}>
						{langdata && langdata.Maximumsimulationactivitydurationis60minutesPleaseadjustthestartandstoptime ? langdata.Maximumsimulationactivitydurationis60minutesPleaseadjustthestartandstoptime:'Maximum simulation activity duration is 60 minutes. Please adjust the start and stop time.'}
					</Col>
				</Row>
			</Modal>
			<Modal 
				open={minimumDefaultDuration} 
				// onOk={handleCloseMinimumDuration} 
				onCancel={handleCloseMinimumDefaultDuration}
				footer={[

					<Button key={'ok'} onClick={handleCloseMinimumDefaultDuration} type="primary">
						{langdata && langdata.OK ? langdata.OK:'OK'}
					</Button>
				]}
			>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={23}>
						{langdata && langdata.Minimumsimulationactivitydurationis3minutesPleaseadjustthestartandstoptime ? langdata.Minimumsimulationactivitydurationis3minutesPleaseadjustthestartandstoptime:'Minimum simulation activity duration is 3 minutes. Please adjust the start and stop time.'}
					</Col>
				</Row>
			</Modal>
			<Modal 
				open={invalidDateModal} 
				// onOk={handleCloseMinimumDuration} 
				onCancel={handleCloseInvalidDateModal}
				footer={[

					<Button key={'ok'} onClick={handleCloseInvalidDateModal} type="primary">
						{langdata && langdata.OK ? langdata.OK:'OK'}

					</Button>
				]}
			>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={23}>
						{langdata && langdata.Starttimecannotbegreaterthanendtime ? langdata.Starttimecannotbegreaterthanendtime:'Start time cannot be greater than end time.'}
					</Col>
				</Row>
			</Modal>
		</>
	);
}
