import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
	createBrowserRouter,
	RouterProvider,
} from 'react-router-dom';

import Error from './error';
import Dashboard from './pages/Dashboard/dashboard';

import Allocations from './pages/Allocation/allocations';
import UserManagement from './pages/Configurations/user-management/index';

import TLPDetails from './pages/smartCp/tlp-details';
import SpeedClassification from './pages/Configurations/speed-classification/index';
import GradeSystem from './pages/Report/GradeSystem/grade-system';
import Login from './pages/Authentication/login';
import Patch from './pages/Report/Patch/patch';
import Vulnerable from './pages/Report/Vulnerable/vulnerable';
import AlarmReport from './pages/Report/Alarms/alarms-report';
import MyLinewalker from './pages/Track/linewalker/mylinewalker';
import PatchDetails from './pages/Report/patch-details';
import RealtimeAlarm from './pages/RealtimeAlarms/realtime-alarms';
import Inspection from './pages/Inspections/inspections';
import InspectionDetails from './pages/Inspections/inspection-view';

import EscalationMatrix from './pages/EscalationMatrix/escalation-matrix';
import VulnerableDetails from './pages/Report/vulnerable-details';
import RealtimeEvents from './pages/RealtimeEvents/realtime-events';
import MyPIG from './pages/Track/Pigtracker/myPig';
import EventDetails from './pages/RealtimeEvents/event-details';
import ReportEvents from './pages/Report/Events/events-report';
import Events from './pages/Report/Events/events';
import AlarmDeatils from './pages/RealtimeAlarms/alarm-details';
import AddAllocation from './pages/Allocation/add-allocation';
import EditAllocation from './pages/Allocation/edit-allocation';
import AllocationSub from './pages/Allocation/allocation-sub';
import EditAllocationPipelinePath from './pages/Allocation/edit-allocation-pipeline-path';
import MyProfile from './pages/MyProfile/index';
import MailConfiguration from './pages/Configurations/mail-template/index';
import AddMailConfiguration from './pages/Configurations/mail-template/add-mail-template';
import EditMailConfiguration from './pages/Configurations/mail-template/edit-mail-template';
import MailConfigurationDetails from './pages/Configurations/mail-template/mail-template-details';
// TLP Modules
import RealtimeCPS from './pages/smartCp/realtime-cps/index';
import FullCPSReport from './pages/smartCp/full-cps/index';
import ObserveCPSReport from './pages/smartCp/observe-cps/index';
import DailyCPSReport from './pages/smartCp/daily-cps/index';
import CPSReport from './pages/smartCp/cps-report/index';
import TLPManagement from './pages/Configurations/tlp-management';
import TLPRules from './pages/Configurations/tlp-rule-engine/index';
import Notifications from './pages/Notifications/index';
import Settings from './pages/Settings';
import RetrainingData from './pages/RetrainingData';
import AlarmReportTable from './pages/Report/Alarms/alarm-table';
import ChainageFilter from './pages/Chainage/index';
import ChainageLisiting from './pages/Chainage/lisitng';
import VulnerablePoints from './pages/VulnerablePoints';
import SnoozedPoints from './pages/Snooze';
import TerrainClassification from './pages/TerrainClassification';
import EdgeMangement from './pages/Edgemanagement/index';

import PidsManagement from './pages/Configurations/pids-management/index';
import PidsDetails from './pages/Configurations/pids-management/pidsmanagementview';
import AlarmAnalytics from './pages/Analytics/Alarm/index';
import AlarmRuleEngine from './pages/Configurations/alarm-rule-engine/index';
import EventAnalytics from './pages/Analytics/Event';
import InspectionView from './pages/Allocation/view-inspection';
import AvailabilityDetailsPage from './pages/Dashboard/availability-details-page';
import GoogleMap from './pages/Map/map';
import ModelConfig from './pages/RetrainingData/model-index';
import TerrainManagement from './pages/TerrainClassification/terrain-index';
import RetrainingHistory from './pages/RetrainingData/retrain-history-index';
import Simulations from './pages/Simulations';
import ModelConfiguration from './pages/RetrainingData/model-configuration';
import AddSimulations from './pages/Simulations/add-simulation';
import EditSimulations from './pages/Simulations/edit-simulation';
import ViewSimulations from './pages/Simulations/view-simulation';
import EventTypes from './pages/Configurations/event-types';
import ModelHistory from './pages/RetrainingData/model-history';
import FiberManagement from './pages/Configurations/fiber-management';
import ChannelInfo from './pages/Configurations/channelinfo';
import ChannelWidth from './pages/Configurations/channelinfo/width';
import Convertion from './pages/Configurations/channelinfo/convertion';
import Metrics from './pages/Configurations/channelinfo/matrics';
import ModelClassification from './pages/RetrainingData/model-classification';
import PredicitionConvertion from './pages/Configurations/channelinfo/prediction_conversion';
import RegionManagement from './pages/Configurations/region-management';
import GradeManagement from './pages/Configurations/grade-management/index';

import AlarmRetrainingHistory from './pages/RetrainingData/alarm-retraining-history';
import SimulationRetrainingHistory from './pages/RetrainingData/simulation-retraining-history';
import RegionManagementDetails from './pages/Configurations/region-management/regionmanagementview';


const router = createBrowserRouter([
	{
		// path: "/",
		element: <App />,
		errorElement: <Error />,
		children: [
			{
				path: 'dashboard',
				element: <Dashboard />,
			},
			{
				path: 'realtime-cps',
				element: <RealtimeCPS />,
			},
			{
				path: 'tlp-details',
				element: <TLPDetails />,
			},
			{
				path: 'full-cps-report',
				element: <FullCPSReport />,
			},
			{
				path: 'observe-cps-report',
				element: <ObserveCPSReport />,
			},
			{
				path: 'daily-cps-report',
				element: <DailyCPSReport />,
			},
			{
				path: 'cps-report',
				element: <CPSReport />,
			},
			{
				path: 'allocations',
				element: <Allocations />,
			},
			{
				path: 'user-management',
				element: <UserManagement />,
			},
			{
				path: 'tlp-management',
				element: <TLPManagement />,
			},
			{
				path: 'tlp-rules',
				element: <TLPRules />,
			},
			{
				path: 'notifications',
				element: <Notifications />,
			},
			{
				path: 'speed-classification',
				element: <SpeedClassification />,
			},
			{
				path:'grade-system',
				element:<GradeSystem/>
			},
			{
				path:'patch',
				element:<Patch/>
			},
			{
				path:'vulnerable',
				element:<Vulnerable/>
			},
			{
				path:'alarm-report',
				element:<AlarmReport/>
			},
			{
				path:'mylinewalker',
				element:<MyLinewalker/>

			},
			{
				path:'patch-details',
				element:<PatchDetails/>
			}
			,
			{
				path:'realtime-alarm',
				element:<RealtimeAlarm/>
			},
			{
				path:'realtime-events',
				element:<RealtimeEvents/>
			},
			{
				path:'inspections',
				element:<Inspection/>
			},
			{
				path:'inspection-details',
				element:<InspectionDetails/>
			},
			{
				path: 'chainage-filter',
				element:<ChainageFilter/>
			},
			{
				path: 'chainage-listing',
				element: <ChainageLisiting/>
			},
			{
				path: 'vulnerable-points',
				element: <VulnerablePoints/>
			},
			{
				path: 'snoozed-points',
				element: <SnoozedPoints/>
			},
			{
				path: 'terrain-classification',
				element: <TerrainClassification/>
			},
			{
				path:'escalationmatrix',
				element:<EscalationMatrix/>
			},
			{ 
				path:'vulnerable-details',
				element:<VulnerableDetails/>
			},
			{
				path:'myPig',
				element:<MyPIG />
			},
			{
				path:'eventDetails',
				element:<EventDetails />
			},
			{
				path: 'events-report',
				element:<ReportEvents />
			},
			{
				path:'events',
				element:<Events />
			},
			{
				path:'alarm-details',
				element:<AlarmDeatils/>
			},
			{
				path: 'add-allocation',
				element: <AddAllocation />
			},
			{
				path: 'edit-allocation',
				element: <EditAllocation />
			},
			{
				path: 'settings',
				element: <Settings />
			},
			{
				path: 'allocation-sub/:usertype',
				element: <AllocationSub />
			},
			{
				path: 'edit-allocation-pipeline-path',
				element: <EditAllocationPipelinePath />
			},
			{
				path: 'retraining-data',
				element: <RetrainingData />
			},
			{
				path: 'alarm-table',
				element: <AlarmReportTable />
			},
			{
				path: 'my-profile',
				element : <MyProfile />
			},
			{
				path: 'edgemanagement',
				element : <EdgeMangement />
			},
			{
				path: 'mail-configuration',
				element : <MailConfiguration />
			},
			{
				path: 'add-mail-configuration',
				element: <AddMailConfiguration />
			},
			{
				path: 'edit-mail-configuration',
				element: <EditMailConfiguration />
			},
			{
				path: 'mail-configuration-details',
				element: <MailConfigurationDetails />
			},
			{
				path: 'pidsmanagement',
				element : <PidsManagement />
			},
			{
				path: 'pid-details',
				element : <PidsDetails />
			},
			{
				path: 'alarm-analytics',
				element : <AlarmAnalytics />
			},
			{
				path: 'alarmrule',
				element : <AlarmRuleEngine />
			},
			{
				path: 'event-analytics',
				element : <EventAnalytics />
			},
			{
				path: 'linewalkerstable',
				element : <InspectionView />
			},
			{
				path: 'availability-details',
				element : <AvailabilityDetailsPage />
			},
			{
				path: 'google-map',
				element : <GoogleMap />
			},
			{
				path: 'model-config',
				element : <ModelConfig />
			},
			{
				path: 'terrain-management',
				element : <TerrainManagement />
			},
			{
				path: 'retrain-history',
				element : <RetrainingHistory />
			},
			{
				path: 'simulations',
				element : <Simulations />
			},
			{
				path: 'model-configuration',
				element: <ModelConfiguration />
			},
			{
				path: 'add-simulations',
				element: <AddSimulations />
			},
			{
				path : 'edit-simulation',
				element: <EditSimulations />
			},
			{
				path : 'view-simulation',
				element: <ViewSimulations />
			},
			{
				path : 'eventtypes',
				element: <EventTypes />
			},
			{
				path : 'model-history',
				element : <ModelHistory />
			},
			{
				path : 'fiber-management',
				element : <FiberManagement />
			},
			{
				path : 'channel-info',
				element : <ChannelInfo />
			},
			{
				path : 'channel-width',
				element : <ChannelWidth />
			},
			{
				path : 'convertion',
				element : <Convertion />
			},
			{
				path : 'metrics',
				element : <Metrics />
			},
			{
				path : 'model-classification',
				element : <ModelClassification />
			},
			{
				path : 'prediction-convertion',
				element : <PredicitionConvertion />
			},
			{
				path : 'region-management',
				element : <RegionManagement />
			},
			{
				path : 'grademanagement',
				element : <GradeManagement />
			},
			{
				path : 'alarm-retraining-history',
				element : <AlarmRetrainingHistory />
			},
			{
				path : 'simulation-retraining-history',
				element : <SimulationRetrainingHistory />
			},
			{
				path : 'region-details',
				element : <RegionManagementDetails />
			}
			
			
		],
	},
	{
		path: '/',
		element: <Login />
	},
	{
		path: '*',
		element: <Login />
	}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
