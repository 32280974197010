import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Avatar, Popover, Card, Empty, DatePicker, Row, Col, Select, Typography, Tooltip, Table, Button, Spin, Modal, Space, Checkbox, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DownloadOutlined } from '@ant-design/icons';

import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { CPS_REPORT_LIST, CPS_REPORT_LIST_SUCCESS, CPS_REPORT_LIST_ERROR }  from './constants';

import cpsReportReducer from './reducer';
import {BatchDownloader} from './downloader';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

import axios from 'axios';
import dayjs from 'dayjs';
import { InfoOutlined, TableOutlined } from '@ant-design/icons';
import moment from 'moment';


const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

export default function CPSReport() {
	let start = new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30);
	let end = new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate());
  
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const tlp_feature = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES` , null));
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const [regionId, setRegionId] = useState(null);
	const [tlpId, setTLPId] = useState(null);
  
	const [url, setURL] = useState(null);

	const [dates, setDates] = useState({ start: start, end: end });
  
	const initalState = { cps: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: {}, tlps: [], loading: true, error: false };
	const [data, dispatch] = useReducer(cpsReportReducer, initalState);
  
	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);
	// const [referenceCellValue, setReferenceCellValue] = useState(tlp_feature==null ? false : tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType==2 ? false : tlp_feature && tlp_feature.refElectrodeType==2 ? false : true);
	const [referenceCellValue, setReferenceCellValue] = useState(tlp_feature==null ? false : tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType==2 ? false : tlp_feature && tlp_feature.refElectrodeType==2 ? false : true);
	

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	const [checkanomaly, setAnomalyValue] = useState(false);
	const [tlpParams, setTLPParams] = useState(null);	
	const [tlptype, setTLPType] = useState(0);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const [startdate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30));
	const [enddate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate()));

	const columns = [
		{
			title: langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)',
			dataIndex: 'pipelineChainage',
			key: 'pipelineChainage'
		},
		{
			title: langdata && langdata.DateTime ? langdata.DateTime : 'Date Time',
			dataIndex: 'datetime',
			key: 'datetime',
		},
		{
			title: langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)',
			dataIndex: 'vac2',
			key: 'vac2',
		},
		{
			title: langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)',
			dataIndex: 'vdc2',
			key: 'vdc2',
			render : (_,record) => (
				<>
					{referenceCellValue === false ? record.vdc2 : (parseFloat(record.vdc2) + -1.1).toFixed(4)}
				</>
			)
		},
		{
			title: langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)',
			dataIndex: 'vac1',
			key: 'vac1',
		},
		{
			title: langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)',
			dataIndex: 'vdc1',
			key: 'vdc1',
			render : (_,record) => (
				<>
					{referenceCellValue === false || record.secondVoltage === 0 ? record.vdc1 : (parseFloat(record.vdc1) + -1.1).toFixed(4)}
				</>
			)
		},
		{
			title: langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)',
			dataIndex: 'vbat',
			key: 'vbat',
		},
		{
			title: langdata && langdata.Current ? langdata.Current : 'Current',
			dataIndex: 'current',
			key: 'current',
		},
		{
			title: langdata && langdata.Temp ? langdata.Temp : 'Temp',
			dataIndex: 'temp',
			key: 'temp',
		},
		{
			title: langdata && langdata.Anomaly ? langdata.Anomaly : 'Anomaly',
			dataIndex: 'anomaly',
			key: 'anomaly',
			render: (_, { anomaly }) => (
				anomaly == 'Normal' ?
					(
						<div style={{ color: 'green' }}>
							{ langdata && langdata.Normal ? langdata.Normal : 'Normal'}
						</div>
					)
					:
					(
						<div style={{ color: 'crimson' }}>
							{langdata && langdata.Anomaly ? langdata.Anomaly : 'Anomaly'}
						</div>
					)
			)
		},
		{
			title: langdata && langdata.SystemStatus ? langdata.SystemStatus : 'System Status',
			dataIndex: 'systemStatus',
			key: 'systemStatus',
		},
	];

	const TLPtypes = [
		{
			value: 0,
			label: langdata && langdata.ALL ? langdata.ALL : 'ALL',
			params:''
		},
		{
			value: 3,
			label: langdata && langdata.Anomaly ? langdata.Anomaly : 'Anomaly',
			params:'&anomaly=true'
		},
		{
			value: 2,
			label: langdata && langdata.GoodTLP ? langdata.GoodTLP :  'Good TLP',
			params:'&anomaly=false'
		}
	];

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.CPSReportContent1 ? langdata.CPSReportContent1 : 'This module is used display all the data that is logged on the system (for all Smart TLP devices)This module is used display all the data that is logged on the system (for all Smart TLP devices).'}</p>
			<br/>
		</Card>
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 25);
	}, []);

	useEffect(() => {
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		loadTLPs();
		loadCPSReport(1);
	}, [regionId, allocationId,tlptype]);

	useEffect(() => {
		loadCPSReport(1);
		loadTLPs();
	}, [tlpId, dates,checkanomaly,tlptype]);

	let regions = [
		{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}
	];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	const handleChangeRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(permission == 'superadmin' || permission == 'admin') {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
		}
		setTLPId(null);
	};

	let tlps = [
		{ value: null, label: langdata && langdata.ALL ? langdata.ALL :  'ALL'}
	];

	data && data.tlps.map((tlp) => {
		tlps.push({ value: tlp.id, label: tlp.pipelineChainage.toString() });
	});



	const handleChangeTLP = (e) => {
		setTLPId(e);
	};

	const handleDateChange = (date, dateString) => {
		let start = dayjs(dateString[0]).format('YYYY-MM-DD');
		let end = dayjs(dateString[1]).format('YYYY-MM-DD');

		if (date) {
			setStartDate(new Date(date[0] && date[0].$d));
			setEndDate(new Date(date[1] && date[1].$d));
		}

		setDates({ start: start, end: end});
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const loadTLPs = () => {
		dispatch({ type: TLPS_LIST });

		const limit = 10000;
		const skip = 0;
		let url = `${api}/smarttlp-list?$limit=${limit}&$skip=${skip}&$sort[pipelineChainage]=-1`;

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}

		if ((regionId && regionId !== null) || (allocationId && allocationId !== null)) {
			url = `${api}/smarttlp-list?$limit=${limit}&$skip=${skip}&$sort[pipelineChainage]=-1${filterByregionIdPart}`;
		}

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: TLPS_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: TLPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadCPSReport = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		dispatch({ type: CPS_REPORT_LIST });

		let startDate=`${ moment.utc(startdate.setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss')}`;
		let endDate=`${moment.utc(enddate.setHours(23,59,59,999)).format('YYYY-MM-DD HH:mm:ss')}`;
		let url;

		console.log('# Start: ', startDate);
		console.log('# End: ', endDate);

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}

		if(tlps.length <= 1){
			url = `${api}/tlplogs-list?$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[datetime]=-1&datetime[$gt]=${startDate}&datetime[$lt]=${endDate}${filterByregionIdPart}${tlpParams ? tlpParams : ''}`;
			
			setURL({base: `${api}/tlplogs-list`, params: `$sort[datetime]=-1&datetime[$gt]=${startDate}&datetime[$lt]=${endDate}${filterByregionIdPart}${tlpParams ? tlpParams : ''}`});

		}

		else if(data && data.tlps.length > 0) {

			url = `${api}/tlplogs-list?$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[datetime]=-1&datetime[$gt]=${startDate}&datetime[$lt]=${endDate}${filterByregionIdPart}${tlpParams ? tlpParams : ''}`;
		
			setURL({base: `${api}/tlplogs-list`, params: `$sort[datetime]=-1&datetime[$gt]=${startDate}&datetime[$lt]=${endDate}${filterByregionIdPart}${tlpParams ? tlpParams : ''}`});

		}
		if (tlpId && tlpId !== null) {

			url = `${api}/tlplogs-list?$limit=${_pagination.limit}&$skip=${_pagination.skip}&$and[0][tlpId]=${tlpId}&$sort[datetime]=-1&datetime[$gt]=${startDate}&datetime[$lt]=${endDate}${filterByregionIdPart}${tlpParams ? tlpParams : ''}`;
      
			setURL({base: `${api}/tlplogs-list`, params: `$and[0][tlpId]=${tlpId}&$sort[datetime]=-1&datetime[$gt]=${startDate}&datetime[$lt]=${endDate}${filterByregionIdPart}${tlpParams ? tlpParams : ''}`});
		}
    
		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
    
		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: CPS_REPORT_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: CPS_REPORT_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: CPS_REPORT_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};

	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
	
	const handleSetAnomaly = (e) =>{

		setAnomalyValue(e);
		if(e==false){
			setTLPType(2);
			setTLPParams('&anomaly=false');
	
		}else{
			setTLPType(3);
			setTLPParams('&anomaly=true');
		}
	
	};

	const handleChangeTLPType = (e,options) => {
		if(e==3){
			setAnomalyValue(true);
		}else{
			setAnomalyValue(false);
		}
		setTLPType(e);
		setTLPParams(options.params);
	};

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={4}>
						<Row>
							<Space>
								<TableOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.CPSReport ? langdata.CPSReport : 'CPS Report'}</Title>
						</Row>
					</Col>

					<Col span={20}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion :  'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px',margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											options={regions}
											onSelect={(e, options)=>handleChangeRegion(e, options)}
										/>
									</Space>
									
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbychainage ? langdata.Filterbychainage : 'Filter by chainage'}>
									<Space>
										<Select
											style={{ width: '120px',margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectChainage ? langdata.SelectChainage : 'Select Chainage'}
											optionFilterProp="children"
											defaultValue={null}
											value={tlpId}
											options={tlps}
											onSelect={handleChangeTLP}
											disabled={tlps.length === 1 ? true : false }
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title= {langdata && langdata.FilterbyTLPtype ? langdata.FilterbyTLPtype : 'Filter by TLP type'}>
									<Space>
										<Select
											style={{ width: '120px',margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectType ? langdata.SelectType : 'Select Type'}
											optionFilterProp="children"
											value={tlptype}
											options={TLPtypes}
											onSelect={(e,options)=>{handleChangeTLPType(e,options);}}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>

								<Tooltip placement="bottom" title={langdata && langdata.Filterbydaterange ? langdata.Filterbydaterange : 'Filter by date range'}>
									<Space>
										<RangePicker 
											allowClear={false} format={settingsdateformat}
											style={{margin:'5px'}}
											defaultValue={[dayjs(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30)),dayjs(new Date())]}
											onChange={handleDateChange}
											disabledDate={disabledDate} 
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Popover title={langdata && langdata.CPSReport ? langdata.CPSReport : 'CPS Report'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
								<Space>&nbsp;&nbsp;</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
							{tlp_feature && tlp_feature.refElectrodeType!==2 ?
								<Col span={12}>
									<Text type="secondary">
										<Checkbox checked={referenceCellValue} onChange={()=> { referenceCellValue === true ? setReferenceCellValue(false) : setReferenceCellValue(true);}}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
									</Text>
								</Col>: null }
							<Col span={12}>
								<Text type="secondary">
									<Checkbox checked={checkanomaly} onChange={()=> {handleSetAnomaly(checkanomaly === true ? false : true);}}>{langdata && langdata.Showanomaly ? langdata.Showanomaly : 'Show anomaly'}</Checkbox>
								</Text>
							</Col>
						</Row>  
						<Row>
							<Col span={24}>
								<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
								<br />
								<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
							</Col>
						</Row>
						<Row style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col span={24}>
								{ data.cps ? (
									<Table 
										scroll={{
											x: 900,
										}}
										columns = { columns } 
										dataSource = {data && data.tlps.length > 0 ? data.cps : [] } 
										pagination = {data && data.tlps.length > 0 ? data.pagination :0}
										onChange = { loadCPSReport }
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
						<Row style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col span={24}>
								<Button disabled={data && data.tlps.length > 0 && data.cps && data.cps.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport : 'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download : 'Download'}</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>

			<Spin spinning={data.loading} fullscreen />

			{/* Fetch and Download Modal Starts */}
			<Modal
				title={langdata && langdata.DownloadReport ? langdata.DownloadReport : 'Download Report'}
				centered
				destroyOnClose={true}
				open={showFetchAndDownloadModal}
				onOk={{closeShowFetchAndDownloadModal}}
				onCancel={closeShowFetchAndDownloadModal}
				footer={[]}
				width={540}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Typography>{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords : 'Total number of records'}: {data.pagination.total}</Typography>
					</Col>
				</Row>

				{ data.pagination.total > 0 ? (
					<BatchDownloader total={data.pagination.total} url={url}/>
				) : (
					<>
						<Row gutter={16} style={{
							backgroundColor: '#d3d3d3',
							padding: '5px',
							marginTop: '10px'
						}}>
							<Col span={24}>
								<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound : 'No records found'}</Typography>
							</Col>
						</Row>
						<br/>
					</>
          
				)}
			</Modal>
			{/* Fetch and Download Modal Modal Ends */}
		</>
	);
}

