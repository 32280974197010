import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col, Breadcrumb, Typography, Tabs, Empty, Checkbox, DatePicker, Table, Card, Space, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { HomeOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import TabPane from 'antd/es/tabs/TabPane';
import moment from 'moment';
import axios from 'axios';
import realtimeCPSReducer from './realtime-cps/reducer.js';
import { TLP_BATTERY_REPLACEMENT_LOGS, TLP_BATTERY_REPLACEMENT_LOGS_ERROR, TLP_BATTERY_REPLACEMENT_LOGS_SUCCESS, TLP_CONFIG_LOGS, TLP_CONFIG_LOGS_ERROR, TLP_CONFIG_LOGS_SUCCESS, TLP_LOGS, TLP_LOGS_ERROR, TLP_LOGS_SUCCESS } from './realtime-cps/constants.js';
import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import { FullscreenOutlined,FullscreenExitOutlined } from '@ant-design/icons';
import { useAuth } from '../Context.jsx';
import GoogleMap from '../Map/map.js';
import MarkerClusterGroup from 'react-leaflet-cluster';
import dayjs from 'dayjs';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


const pipecolor = { color: 'black' };
const bordercolor = { color: '#fc8383'};

const { Title, Text} = Typography;

const TLPicon = new Icon({
	iconUrl:require('../../images/tlpred.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});

const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});

const dateFormat = 'YYYY-MM-DD';

export default function TLPDetails() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const tlp_feature = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES` , null));
	const { state } = useLocation();
	const navigate = useNavigate();
	const initalState = { cpsLogs: [], batteryReplacedLogs:[], hardwareconfiglogs:[], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: {}, loading: true, error: false };
	const [data, dispatch] = useReducer(realtimeCPSReducer, initalState);
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
	const center = [];
	// center.push(state && state.details && state.details.latitude, state && state.details && state.details.longitude);
	const [size, setSize] = useState('200px');
	const [referenceCellValue, setReferenceCellValue] = useState(tlp_feature==null ? false : tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType==2 ? false : tlp_feature && tlp_feature.refElectrodeType==2 ? false : true);
	const {handleGetMapdata, mapdata} = useAuth();
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const device_id = state && state.details && state.details.deviceId;
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);

	const [startdate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate()));
	const [enddate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate()));

	const columns = [
		{
			title: langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)',
			dataIndex: 'pipelineChainage',
			key: 'pipelineChainage'
		},
		{
			title: langdata && langdata.Device ? langdata.Device : 'Device',
			dataIndex: 'deviceId',
			key: 'deviceId',
			fixed: 'left',
		},
		{
			title: langdata && langdata.DateTime ? langdata.DateTime : 'Date Time',
			dataIndex: 'datetime',
			key: 'datetime',
		},
		{
			title: langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)',
			dataIndex: 'vac2',
			key: 'vac2',
		},
		{
			title: langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)',
			dataIndex: 'vdc2',
			key: 'vdc2',
			render : (_,record) => (
				<>
					{referenceCellValue === false ? record.vdc2 : (parseFloat(record.vdc2) + -1.1).toFixed(4)}
				</>
			)
		},
		{
			title: langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)',
			dataIndex: 'vac1',
			key: 'vac1',
		},
		{
			title: langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)',
			dataIndex: 'vdc1',
			key: 'vdc1',
			render : (_,record) => (
				<>
					{referenceCellValue === false || record.secondVoltage === 0  ? record.vdc1 : (parseFloat(record.vdc1) + -1.1).toFixed(4)}
				</>
			)
		},
		{
			title: langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)',
			dataIndex: 'vbat',
			key: 'vbat',
		},
		{
			title: langdata && langdata.Current ? langdata.Current : 'Current',
			dataIndex: 'current',
			key: 'current',
		},
		{
			title: langdata && langdata.Temp ? langdata.Temp : 'Temp',
			dataIndex: 'temp',
			key: 'temp',
		},
		{
			title: langdata && langdata.Anomaly ? langdata.Anomaly : 'Anomaly',
			dataIndex: 'anomaly',
			key: 'anomaly',
			render: (_, { anomaly }) => (
				anomaly == 'Normal' ?
					(
						<Space><div style={{ color: 'green' }}>{'NORMAL'}</div></Space>
					)
					:
					(
						<Space><div style={{ color: 'crimson' }}>{'ANOMALY'}</div></Space>
					)
			)
		}
	];

	const batteryReplacedColumn = [
		{
			title: langdata && langdata.DateTime ? langdata.DateTime : 'Date Time',
			dataIndex: 'datetime',
			key: 'datetime'
		},
		{
			title: langdata && langdata.Voltage ? langdata.Voltage : 'Voltage',
			dataIndex: 'voltage',
			key: 'voltage'
		},
		{
			title: langdata && langdata.PreviousVoltage ? langdata.PreviousVoltage : 'Previous Voltage',
			dataIndex: 'previousVoltage',
			key: 'previousVoltage'
		},
		
		{
			title: langdata && langdata.Status ? langdata.Status : 'Status',
			dataIndex: 'status',
			key: 'status'
		}
	];


	const hardwareconfigColumn=[
		{
			title: langdata && langdata.LastSyncedDate ? langdata.LastSyncedDate : 'Last Synced Date',
			dataIndex: 'hardwareConfigSyncedDate',
			key: 'hardwareConfigSyncedDate'
		},
		{
			title: langdata && langdata.PSPRange ? langdata.PSPRange : 'PSP Range',
			dataIndex: 'vdc2Normal',
			key: 'vdc2Normal'
		},
		{
			title: langdata && langdata.ACPICKUPRange ? langdata.ACPICKUPRange : 'AC PICKUP Range',
			dataIndex: 'vac2Normal',
			key: 'vac2Normal'
		},
		{
			title: langdata && langdata.CSPRange ? langdata.CSPRange : 'CSP Range',
			dataIndex: 'vdc1Normal',
			key: 'vdc1Normal'
		},
		{
			title: langdata && langdata.CASINGACPICKUPRange ? langdata.CASINGACPICKUPRange : 'CASING AC PICKUP Range',
			dataIndex: 'vac1Normal',
			key: 'vac1Normal'
		},
		{
			title: langdata && langdata.PollFrequency ? langdata.PollFrequency : 'Poll Frequency',
			dataIndex: 'pollFrequencyInMinutes',
			key: 'datetpollFrequencyInMinutesime'
		},
		{
			title: langdata && langdata.AnomalyPollFrequency ? langdata.AnomalyPollFrequency : 'Anomaly Poll Frequency',
			dataIndex: 'anomalyPollFrequencyInMinutes',
			key: 'anomalyPollFrequencyInMinutes'
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		if (map_type && map_type === 'leaflet') {
			handleGetMapdata(authtoken,null,null,null);
		}
		loadCPSReport(1);
		loadBatteryReplacementLogs(1);
		tlpConfigLogs();
	},[authtoken,date]);

	const pipeline = [];

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			if(state && state.details && state.details.latitude){
				center.push(parseFloat(state && state.details && state.details.latitude), parseFloat(state && state.details && state.details.longitude));
			}else{
				center.push(item.latitude,item.longitude);
			}
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	const tempMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
	}

	const handleTab = (value) => {
		if (parseInt(value) === 2) {
			loadCPSReport(1);
		}
	};

	const loadCPSReport = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		dispatch({ type: TLP_LOGS });

		let startDate=`${ moment.utc(startdate.setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss')}`;
		let endDate=`${moment.utc(enddate.setHours(23,59,59,999)).format('YYYY-MM-DD HH:mm:ss')}`;

		// let url = `${api}/tlplogs?tlpId=${state && state.details.id}&$limit=${_pagination.limit}&$skip=${_pagination.skip}&logDate=${date.split('/').reverse().join('-')}&$sort[datetime]=-1`;
		let url = `${api}/tlplogs?tlpId=${state && state.details.id}&$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[datetime]=-1&datetime[$gt]=${startDate}&datetime[$lt]=${endDate}&$sort[datetime]=-1`;
		

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
    
		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: TLP_LOGS_SUCCESS, payload: response.data, page:pagination.current });
			} else {
				dispatch( { type: TLP_LOGS_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLP_LOGS_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const tlpConfigLogs = () =>{

		dispatch({ type: TLP_CONFIG_LOGS });

		let url = `${api}/tlpconfiglogs?tlpId=${state && state.details.id}&$sort[hardwareConfigSyncedDate]=-1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};
    
		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: TLP_CONFIG_LOGS_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: TLP_CONFIG_LOGS_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLP_CONFIG_LOGS_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});

	};

	const handleDate  = (date) => {
		setDate(new Date(date && date.$d).toLocaleDateString('en-UK', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}));
		if (date) {
			setStartDate(new Date(date && date.$d));
			setEndDate(new Date(date && date.$d));
		}
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const loadBatteryReplacementLogs = () => {
		if(device_id) {
			dispatch({ type : TLP_BATTERY_REPLACEMENT_LOGS });
			const config = {
				method: 'get',
				maxBodyLength: Infinity,
				url:  `${api}/tlpbatterylogs?deviceId=${device_id}&$sort[id]=1`,
				timeout: timeout,
				headers: {
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				}
			};
    
			axios.request(config) .then((response) => {
				if (response && response.data) {
					dispatch( { type: TLP_BATTERY_REPLACEMENT_LOGS_SUCCESS, payload: response.data });
				} else {
					dispatch( { type: TLP_BATTERY_REPLACEMENT_LOGS_ERROR });
				}
			}).catch((error) => {
				console.log(error);
				dispatch( { type: TLP_BATTERY_REPLACEMENT_LOGS_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		}
	};

	return (
		<>
			{state ?
				<>
					<Row style={{
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={12}>
							<Title level={3} style={{ lineHeight: '1'}}>{langdata && langdata.CPDetails ? langdata.CPDetails : 'CP CPDetails'}</Title>
						</Col>
					</Row>
					{size && size === '200px' ? 
						<Row gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}} style={{margin:'10px'}} >

							<Col className="gutter-row" span={24}>
      

								<Card>
									<Breadcrumb
										items={[
											{
												href: '/dashboard',
												title: <HomeOutlined />,
											},
											{
												title: (
													<>
														<a onClick={()=>{navigate(-1);}}>{langdata && langdata.RealtimeCPS ? langdata.RealtimeCPS : 'Realtime CPS'}</a>
													</>
												),
											},
											{
												title: 'CP Details',
											},
										]}
									/>
									<br/>
									{/* Map Section Starts */}
									{/* <Row style={{justifyContent:'right', display:'flex'}}>

				<Button type='primary' onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} style={{marginRight:'20px'}} >View Map</Button>
			</Row> */}
									<Content>

										<Row style={{
											// margin: '10px',
											// paddingLeft: '10px',
											// paddingRight: '10px',
											// paddingTop: '10px',
											// paddingBottom: '10px',
											background: '#FFFFFF',
											alignItems:'center'
										}} >
											{/* <Col span={24} style={{ textAlign : 'right'}}>
          <Button type='primary' onClick={()=>{setSize(size === 'auto' ? '200px':'auto')}} style={{marginRight:'20px'}} >View Map</Button>
          </Col> */}
											<Col span={24}>
												{state && state.details && state.details.latitude && state && state.details && state.details.longitude ? 

													map_type && map_type !== 'leaflet' ?

														< GoogleMap TLPDetails = {state.details} height={'30vh'} />
			
														:

														mapdata && mapdata ? 
															<div className="App" style={{textAlign: 'left'}}>
        
																<MapContainer center={center} zoom={17} scrollWheelZoom={false} style={{height:size}}>
																	<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenOutlined /></button>
																	<TileLayer
																		attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
																		url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
																	/>

																	<LayersControl position="topright" >

																		<MarkerClusterGroup>
																			{allPidsDatas && allPidsDatas.map((marker,index)=>
																				<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
																					<Popup>
																						{marker.pop}
																					</Popup>

																				</Marker>

	

																			)}       
																		</MarkerClusterGroup>

																		<Marker key={'pidsmarker'} position={[state && state.details && state.details.latitude, state && state.details && state.details.longitude]} icon={TLPicon}>
																			<Popup>
																				{state && state.details && state.details.pipelineChainage}
																			</Popup>
																			{/* <Tooltip>{state && state.details && state.details.pipelineChainage}</Tooltip> */}
																		</Marker>

																		<LayersControl.Overlay  checked name={langdata && langdata.Pipeline ? langdata.Pipeline : 'Pipeline'}>
																			<MarkerClusterGroup>
																				<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
																					{tempMapArray && Object.values(tempMapArray).map((item) => {
																						let pipeline = [];
																						item.map((data) => {
																							pipeline.push({lat : data.latitude,lng : data.longitude});
																						});
																						return (
																							<>
																								<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																							</>
																						);
																					})}
																				</Pane>
																			</MarkerClusterGroup>
																		</LayersControl.Overlay>


																		<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder : 'Pipeline Border'}>
																			<MarkerClusterGroup>
																				{tempMapArray && Object.values(tempMapArray).map((item) => {
																					let pipelineBorder = [];
																					item.map((data) => {
																						pipelineBorder.push({lat : data.latitude,lng : data.longitude});
																					});
																					return (
																						<>
																							<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																						</>
																					);
																				})}
																			</MarkerClusterGroup>
																		</LayersControl.Overlay>

																	</LayersControl>
																</MapContainer>
    
															</div>:null
													:null}
											</Col>
										</Row>
										{/* Map Section Ends */}

										<Row style={{
											margin: '10px',
											paddingLeft: '10px',
											paddingRight: '10px',
											background: '#FFFFFF',
											alignItems:'center'
										}} >
											<Col span={24}>
												{/* <Tabs defaultActiveKey="1" items={tabItems} /> */}
            
												<Tabs 
													onChange={handleTab}
													style={{
														margin: '10px',
														paddingLeft: '10px',
														paddingRight: '10px',
														background: '#FFFFFF',
													}} >
													<TabPane tab= {langdata && langdata.TLPDetails ? langdata.TLPDetails : 'TLP Details'} key="1" >
														<Row>
															<Col span={12}>
																<Row gutter={16}>
																	<Col span={24}>
																		<h3><u>{langdata && langdata.Coredetails ? langdata.Coredetails : 'Core details'}</u></h3>
																	</Col>
																	<Col span={24}  style={{ marginTop : '2%'}}>
																		<label>{langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)'}: {state && state.details && state.details.pipelineChainage}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.Deviceid ? langdata.Deviceid : 'Device id'}: {state && state.details && state.details.deviceId}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.Installatondate ? langdata.Installatondate : 'Installaton date'}: {state && state.details && state.details.installedDate}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.LastSync ? langdata.LastSync : 'Last Sync'}: {state && state.details && state.details.lastSync}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.LastLoggedDate ? langdata.LastLoggedDate : 'Last Logged Date'}: {state && state.details && state.details.lastLoggedDate}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.Latitude ? langdata.Latitude : 'Latitude'}: {state && state.details && state.details.latitude}</label> 
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'}: {state && state.details && state.details.longitude}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.ACPICKUPVLimit ? langdata.ACPICKUPVLimit : 'AC PICKUP (V) Limit'}: {state && state.details && state.details.vac2Normal}</label> 
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.PSPVLimit ? langdata.PSPVLimit : 'PSP (V) Limit'}: {state && state.details && state.details.vdc2Normal}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.CASINGACPICKUPVLimit ? langdata.CASINGACPICKUPVLimit : 'CASING AC PICKUP (V) Limit'}: {state && state.details && state.details.vac1Normal}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.CSPVLimit ? langdata.CSPVLimit : 'CSP (V) Limit'}: {state && state.details && state.details.vdc1Normal}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.HardwareConfigSyncedOn ? langdata.HardwareConfigSyncedOn : 'Hardware Config Synced On'}: {state && state.details && state.details.hardwareConfigSyncedDate}</label> 
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.BatteryReplacedOn ? langdata.BatteryReplacedOn : 'Battery Replaced On'}: {state && state.details && state.details.batteryReplacedDate}</label> 
																	</Col>
																</Row>
															</Col>
															<Col span={12} >
																<Row gutter={16}>
																	<Col span={24}>
																		<h3><u>{langdata && langdata.CurrentValues ? langdata.CurrentValues : 'Current Values'}</u></h3>
																	</Col>
																	{tlp_feature && tlp_feature.refElectrodeType !==2 ?
																		<Col span={24} style={{ marginTop : '2%'}}>
																			<Checkbox checked={referenceCellValue} onChange={()=> { referenceCellValue === true ? setReferenceCellValue(false) : setReferenceCellValue(true);}}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
																		</Col> : null }
																	<Col span={24}  style={{ marginTop : '2%'}}>
																		<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
																		<br />
																		<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
																	</Col>
																	<Col span={24}  style={{ marginTop : '2%'}}>
																		<label>{langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}: {state && state.details && state.details.vac2}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}: {referenceCellValue === false ? state && state.details && state.details.vdc2 : (parseFloat(state && state.details && state.details.vdc2) + -1.1).toFixed(4)}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}: {state && state.details && state.details.vac1}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}: { referenceCellValue === false || state && state.details && state.details.secondVoltage === 0 ? state && state.details && state.details.vdc1 :  (parseFloat(state && state.details && state.details.vdc1) + -1.1).toFixed(4)}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.CurrentmA ? langdata.CurrentmA : 'Current (mA)'}: {state && state.details && state.details.current}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)'}: {state && state.details && state.details.vbat}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.Temp ? langdata.Temp : 'Temp'}: {state && state.details && state.details.temp} &deg;C</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.IsTRU ? langdata.IsTRU : 'Is TRU'}: </label>
																		{ (state && state.details && state.details.isTRU)
																			?
																			'YES'
																			:
																			'NO'
																		}
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.TRUvoltageV ? langdata.TRUvoltageV : 'TRU voltage (V)'}: {state && state.details && state.details.truVoltage}</label>
																	</Col>
																	<Col span={24}>
																		<label>{langdata && langdata.TRUcorrectionvoltageV ? langdata.TRUcorrectionvoltageV : 'TRU correction voltage (V)'}: {state && state.details && state.details.truCorrectionVoltage}</label>  
																	</Col>
																</Row>
															</Col>
														</Row>
													</TabPane>
													<TabPane tab={langdata && langdata.TLPLogs ? langdata.TLPLogs : 'TLP Logs'} key="2">
														<Row>
															<Col span={24}>
																<Row gutter={24}>
															
																	<Col span={12} style={{ marginTop : '1%'}}>
																		{tlp_feature && tlp_feature.refElectrodeType!==2 ?
																			<Checkbox checked={referenceCellValue} onChange={()=> { referenceCellValue === true ? setReferenceCellValue(false) : setReferenceCellValue(true);}}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
																			: null }
																	</Col>
																	<Col span={12} style={{ textAlign : 'right'}} >
																		<DatePicker allowClear={false} defaultValue={dayjs(moment().format('YYYY-MM-DD'), dateFormat)} onChange={handleDate} disabledDate={disabledDate} format={settingsdateformat}/>
																	</Col>
																	<Col span={24}  style={{ marginTop : '1%'}}>
																		<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
																		<br />
																		<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
																	</Col>
																	<Col span={24} style={{ marginTop : '2%'}}>
																		{ data.cpsLogs ? (
																			<Table
																				scroll={{
																					x: 900,
																				}}
																				columns = { columns } 
																				dataSource = { data.cpsLogs } 
																				pagination = { data.cpsLogs.length > 0 ? data.pagination : 0}
																				onChange = { loadCPSReport }
																			/>
																		)
																			:
																			(
																				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
																			)}
																	</Col>
																</Row>
															</Col>
														</Row>
													</TabPane>
													<TabPane tab={langdata && langdata.BatteryReplacementLogs ? langdata.BatteryReplacementLogs : 'Battery Replacement Logs'} key="3" >
														<Table
															scroll={{
																x: 900,
															}}
															columns = { batteryReplacedColumn } 
															dataSource = { data.batteryReplacedLogs }
														/>			

													</TabPane>
											
													<TabPane tab= {langdata && langdata.HardwareConfigLogs ? langdata.HardwareConfigLogs : 'Hardware Config Logs'}  key="4" >
												
														{data.hardwareconfiglogs.length > 0 ? 
															<Table
																scroll={{
																	x: 900,
																}}
																columns = { hardwareconfigColumn } 
																dataSource = { data.hardwareconfiglogs }
															/> 
															: 
															<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
	

													</TabPane>

												</Tabs>
											</Col>
										</Row>
									</Content>
								</Card>
							</Col>
						</Row>
						:

						mapdata && mapdata ?
							<div className="App" style={{textAlign: 'left'}}>
        
								<MapContainer center={center} zoom={17} scrollWheelZoom={false} >
									<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenExitOutlined /></button>
									<TileLayer
										attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
										url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									/>
									<LayersControl position="topright" >

										<MarkerClusterGroup>
											{allPidsDatas && allPidsDatas.map((marker,index)=>
												<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
													<Popup>
														{marker.pop}
													</Popup>

												</Marker>

	

											)}       
										</MarkerClusterGroup>

										<Marker key={'tlpmarker'} position={[state && state.details && state.details.latitude, state && state.details && state.details.longitude]} icon={TLPicon}>
											<Popup>
												{state && state.details && state.details.pipelineChainage}
											</Popup>
											{/* <Tooltip>{state && state.details && state.details.pipelineChainage}</Tooltip> */}
										</Marker>
										<LayersControl.Overlay checked name="Pipeline">
											<MarkerClusterGroup>
												<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
													{tempMapArray && Object.values(tempMapArray).map((item) => {
														let pipeline = [];
														item.map((data) => {
															pipeline.push({lat : data.latitude,lng : data.longitude});
														});
														return (
															<>
																<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
															</>
														);
													})}
												</Pane>
											</MarkerClusterGroup>
										</LayersControl.Overlay>


										<LayersControl.Overlay name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder : 'Pipeline Border'}>
											<MarkerClusterGroup>
												{tempMapArray && Object.values(tempMapArray).map((item) => {
													let pipelineBorder = [];
													item.map((data) => {
														pipelineBorder.push({lat : data.latitude,lng : data.longitude});
													});
													return (
														<>
															<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
														</>
													);
												})}
											</MarkerClusterGroup>
										</LayersControl.Overlay>

									</LayersControl>
								</MapContainer>
    
							</div>:null
					}
				</>
				: '' }
		</>
	);
}