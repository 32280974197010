import { React, useEffect, useReducer, useState } from 'react';

import { Row, Col, Typography, Space, Table, Tooltip, Button, Modal, Form, Select, Input, Spin, Divider, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ToolOutlined, PlusOutlined, EditOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';
import { MODELCONFIG_DETAILS, MODELCONFIG_DETAILS_ERROR, MODELCONFIG_DETAILS_SUCCESS, MODELCONFIG_LIST, MODELCONFIG_LIST_ERROR, MODELCONFIG_LIST_SUCCESS, MODEL_CLASSIFICATIONS, MODEL_CLASSIFICATIONS_ERROR, MODEL_CLASSIFICATIONS_SUCCESS, RETRAIN_MODEL_TYPE, RETRAIN_MODEL_TYPE_ERROR, RETRAIN_MODEL_TYPE_SUCCESS, RETRAIN_TYPE_DETAILS } from './constants';
import axios from 'axios';
import modelReducer from './reducer';
import { useAuth } from '../Context';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;

let model_data_pagination = {};


export default function ModelConfiguration() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = { loading: false, error: false, modelConfigData : [], retraintype_details:[], modelConfigPagination: { total: 0, limit: 10, skip: 0, current:1 }, modelConfigDetails : [], modeltype:[], modelClassificationDate:[]};
	const [data, dispatch] = useReducer(modelReducer, initalState);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const {getAllEvents, eventsData,  getPids, pidsData } = useAuth();
	const [showAddModelConfig, setShowAddModelConfig] = useState(false);
	const [showEditModelConfig, setShowEditModelConfig] = useState(false);
	const [showDeleteModel, setShowDeleteModel] = useState(false);
	const [showSaveButton, setShowSaveButton] = useState(false);
	const [openPrimaryModal, setOpenPrimaryModal] = useState(false);
	const [openSecondaryModal, setOpenSecondaryModal] = useState(false);
	const [pidsId, setPidsId] = useState('all');
	const navigate = useNavigate();

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;


	// const navigate = useNavigate();

	const pidsList = [];
	const eventsList = [];
	const pidsListFilter = [];
	const modeltypelist = [];
	const modelClassficationslist=[];

	data.modeltype && data.modeltype.map((type)=>{
		modeltypelist.push({value:type.id, label:type.name});
	});

	data.modelClassificationDate && data.modelClassificationDate.map((type)=>{
		modelClassficationslist.push({value:type.id, label:type.alias ? type.alias : type.modelLevel});
	});
	

	pidsListFilter.push({
		value : 'all',
		label : `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	},
	{
		value : 0,
		label : 'CENTRAL'
	});

	pidsList.push(
		{
			value : 0,
			label : 'CENTRAL'
		}),

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
		pidsListFilter.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
	});

	pidsList.sort((a,b) => { return a.label - b.label; });
	pidsListFilter.sort((a,b) => { return a.label - b.label; });

	eventsData && eventsData.map((item) => {
		eventsList.push({
			value : item.eventType,
			label: item.eventType
		});
	});
  
	const columns = [
		{
			title: `${langdata && langdata.PIDSName ? langdata.PIDSName:'PIDS Name'}`,
			dataIndex: 'pidsinfoId',
			key: 'pidsinfoId',
			// fixed: 'left',
			render: (pidsinfoId) => (
				<Space>
					{ pidsinfoId ? 
						(pidsData && pidsData.find((pid) => pid.id == pidsinfoId).name.toUpperCase())
						: 'CENTRAL' }
				</Space>
			)
		},
		{
			title: `${langdata && langdata.ModelType ? langdata.ModelType:'Model Type'}`,
			dataIndex: 'modelType',
			key: 'modelType',
		},
		{
			title: `${langdata && langdata.ModelLevel ? langdata.ModelLevel:'Model Level'}`,
			dataIndex: 'modelClassfications',
			key: 'modelClassfications',
		},
		{
			title: `${langdata && langdata.ModelClasses ? langdata.ModelClasses:'Model Classes'}`,
			dataIndex: 'eventTypes',
			key: 'eventTypes',
		},
		{
			title: `${langdata && langdata.Interpolation ? langdata.Interpolation:'Interpolation'}`,
			dataIndex: 'interpolationRequired',
			key: 'interpolationRequired',
			render: (interpolationRequired) => (
				<Space size="middle">
					{ interpolationRequired ? 
						'YES'
						: 'NO' }
				</Space>
			)
		},
		{
			title: `${langdata && langdata.SampleLength ? langdata.SampleLength:'Sample Length'}`,
			dataIndex: 'sampleLength',
			key: 'sampleLength',
		},
		{
			title: `${langdata && langdata.SampleType ? langdata.SampleType:'Sample Type'}`,
			dataIndex: 'datasetType',
			key: 'datasetType',
		},
		{
			title: `${langdata && langdata.RetrainingType ? langdata.RetrainingType:'Retraining Type'}`,
			dataIndex: '',
			key: '',
			render: (_, record) => (
				<Space style={{justifyContent:'',display:'flex'}} size="middle">
					<>
						<Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {handlePrimaryModal(record);}}>{langdata && langdata.Primary ? langdata.Primary:'Primary'}</Button>
						| <Button style={{ display : 'contents', color : '#1FA8FF'}} onClick={() => {handleSecondaryModal(record);}}>{langdata && langdata.Secondary ? langdata.Secondary:'Secondary'}</Button>
					</>
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
			key: 'action',
			align:'center',
			// fixed: 'right',
			// width: 100,
			render: () => (
				<Space style={{justifyContent:'center',display:'flex'}} size="middle">
					<a title={langdata && langdata.Edit ? langdata.Edit:'Edit'} onClick={() => editModalOpen()}><EditOutlined/></a> 
					<a title={langdata && langdata.Delete ? langdata.Delete:'Delete'} onClick={() => setShowDeleteModel(true)} ><DeleteOutlined/></a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		}
	];

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		modelData(1);
		getPids(authtoken);
		getAllEvents(authtoken);
	}, [authtoken, pidsId]);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 1135);
	}, []);

	const modelData = (pagination) => {

		let _pagination = { };
		_pagination.total = model_data_pagination.total ? model_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);
		


		dispatch({ type : MODELCONFIG_LIST});

		let params = '';
		if(pidsId != 'all') {
			params = `&pidsinfoId=${pidsId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/modelconfiguration?$limit=${_pagination.limit}&$skip=${_pagination.skip}${params}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					model_data_pagination.total = response.data.total ? response.data.total : 0;
					model_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					model_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					model_data_pagination.current = pagination.current ? pagination.current : 1;
					dispatch({ type : MODELCONFIG_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : MODELCONFIG_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : MODELCONFIG_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const closeAddModelConfig = () => {
		setShowAddModelConfig(false);
		addModelConfigFormik.values.pidsinfoId = null;
		addModelConfigFormik.values.modelType = null;
		addModelConfigFormik.values.modelClassfications = null;
	};

	const closeEditModelConfig = () => {
		setShowEditModelConfig(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());	

	const loadModelDetails = ((id) =>  {
		let details = null;
		
		dispatch({ type: MODELCONFIG_DETAILS });
		
		if (data && data.modelConfigData) {
			data && data.modelConfigData.map((item) => {
				if (item.id === id) {
					details = item;
				}
		
				return true;
			});
		
			dispatch({ type: MODELCONFIG_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: MODELCONFIG_DETAILS_ERROR });
		}
	});

	const closeDeleteModel = () => {
		setShowDeleteModel(false);
	};

	const addModelConfigFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			pidsinfoId : '',
			numOfClasses :null,
			eventTypes :null,
			datasetType :null,
			interpolationRequired :null,
			sampleLength :null,
			modelDimension :null,
			modelType :null,
			primaryTotalSampleCount :null,
			secondaryTotalSampleCount :null,
			primaryMinSampleCount :null,
			secondaryMinSampleCount :null,
			primarySampleWeightage :null,
			secondarySampleWeightage :null,
			primaryLearningRate :null,
			secondaryLearningRate :null,
			primaryTrainAccuracy :null,
			secondaryTrainAccuracy :null,
			primaryTestAccuracy :null,
			secondaryTestAccuracy :null,
			primaryTrainF1Score :null,
			secondaryTrainF1Score :null,
			primaryTestF1Score : null,
			secondaryTestF1Score : null,
			primaryEpochs : null,
			secondaryEpochs : null,
			primaryBatchSize : null,
			secondaryBatchSize : null,
			modelClassfications:null
		},

		onSubmit: (values) => {
			const {pidsinfoId,numOfClasses,eventTypes,datasetType,interpolationRequired,sampleLength,
				modelType,primaryTotalSampleCount,secondaryTotalSampleCount,primaryMinSampleCount,
				secondaryMinSampleCount,primarySampleWeightage,secondarySampleWeightage,primaryLearningRate,
				secondaryLearningRate,primaryTrainAccuracy,secondaryTrainAccuracy,primaryTestAccuracy,
				secondaryTestAccuracy,primaryTrainF1Score,secondaryTrainF1Score, primaryTestF1Score, 
				secondaryTestF1Score, primaryEpochs, secondaryEpochs, primaryBatchSize, secondaryBatchSize, modelClassfications } = values;

			if(pidsinfoId === null || numOfClasses === null || eventTypes === null || datasetType === null || interpolationRequired === null || sampleLength === null || 
				modelType === null || primaryTotalSampleCount === null || secondaryTotalSampleCount === null || primaryMinSampleCount === null || 
				secondaryMinSampleCount === null || primarySampleWeightage === null || secondarySampleWeightage === null || primaryLearningRate === null || 
				secondaryLearningRate === null || primaryTrainAccuracy === null || secondaryTrainAccuracy === null || primaryTestAccuracy === null || 
				secondaryTestAccuracy === null || primaryTrainF1Score === null || secondaryTrainF1Score === null, primaryTestF1Score === null ||
				secondaryTestF1Score === null ||  primaryEpochs === null ||  secondaryEpochs === null ||  primaryBatchSize === null ||  secondaryBatchSize === null || modelClassfications ==null) {
				Modal.warning({title : `${langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields :'Please fill all the mandatory fields'}` });
			}
			else {

				let data = JSON.stringify({
					'pidsinfoId' : pidsinfoId,
					'numOfClasses' : numOfClasses,
					'eventTypes' : eventTypes,
					'datasetType' : datasetType,
					'interpolationRequired' : interpolationRequired,
					'sampleLength' : sampleLength,
					'modelDimension' : 'Single',
					'modeltypeId' : modelType,
					'primaryTotalSampleCount' : primaryTotalSampleCount,
					'secondaryTotalSampleCount' : secondaryTotalSampleCount,
					'primaryMinSampleCount' : primaryMinSampleCount,
					'secondaryMinSampleCount' : secondaryMinSampleCount,
					'primarySampleWeightage' : primarySampleWeightage,
					'secondarySampleWeightage' : secondarySampleWeightage,
					'primaryLearningRate' : primaryLearningRate,
					'secondaryLearningRate' : secondaryLearningRate,
					'primaryTrainAccuracy' : primaryTrainAccuracy,
					'secondaryTrainAccuracy' : secondaryTrainAccuracy,
					'primaryTestAccuracy' : primaryTestAccuracy,
					'secondaryTestAccuracy' : secondaryTestAccuracy,
					'primaryTrainF1Score' : primaryTrainF1Score,
					'secondaryTrainF1Score' : secondaryTrainF1Score,
					'primaryTestF1Score' : primaryTestF1Score,
					'secondaryTestF1Score' : secondaryTestF1Score,
					'primaryEpochs' : primaryEpochs,
					'secondaryEpochs' : secondaryEpochs,
					'primaryBatchSize' : primaryBatchSize,
					'secondaryBatchSize' : secondaryBatchSize,
					'modelclassificationsId':modelClassfications
				});

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/modelconfiguration`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then(() => {
						Modal.success({ title: `${langdata && langdata.Successfullyaddedmodelconfiguration ? langdata.Successfullyaddedmodelconfiguration :'Successfully added model configuration.'}` });
						setShowAddModelConfig(false);
						modelData(1);
					})
					.catch((error) => {
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
							});
						}
						console.log(error);
					});
			}
		}
	});


	const editModelConfigFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			pidsinfoId : data.modelConfigDetails && data.modelConfigDetails.pidsinfoId ? data.modelConfigDetails.pidsinfoId : 0,
			numOfClasses : data.modelConfigDetails ? data.modelConfigDetails.numOfClasses : null,
			eventTypes : data.modelConfigDetails ? data.modelConfigDetails.events : null,
			datasetType : data.modelConfigDetails ? data.modelConfigDetails.datasetType : null,
			interpolationRequired : data.modelConfigDetails ? data.modelConfigDetails.interpolationRequired : null,
			sampleLength : data.modelConfigDetails ? data.modelConfigDetails.sampleLength : null,
			modelDimension : data.modelConfigDetails ? data.modelConfigDetails.modelDimension : null,
			modelType : data.modelConfigDetails ? parseInt(data.modelConfigDetails.modeltypeId) : null,
			primaryTotalSampleCount : data.modelConfigDetails ? data.modelConfigDetails.primaryTotalSampleCount : null,
			secondaryTotalSampleCount : data.modelConfigDetails ? data.modelConfigDetails.secondaryTotalSampleCount : null,
			primaryMinSampleCount : data.modelConfigDetails ? data.modelConfigDetails.primaryMinSampleCount : null,
			secondaryMinSampleCount : data.modelConfigDetails ? data.modelConfigDetails.secondaryMinSampleCount : null,
			primarySampleWeightage : data.modelConfigDetails ? data.modelConfigDetails.primarySampleWeightage : null,
			secondarySampleWeightage : data.modelConfigDetails ? data.modelConfigDetails.secondarySampleWeightage : null,
			primaryLearningRate : data.modelConfigDetails ? data.modelConfigDetails.primaryLearningRate : null,
			secondaryLearningRate : data.modelConfigDetails ? data.modelConfigDetails.secondaryLearningRate : null,
			primaryTrainAccuracy : data.modelConfigDetails ? data.modelConfigDetails.primaryTrainAccuracy : null,
			secondaryTrainAccuracy : data.modelConfigDetails ? data.modelConfigDetails.secondaryTrainAccuracy : null,
			primaryTestAccuracy : data.modelConfigDetails ? data.modelConfigDetails.primaryTestAccuracy : null,
			secondaryTestAccuracy : data.modelConfigDetails ? data.modelConfigDetails.secondaryTestAccuracy : null,
			primaryTrainF1Score : data.modelConfigDetails ? data.modelConfigDetails.primaryTrainF1Score : null,
			secondaryTrainF1Score : data.modelConfigDetails ? data.modelConfigDetails.secondaryTrainF1Score : null,
			primaryTestF1Score : data.modelConfigDetails ? data.modelConfigDetails.primaryTestF1Score : null,
			secondaryTestF1Score : data.modelConfigDetails ? data.modelConfigDetails.secondaryTestF1Score : null,
			primaryEpochs : data.modelConfigDetails ? data.modelConfigDetails.primaryEpochs : null,
			secondaryEpochs : data.modelConfigDetails ? data.modelConfigDetails.secondaryEpochs : null,
			primaryBatchSize : data.modelConfigDetails ? data.modelConfigDetails.primaryBatchSize : null,
			secondaryBatchSize : data.modelConfigDetails ? data.modelConfigDetails.secondaryBatchSize : null,
			modelClassfications : data.modelConfigDetails ? parseInt(data.modelConfigDetails.modelclassificationsId) : null
		},

		onSubmit: (values) => {
		
			const configId = data.modelConfigDetails.id;

			const {pidsinfoId,numOfClasses,eventTypes,datasetType,interpolationRequired,sampleLength,
				modelType,primaryTotalSampleCount,secondaryTotalSampleCount,primaryMinSampleCount,
				secondaryMinSampleCount,primarySampleWeightage,secondarySampleWeightage,primaryLearningRate,
				secondaryLearningRate,primaryTrainAccuracy,secondaryTrainAccuracy,primaryTestAccuracy,
				secondaryTestAccuracy,primaryTrainF1Score,secondaryTrainF1Score, primaryTestF1Score, 
				secondaryTestF1Score, primaryEpochs, secondaryEpochs, primaryBatchSize, secondaryBatchSize, modelClassfications} = values;


			let datas = JSON.stringify({
				'pidsinfoId' : pidsinfoId,
				'numOfClasses' : numOfClasses,
				'eventTypes' : eventTypes,
				'datasetType' : datasetType,
				'interpolationRequired' : interpolationRequired,
				'sampleLength' : sampleLength,
				'modelDimension' : 'Single',
				'modeltypeId' : modelType,
				'primaryTotalSampleCount' : primaryTotalSampleCount,
				'secondaryTotalSampleCount' : secondaryTotalSampleCount,
				'primaryMinSampleCount' : primaryMinSampleCount,
				'secondaryMinSampleCount' : secondaryMinSampleCount,
				'primarySampleWeightage' : primarySampleWeightage,
				'secondarySampleWeightage' : secondarySampleWeightage,
				'primaryLearningRate' : primaryLearningRate,
				'secondaryLearningRate' : secondaryLearningRate,
				'primaryTrainAccuracy' : primaryTrainAccuracy,
				'secondaryTrainAccuracy' : secondaryTrainAccuracy,
				'primaryTestAccuracy' : primaryTestAccuracy,
				'secondaryTestAccuracy' : secondaryTestAccuracy,
				'primaryTrainF1Score' : primaryTrainF1Score,
				'secondaryTrainF1Score' : secondaryTrainF1Score,
				'primaryTestF1Score' : primaryTestF1Score,
				'secondaryTestF1Score' : secondaryTestF1Score,
				'primaryEpochs' : primaryEpochs,
				'secondaryEpochs' : secondaryEpochs,
				'primaryBatchSize' : primaryBatchSize,
				'secondaryBatchSize' : secondaryBatchSize,
				'modelclassificationsId':modelClassfications
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/modelconfiguration/${configId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : datas
			};

			axios.request(config)
				.then((response) => {
					if(response.status === 200 || response.status === 201){
						Modal.success({ title: `${langdata && langdata.Successfullyupdatedmodelconfiguration ? langdata.Successfullyupdatedmodelconfiguration :'Successfully updated model configuration.'}` });
						setShowEditModelConfig(false);
						setOpenPrimaryModal(false);
						setOpenSecondaryModal(false);
						modelData(1);
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});
		}
	});

	const deleteModel = () => {
		const id = data.modelConfigDetails.id;

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/modelconfiguration/${id}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then(() => {
				Modal.success({ title: `${langdata && langdata.Modelconfigurationdeletedsuccessfully ? langdata.Modelconfigurationdeletedsuccessfully :'Model configuration deleted successfully.'}` });
				modelData(1);
				setShowDeleteModel(false);
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
				setShowDeleteModel(false);
			});
	};

	useEffect(() => {
		if((addModelConfigFormik.values.pidsinfoId || addModelConfigFormik.values.pidsinfoId === 0) && addModelConfigFormik.values.modelType && addModelConfigFormik.values.modelClassfications) {
			const pidsId = addModelConfigFormik.values.pidsinfoId;
			const modelTypeId = addModelConfigFormik.values.modelType;
			const modelClassId = addModelConfigFormik.values.modelClassfications;
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/modelconfiguration?pidsinfoId=${pidsId}&modelclassificationsId=${modelClassId}&modeltypeId=${modelTypeId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
		
			axios.request(config)
				.then((response) => {
					if(response.data.total !== 0) {
						Modal.warning({ title: `${langdata && langdata.ModelconfigurationalreadyavailablefortheselectedPIDS ? langdata.ModelconfigurationalreadyavailablefortheselectedPIDS :'Model configuration already available for the selected PIDS.'}` });
						setShowSaveButton(true);
					}else {
						setShowSaveButton(false);
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});
		}

	},[addModelConfigFormik.values.pidsinfoId, addModelConfigFormik.values.modelType, addModelConfigFormik.values.modelClassfications]);


	const handlePrimaryModal = (record)=>{
		setOpenPrimaryModal(true);
		dispatch({type:RETRAIN_TYPE_DETAILS, payload:record});
	};

	const handleSecondaryModal = (record)=>{
		setOpenSecondaryModal(true);
		dispatch({type:RETRAIN_TYPE_DETAILS, payload:record});

	};
  
	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.Modelconfigcontent1 ? langdata.Modelconfigcontent1 :'Module helps to configure various retraining parameters related to retraining processes.'}</p>
		</Card>
	);

	const getModelType = () => {

		dispatch({ type : RETRAIN_MODEL_TYPE});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/modeltype`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : RETRAIN_MODEL_TYPE_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : RETRAIN_MODEL_TYPE_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : RETRAIN_MODEL_TYPE_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const getModelClassification = () => {

		dispatch({ type : MODEL_CLASSIFICATIONS});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/modelclassifications`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : MODEL_CLASSIFICATIONS_SUCCESS, payload : response.data});
				}else {
					dispatch({ type : MODEL_CLASSIFICATIONS_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : MODEL_CLASSIFICATIONS_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const addNewModel =()=>{
		setShowAddModelConfig(true);
		getModelType();
		getModelClassification();
	};

	const editModalOpen = ()=>{
		setShowEditModelConfig(true);
		getModelType();
		getModelClassification();
	};

	return (
		permission === 'superadmin' ?
			<>
				<Content>
					{/* <Row 
						style={{
							// marginLeft:'15px',
							// marginTop: '10px'
							margin : '10px'
						}}            
					>
						<Breadcrumb
							items={[
								{
									href: '/dashboard',
									title: <HomeOutlined />,
								},
								{
									title:  <a onClick={()=>{navigate(-1);}}>Model Info</a> 
								},
								{
									title: 'Model Configurations',
								},
							]}
						/>
					</Row> */}
					<Row 
						style={{
							margin: '10px',
							paddingLeft: '10px',
							paddingRight: '10px',
							background: '#FFFFFF',
							alignItems:'center'
						}} >

						<Col span={8}>
							<Row>

								<Space>
									<ToolOutlined style={{fontSize:'30px'}}/>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
					
								<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.ModelConfiguration ? langdata.ModelConfiguration :'Model Configuration'}</Title>
							</Row>
						</Col>

						<Col style={{ display : 'flex', justifyContent : 'right'}} span={16} >
							{permission==='superadmin' || permission==='admin' ?

								<Row>
									<Col>
										<Tooltip placement="bottom" title={langdata && langdata.Filterbypids ? langdata.Filterbypids:'Filter by pids'}>
											<Space>
												<Select Item
													showSearch
													filterOption={filterOption}
													style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
													placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
													optionFilterProp="children"
													defaultValue={'all'}
													options={pidsListFilter}
													onSelect={(e)=>setPidsId(e)}
												/>
											</Space>
										</Tooltip>
										<Space>&nbsp;&nbsp;</Space>
	
										<Tooltip placement="bottom" title={langdata && langdata.AddModelConfigurations ? langdata.AddModelConfigurations :'Add Model Configurations'}>

											<Space>

												<Button
													style={{ margin:'5px'}}
													type='primary'
													onClick={() => addNewModel()}
												><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew :'Add New'}</Button>
											</Space>
										</Tooltip>
									</Col>
								</Row>
								: null }
							<Space>&nbsp;&nbsp;</Space>
							<Space>
								<Typography>
									<Popover title={langdata && langdata.ModelConfiguration ? langdata.ModelConfiguration :'Model Configuration'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Col> 


					
					</Row>
				
					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						// marginTop:'0px',
						background: '#FFFFFF',
						alignItems:'center'
					}}>
						<Col span={24}>
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={data.modelConfigData} pagination = { data.modelConfigData.length > 0 ? model_data_pagination : 0}
								onChange = { modelData }
								onRow={(record) => {
									return {
										onClick: () => { 
											loadModelDetails(record.id);
										}
									};
								}} 
							/>
						</Col>
					</Row>
						
					
				</Content>

				<Modal
					title={langdata && langdata.AddModelConfigurations ? langdata.AddModelConfigurations :'Add Model Configurations'}
					centered
					destroyOnClose={true}
					maskClosable={false}
					open={showAddModelConfig}
					onOk={{closeAddModelConfig}}
					onCancel={closeAddModelConfig}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeAddModelConfig}>
              CANCEL
							</Button>
							<Button key="submit"  disabled={showSaveButton} type="primary" onClick={addModelConfigFormik.handleSubmit}>
              SAVE
							</Button>
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={addModelConfigFormik.handleSubmit}>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.PIDSName ? langdata.PIDSName:'PIDS Name'}  name="PIDS" rules={[{ required: true }]} >
									<Select
										name="pidsinfoId" 
										showSearch
										placeholder={langdata && langdata.SelectPIDSname ? langdata.SelectPIDSname:'Select PIDS name'}
										optionFilterProp="children"
										options={pidsList}
										onChange={(value) => addModelConfigFormik.setFieldValue('pidsinfoId', value)}
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item label={langdata && langdata.ModelType ? langdata.ModelType:'Model Type'} name="Model Type" rules={[{ required: true }]} >
									<Select
										name="modelType" 
										showSearch
										placeholder={langdata && langdata.Selectmodeltype ? langdata.Selectmodeltype:'Select model type'}
										optionFilterProp="children"
										options={modeltypelist}
										onChange={(value) => addModelConfigFormik.setFieldValue('modelType', value)}
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.ModelClasses ? langdata.ModelClasses:'Model Classes'} name="Event Types" rules={[{ required: true }]} >
									<Select
										name="eventTypes" 
										showSearch
										placeholder={langdata && langdata.Selecteventtypes ? langdata.Selecteventtypes:'Select event types'}
										optionFilterProp="children"
										options={eventsList}
										mode="multiple"
										onChange={(value) => addModelConfigFormik.setFieldValue('eventTypes', value)}
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.Interpolation ? langdata.Interpolation:'Interpolation'} name="Interpolation Required" rules={[{ required: true }]} >
									<Select
										name="interpolationRequired" 
										showSearch
										placeholder={langdata && langdata.Selectinterpolationrequired ? langdata.Selectinterpolationrequired:'Select interpolation required'}
										optionFilterProp="children"
										options={[
											{

												value : true,
												label : `${langdata && langdata.YES ? langdata.YES:'YES'}`
											},
											{
												value : false,
												label : `${langdata && langdata.NO ? langdata.NO:'NO'}`,
											}
										]}
										onChange={(value) => addModelConfigFormik.setFieldValue('interpolationRequired', value)}
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SampleLength ? langdata.SampleLength:'Sample Length'} name="Sample Length" rules={[{ required: true }]} >
									<Input 
										name="sampleLength" 
										placeholder={langdata && langdata.Pleasespecifythecellsamplelength ? langdata.Pleasespecifythecellsamplelength:'Please specify the cell sample length'} 
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.sampleLength}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SampleType ? langdata.SampleType:'Sample Type'} name="Dataset Type" rules={[{ required: true }]} >
									<Select
										name="datasetType" 
										showSearch
										placeholder={langdata && langdata.Selectdatasettype ? langdata.Selectdatasettype:'Select dataset type'}
										optionFilterProp="children"
										options={[
											{

												value : 'Balanced',
												label : `${langdata && langdata.Balanced ? langdata.Balanced:'Balanced'}`
											},
											{
												value : 'Unbalanced',
												label : `${langdata && langdata.Unbalanced ? langdata.Unbalanced:'Unbalanced'}`,
											}
										]}
										onChange={(value) => addModelConfigFormik.setFieldValue('datasetType', value)}
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							{/* <Col span={12}>
								<Form.Item label="Model Dimension" name="Model Dimension" rules={[{ required: true }]} >
									<Select
										name="modelDimension" 
										showSearch
										placeholder="Select model dimension"
										optionFilterProp="children"
										options={[
											{

												value : 'Single',
												label : 'Single'
											},
											{
												value : 'Multiple',
												label : 'Multiple',
											}
										]}
										onChange={(value) => addModelConfigFormik.setFieldValue('modelDimension', value)}
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col> */}
						</Row>
						<Row gutter={16}>
							{/* <Col span={12}>
								<Form.Item label="Interpolation" name="Interpolation Required" rules={[{ required: true }]} >
									<Select
										name="interpolationRequired" 
										showSearch
										placeholder="Select interpolation required"
										optionFilterProp="children"
										options={[
											{

												value : true,
												label : 'YES'
											},
											{
												value : false,
												label : 'NO',
											}
										]}
										onChange={(value) => addModelConfigFormik.setFieldValue('interpolationRequired', value)}
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col> */}
							<Col span={12}>
								<Form.Item label={langdata && langdata.NumberOfClasses ? langdata.NumberOfClasses:'Number Of Classes'} name="Number Of Classes" rules={[{ required: true }]} >
									<Input
										name="numOfClasses" 
										placeholder={langdata && langdata.Pleasespecifythenumberofclasses ? langdata.Pleasespecifythenumberofclasses:'Please specify the number of classes'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.numOfClasses}
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item label={langdata && langdata.ModelLevel ? langdata.ModelLevel:'Model Level'} name="Model Level" rules={[{ required: true }]} >
									<Select
										name="modelClassfications" 
										showSearch
										optionFilterProp="children"
										placeholder={langdata && langdata.Pleasespecifythemodelclassification ? langdata.Pleasespecifythemodelclassification:'Please specify the model classification'}
										onChange={(value) => addModelConfigFormik.setFieldValue('modelClassfications', value)}
										value={addModelConfigFormik.values.modelClassfications}
										filterOption={filterOption}
										options={modelClassficationslist}
									/>
								</Form.Item>
							</Col>
							
						</Row>

						<Typography style={{fontSize:'16px', color:'grey'}}>{langdata && langdata.Primary ? langdata.Primary:'Primary'}</Typography>

						<Divider/>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryNumberofEpochs ? langdata.PrimaryNumberofEpochs:'Primary Number of Epochs'} name="Primary Epochs" rules={[{ required: true }]} >
									<Input 
										name="primaryEpochs" 
										placeholder={langdata && langdata.Pleasespecifytheprimaryepochs ? langdata.Pleasespecifytheprimaryepochs:'Please specify the primary epochs'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryEpochs}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryBatchSize ? langdata.PrimaryBatchSize:'Primary Batch Size'} name="Primary Batch Size" rules={[{ required: true }]} >
									<Input 
										name="primaryBatchSize" 
										placeholder={langdata && langdata.Pleasespecifytheprimarybatchsize ? langdata.Pleasespecifytheprimarybatchsize:'Please specify the primary batch size'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryBatchSize}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryLearningRate ? langdata.PrimaryLearningRate:'Primary Learning Rate'} name="Primary Learning Rate" rules={[{ required: true }]} >
									<Input 
										name="primaryLearningRate" 
										placeholder={langdata && langdata.Pleasespecifytheprimarylearningrate ? langdata.Pleasespecifytheprimarylearningrate:'Please specify the primary learning rate'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryLearningRate}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimarySampleWeightage ? langdata.PrimarySampleWeightage:'Primary Sample Weightage'} name="Primary Sample Weightage" rules={[{ required: true }]} >
									<Input 
										name="primarySampleWeightage" 
										placeholder={langdata && langdata.Pleasespecifytheprimarysampleweightage ? langdata.Pleasespecifytheprimarysampleweightage:'Please specify the primary sample weightage'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primarySampleWeightage}
									/>
								</Form.Item>
							</Col>
							
						</Row>


						<Divider/>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryMinimumSampleCount ? langdata.PrimaryMinimumSampleCount:'Primary Minimum Sample Count'} name="Primary Total Sample Count" rules={[{ required: true }]} >
									<Input 
										name="primaryTotalSampleCount" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytotalsamplecount ? langdata.Pleasespecifytheprimarytotalsamplecount:'Please specify the primary total sample count'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryTotalSampleCount} 
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item  label={langdata && langdata.PrimaryMinimumSampleCountForEachClass ? langdata.PrimaryMinimumSampleCountForEachClass:'Primary Minimum Sample Count For Each Class'} name="Primary Min Sample Count" rules={[{ required: true }]} >
									<Input 
										name="primaryMinSampleCount"  
										placeholder={langdata && langdata.Pleaseconfirmtheprimaryminsamplecount ? langdata.Pleaseconfirmtheprimaryminsamplecount:'Please confirm the primary min sample count'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryMinSampleCount} 
									/>
								</Form.Item>
							</Col>

						</Row>
						
						<Row gutter={16}>

							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryTrainAccuracy ? langdata.PrimaryTrainAccuracy:'Primary Train Accuracy'} name="Primary Train Accuracy" rules={[{ required: true }]} >
									<Input 
										name="primaryTrainAccuracy" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytrainaccuracy ? langdata.Pleasespecifytheprimarytrainaccuracy:'Please specify the primary train accuracy'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryTrainAccuracy}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.PrimaryTestAccuracy ? langdata.PrimaryTestAccuracy:'Primary Test Accuracy'} name="Primary Test Accuracy" rules={[{ required: true }]} >
									<Input 
										name="primaryTestAccuracy" 
										placeholder={langdata && langdata.Pleaseconfirmtheprimarytestaccuracy ? langdata.Pleaseconfirmtheprimarytestaccuracy:'Please confirm the primary test accuracy'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryTestAccuracy}
									/>
								</Form.Item>
							</Col>
							

						</Row>

						<Row gutter={16}>

							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryTrainF1Score ? langdata.PrimaryTrainF1Score:'Primary Train F1 Score'} name="Primary Train F1 Score" rules={[{ required: true }]} >
									<Input 
										name="primaryTrainF1Score" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytrainf1score ? langdata.Pleasespecifytheprimarytrainf1score:'Please specify the primary train f1 score'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryTrainF1Score}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryTestF1Score ? langdata.PrimaryTestF1Score:'Primary Test F1 Score'} name="Primary Test F1 Score" rules={[{ required: true }]} >
									<Input 
										name="primaryTestF1Score" 
										placeholder={langdata && langdata.PleasespecifytheprimarytestF1score ? langdata.PleasespecifytheprimarytestF1score:'Please specify the primary test F1 score'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.primaryTestF1Score}
									/>
								</Form.Item>
							</Col>
						</Row>
						

						<Typography style={{fontSize:'16px', color:'grey'}}>{langdata && langdata.Secondary ? langdata.Secondary:'Secondary'}</Typography>
						<Divider/>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryNumberofEpochs ? langdata.SecondaryNumberofEpochs:'Secondary Number of Epochs'} name="Secondary Epochs" rules={[{ required: true }]} >
									<Input 
										name="secondaryEpochs" 
										placeholder={langdata && langdata.Pleasespecifythesecondaryepochs ? langdata.Pleasespecifythesecondaryepochs:'Please specify the secondary epochs'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryEpochs}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryBatchSize ? langdata.SecondaryBatchSize:'Secondary Batch Size'} name="Secondary Batch Size" rules={[{ required: true }]} >
									<Input 
										name="secondaryBatchSize" 
										placeholder={langdata && langdata.Pleasespecifythesecondarybatchsize ? langdata.Pleasespecifythesecondarybatchsize:'Please specify the secondary batch size'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryBatchSize}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryLearningRate ? langdata.SecondaryLearningRate:'Secondary Learning Rate'} name="Secondary Learning Rate" rules={[{ required: true }]} >
									<Input 
										name="secondaryLearningRate" 
										placeholder={langdata && langdata.Pleasespecifythesecondarylearningrate ? langdata.Pleasespecifythesecondarylearningrate:'Please specify the secondary learning rate'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryLearningRate}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondarySampleWeightage ? langdata.SecondarySampleWeightage:'Secondary Sample Weightage'} name="Secondary Sample Weightage" rules={[{ required: true }]} >
									<Input 
										name="secondarySampleWeightage" 
										placeholder={langdata && langdata.Pleasespecifythesecondarysampleweightage ? langdata.Pleasespecifythesecondarysampleweightage:'Please specify the secondary sample weightage'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondarySampleWeightage}
									/>
								</Form.Item>
							</Col>
							
						</Row>


						<Divider/>

						<Row gutter={16}>
						
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryMinimumSampleCount ? langdata.SecondaryMinimumSampleCount:'Secondary Minimum Sample Count'} name="Secondary Total Sample Count" rules={[{ required: true }]} >
									<Input 
										name="secondaryTotalSampleCount"  
										placeholder={langdata && langdata.Pleaseconfirmthesecondarytotalsamplecount ? langdata.Pleaseconfirmthesecondarytotalsamplecount:'Please confirm the secondary total sample count'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryTotalSampleCount} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryMinimumSampleCountForEachClass ? langdata.SecondaryMinimumSampleCountForEachClass:'Secondary Minimum Sample Count For Each Class'} name="Secondary Min Sample Count" rules={[{ required: true }]} >
									<Input 
										name="secondaryMinSampleCount" 
										placeholder={langdata && langdata.Pleasespecifythesecondaryminsamplecount ? langdata.Pleasespecifythesecondaryminsamplecount:'Please specify the secondary min sample count'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryMinSampleCount}
									/>
								</Form.Item>
							</Col>

						</Row>
						
						<Row gutter={16}>

							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTrainAccuracy ? langdata.SecondaryTrainAccuracy:'Secondary Train Accuracy'} name="Secondary Train Accuracy" rules={[{ required: true }]} >
									<Input 
										name="secondaryTrainAccuracy" 
										placeholder={langdata && langdata.Pleaseconfirmthesecondarytrainaccuracy ? langdata.Pleaseconfirmthesecondarytrainaccuracy:'Please confirm the secondary train accuracy'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryTrainAccuracy}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTestAccuracy ? langdata.SecondaryTestAccuracy:'Secondary Test Accuracy"='} name="Secondary Test Accuracy" rules={[{ required: true }]} >
									<Input 
										name="secondaryTestAccuracy" 
										placeholder={langdata && langdata.Pleasespecifythesecondarytestaccuracy ? langdata.Pleasespecifythesecondarytestaccuracy:'Please specify the secondary test accuracy'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryTestAccuracy}
									/>
								</Form.Item>
							</Col>
							

						</Row>

						<Row gutter={16}>

							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTrainF1Score ? langdata.SecondaryTrainF1Score:'Secondary Train F1 Score'} name="Secondary Train F1 Score" rules={[{ required: true }]} >
									<Input 
										name="secondaryTrainF1Score" 
										placeholder={langdata && langdata.PleasespecifythesecondarytrainF1score ? langdata.PleasespecifythesecondarytrainF1score:'Please specify the secondary train F1 score'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryTrainF1Score}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTestF1Score ? langdata.SecondaryTestF1Score:'Secondary Test F1 Score'} name="Secondary Test F1 Score" rules={[{ required: true }]} >
									<Input 
										name="secondaryTestF1Score" 
										placeholder={langdata && langdata.PleasespecifythesecondarytestF1score ? langdata.PleasespecifythesecondarytestF1score:'Please specify the secondary test F1 score'}
										onChange={addModelConfigFormik.handleChange} 
										value={addModelConfigFormik.values.secondaryTestF1Score}
									/>
								</Form.Item>
							</Col>
						</Row>

					</Form>
				</Modal>

				<Modal
					title={langdata && langdata.EditModelConfiguration ? langdata.EditModelConfiguration:'Edit Model Configuration'}
					centered
					open={showEditModelConfig}
					onOk={{closeEditModelConfig}}
					onCancel={closeEditModelConfig}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={closeEditModelConfig}>
								{langdata && langdata.EditModelConfiguration ? langdata.EditModelConfiguration:'CANCEL'}
							</Button>
							<Button key="submit"  type="primary" onClick={editModelConfigFormik.handleSubmit}>
								{langdata && langdata.UPDATE ? langdata.UPDATE:'UPDATE'}
							</Button>
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={editModelConfigFormik.handleSubmit}>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.PIDSName ? langdata.PIDSName:'PIDS Name'} required>
									<Select
										name="pidsinfoId" 
										showSearch
										placeholder={langdata && langdata.SelectPIDSname ? langdata.SelectPIDSname:'Select PIDS name'}
										optionFilterProp="children"
										options={pidsList}
										onChange={(value) => editModelConfigFormik.setFieldValue('pidsinfoId', value)}
										filterOption={filterOption}
										value={editModelConfigFormik.values.pidsinfoId}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.ModelType ? langdata.ModelType:'Model Type'}  required>
									<Select
										name="modelType" 
										showSearch
										placeholder={langdata && langdata.Selectmodeltype ? langdata.Selectmodeltype:'Select model type'}
										optionFilterProp="children"
										options={modeltypelist}
										onChange={(value) => editModelConfigFormik.setFieldValue('modelType', value)}
										filterOption={filterOption}
										value={editModelConfigFormik.values.modelType}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.ModelClasses ? langdata.ModelClasses:'Model Classes'} required>
									<Select
										name="eventTypes" 
										showSearch
										placeholder={langdata && langdata.Selecteventtypes ? langdata.Selecteventtypes:'Select event types'}
										optionFilterProp="children"
										options={eventsList}
										mode="multiple"
										onChange={(value) => editModelConfigFormik.setFieldValue('eventTypes', value)}
										filterOption={filterOption}
										value={editModelConfigFormik.values.eventTypes}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.Interpolation ? langdata.Interpolation:'Interpolation'}  required>
									<Select
										name="interpolationRequired" 
										showSearch
										placeholder={langdata && langdata.Selectinterpolationrequired ? langdata.Selectinterpolationrequired:'Select interpolation required'}
										optionFilterProp="children"
										options={[
											{

												value : true,
												label : `${langdata && langdata.YES ? langdata.YES:'YES'}`
											},
											{
												value : false,
												label : `${langdata && langdata.NO ? langdata.NO:'NO'}`,
											}
										]}
										onChange={(value) => editModelConfigFormik.setFieldValue('interpolationRequired', value)}
										filterOption={filterOption}
										value={editModelConfigFormik.values.interpolationRequired}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SampleLength ? langdata.SampleLength:'Sample Length'} required>
									<Input 
										name="sampleLength" 
										placeholder={langdata && langdata.Pleasespecifythecellsamplelength ? langdata.Pleasespecifythecellsamplelength:'Please specify the cell sample length'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.sampleLength}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SampleType ? langdata.SampleType:'Sample Type'} required>
									<Select
										name="datasetType" 
										showSearch
										placeholder={langdata && langdata.Selectdatasettype ? langdata.Selectdatasettype:'Select dataset type'}
										optionFilterProp="children"
										options={[
											{

												value : 'Balanced',
												label : `${langdata && langdata.Balanced ? langdata.Balanced:'Balanced'}`
											},
											{
												value : 'Unbalanced',
												label : `${langdata && langdata.Unbalanced ? langdata.Unbalanced:'Unbalanced'}`,
											}
										]}
										onChange={(value) => editModelConfigFormik.setFieldValue('datasetType', value)}
										filterOption={filterOption}
										value={editModelConfigFormik.values.datasetType}
									/>
								</Form.Item>
							</Col>
							{/* <Col span={12}>
								<Form.Item label="Model Dimension" >
									<Select
										name="modelDimension" 
										showSearch
										placeholder="Select model dimension"
										optionFilterProp="children"
										options={[
											{

												value : 'Single',
												label : 'Single'
											},
											{
												value : 'Multiple',
												label : 'Multiple',
											}
										]}
										onChange={(value) => editModelConfigFormik.setFieldValue('modelDimension', value)}
										filterOption={filterOption}
										value={editModelConfigFormik.values.modelDimension}
									/>
								</Form.Item>
							</Col> */}
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.NumberOfClasses ? langdata.NumberOfClasses:'Number Of Classes'} required>
									<Input
										name="numOfClasses" 
										placeholder={langdata && langdata.Pleasespecifythenumberofclasses ? langdata.Pleasespecifythenumberofclasses:'Please specify the number of classes'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.numOfClasses}
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item label={langdata && langdata.ModelLevel ? langdata.ModelLevel:'Model Level'} required >
									<Select
										name="modelClassfications" 
										showSearch
										optionFilterProp="children"
										placeholder={langdata && langdata.Pleasespecifythemodelclassification ? langdata.Pleasespecifythemodelclassification:'Please specify the model classification'}
										onChange={(value) => editModelConfigFormik.setFieldValue('modelClassfications', value)}
										value={editModelConfigFormik.values.modelClassfications}
										filterOption={filterOption}
										options={modelClassficationslist}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<br/>
						<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.Primary ? langdata.Primary:'Primary'}</b></Typography>
						<Divider/>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryNumberofEpochs ? langdata.PrimaryNumberofEpochs:'Primary Number of Epochs'} required>
									<Input 
										name="primaryEpochs" 
										placeholder={langdata && langdata.Pleasespecifytheprimaryepochs ? langdata.Pleasespecifytheprimaryepochs:'Please specify the primary epochs'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryEpochs}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryBatchSize ? langdata.PrimaryBatchSize:'Primary Batch Size'} required>
									<Input 
										name="primaryBatchSize" 
										placeholder={langdata && langdata.Pleasespecifytheprimarybatchsize ? langdata.Pleasespecifytheprimarybatchsize:'Please specify the primary batch size'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryBatchSize}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryLearningRate ? langdata.PrimaryLearningRate:'Primary Learning Rate'} required>
									<Input 
										name="primaryLearningRate" 
										placeholder={langdata && langdata.Pleasespecifytheprimarylearningrate ? langdata.Pleasespecifytheprimarylearningrate:'Please specify the primary learning rate'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryLearningRate}
									/>
								</Form.Item>
							</Col>
							
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimarySampleWeightage ? langdata.PrimarySampleWeightage:'Primary Sample Weightage'} required>
									<Input 
										name="primarySampleWeightage" 
										placeholder={langdata && langdata.Pleasespecifytheprimarysampleweightage ? langdata.Pleasespecifytheprimarysampleweightage:'Please specify the primary sample weightage'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primarySampleWeightage}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider/>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryMinimumSampleCount ? langdata.PrimaryMinimumSampleCount:'Primary Minimum Sample Count'} required>
									<Input 
										name="primaryTotalSampleCount" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytotalsamplecount ? langdata.Pleasespecifytheprimarytotalsamplecount:'Please specify the primary total sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTotalSampleCount} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.PrimaryMinimumSampleCountForEachClass ? langdata.PrimaryMinimumSampleCountForEachClass:'Primary Minimum Sample Count For Each Class'} required>
									<Input 
										name="primaryMinSampleCount"  
										placeholder={langdata && langdata.Pleaseconfirmtheprimaryminsamplecount ? langdata.Pleaseconfirmtheprimaryminsamplecount:'Please confirm the primary min sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryMinSampleCount} 
									/>
								</Form.Item>
							</Col>
							
						</Row>
						

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryTrainAccuracy ? langdata.PrimaryTrainAccuracy:'Primary Train Accuracy'} required>
									<Input 
										name="primaryTrainAccuracy" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytrainaccuracy ? langdata.Pleasespecifytheprimarytrainaccuracy:'Please specify the primary train accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTrainAccuracy}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.PrimaryTestAccuracy ? langdata.PrimaryTestAccuracy:'Primary Test Accuracy'} required>
									<Input 
										name="primaryTestAccuracy" 
										placeholder={langdata && langdata.Pleaseconfirmtheprimarytestaccuracy ? langdata.Pleaseconfirmtheprimarytestaccuracy:'Please confirm the primary test accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTestAccuracy}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryTrainF1Score ? langdata.PrimaryTrainF1Score:'Primary Train F1 Score'}  required>
									<Input 
										name="primaryTrainF1Score" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytrainf1score ? langdata.Pleasespecifytheprimarytrainf1score:'Please specify the primary train f1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTrainF1Score}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.PrimaryTestF1Score ? langdata.PrimaryTestF1Score:'Primary Test F1 Score'} required>
									<Input 
										name="primaryTestF1Score" 
										placeholder={langdata && langdata.PleasespecifytheprimarytestF1score ? langdata.PleasespecifytheprimarytestF1score:'Please specify the primary test F1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTestF1Score}
									/>
								</Form.Item>
							</Col>
							
						</Row>

						<br/>
						<Typography style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.Secondary ? langdata.Secondary:'Secondary'}</b></Typography>
						<Divider />

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryNumberofEpochs ? langdata.SecondaryNumberofEpochs:'Secondary Number of Epochs'} required>
									<Input 
										name="secondaryEpochs" 
										placeholder={langdata && langdata.Pleasespecifythesecondaryepochs ? langdata.Pleasespecifythesecondaryepochs:'Please specify the secondary epochs'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryEpochs}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryBatchSize ? langdata.SecondaryBatchSize:'Secondary Batch Size'} required>
									<Input 
										name="secondaryBatchSize" 
										placeholder={langdata && langdata.Pleasespecifythesecondarybatchsize ? langdata.Pleasespecifythesecondarybatchsize:'Please specify the secondary batch size'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryBatchSize}
									/>
								</Form.Item>
							</Col>
							
						</Row>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryLearningRate ? langdata.SecondaryLearningRate:'Secondary Learning Rate'} required>
									<Input 
										name="secondaryLearningRate" 
										placeholder={langdata && langdata.Pleasespecifythesecondarylearningrate ? langdata.Pleasespecifythesecondarylearningrate:'Please specify the secondary learning rate'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryLearningRate}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondarySampleWeightage ? langdata.SecondarySampleWeightage:'Secondary Sample Weightage'} required>
									<Input 
										name="secondarySampleWeightage" 
										placeholder={langdata && langdata.Pleasespecifythesecondarysampleweightage ? langdata.Pleasespecifythesecondarysampleweightage:'Please specify the secondary sample weightage'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondarySampleWeightage}
									/>
								</Form.Item>
							</Col>
							
							
						</Row>

						<Divider />
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryMinimumSampleCount ? langdata.SecondaryMinimumSampleCount:'Secondary Minimum Sample Count'} required>
									<Input 
										name="secondaryTotalSampleCount"  
										placeholder={langdata && langdata.Pleaseconfirmthesecondarytotalsamplecount ? langdata.Pleaseconfirmthesecondarytotalsamplecount:'Please confirm the secondary total sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTotalSampleCount} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryMinimumSampleCountForEachClass ? langdata.SecondaryMinimumSampleCountForEachClass:'Secondary Minimum Sample Count For Each Class'}  required>
									<Input 
										name="secondaryMinSampleCount" 
										placeholder={langdata && langdata.Pleasespecifythesecondaryminsamplecount ? langdata.Pleasespecifythesecondaryminsamplecount:'Please specify the secondary min sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryMinSampleCount}
									/>
								</Form.Item>
							</Col>

						</Row>
						
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTrainAccuracy ? langdata.SecondaryTrainAccuracy:'Secondary Train Accuracy'} required>
									<Input 
										name="secondaryTrainAccuracy" 
										placeholder={langdata && langdata.Pleaseconfirmthesecondarytrainaccuracy ? langdata.Pleaseconfirmthesecondarytrainaccuracy:'Please confirm the secondary train accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTrainAccuracy}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTestAccuracy ? langdata.SecondaryTestAccuracy:'Secondary Test Accuracy"='}  required>
									<Input 
										name="secondaryTestAccuracy" 
										placeholder={langdata && langdata.Pleasespecifythesecondarytestaccuracy ? langdata.Pleasespecifythesecondarytestaccuracy:'Please specify the secondary test accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTestAccuracy}
									/>
								</Form.Item>
							</Col>
							
							
						</Row>
						
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTrainF1Score ? langdata.SecondaryTrainF1Score:'Secondary Train F1 Score'} required>
									<Input 
										name="secondaryTrainF1Score" 
										placeholder={langdata && langdata.PleasespecifythesecondarytrainF1score ? langdata.PleasespecifythesecondarytrainF1score:'Please specify the secondary train F1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTrainF1Score}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SecondaryTestF1Score ? langdata.SecondaryTestF1Score:'Secondary Test F1 Score'} required>
									<Input 
										name="secondaryTestF1Score" 
										placeholder={langdata && langdata.PleasespecifythesecondarytestF1score ? langdata.PleasespecifythesecondarytestF1score:'Please specify the secondary test F1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTestF1Score}
									/>
								</Form.Item>
							</Col>
							
							
						</Row>
						
						
						
					</Form>
				</Modal>
			
				<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete:'Confirm delete'} open={showDeleteModel} onOk={deleteModel} onCancel={closeDeleteModel}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={()=>closeDeleteModel()}>
								{langdata && langdata.CANCEL ? langdata.CANCEL:'CANCEL'}
							</Button>
							<Button type = 'primary' onClick={()=>deleteModel()}>
								{langdata && langdata.OK ? langdata.OK:'OK'}
							</Button>
						</Space>
					]}
				
				>
					<Row style={{
						marginTop: '10px',
						marginBottom: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={1}>
							<DeleteOutlined  style={{ fontSize: '14px'}}/>
						</Col>
						<Col span={23}>
							{langdata && langdata.Areyousureyouwanttodeletethismodelconfiguration ? langdata.Areyousureyouwanttodeletethismodelconfiguration:'Are you sure you want to delete this model configuration?'}
						</Col>
					</Row>
				</Modal>

				{/* Primary modal start */}
				<Modal title={langdata && langdata.PrimaryModelConfiguration ? langdata.PrimaryModelConfiguration:'Primary Model Configuration'} open={openPrimaryModal} onOk={()=>{setOpenPrimaryModal(false);}} onCancel={()=>{setOpenPrimaryModal(false);}}
				
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={()=>{setOpenPrimaryModal(false);}}>
								{langdata && langdata.CANCEL ? langdata.CANCEL:'CANCEL'}
							</Button>
							<Button type = 'primary' onClick={editModelConfigFormik.handleSubmit}>
								{langdata && langdata.SAVE ? langdata.SAVE:'SAVE'}
							</Button>
						</Space>
					]}
					width={750}
				>
					{/* <Col span={24}>
						<Typography style={{fontSize:'15px'}}>No: Epochs: {data && data.retraintype_details ? data.retraintype_details.primaryEpochs : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Batch Size: {data && data.retraintype_details ? data.retraintype_details.primaryBatchSize : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Learning Rate: {data && data.retraintype_details ? data.retraintype_details.primaryLearningRate : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Sample Weight: {data && data.retraintype_details ? data.retraintype_details.primarySampleWeightage : 0}</Typography>
						<Divider/>
						<Typography style={{fontSize:'15px'}}>Primary Minimum Sample Count: {data && data.retraintype_details ? data.retraintype_details.primaryTotalSampleCount : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Minimum Sample Count For Each Class: {data && data.retraintype_details ? data.retraintype_details.primaryMinSampleCount : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Train Accuracy: {data && data.retraintype_details ? data.retraintype_details.primaryTrainAccuracy : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Test Accuracy: {data && data.retraintype_details ? data.retraintype_details.primaryTestAccuracy : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Train F1 Score: {data && data.retraintype_details ? data.retraintype_details.primaryTrainF1Score : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Primary Test F1 Score: {data && data.retraintype_details ? data.retraintype_details.primaryTestF1Score : 0}</Typography>

					</Col> */}
					<Form layout="vertical" onSubmit={editModelConfigFormik.handleSubmit}>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.NumberofEpochs ? langdata.NumberofEpochs:'Number of Epochs'} required>
									<Input 
										name="primaryEpochs" 
										placeholder={langdata && langdata.Pleasespecifytheprimaryepochs ? langdata.Pleasespecifytheprimaryepochs:'Please specify the primary epochs'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryEpochs}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.BatchSize ? langdata.BatchSize:'Batch Size'} required>
									<Input 
										name="primaryBatchSize" 
										placeholder={langdata && langdata.Pleasespecifytheprimarybatchsize ? langdata.Pleasespecifytheprimarybatchsize:'Please specify the primary batch size'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryBatchSize}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.LearningRate ? langdata.LearningRate:'Learning Rate'} required>
									<Input 
										name="primaryLearningRate" 
										placeholder={langdata && langdata.Pleasespecifytheprimarylearningrate ? langdata.Pleasespecifytheprimarylearningrate:'Please specify the primary learning rate'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryLearningRate}
									/>
								</Form.Item>
							</Col>
							
							<Col span={12}>
								<Form.Item label={langdata && langdata.SampleWeightage ? langdata.SampleWeightage:'Sample Weightage'} required>
									<Input 
										name="primarySampleWeightage" 
										placeholder={langdata && langdata.Pleasespecifytheprimarysampleweightage ? langdata.Pleasespecifytheprimarysampleweightage:'Please specify the primary sample weightage'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primarySampleWeightage}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider/>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.MinimumSampleCount ? langdata.MinimumSampleCount:'Minimum Sample Count'} required>
									<Input 
										name="primaryTotalSampleCount" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytotalsamplecount ? langdata.Pleasespecifytheprimarytotalsamplecount:'Please specify the primary total sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTotalSampleCount} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.MinimumSampleCountForEachClass ? langdata.MinimumSampleCountForEachClass:'Minimum Sample Count For Each Class'} required >
									<Input 
										name="primaryMinSampleCount"  
										placeholder={langdata && langdata.Pleaseconfirmtheprimaryminsamplecount ? langdata.Pleaseconfirmtheprimaryminsamplecount:'Please confirm the primary min sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryMinSampleCount} 
									/>
								</Form.Item>
							</Col>
							
						</Row>
						

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.TrainAccuracy ? langdata.TrainAccuracy:'Train Accuracy'} required>
									<Input 
										name="primaryTrainAccuracy" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytrainaccuracy ? langdata.Pleasespecifytheprimarytrainaccuracy:'Please specify the primary train accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTrainAccuracy}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item  label={langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'} required>
									<Input 
										name="primaryTestAccuracy" 
										placeholder={langdata && langdata.PrimaryTestAccuracy ? langdata.PrimaryTestAccuracy:'Primary Test Accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTestAccuracy}
									/>
								</Form.Item>
							</Col>
							
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.TrainF1Score ? langdata.TrainF1Score:'Train F1 Score'}  required>
									<Input 
										name="primaryTrainF1Score" 
										placeholder={langdata && langdata.Pleasespecifytheprimarytrainf1score ? langdata.Pleasespecifytheprimarytrainf1score:'Please specify the primary train f1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTrainF1Score}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.TestF1Score ? langdata.TestF1Score:'Test F1 Score'} required>
									<Input 
										name="primaryTestF1Score" 
										placeholder={langdata && langdata.PleasespecifytheprimarytestF1score ? langdata.PleasespecifytheprimarytestF1score:'Please specify the primary test F1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.primaryTestF1Score}
									/>
								</Form.Item>
							</Col>
							
						</Row>	
					
					</Form>
				</Modal>
				{/* primary modal ends */}


				{/* Secondary modal start */}
				<Modal title={langdata && langdata.SecondaryModelConfiguration ? langdata.SecondaryModelConfiguration:'Secondary Model Configuration'} open={openSecondaryModal} onOk={()=>{setOpenSecondaryModal(false);}} onCancel={()=>{setOpenSecondaryModal(false);}}
				
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={()=>{setOpenSecondaryModal(false);}}>
								{langdata && langdata.CANCEL ? langdata.CANCEL:'CANCEL'}
							</Button>
							<Button type = 'primary' onClick={editModelConfigFormik.handleSubmit}>
								{langdata && langdata.SAVE ? langdata.SAVE:'SAVE'}
							</Button>
						</Space>
					]}
					width={750}
				>
					
					{/* <Col span={24}>
						<Typography style={{fontSize:'15px'}}>No: Epochs: {data && data.retraintype_details ? data.retraintype_details.secondaryEpochs : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Batch Size: {data && data.retraintype_details ? data.retraintype_details.secondaryBatchSize : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Learning Rate: {data && data.retraintype_details ? data.retraintype_details.secondaryLearningRate : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Sample Weight: {data && data.retraintype_details ? data.retraintype_details.secondarySampleWeightage : 0}</Typography>
						<Divider/>
						<Typography style={{fontSize:'15px'}}>Secondary Minimum Sample Count: {data && data.retraintype_details ? data.retraintype_details.secondaryTotalSampleCount : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Minimum Sample Count For Each Class: {data && data.retraintype_details ? data.retraintype_details.secondaryMinSampleCount : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Train Accuracy: {data && data.retraintype_details ? data.retraintype_details.secondaryTrainAccuracy : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Test Accuracy: {data && data.retraintype_details ? data.retraintype_details.secondaryTestAccuracy : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Train F1 Score: {data && data.retraintype_details ? data.retraintype_details.secondaryTrainF1Score : 0}</Typography>
						<Typography style={{fontSize:'15px'}}>Secondary Test F1 Score: {data && data.retraintype_details ? data.retraintype_details.secondaryTestF1Score : 0}</Typography>

					</Col> */}
					<Form layout="vertical" onSubmit={editModelConfigFormik.handleSubmit}>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.NumberofEpochs ? langdata.NumberofEpochs:'Number of Epochs'} required>
									<Input 
										name="secondaryEpochs" 
										placeholder={langdata && langdata.Pleasespecifythesecondaryepochs ? langdata.Pleasespecifythesecondaryepochs:'Please specify the secondary epochs'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryEpochs}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.BatchSize ? langdata.BatchSize:'Batch Size'} required>
									<Input 
										name="secondaryBatchSize" 
										placeholder={langdata && langdata.Pleasespecifythesecondarybatchsize ? langdata.Pleasespecifythesecondarybatchsize:'Please specify the secondary batch size'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryBatchSize}
									/>
								</Form.Item>
							</Col>
						
						</Row>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.LearningRate ? langdata.LearningRate:'Learning Rate'} required>
									<Input 
										name="secondaryLearningRate" 
										placeholder={langdata && langdata.Pleasespecifythesecondarylearningrate ? langdata.Pleasespecifythesecondarylearningrate:'Please specify the secondary learning rate'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryLearningRate}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.SampleWeightage ? langdata.SampleWeightage:'Sample Weightage'} required>
									<Input 
										name="secondarySampleWeightage" 
										placeholder={langdata && langdata.Pleasespecifythesecondarysampleweightage ? langdata.Pleasespecifythesecondarysampleweightage:'Please specify the secondary sample weightage'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondarySampleWeightage}
									/>
								</Form.Item>
							</Col>
						
						
						</Row>

						<Divider />
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.MinimumSampleCount ? langdata.MinimumSampleCount:'Minimum Sample Count'} required>
									<Input 
										name="secondaryTotalSampleCount"  
										placeholder={langdata && langdata.Pleaseconfirmthesecondarytotalsamplecount ? langdata.Pleaseconfirmthesecondarytotalsamplecount:'Please confirm the secondary total sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTotalSampleCount} 
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.MinimumSampleCountForEachClass ? langdata.MinimumSampleCountForEachClass:'Minimum Sample Count For Each Class'} required>
									<Input 
										name="secondaryMinSampleCount" 
										placeholder={langdata && langdata.Pleasespecifythesecondaryminsamplecount ? langdata.Pleasespecifythesecondaryminsamplecount:'Please specify the secondary min sample count'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryMinSampleCount}
									/>
								</Form.Item>
							</Col>

						</Row>
					
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.TrainAccuracy ? langdata.TrainAccuracy:'Train Accuracy'} required>
									<Input 
										name="secondaryTrainAccuracy" 
										placeholder={langdata && langdata.Pleaseconfirmthesecondarytrainaccuracy ? langdata.Pleaseconfirmthesecondarytrainaccuracy:'Please confirm the secondary train accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTrainAccuracy}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.TestAccuracy ? langdata.TestAccuracy:'Test Accuracy'} required>
									<Input 
										name="secondaryTestAccuracy" 
										placeholder={langdata && langdata.Pleasespecifythesecondarytestaccuracy ? langdata.Pleasespecifythesecondarytestaccuracy:'Please specify the secondary test accuracy'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTestAccuracy}
									/>
								</Form.Item>
							</Col>
						
						
						</Row>
					
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label={langdata && langdata.TrainF1Score ? langdata.TrainF1Score:'Train F1 Score'}  required>
									<Input 
										name="secondaryTrainF1Score" 
										placeholder={langdata && langdata.PleasespecifythesecondarytrainF1score ? langdata.PleasespecifythesecondarytrainF1score:'Please specify the secondary train F1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTrainF1Score}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label={langdata && langdata.TestF1Score ? langdata.TestF1Score:'Test F1 Score'} required>
									<Input 
										name="secondaryTestF1Score" 
										placeholder={langdata && langdata.PleasespecifythesecondarytestF1score ? langdata.PleasespecifythesecondarytestF1score:'Please specify the secondary test F1 score'}
										onChange={editModelConfigFormik.handleChange} 
										value={editModelConfigFormik.values.secondaryTestF1Score}
									/>
								</Form.Item>
							</Col>
						
						
						</Row>
					</Form>
				</Modal>
				{/* Secondary modal ends */}

				<Spin spinning={data.loading} fullscreen />
			</> : null
	);
}