export const SETTINGS_LIST = 'SETTINGS_LIST';
export const SETTINGS_LIST_SUCCESS = 'SETTINGS_LIST_SUCCESS';
export const SETTINGS_LIST_ERROR = 'SETTINGS_LIST_ERROR';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR';

export const GET_TLP_HARDWARE_SETTINGS = 'GET_TLP_HARDWARE_SETTINGS';
export const GET_TLP_HARDWARE_SETTINGS_SUCCESS = 'GET_TLP_HARDWARE_SETTINGS_SUCCESS';
export const GET_TLP_HARDWARE_SETTINGS_ERROR = 'GET_TLP_HARDWARE_SETTINGS_ERROR';

export const UPDATE_TLP_HARDWARE_SETTINGS = 'UPDATE_TLP_HARDWARE_SETTINGS';
export const UPDATE_TLP_HARDWARE_SETTINGS_SUCCESS = 'UPDATE_TLP_HARDWARE_SETTINGS_SUCCESS';
export const UPDATE_TLP_HARDWARE_SETTINGS_ERROR = 'UPDATE_TLP_HARDWARE_SETTINGS_ERROR';