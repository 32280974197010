import React, { useEffect, useReducer, useState } from 'react';

import { Empty, Row, Col, Select, Typography, Button, Space, Table, Form, Input, Modal, DatePicker, Tag, Tooltip, message, Spin, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context';
import { GRADE_LIST, GRADE_LIST_ERROR, GRADE_LIST_SUCCESS, GRADE_UPDATE, GRADE_UPDATE_DETAILS, GRADE_UPDATE_ERROR, GRADE_UPDATE_SUCCESS } from './constants';
import axios from 'axios';
import gradedata from './reducer';
import { useFormik } from 'formik';
import {BatchDownloader} from './downloader';
import { DownloadOutlined, FileSearchOutlined, InfoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

let grade_data_pagination = {};
const dateFormat = 'YYYY-MM-DD';

export default function GradeSystem() {
	const [openEditDrawer, setOpenEditDrawer] = useState(false);
	const [getgrade, setGrade] = useState({});
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const navigate = useNavigate();
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const initalState = {grade_data:[], grade_update:[], details: null, pending: false, error: false };
	const [grade_data, dispatch] = useReducer(gradedata, initalState);
	const [date, setDate] = useState(new Date().toLocaleDateString('en-UK', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}));
	const [regionId, setRegionId] = useState(null);
	// const regionparams = `&regioninfoId=${regionId}`;
	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);
	const [url, setURL] = useState(null);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [allocationId, setAllocationId] = useState(null);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const options = [
		{
			value: 1,
			label: `${langdata && langdata.GREEN ? langdata.GREEN:'GREEN'}`,
		},
		{
			value: 0,
			label: `${langdata && langdata.RED ? langdata.RED:'RED'}`,
		},
	];

	let Gradedata = [];

	grade_data.grade_data && grade_data.grade_data.map((item) => {
		Gradedata.push({
			key: item.id,
			id: item.id,
			name: item.userName,
			totaldistance:item.totalDistance ? item.totalDistance.toFixed(2):0 ,
			covereddistance:item.coveredDistance ? item.coveredDistance.toFixed(2) : 0,
			avgvelocity: parseFloat(item.avgVelocity).toFixed(2),
			coverage: parseFloat(item.coverage).toFixed(2)+ ' ' +'%',
			idletime: item.idleTime.toFixed(2),
			speedviolation: item.speedViolation,
			systemgrade: item.systemGrade,
			finalgrade:item.finalGrade,
			remarks:item.remarks
		});
	});

	const columns = [
		{
			title: `${langdata && langdata.Name ? langdata.Name:'Name'}`,
			dataIndex: 'name',
			key: 'name',
			//   render: (_,text) => <a onClick={showEditDrawer}>{text}</a>)

			render: (_, text) => (
				<Space size="middle">
					<a title={langdata && langdata.EditGrade ? langdata.EditGrade:'Edit Grade'} onClick={()=>{showEditDrawer(text);}}>{text.name}</a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)

		},
		{
			title: `${langdata && langdata.TotalDistance ? langdata.TotalDistance:'Total Distance (km)'}`,
			dataIndex: 'totaldistance',
			key: 'totaldistance',
		},
		{
			title: `${langdata && langdata.CoveredDistance ? langdata.CoveredDistance:'Covered Distance (km)'}`,
			dataIndex: 'covereddistance',
			key: 'covereddistance',
		},
		{
			title: `${langdata && langdata.AvgVelocity ? langdata.AvgVelocity:'Avg Velocity (km/h)'}`,
			dataIndex: 'avgvelocity',
			key: 'avgvelocity',
		},
		{
			title: `${langdata && langdata.Coverage ? langdata.Coverage:'Coverage'}`,
			dataIndex: 'coverage',
			key: 'coverage',
		},
		{
			title: `${langdata && langdata.IdleTime ? langdata.IdleTime:'Idle Time (min)'}`,
			dataIndex: 'idletime',
			key: 'idletime',
		},
		{
			title: `${langdata && langdata.SpeedViolation ? langdata.SpeedViolation:'Speed Violation'}`,
			dataIndex: 'speedviolation',
			key: 'speedviolation',
		},
		{
			title: `${langdata && langdata.SystemGrade ? langdata.SystemGrade:'System Grade'}`,
			dataIndex: 'systemgrade',
			key: 'systemgrade',
			render: (_, { systemgrade }) => (
				<>
					<Tag color={systemgrade === 1 ?'green' :'red'} key={systemgrade}>
						{systemgrade === 1 ?'GREEN':'RED'}
					</Tag>
				</>
			),
		},
		{
			title: `${langdata && langdata.FinalGrade ? langdata.FinalGrade:'Final Grade'}`,
			dataIndex: 'finalgrade',
			key: 'finalgrade',
			render: (_, { finalgrade }) => (
				<>
					<Tag color={finalgrade === 1 ?'green' :'red'} key={finalgrade}>
						{finalgrade === 1 ?'GREEN':'RED'}
					</Tag>
				</>
			),
		},
      
		{
			title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
			key: 'action',
			align:'center',
			render: (_, record) => (
				<Space style={{justifyContent:'center',display:'flex'}} size="middle">
					<a title={langdata && langdata.EditGrade ? langdata.EditGrade:'Edit Grade'} onClick={()=>{showEditDrawer(record);}}><EditOutlined/></a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		}
	];

	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}


	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 65);
	}, []);
	
	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
			if(admin_roles.includes(permission)){
				getRegion(authtoken);
			}
			else{
				getUserAllocation(authtoken);
			}
			
			getGrade(1);
		}

	},[authtoken,regionId, date, allocationId]);


	const getGrade =(pagination)=>{

		let _pagination = { };
		_pagination.total = grade_data_pagination.total ? grade_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		let allocationParams = '';
		if(admin_roles.includes(permission)){
			allocationParams = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			allocationParams = allocationId ? `&allocationId=${allocationId}` : '';
		}

		dispatch({ type : GRADE_LIST});
		setURL({base: `${api}/gradesystem-list`,params:`date=${date.split('/').reverse().join('-')}${allocationParams}`});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/gradesystem-list?date=${date.split('/').reverse().join('-')}${allocationParams}&$limit=${_pagination.limit}&$skip=${_pagination.skip}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					grade_data_pagination.total = response.data.total ? response.data.total : 0;
					grade_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					grade_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					grade_data_pagination.current = pagination.current ? pagination.current : 1;
					dispatch({ type : GRADE_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : GRADE_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : GRADE_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};


	const formik = useFormik({
		initialValues: {
			name:  '',
			remarks: '',
			grade:'',
		},
		onSubmit: (values) => {
			const {name} = values;
			console.log(name); 

			dispatch({type:GRADE_UPDATE});
			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/gradesystem/${getgrade.id}`,
				timeout: timeout,
				headers: {
					accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${authtoken}`
				},
				data: {
					finalGrade: getgrade.grade,
					remarks: values.remarks,
				}
			};
  
			axios
				.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch( { type: GRADE_UPDATE_SUCCESS, payload: response.data.data });
						Modal.success({ title: `${langdata && langdata.Gradeupdatedsuccessfully ? langdata.Gradeupdatedsuccessfully :'Grade updated successfully.'}` });
						getGrade({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
						setOpenEditDrawer(false);
						// navigate('/dashboard');
						// success('Login success')
					}
				})
				.catch((error) => {
					console.log(error);
					// error(error.response.data.message)
					dispatch({ type: GRADE_UPDATE_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}
          
	});

	const handleSetDate = (e) =>{
		setDate(new Date(e && e.$d).toLocaleDateString('en-UK', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		}));
   
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const showEditDrawer = (grade) => {
		setOpenEditDrawer(true);
		dispatch({type: GRADE_UPDATE_DETAILS, payload:grade});
		setGrade({grade:grade.finalgrade, name:grade.name, id:grade.id, remarks:grade.remarks});
	};

	const onCloseEditDrawer = () => {
		setOpenEditDrawer(false);
	};

	const handleSelectGrade = (e) => {
		setGrade({...getgrade,grade:e});
	};

	const handleSetregion = (e, options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setRegionId(options.region);
			setAllocationId(e);
		}
		
	};

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};

	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.gradecontent1 ? langdata.gradecontent1 :'The grading system provides an attendance report for a linewalker or NPV during their 1inspections using the Security Tracking System (STS) application. This report assigns a grade to each linewalker based on their patch coverage, average speed, idle time, and speed violations. A green grade indicates compliance with all criteria, while a red grade signifies some criteria have been violated. The system-generated grade can be adjusted by an administrative officer.'}</p>
			<br />
			<p>{langdata && langdata.gradecontent2 ? langdata.gradecontent2 :'To achieve a green grade, the following criteria must be met: patch coverage greater than 90%, average speed less than 6.5 km/h, idle time under 45 minutes, and fewer than three violations. These criteria can be configured in the grade management section.'}</p>
			< br/>
			<p>{langdata && langdata.gradecontent3 ? langdata.gradecontent3 :'By default, the report displays details for one day, with filter options available for allocation and date.'}</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>


					<Col span={10}>
						<Row>

							<Space>
								<FileSearchOutlined style={{fontSize:'30px'}}/>
							</Space>

							<Space>&nbsp;&nbsp;</Space>
					
					
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.ReportSTSGradeSystem ? langdata.ReportSTSGradeSystem :'Report - STS Grade System'}</Title>
						</Row>
					</Col>
					<Col style ={{justifyContent:'right', display:'flex'}} span={14} >
						<Row>
							<Col span={24}>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion:'Filter by region'}>
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ minWidth: '240px', maxWidth:'240px', margin:'5px'}}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion:'Select Region'}
											optionFilterProp="children"
											// value={regionId}
											defaultValue={null}
											options={regions}
											onSelect={(e, options)=>handleSetregion(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>

								<Tooltip placement="bottom" title={langdata && langdata.Selectdate ? langdata.Selectdate:'Select date'}>
									<Space>
					
										<DatePicker allowClear={false} placeholder="Today" onChange={handleSetDate} style={{ width: '200px', margin:'5px'}} format={settingsdateformat} disabledDate={disabledDate} defaultValue={dayjs(moment().format('YYYY-MM-DD'), dateFormat)}/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.STSGradeSystem ? langdata.STSGradeSystem:'STS Grade System'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
					{/* </Space> */}
				</Row>

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ Gradedata.length > 0 ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={Gradedata} pagination = { Gradedata.length > 0 ? grade_data_pagination : 0 }  onChange = { getGrade }/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>

				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}}
				style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
					justifyContent:'center',
					display:'flex'
				}} 
				>
					<Col style={{
						alignItems:'left',
						justifyContent:'left',
						display:'flex',
						padding:'10px'
					}}  className="gutter-row" span={24}>
						<Button disabled={Gradedata && Gradedata.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download:'Download'}</Button>
					</Col>

				</Row>

			</Content>
            

			{/* Edit grade Starts */}
			<Modal
				title={langdata && langdata.EditSystemGrade ? langdata.EditSystemGrade:'Edit System Grade'}
				// width={720}
				onOk={formik.handleSubmit}
				onCancel={onCloseEditDrawer}
				open={openEditDrawer}
				styles={{
					body: {
					},
				}}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={onCloseEditDrawer}>
							{langdata && langdata.CANCEL ? langdata.CANCEL:'CANCEL'}
						</Button>
						<Button onClick={formik.handleSubmit} type="primary">
							{langdata && langdata.SAVE ? langdata.SAVE:'SAVE'}
						</Button>
					</Space>
				]}
			>
				<Form onSubmit={formik.handleSubmit} layout="vertical">
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Name ? langdata.Name:'Name'} rules={[{ required: true, message: `${langdata && langdata.Name ? langdata.Name:'Name'}` }]}>
								<Input name="name" value={getgrade.name} defaultValue={getgrade.name} disabled placeholder={langdata && langdata.Name ? langdata.Name:'Name'} />
							</Form.Item>
						</Col>
					</Row>
  
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item name="remarks" label={langdata && langdata.Remarks ? langdata.Remarks:'Remarks'} rules={[{ required: false, message: `${langdata && langdata.Pleaseenteryourremarks ? langdata.Pleaseenteryourremarks:'Please enter your remarks'}` }]} onChange={formik.handleChange} >
								<Input name="remarks" value={getgrade.remarks} defaultValue={getgrade.remarks} placeholder={langdata && langdata.Pleaseenteryourremarks ? langdata.Pleaseenteryourremarks:'Please enter your remarks'} />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col span={24}>
							<Form.Item name="finalgrade" label={langdata && langdata.FinalGrade ? langdata.FinalGrade:'Final Grade'} rules={[{ required: true, message: `${langdata && langdata.Pleaseselectthegrade ? langdata.Pleaseselectthegrade:'Please select the grade'}` }]} >
								<Select value={getgrade.grade === 1 ? `${langdata && langdata.GREEN ? langdata.GREEN:'GREEN'}` : `${langdata && langdata.RED ? langdata.RED:'RED'}`} defaultValue={getgrade.grade === 1 ? `${langdata && langdata.GREEN ? langdata.GREEN:'GREEN'}` : `${langdata && langdata.RED ? langdata.RED:'RED'}`} onSelect={handleSelectGrade} options={options} />
							</Form.Item>
						</Col>
					</Row>

				</Form>
			</Modal>
			{/* Edit grade ends */}


			{/* Fetch and Download Modal Starts */}
			<Modal
				title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'}
				centered
				destroyOnClose={true}
				open={showFetchAndDownloadModal}
				onOk={{closeShowFetchAndDownloadModal}}
				onCancel={closeShowFetchAndDownloadModal}
				footer={[
					
				]}
				width={540}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Typography>
							{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords:'Total number of records'} : {grade_data_pagination.total}
						</Typography>
					</Col>
				</Row>

				{grade_data_pagination && grade_data_pagination.total> 0 ? (
					<BatchDownloader total={grade_data_pagination && grade_data_pagination.total} url={url}/>
				) : (
					<>
						<Row gutter={16} style={{
							backgroundColor: '#d3d3d3',
							padding: '5px',
							marginTop: '10px'
						}}>
							<Col span={24}>
								<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound:'No records found.'}</Typography>
							</Col>
						</Row>
						<br/>
					</>
          
				)}
			</Modal>
			{/* Fetch and Download Modal Modal Ends */}
			<Spin spinning={grade_data.pending} fullscreen />
		</>
	);
}