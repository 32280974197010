import React, { useEffect, useState } from 'react';


import { Row, Col, Select, Typography, Button, Space,  DatePicker, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { TimePicker } from 'antd';
import { useAuth } from '../../Context';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import { enddatetimeCoverter, startdatetimeCoverter } from '../../datetime-converter';
import { SearchOutlined, FileSearchOutlined, InfoOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';


export default function AlarmReport() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getAllEvents, eventsData, getChannels, channelData, getUserAllocation ,getUserAllocationData, pidsRegionData, getPidsRegion} = useAuth();

	const lastAllocationId = parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`));
	const lastStartDate = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_START_DATE`);
	const lastEndDate = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_END_DATE`);
	const lastActivity = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ACTIVITY`);
	const lastPids = parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`));
	const lastchannel = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);
	const lastStartTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_START_TIME`);
	const lastEndTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_END_TIME`);
	const lastAnomalyfrequency = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_FREQUENCY`);
	const lastSeverity = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_SEVERITY`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_SEVERITY`) == 'all' ? 'all' : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_SEVERITY`));
	const lastRelatedAlarm = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_RELATED_ALARM`) =='true' ? true : false;
	const lastAnomalyStatus = localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_STATUS`) == 'null' ? null : localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_STATUS`) == 'false' ? false : localStorage.getItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_STATUS`);

	const navigate = useNavigate();
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [reportFilter, setFilter] = useState({pidsinfoId:lastPids ? lastPids : null, regioninfoId:null, channelId:'all', event:['all'], severity:'all', 
		related_alarm:false, frequency:'Random', status:null, starttime:'00,00,00', endtime:'23,59,00'
	});  
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [showEndTime, setShoeEndTime] = useState(dayjs());

	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const severity = [
		{
			value: 'all',
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`,
		},
		{
			value: 2,
			label: `${langdata && langdata.AMBER ? langdata.AMBER:'AMBER'}`,
		},
		{
			value: 0,
			label: `${langdata && langdata.CLEAR ? langdata.CLEAR:'CLEAR'}`,
		},
		{
			value: 1,
			label: `${langdata && langdata.GREEN ? langdata.GREEN:'GREEN'}`,
		},
		{
			value: 3,
			label: `${langdata && langdata.RED ? langdata.RED:'RED'}`,
		},
	];

	// const severity = [
	// 	{
	// 		value: 'all',
	// 		label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	// 	},
	// 	{
	// 		value: '2',
	// 		label: `${langdata && langdata.Amber ? langdata.Amber:'Amber'}`
	// 	},
	// 	{
	// 		value: '0',
	// 		label: `${langdata && langdata.Clear ? langdata.Clear:'Clear'}`
	// 	},
	// 	{
	// 		value: '1',
	// 		label: `${langdata && langdata.Green ? langdata.Green:'Green'}`
	// 	},
	// 	{
	// 		value: '3',
	// 		label: `${langdata && langdata.Red ? langdata.Red:'Red'}`
	// 	}
	// ];
	
	
	const status = [
		{
			value: null,
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`,
		},
		{
			value: 'Acknowledged',
			label: `${langdata && langdata.ACKNOWLEDGED ? langdata.ACKNOWLEDGED:'ACKNOWLEDGED'}`,
		},
		{
			value: false,
			label: `${langdata && langdata.NOTRESOLVED ? langdata.NOTRESOLVED:'NOT RESOLVED'}`,
		},
		{
			value: 'Resolved',
			label: `${langdata && langdata.RESOLVED ? langdata.RESOLVED:'RESOLVED'}`,
		},
	];
	
	
	const frequency = [
		{
			value: 'Continous',
			label: `${langdata && langdata.Continous ? langdata.Continous:'Continous'}`,
		},
		{
			value: 'Random',
			label: `${langdata && langdata.Random ? langdata.Random:'Random'}`,
		}
	];
	
	const related_alarm = [
	
		{
			value: true,
			label: `${langdata && langdata.Yes ? langdata.Yes:'Yes'}`,
		},
		{
			value: false,
			label: `${langdata && langdata.No ? langdata.No:'No'}`,
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 62);
	}, []);
  
	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
			let pidsallocation = '';
			if(admin_roles.includes(permission)){
				getRegion(authtoken);
				pidsallocation = reportFilter.regioninfoId;
			}
			else{
				getUserAllocation(authtoken);
				pidsallocation=allocationId;
			}
			
			// getPids(authtoken);
			getAllEvents(authtoken);
			getPidsRegion(authtoken,pidsallocation);
		}

	},[authtoken, reportFilter.regioninfoId, allocationId]);

	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let pids=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	pidsRegionData && pidsRegionData.map((pid)=>{
		pids.push({
			value:pid.id,
			label:pid.name.toUpperCase()});
	});

	let allevents=[{value:'all',
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];
    
	eventsData && eventsData.map((item) => {
		allevents.push({
			value : item.eventType,
			label: item.eventType
		});
	});


	let channels=[{value:'all',
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(reportFilter.pidsinfoId){

		channelData && channelData.map((item) => {
			channels.push({
				value : item.channelId,
				label : item.name
			});
		});
	}
    

	pids.sort((a, b) => { return a.value - b.value; });

	const from_datetimeobj=[];

	if(startDate && startDate !=='1970-01-01' && reportFilter.starttime){
		const starttime = reportFilter.starttime;
		var resultArray = starttime.split(',').map(function(starttime){return Number(starttime);});
		if(resultArray.length > 0) {
			startDate.setHours(resultArray[0],resultArray[1],resultArray[2]);
			let startdatetime=startdatetimeCoverter(startDate);
			from_datetimeobj.push({startdatetime});

		}

	}

	const to_datetimeobj=[];

	if(endDate && endDate !=='1970-01-01' && reportFilter.endtime ){

		const endtime = reportFilter.endtime;

		var resultEndArray = endtime.split(',').map(function(endtime){return Number(endtime);});
		if(resultEndArray.length > 0){
			endDate.setHours(resultEndArray[0],resultEndArray[1],resultEndArray[2]);
			// filterDate.setHours(parseFloat(filterEndTime));
			let enddatetime=enddatetimeCoverter(endDate);
			to_datetimeobj.push({enddatetime});

		}

	}

	const handleSetregion = (e, options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(admin_roles.includes(permission)) {
			setAllocationId(null);
			setFilter({...reportFilter,regioninfoId:e, pidsinfoId:null, channelId : 'all'});
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`,e);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);

		} else {
			setFilter({...reportFilter,regioninfoId:options.region, pidsinfoId:null, channelId : 'all'});
			setAllocationId(e);
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ALLOCATION_ID`,e);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`);
			localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);
		}
	};

	const handleSetPids = (e) =>{
		getChannels(authtoken,e);
		setFilter({...reportFilter,pidsinfoId:e, channelId : 'all'});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_PIDS`,e);
		localStorage.removeItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`);
	};

	const handleSetChannel = (e, options) =>{
		setFilter({...reportFilter,channelId:e});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_CHANNEL`,options.label);
	};

	const handleSelectEvent = (e)=>{
		setFilter({...reportFilter,event:e});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ACTIVITY`,e);
	};

	const handleSetSeverity = (e)=>{
		setFilter({...reportFilter,severity:e});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_SEVERITY`,e);
	};

	const handleStartTime = (time) => {
		// setFilterStartTIme(moment(time && time.$d).format('HH,mm,ss'));

		setFilter({...reportFilter,starttime:moment(time && time.$d).format('HH,mm,ss')});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_START_TIME`,moment(time && time.$d).format('HH:mm:ss'));
	};

	const handleEndTime = (time) => {
		setFilter({...reportFilter,endtime:moment(time && time.$d).format('HH,mm,ss')});
		setShoeEndTime(moment(time && time.$d).format('HH,mm,ss'));
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_END_TIME`,moment(time && time.$d).format('HH:mm:ss'));

	};

	const handleSetRelated = (e)=>{
		setFilter({...reportFilter,related_alarm:e});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_RELATED_ALARM`,e);
	};

	const handleSetFrequency = (e)=>{
		setFilter({...reportFilter,frequency:e});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_FREQUENCY`,e);

	};

	const handleSetStatus = (e)=>{
		setFilter({...reportFilter,status:e});
		localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_ANOMALY_STATUS`,e);
	};

	const disabledStartHours = () => {
		const hours = [];
		const currentHour = moment().hour();
		let hour = 0;
		
		if(moment.utc(moment()).date() == moment(startDate).date()) {
			hour = currentHour;
		} else {
			hour = 23;
		}
	
		for (let i = hour + 1; i < 24; i++) {
			hours.push(i);
		}
	
		return hours;
	};
	
	const disabledStartMinutes = (selectedHour) => {
		const minutes = [];
		let minute = 0;
		const currentMinute = moment().minute();

		if(moment.utc(moment()).date() == moment(startDate).date()) {
			minute = currentMinute;
		} else {
			minute = 59;
		}

		if (selectedHour === moment().hour()) {
			for (let i = minute + 1; i <= 60; i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};

	const disabledEndHours = () => {
		const hours = [];
		const currentHour = moment().hour();
		let hour = 0;
		
		if(moment.utc(moment()).date() == moment(endDate).date()) {
			hour = currentHour;
		} else {
			hour = 23;
		}
	
		for (let i = hour + 1; i < 24; i++) {
			hours.push(i);
		}
	
		return hours;
	};
	
	const disabledEndMinutes = (selectedHour) => {
		const minutes = [];
		let minute = 0;
		const currentMinute = moment().minute();

		if(moment.utc(moment()).date() == moment(endDate).date()) {
			minute = currentMinute;
		} else {
			minute = 59;
		}

		if (selectedHour === moment().hour()) {
			for (let i = minute + 1; i <= 60; i++) {
				minutes.push(i);
			}
		}
		return minutes;
	};
  

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(new Date(dates[0] && dates[0].$d));
			setEndDate(new Date(dates[1] && dates[1].$d));
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_START_DATE`,new Date(dates[0] && dates[0].$d));
			localStorage.setItem(`${dashboardtype.toUpperCase()}_REPORT_END_DATE`,new Date(dates[1] && dates[1].$d));
			if(moment.utc(moment()).date() == moment(new Date(dates[1] && dates[1].$d)).date()) {
				setShoeEndTime(dayjs());
			} else {
				setShoeEndTime(dayjs('23:59:59', 'HH:mm:ss'));
			}
		} else {
			console.log('Clear');
		}
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.reportalarmcontent1 ? langdata.reportalarmcontent1:'This feature allow the user to download alarms as report by sorting and filtering details by controller and channel for precise analysis. The report can be downloaded as .csv format.'} </p>
		</Card>
	);


	return (
		<>
			<Content>
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>

					<Col span={12}>
						<Row>
							<Space>
								<FileSearchOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.ReportAlarms ? langdata.ReportAlarms:'Report - Alarms'}</Title>
						</Row>
					</Col>

					<Col span={12}>
						<Row justify="end">
							<Col>
								{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
								<Space>
									<Typography>
										<Popover title={langdata && langdata.ReportAlarms ? langdata.ReportAlarms:'Report - Alarms'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>

				</Row>



				<Row style={{
					margin: '10px',
					paddingTop:'10px',
					// paddingLeft: '100px',
					// paddingRight: '180px',
					paddingBottom:'30px',
					background: '#FFFFFF',
					justifyContent:'left', display:'flex', alignItems:'left'
				}}>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Allocation ? langdata.Allocation :'Allocation'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{ width: '360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.Selectallocation ? langdata.Selectallocation :'Select allocation'}
											optionFilterProp="children"
											// defaultValue={'ALL'}
											defaultValue={lastAllocationId ? lastAllocationId : null}
											options={regions}
											onSelect={(e, options)=>handleSetregion(e, options)}                  

										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Date ? langdata.Date :'Date'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<RangePicker allowClear={false} onChange={onRangeChange} disabledDate={disabledDate} format={settingsdateformat}
											// defaultValue={[dayjs(moment().format('YYYY-MM-DD'), dateFormat), dayjs(moment().format('YYYY-MM-DD'), dateFormat)]}
											defaultValue={[dayjs(moment(lastStartDate ? lastStartDate : new Date()).format('YYYY-MM-DD'), dateFormat), dayjs(moment(lastEndDate ? lastEndDate : new Date()).format('YYYY-MM-DD'), dateFormat)]} 
											style={{ width: '360px'}} />
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Activity ? langdata.Activity :'Activity'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											mode="multiple"
											style={{ width: '360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectEvent ? langdata.SelectEvent :'Select Event'}
											optionFilterProp="children"
											
											// defaultValue={['all']}
											defaultValue={[lastActivity ? lastActivity : 'all']}
											options={allevents}
											onChange={handleSelectEvent}
										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{ width: '360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS :'Select PIDS'}
											optionFilterProp="children"
		
											// value={reportFilter.pidsinfoId}
											value={lastPids ? lastPids : reportFilter.pidsinfoId}
											onSelect={handleSetPids}                  
											options={pids}
										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Channel ? langdata.Channel :'Channel'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{ width: '360px'}}
											showSearch
											filterOption={filterOption}
											disabled={channels.length > 1 || lastchannel || reportFilter.pidsinfoId ? false :true}
											placeholder={langdata && langdata.SelectChannel ? langdata.SelectChannel :'Select Channel'}
											optionFilterProp="children"
		
											// value={reportFilter.channelId}
											value={lastchannel ? lastchannel : reportFilter.channelId}
											// onSelect={handleSetChannel}
											onSelect={(e,options)=>{handleSetChannel(e,options);}}
											options={channels}
										/>
									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.Severity ? langdata.Severity :'Severity'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{ width: '360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectSeverity ? langdata.SelectSeverity :'Select Severity'}
											optionFilterProp="children"
											value={lastSeverity ? lastSeverity : reportFilter.severity}
											onSelect={handleSetSeverity}                  
											options={severity}
										/>

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>



					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.StartTime ? langdata.StartTime :'Start Time'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<TimePicker style={{ width: '360px'}} 
											// defaultValue={dayjs('00:00', 'HH:mm')}
											defaultValue={dayjs(lastStartTime ? lastStartTime : '00:00', 'HH:mm')} 
											allowClear={false}
											onChange={handleStartTime}
											disabledHours={disabledStartHours}
											disabledMinutes={disabledStartMinutes} />

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>




					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.EndTime ? langdata.EndTime :'End Time'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<TimePicker style={{ width: '360px'}} 
											// defaultValue={dayjs('23:59:59', 'HH:mm:ss')} 
											// value={dayjs(showEndTime, 'HH:mm:ss')}
											value={dayjs(lastEndTime ? lastEndTime : showEndTime, 'HH:mm:ss')}
											allowClear={false}
											onChange={handleEndTime}
											disabledHours={disabledEndHours}
											disabledMinutes={disabledEndMinutes} />

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>
					{dashboardtype == 'oneview' ? 
						<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
							<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
							>
								<Space>
									<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

										<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

											{langdata && langdata.RelatedAlarms ? langdata.RelatedAlarms :'Related Alarms'}:
										</Space>
										<Space>&nbsp;</Space>

										<Space>
											<Select Item
												style={{ width: '360px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectAlarmType ? langdata.SelectAlarmType :'Select Alarm Type'}
												optionFilterProp="children"
												value={lastRelatedAlarm ? lastRelatedAlarm : reportFilter.related_alarm}
												options={related_alarm}
												onSelect={handleSetRelated}                  

											/>

										</Space>
										<Space>&nbsp;</Space>

									</Row>
								</Space>
							</Col>	
						</Row>
						: null }



					<Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

										{langdata && langdata.AnomalyStatus ? langdata.AnomalyStatus :'Anomaly Status'}:
									</Space>
									<Space>&nbsp;</Space>

									<Space>
										<Select Item
											style={{ width: '360px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus :'Select Status'}
											optionFilterProp="children"
											value={lastAnomalyStatus ? lastAnomalyStatus : reportFilter.status}
											options={status}
											onSelect={handleSetStatus}                  

										/>

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						
						</Col>	

					</Row>
					{dashboardtype == 'oneview' ? 
						<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
							<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
							>
								<Space>
									<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

										<Space style={{textAlign:'right', width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

											{langdata && langdata.AnomalyFrequency ? langdata.AnomalyFrequency :'Anomaly Frequency'}:
										</Space>
										<Space>&nbsp;</Space>

										<Space>
											<Select Item
												style={{ width: '360px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.Selectfrequency ? langdata.Selectfrequency :'Select frequency'}
												optionFilterProp="children"
												value={lastAnomalyfrequency ? lastAnomalyfrequency : reportFilter.frequency}
												options={frequency}
												onSelect={handleSetFrequency}
											/>

										</Space>
										<Space>&nbsp;</Space>

									</Row>
								</Space>
							</Col>	
						</Row>
						: null }


					{/* <Row style={{marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}} >


						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>

							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									&nbsp;
									</Space>
									<Space>&nbsp;</Space>

									<Space style={{width:'360px', justifyContent:'right', display:'flex', alignItems:'right'}}>
										<Button type ={'primary'} onClick={() => {navigate(( '/alarm-table'),{ state: {reportFilter:reportFilter, fromDate:from_datetimeobj[0].startdatetime.utcdate,toDate:to_datetimeobj[0].enddatetime.utcdate,
											fromTime:from_datetimeobj[0].startdatetime.utctime, toTime:to_datetimeobj[0].enddatetime.utctime, allocationId : allocationId} });}} >SEARCH</Button>

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>

						</Col>	

					</Row>
					<Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
						<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									&nbsp;
									</Space>
									<Space>&nbsp;</Space>

									<Space style={{width:'360px'}}>
									&nbsp;

									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row> */}

					<Col span={24}>

					

						<Row style={{ marginTop:'30px', justifyContent:'left', display:'flex', alignItems:'left'}}>
							<Col style={{justifyContent:'left', display:'flex', alignItems:'left'}} span={24}
							>
								<Space>
									<Row  style={{justifyContent:'left', display:'flex', alignItems:'left'}}>

										<Space style={{width:'160px', justifyContent:'left', display:'flex', alignItems:'left'}}>

									&nbsp;
										</Space>
										<Space>&nbsp;</Space>

										<Space style={{justifyContent:'left', display:'flex', alignItems:'left', width:'360px'}}>
											<Button type ={'primary'} onClick={() => {navigate(( '/alarm-table'),{ state: {reportFilter:reportFilter, fromDate:from_datetimeobj[0].startdatetime.utcdate,toDate:to_datetimeobj[0].enddatetime.utcdate,
												fromTime:from_datetimeobj[0].startdatetime.utctime, toTime:to_datetimeobj[0].enddatetime.utctime, allocationId : allocationId} });}} ><SearchOutlined />{langdata && langdata.Search ? langdata.Search :'Search'}</Button>


										</Space>
										<Space>&nbsp;</Space>

									</Row>
								</Space>
							</Col>	
						</Row>

					</Col>

				</Row>



			</Content>
            

			{/* Edit grade Starts */}
      
			{/* Edit grade ends */}

		</>
	);
}