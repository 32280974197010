import { Breadcrumb, Button, Col, Empty, Form, InputNumber, Modal, Row, Select, Space, Spin, Switch, Table, Tag, TimePicker, Tooltip, Typography, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { SplitCellsOutlined, HomeOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import channelInfoReducer from './reducer';
import { useAuth } from '../../Context';
import { useFormik } from 'formik';
import axios from 'axios';
import {GET_EVENT_RULES, GET_EVENT_RULES_DETAILS, GET_EVENT_RULES_ERROR, GET_EVENT_RULES_SUCCESS, GET_TERRAIN, GET_TERRAIN_ERROR, GET_TERRAIN_SUCCESS } from './constants';
import moment from 'moment';
import dayjs from 'dayjs';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;

export default function Convertion () {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const format = 'HH:mm';

	const navigate = useNavigate();
	const { state } = useLocation();

	const initalState = {eventRluesDetails : [], terrains : [], eventrules : [], loading: false, error: false};
	const [data, dispatch] = useReducer(channelInfoReducer, initalState);
	const {getAllEvents, eventsData} = useAuth();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalAddOpen, setIsModalAddOpen] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteActivityId, setDeleteActivityId] = useState();
	const [enableRule, setEnableRule] = useState(data.eventRluesDetails && data.eventRluesDetails.isEnabled);
	const [eventType, setEventType] = useState(null);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const eventDataFilter = [{
		value : null,
		label :  langdata && langdata.ALL ? langdata.ALL : 'ALL'
	}];

	const convertion = [];

	const channelid = parseInt(state.id);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const eventData = [];
	eventsData && eventsData.map((item) => {
		eventData.push({
			value : item.id,
			label : item.eventType
		});
		eventDataFilter.push({
			value : item.id,
			label : item.eventType
		});
	});

	const terrainData = [{
		value: 'all',
		label: langdata && langdata.ALL ? langdata.ALL : 'ALL'
	},];

	const terrainsOperators = [{
		value: 'in',
		label: langdata && langdata.IN ? langdata.IN : 'IN'
	},
	{
		value: 'not in',
		label: langdata && langdata.NOTIN ? langdata.NOTIN : 'NOT IN'
	}];
	
	data.terrains && data.terrains.map((item) => {
		terrainData.push({
			value : item.name,
			label : item.name
		});
	});

	data.eventrules && data.eventrules.map((item)=>{
		convertion.push({
			id:item.id,
			activity:item.events.eventType,
			mappedActivity:item.convertevents.eventType,
			eventConfidenceLower:item.eventConfidenceLower,
			eventConfidenceUpper:item.eventConfidenceUpper,
			starttime:moment(item.startTime).format(settingstimeformat),
			endtime:moment(item.endTime).format(settingstimeformat),
			terrain:item.terrains,
			terrainsOperator:item.terrainsOperator.toUpperCase(),
			activityId:item.eventTypeId,
			mappedActivityId:item.convertEventTypeId,
			isEnabled:item.isEnabled

		});
	});

	useEffect(() => {
		getAllEvents(authtoken);
		loadTerrains();
		loadEventRules();
	},[authtoken, eventType]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: langdata && langdata.Activity ? langdata.Activity : 'Activity',
			dataIndex: 'activity',
			key: 'activity',
		},
		{
			title: langdata && langdata.MappedActivity ? langdata.MappedActivity : 'Mapped Activity',
			dataIndex: 'mappedActivity',
			key: 'mappedActivity',
		},
		{
			title: langdata && langdata.LowerEventConfidence ? langdata.LowerEventConfidence : 'Lower Event Confidence',
			dataIndex: 'eventConfidenceLower',
			key: 'eventConfidenceLower',
		},
		{
			title: langdata && langdata.UpperEventConfidence ? langdata.UpperEventConfidence : 'Upper Event Confidence',
			dataIndex: 'eventConfidenceUpper',
			key: 'eventConfidenceUpper',
		},
		{
			title: langdata && langdata.TerrainsOperator ? langdata.TerrainsOperator : 'Terrains Operator',
			dataIndex: 'terrainsOperator',
			key: 'terrainsOperator',
			render: (_, operator) => (
				<>
					{operator &&
						<Tag color={operator.terrainsOperator=='IN' ? 'green' : 'red'}>
							{operator.terrainsOperator}
						</Tag>}
				</>
			),
		},
		{
			title:  langdata && langdata.Terrains ? langdata.Terrains : 'Terrains',
			dataIndex: 'terrain',
			key: 'terrain',
			render: (terrain) => terrain ? terrain.map(terrain =>  terrain).join(', ') : ''
		},
		{
			title:  langdata && langdata.StartTime ? langdata.StartTime : 'Start Time',
			dataIndex: 'starttime',
			key: 'starttime',
		},
		{
			title:  langdata && langdata.EndTime ? langdata.EndTime : 'End Time',
			dataIndex: 'endtime',
			key: 'endtime',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (record) => (
				<Space size="middle">
					<a title={langdata && langdata.EditPredictionconvertion ? langdata.EditPredictionconvertion : 'Edit Prediction convertion'} onClick={showModal}><EditOutlined /></a>
					<a title={langdata && langdata.DeletePredictionconvertion ? langdata.DeletePredictionconvertion : 'Delete Prediction convertion'} onClick={() => {openDeleteModal(record);}}><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	const loadEventRules = () =>{

		dispatch({ type : GET_EVENT_RULES });
		let filterparamas = '';
		if(eventType){
			filterparamas = `&eventTypeId=${eventType}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/eventrules?channelinfoId=${channelid}${filterparamas}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					// setTerrainInfo(response.data);
					dispatch({ type : GET_EVENT_RULES_SUCCESS, payload : response.data});
				}
				else {
					dispatch({ type : GET_EVENT_RULES_ERROR});
				}
			})
			.catch((error) => {
				dispatch({ type : GET_EVENT_RULES_ERROR});
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});


	};

	const openDeleteModal = (value) => {
		setDeleteActivityId(value.id);
		setShowDeleteModal(true);
	};

	const handleDeleteOk = () => {
		setShowDeleteModal(false);
	};

	const handleDelete = () => {

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/eventrules/${deleteActivityId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ){
					Modal.success({ title: langdata && langdata.Channelpredictionconvertiondeletedsuccessfully ? langdata.Channelpredictionconvertiondeletedsuccessfully :'Channel prediction convertion deleted successfully' });
					loadEventRules();
				} else {
					Modal.warning({ title:langdata && langdata.Unabletodeletechannelpredictionconvertion ? langdata.Unabletodeletechannelpredictionconvertion : 'Unable to delete channel prediction convertion ' });
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		setShowDeleteModal(false);
	};


	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: data.eventRluesDetails && data.eventRluesDetails.id,
			activity: data.eventRluesDetails && data.eventRluesDetails.activityId ? data.eventRluesDetails.activityId : '-' ,
			mappedActivity: data.eventRluesDetails && data.eventRluesDetails.mappedActivityId ? data.eventRluesDetails.mappedActivityId : '-',
			eventConfidenceLower : data.eventRluesDetails && data.eventRluesDetails.eventConfidenceLower || data.eventRluesDetails.eventConfidenceLower == 0 ? data.eventRluesDetails.eventConfidenceLower : '-',
			eventConfidenceUpper : data.eventRluesDetails && data.eventRluesDetails.eventConfidenceUpper || data.eventRluesDetails.eventConfidenceUpper == 0 ? data.eventRluesDetails.eventConfidenceUpper : '-',
			starttime : data.eventRluesDetails && data.eventRluesDetails.starttime ? data.eventRluesDetails.starttime : '-',
			endtime :  data.eventRluesDetails && data.eventRluesDetails.endtime ? data.eventRluesDetails.endtime : '-',
			terrain : data.eventRluesDetails && data.eventRluesDetails.terrain ? data.eventRluesDetails.terrain : '-',
			terrainsOperator : data.eventRluesDetails && data.eventRluesDetails.terrainsOperator ? data.eventRluesDetails.terrainsOperator.toLowerCase() : '-'
		},
		onSubmit: (values) => {
			const {activity, mappedActivity, eventConfidenceLower, eventConfidenceUpper, starttime, endtime, terrain, terrainsOperator} = values;
			const date = new Date();
			var resultstartArray = starttime.split(':').map(function(starttime){return Number(starttime);});
			var resultendArray = endtime.split(':').map(function(endtime){return Number(endtime);});
			const startdatetime = moment(date.setHours(resultstartArray[0],resultstartArray[1],0)).format();
			const enddatetime = moment(date.setHours(resultendArray[0],resultendArray[1],0)).format();

			let data = JSON.stringify({
				channelinfoId: channelid,
				eventTypeId: activity,
				eventConfidenceLower: eventConfidenceLower,
				eventConfidenceUpper: eventConfidenceUpper,
				terrains: terrain,
				terrainsOperator: terrainsOperator.toLowerCase(),
				startTime: startdatetime,
				endTime: enddatetime,
				convertEventTypeId: mappedActivity,
				isEnabled: enableRule,
				priority:1
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/eventrules/${values.id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 ){
						Modal.success({ title: langdata && langdata.Channelpredictionconvertionupdatedsuccessfully ? langdata.Channelpredictionconvertionupdatedsuccessfully : 'Channel prediction convertion updated successfully' });
						loadEventRules();
					} else {
						Modal.warning({ title: langdata && langdata.Channelpredictionconvertionnotupdated ? langdata.Channelpredictionconvertionnotupdated :  'Channel prediction convertion not updated' });
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					}
				});
			setIsModalOpen(false);
		}
	});

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const showAddModal = () => {
		setIsModalAddOpen(true);
	};

	const handleAddOk = () => {
		setIsModalAddOpen(false);
	};

	const handleAddCancel = () => {
		setIsModalAddOpen(false);
		addFormik.values.terrain = null;
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const loadeventRluesDetails = ((details) => {
		dispatch({ type : GET_EVENT_RULES_DETAILS, payload : details });		
	});

	const addFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			activity: null ,
			mappedActivity: null,
			eventConfidenceLower : 0,
			eventConfidenceUpper : 1,
			starttime : '00:00',
			endtime :  '23:59',
			terrain : null,
			terrainsOperator: 'in'
		},
		onSubmit: (values) => {
			const {activity, mappedActivity, eventConfidenceLower, eventConfidenceUpper, starttime, endtime, terrain, terrainsOperator} = values;
			const date = new Date();
			var resultstartArray = starttime.split(':').map(function(starttime){return Number(starttime);});
			var resultendArray = endtime.split(':').map(function(endtime){return Number(endtime);});
			const startdatetime = moment(date.setHours(resultstartArray[0],resultstartArray[1],0)).format();
			const enddatetime = moment(date.setHours(resultendArray[0],resultendArray[1],0)).format();
			if(activity == null || mappedActivity == null || eventConfidenceLower == null || eventConfidenceUpper == null || startdatetime == null || enddatetime == null || terrain == null || terrainsOperator == null ) {
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields : 'Please fill all the mandatory fields'});
				return true;
			}
			else {

				let data = JSON.stringify({
					channelinfoId: channelid,
					eventTypeId: activity,
					eventConfidenceLower: eventConfidenceLower,
					eventConfidenceUpper: eventConfidenceUpper,
					terrains: terrain,
					terrainsOperator: terrainsOperator.toLowerCase(),
					startTime: startdatetime,
					endTime: enddatetime,
					convertEventTypeId: mappedActivity,
					isEnabled: true,
					priority:1
				});

				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/eventrules`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};

				axios.request(config)
					.then((response) => {
						if(response.status == 201 ){
							Modal.success({ title: langdata && langdata.Channelpredictionconvertionsuccessfullyadded ? langdata.Channelpredictionconvertionsuccessfullyadded : 'Channel prediction convertion successfully added' });
							addFormik.values.activity = null;
							addFormik.values.width = null;
							addFormik.values.mappedActivity = null;
							loadEventRules();
						} else {
							Modal.warning({ title: langdata && langdata.Channelpredictionconvertionnotadded ? langdata.Channelpredictionconvertionnotadded :'Channel prediction convertion not added' });
						}
					})
					.catch((error) => {
						console.log(error);
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
							});
						}
					});
			}
			setIsModalAddOpen(false);
		}
	});


	const loadTerrains = () => {

		dispatch({ type : GET_TERRAIN });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/terrains`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					// setTerrainInfo(response.data);
					dispatch({ type : GET_TERRAIN_SUCCESS, payload : response.data.data});
				}
				else {
					dispatch({ type : GET_TERRAIN_ERROR});
				}
			})
			.catch((error) => {
				dispatch({ type : GET_TERRAIN_ERROR});
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const onEnable = (checked) => {
		setEnableRule(checked);
	};

	const handleEventType = (value) => {
		setEventType(value);
	};
    
	return (
		<Content>
			<Row 
				style={{
					marginLeft:'10px',
					marginTop: '10px'
				}}            
			>
				<Breadcrumb
					items={[
						{
							href: '/dashboard',
							title: <HomeOutlined />,
						},
						{
							title:  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.ChannelInfo ? langdata.ChannelInfo : 'Channel Info'} / {state.channel}</a> 
						},
						{
							title: langdata && langdata.PredictionConversion ? langdata.PredictionConversion : 'Prediction Conversion',
						},
					]}
				/>
			</Row>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>

				<Col span={8}>
					<Row>
						<Space>
							<SplitCellsOutlined style={{fontSize:'30px'}}/>
							{/* </Col> */}
						</Space>
						<Space>&nbsp;&nbsp;</Space>
					
						{/* <Col style={{marginLeft:'4px'}} span={19}> */}
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.PredictionConversion ? langdata.PredictionConversion : 'Prediction Conversion'}</Title>
					</Row>
				</Col>
				<Col style={{ display : 'flex', justifyContent : 'right'}} span={16} >
					<Row>
						<Col>
							<Tooltip placement="bottom" title={langdata && langdata.Filterbyeventtype ? langdata.Filterbyeventtype : 'Filter by event type'}>
								<Space>
									<Select
										style={{ minWidth: '200px', margin:'5px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectEventType ? langdata.SelectEventType : 'Select Event Type'}
										optionFilterProp="children"
										options={eventDataFilter}
										onSelect={handleEventType}
										value={eventType}
									/>		
								</Space>
							</Tooltip>	
							<Space>&nbsp;&nbsp;</Space>									
							<Tooltip placement="bottom" title={langdata && langdata.Newchannelconvertion ? langdata.Newchannelconvertion : 'New channel convertion'}>
								<Space>

									<Button
										style={{ margin:'5px'}}
										type='primary'
										onClick={showAddModal}
									><PlusOutlined/>{langdata && langdata.New ? langdata.New : 'New'}</Button>
								</Space>
							</Tooltip>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ convertion.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}} 
							columns={columns} 
							dataSource={convertion} 
							onRow = {(record) => {
								return {
									onClick: () => { 
										loadeventRluesDetails(record);
									}
								};
							}}
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>

			{/* MODAL - Edit model event rules starts */}

			<Modal title={langdata && langdata.EditChannelPredictionConvertion ? langdata.EditChannelPredictionConvertion : 'Edit Channel Prediction Convertion'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose= {true}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={formik.handleSubmit}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'SAVE'}</Button>
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" 
					onSubmit={formik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Activity ? langdata.Activity : 'Activity'} name="activity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Activity ? langdata.Activity : 'Activity'}
									optionFilterProp="children"
									options={eventData}
									defaultValue={data.eventRluesDetails && data.eventRluesDetails.activity}
									onChange={(value) => formik.setFieldValue('activity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.MappedActivity ? langdata.MappedActivity : 'Mapped activity'} name="mappedActivity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.MappedActivity ? langdata.MappedActivity : 'Mapped activity'}
									optionFilterProp="children"
									options={eventData}
									defaultValue={data.eventRluesDetails && data.eventRluesDetails.mappedActivity}
									onChange={(value) => formik.setFieldValue('mappedActivity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.LowerEventConfidenceLimit ? langdata.LowerEventConfidenceLimit : 'Lower Event Confidence Limit'} name="eventConfidenceLower" rules={[{ required: true }]}>
								<InputNumber 
									stringMode 
									style={{
										width: 650,
									}}
									min="0"
									max="1"
									step="0.1"
									defaultValue={data.eventRluesDetails && data.eventRluesDetails.eventConfidenceLower} 
									// onChange={formik.handleChange}
									onChange={(value) => formik.setFieldValue('eventConfidenceLower', value)}

								/>											
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.UpperEventConfidenceLimit ? langdata.UpperEventConfidenceLimit : 'Upper Event Confidence Limit'} name="eventConfidenceUpper" rules={[{ required: true }]}>
								<InputNumber 
									stringMode
									style={{
										width: 650,
									}}
									min="0"
									max="1"
									step="0.1" 
									defaultValue={data.eventRluesDetails && data.eventRluesDetails.eventConfidenceUpper}
									onChange={(value) => formik.setFieldValue('eventConfidenceUpper', value)}
									// onChange={formik.handleChange}
								/>											
							</Form.Item>
						</Col>
					</Row>		
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.TerrainsOperator ? langdata.TerrainsOperator : 'Terrains Operator'} name="terrainsOperator" rules={[{ required: true }]}>
								<Select Item
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectterrianoperator ? langdata.Selectterrianoperator : 'Select terrians operator'}
									optionFilterProp="children"
									options={terrainsOperators}
									defaultValue={data && data.eventRluesDetails && data.eventRluesDetails.terrainsOperator ? data.eventRluesDetails.terrainsOperator.toLowerCase() :'-'}
									onChange={(value) => formik.setFieldValue('terrainsOperator', value)}
								/>
							</Form.Item>
						</Col>
					</Row>			
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Terrains ? langdata.Terrains : 'Terrains'} name="terrain" rules={[{ required: true }]}>
								<Select Item
									mode="multiple"
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectterrian ? langdata.Selectterrian : 'Select terrian'}
									optionFilterProp="children"
									options={terrainData}
									defaultValue={data.eventRluesDetails && data.eventRluesDetails.terrain}
									onChange={(value) => formik.setFieldValue('terrain', value)}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Tag color="blue">{langdata && langdata.Terrain ? langdata.Terrain : 'Terrain'} <b>{formik.values.terrainsOperator}</b> {'['}{formik.values && formik.values.terrain && formik.values.terrain.toString()}{']'}</Tag>
						</Col>
						<Space>&nbsp;</Space>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} name="starttime" rules={[{ required: true }]}>
								<TimePicker placeholder={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} allowClear format={format} defaultValue={dayjs(data.eventRluesDetails && data.eventRluesDetails.starttime, 'HH:mm')}  style={{ width: '100%' }} 
									onChange={(value, dateString) => formik.setFieldValue('starttime', dateString)}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} name="endtime" rules={[{ required: true }]}>
								<TimePicker placeholder={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} allowClear format={format} defaultValue={dayjs(data.eventRluesDetails && data.eventRluesDetails.endtime, 'HH:mm')}  style={{ width: '100%' }} 
									onChange={(value, dateString) => formik.setFieldValue('endtime', dateString)}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Rule ? langdata.Rule : 'Rule'} rules={[{ required: true }]} required>
								<Switch checkedChildren={langdata && langdata.Enabled ? langdata.Enabled : 'Enabled'} unCheckedChildren ={langdata && langdata.Disabled ? langdata.Disabled : 'Disabled'} defaultChecked onChange={onEnable} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			{/* MODAL - Add model event rules starts */}
			
			<Modal title={langdata && langdata.AddChannelPredictionConvertion ? langdata.AddChannelPredictionConvertion :'Add Channel Prediction Convertion'} open={isModalAddOpen} onOk={handleAddOk} onCancel={handleAddCancel} destroyOnClose= {true}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleAddCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={addFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" 
					onSubmit={addFormik.handleSubmit} 
					// form={form} 
				// onValuesChange={handleFormValuesChange}
				>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Activity ? langdata.Activity : 'Activity'} name="activity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Activity ? langdata.Activity : 'Activity'}
									optionFilterProp="children"
									options={eventData}
									// defaultValue={data.eventRluesDetails && data.eventRluesDetails.activity}
									onChange={(value) => addFormik.setFieldValue('activity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.MappedActivity ? langdata.MappedActivity : 'Mapped Activity'} name="mappedActivity" rules={[{ required: true }]}>
								<Select
									// name="activity" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.MappedActivity ? langdata.MappedActivity : 'Mapped activity'}
									optionFilterProp="children"
									options={eventData}
									// defaultValue={data.eventRluesDetails && data.eventRluesDetails.mappedActivity}
									onChange={(value) => addFormik.setFieldValue('mappedActivity', value)}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.LowerEventConfidenceLimit ? langdata.LowerEventConfidenceLimit : 'Lower Event Confidence Limit'} name="eventConfidenceLower" rules={[{ required: true }]}>
								<InputNumber 
									stringMode 
									style={{
										width: 650,
									}}
									defaultValue="0"
									min="0"
									max="1"
									step="0.1"
									placeholder={langdata && langdata.Selecteventconfidence ? langdata.Selecteventconfidence : 'Select event confidence'}
									// defaultValue={data.eventRluesDetails && data.eventRluesDetails.confidence && data.eventRluesDetails.confidence[0]} 
									onChange={(value) => addFormik.setFieldValue('eventConfidenceLower', value)}/>											
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.UpperEventConfidenceLimit ? langdata.UpperEventConfidenceLimit : 'Upper Event Confidence Limit'} name='eventConfidenceUpper' rules={[{ required: true }]}>
								<InputNumber 
									stringMode
									style={{
										width: 650,
									}}
									defaultValue="1"
									min="0"
									max="1"
									step="0.1" 
									placeholder={langdata && langdata.SelectUpperEventConfidenceLimit ? langdata.SelectUpperEventConfidenceLimit : 'Select Upper Event Confidence Limit'}
									// defaultValue={data.eventRluesDetails && data.eventRluesDetails.confidence && data.eventRluesDetails.confidence[1]}
									onChange={(value) => addFormik.setFieldValue('eventConfidenceUpper', value)}/>											
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.TerrainsOperator ? langdata.TerrainsOperator : 'Terrains Operator'} name="terrainsOperator" rules={[{ required: true }]}>
								<Select Item
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectterrianoperator ? langdata.Selectterrianoperator : 'Select terrians operator'}
									optionFilterProp="children"
									options={terrainsOperators}
									defaultValue={'in'}
									onChange={(value) => addFormik.setFieldValue('terrainsOperator', value)}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Terrains ? langdata.Terrains : 'Terrains'} name="terrain" rules={[{ required: true }]}>
								<Select Item
									mode="multiple"
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Selectterrian ? langdata.Selectterrian : 'Select terrian'}
									optionFilterProp="children"
									options={terrainData}
									onChange={(value) => addFormik.setFieldValue('terrain', value)}
								/>
							</Form.Item>
						</Col>
					</Row>
					
					<Row gutter={24}>
						<Col span={24}>
							<Tag color="blue">{langdata && langdata.Selectterrian ? langdata.Selectterrian : 'Terrain' } <b>{addFormik.values.terrainsOperator}</b> {'['}{addFormik.values && addFormik.values.terrain && addFormik.values.terrain.toString()}{']'}</Tag>
						</Col>
						<Space>&nbsp;</Space>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} name="starttime" rules={[{ required: true }]}>
								<TimePicker placeholder={langdata && langdata.StartTime ? langdata.StartTime : 'Start Time'} allowClear format={format} 
									// value={data.eventRluesDetails && data.eventRluesDetails.starttime}  
									style={{ width: '100%' }} 
									defaultValue={dayjs('00:00', 'HH:mm')}
									onChange={(value, dateString) => addFormik.setFieldValue('starttime', dateString)}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} name="endtime" rules={[{ required: true }]}>
								<TimePicker placeholder={langdata && langdata.EndTime ? langdata.EndTime : 'End Time'} allowClear format={format} 
									defaultValue={dayjs('23:59', 'HH:mm')}
									style={{ width: '100%' }} 
									onChange={(value, dateString) => addFormik.setFieldValue('endtime', dateString)}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteModal} onOk={handleDelete} onCancel={handleDeleteOk}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleDeleteOk}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>
						{langdata && langdata.Areyousureyouwanttodeletethischannelpredictionconvertion ? langdata.Areyousureyouwanttodeletethischannelpredictionconvertion : 'Are you sure you want to delete this channel prediction convertion?' }
					</Col>
				</Row>
			</Modal>
			<Spin fullscreen spinning={data.loading} />

		</Content>
	);
}