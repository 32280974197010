import React, { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../../Context';

import { Row, Col, Typography, Button, Space, Form, Input, Modal, Tooltip, Select, DatePicker, Table, Empty, Spin, message, Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PlusOutlined} from '@ant-design/icons';


import fiberManagementReducer from './reducer';

// import { useFormik } from 'formik';
import axios from 'axios';
import { NodeIndexOutlined, DeleteOutlined, InfoOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import { FIBER_SPLICE_ADD, FIBER_SPLICE_ADD_ERROR, FIBER_SPLICE_ADD_SUCCESS, FIBER_SPLICE_DELETE, FIBER_SPLICE_DELETE_ERROR, FIBER_SPLICE_DELETE_SUCCESS, FIBER_SPLICE_LIST, FIBER_SPLICE_LIST_ERROR, FIBER_SPLICE_LIST_SUCCESS } from './constants';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

const { Title } = Typography;
const dateFormat = 'YYYY-MM-DD';

export default function FiberManagement() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const initalState = {fiber_splice_data : [], pending: false, error: false, fiber_splice_data_pagination: { total: 0, limit: 10, skip: 0, current:1 }, };
	const [data, dispatch] = useReducer(fiberManagementReducer, initalState);
	const [showAddModal, setShowAddModal] = useState(false);
	const [date, setDate] = useState();
	const [pidsId, setPidsId] = useState('all');
	const [status, setStatus] = useState(null);
	const [filterDate, setFilterDate] = useState(new Date());
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [apiPidsId, setApiPidsId] = useState(null);
	const [channelId, setChannelId] = useState(null);
	const [startChainage, setStartChainage] = useState(null);
	const [endChainage, setEndChainage] = useState(null);
	const [odMeter, setODMeter] = useState(null);
	const [fiberLength, setFiberLength] = useState(null);
	const [startODMeter, setStartOdMeter] = useState(null);
	const [endODMeter, setEndOdMeter] = useState(null);

	const {getPids, pidsData, getChannels, channelData, getPipelineConfig, pipelineConfig} = useAuth();
	const navigate = useNavigate();
	// const [form] = Form.useForm();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const pidsList = [];
	const statusOptions = [
		{ value: null, label: langdata && langdata.ALL ? langdata.ALL :  'ALL'}, 
		{ value: 'Completed', label: langdata && langdata.COMPLETED ? langdata.COMPLETED : 'COMPLETED'},
		{ value: 'Failed', label: langdata && langdata.FAILED ? langdata.FAILED : 'FAILED'},
		{ value: 'New', label: langdata && langdata.NEW ? langdata.NEW : 'NEW'},
		{ value: 'Skipped', label: langdata && langdata.SKIPPED ? langdata.SKIPPED : 'SKIPPED'},
	];
	const channelList = [];
	const odMeterList = [];
	const pipelineChainage = [];
	const pidsListFilter = [];
	const columns = [
		{
			title: langdata && langdata.PIDSName ? langdata.PIDSName : 'PIDS Name',
			dataIndex: 'pidsName',
			key: 'pidsName',
		},
		{
			title: langdata && langdata.ChannelName ? langdata.ChannelName :  'Channel Name',
			dataIndex: 'channelName',
			key: 'channelName',
		},
		{
			title: langdata && langdata.StartPipelineChainage ? langdata.StartPipelineChainage : 'Start Pipeline Chainage',
			dataIndex: 'pipelineChainageFrom',
			key: 'pipelineChainageFrom',
		},
		{
			title: langdata && langdata.EndPipelineChainage ? langdata.EndPipelineChainage : 'End Pipeline Chainage',
			dataIndex: 'pipelineChainageTo',
			key: 'pipelineChainageTo',
		},
		{
			title: langdata && langdata.ODMeter ? langdata.ODMeter : 'OD Meter',
			dataIndex: 'ODMeter',
			key: 'ODMeter',
		},
		// {
		// 	title: 'Fiber length (in Meter)',
		// 	dataIndex: 'ODMeter',
		// 	key: 'ODMeter',
		// },
		{
			title: langdata && langdata.LengthoffiberaddedinMeter ? langdata.LengthoffiberaddedinMeter : 'Length of fiber added (in Meter)',
			dataIndex: 'lengthDifferenceInMetre',
			key: 'lengthDifferenceInMetre',
		},
		{
			title: langdata && langdata.DateandTime ? langdata.DateandTime : 'Date and Time',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: langdata && langdata.Status ? langdata.Status : 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (record) => (
				<Space size="middle">
					<a title={langdata && langdata.Deletefibersplicedetails ? langdata.Deletefibersplicedetails : 'Delete fiber splice details'} disabled =  {record.status != 'New' ? true : false} onClick={record.status == 'New' ? () => openDeleteModal(record.id) : null}><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	pidsListFilter.push({ value : 'all', label : langdata && langdata.ALL ? langdata.ALL : 'ALL'});

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name,
		});
		pidsListFilter.push({
			value : item.id,
			label : item.name,
		});
	});

	channelData && channelData.map((item) => {
		channelList.push({
			value : item.channelId,
			label : item.name
		});
	});

	pipelineConfig && pipelineConfig.map((item) => {
		odMeterList.push({
			value : item.ODMeter,
			label : item.ODMeter
		});
		pipelineChainage.push({
			value : item.pipelineChainage,
			label : item.pipelineChainage.toString(),
			odMeter : item.ODMeter
		});
	});

	const openDeleteModal = (value) => {
		setDeleteId(value);
		setShowDeleteModal(true);
	};

	const openAddModal = () => {
		setShowAddModal(true);
	};

	const cloaseModal = () => {
		setShowAddModal(false);
		setShowDeleteModal(false);
		setApiPidsId(null);
		setChannelId(null);
		setStartChainage(null);
		setEndChainage(null);
		setODMeter(null);
		setFiberLength(null);
		setDate(null);
	};


	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 49);
		getPids(authtoken);
	}, [authtoken]);

	useEffect(() => {
		loadFiberSplice(1);
	},[pidsId, status, filterDate]);

	const handleStatusFilter = (e) => {
		setStatus(e);
	};

	const loadFiberSplice = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.fiber_splice_data_pagination.total ? data.fiber_splice_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		dispatch({ type : FIBER_SPLICE_LIST });

		let params = '';
		let filterByStatusPart = '';

		if(pidsId !== 'all') {
			params+=`&pidsinfoId=${pidsId}`;
		}

		if (status && status !== null) {
			filterByStatusPart = `&status=${status}`;
		} else {
			filterByStatusPart = '';
		}

		if(filterDate) {
			const startdatetime = moment.utc(filterDate.setHours(0,0,0,0)).toISOString();
			const enddatetime = moment.utc(filterDate.setHours(23,59,59,999)).toISOString();
			params += `&spliceDate[$gte]=${startdatetime}&spliceDate[$lte]=${enddatetime}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/fibresplice-list?$limit=${_pagination.limit}&$skip=${_pagination.skip}&${filterByStatusPart}&$sort[id]=-1${params}`,
			timeout: timeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 ) {
					dispatch({ type : FIBER_SPLICE_LIST_SUCCESS, payload : response.data,  page: pagination.current, pageSize : pagination.pageSize  });
				} else {
					dispatch ({ type : FIBER_SPLICE_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch ({ type : FIBER_SPLICE_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const handlePids = (value) => {
		getChannels(authtoken, value);
		setApiPidsId(value);
		setChannelId(null);
		setStartChainage(null);
		setEndChainage(null);
		setODMeter(null);
	};

	const handleChannel = (value) => {
		getPipelineConfig(authtoken, apiPidsId, value);
		setChannelId(value);
		setStartChainage(null);
		setEndChainage(null);
		setODMeter(null);
	};

	const handleStartChainage = (data) => {
		setStartChainage(data.value);
		setStartOdMeter(data.odMeter);
	};

	const handleEndChainage = (data) => {
		setEndChainage(data.value);
		setEndOdMeter(data.odMeter);
	};

	const handleODMeter = (value) => {
		setODMeter(value.target.value);
	};

	const handleFiberLength = (value) => {
		setFiberLength(value.target.value);
	};
	
	// const formik = useFormik({
	// 	enableReinitialize: true,
	// 	initialValues: {
	// 		pidsinfoId: null,
	// 		channelId: null,
	// 		odmeter: null,
	// 		dateTime : null,
	// 		addedLength : null,
	// 		startChainage : null,
	// 		endChainage : null,
	// 		startODMeter : null,
	// 		endODMeter : null
	// 	},
	// 	onSubmit: (values) => {

	// 		const {pidsinfoId, channelId, odmeter, addedLength, startChainage, endChainage, startODMeter, endODMeter} = values;

	// 		if (!pidsinfoId) {
	// 			Modal.warning({ title: 'Please select the pids' });
	// 			return false;
	// 		}

	// 		if (!channelId) {
	// 			Modal.warning({ title: 'Please select the channel' });
	// 			return false;
	// 		}

	// 		if (!odmeter) {
	// 			Modal.warning({ title: 'Please enter the fiber' });
	// 			return false;
	// 		}

	// 		if (!date) {
	// 			Modal.warning({ title: 'Please select the date and time' });
	// 			return false;
	// 		}

	// 		if (!addedLength) {
	// 			Modal.warning({ title: 'Please enter the fiber length' });
	// 			return false;
	// 		}

	// 		if (startChainage == null) {
	// 			Modal.warning({ title: 'Please select the start chainage' });
	// 			return false;
	// 		}

	// 		if (endChainage == null) {
	// 			Modal.warning({ title: 'Please select the end chainage' });
	// 			return false;
	// 		}

	// 		if (startChainage > endChainage) {
	// 			Modal.warning({title : 'End chainage should be greater than start chainage'});
	// 			return false;
	// 		}

	// 		if(parseFloat(startODMeter) > parseFloat(odmeter) || parseFloat(endODMeter) < parseFloat(odmeter) ) {
	// 			Modal.warning({title : 'Please enter a valid OD meter value.'});
	// 			return false;
	// 		}

	// 		dispatch({ type : FIBER_SPLICE_ADD });

	// 		let data = JSON.stringify({
	// 			'pidsinfoId': pidsinfoId,
	// 			'channelId': channelId,
	// 			'ODMeter': odmeter,
	// 			'lengthDifferenceInMetre': addedLength,
	// 			'spliceDate': moment.utc(date).toISOString(),
	// 			'status': 'New',
	// 			'pipelineChainageFrom' : startChainage,
	// 			'pipelineChainageTo' : endChainage
	// 		});

	// 		let config = {
	// 			method: 'post',
	// 			maxBodyLength: Infinity,
	// 			url: `${api}/fibresplice`,
	// 			headers: { 
	// 				'Content-Type': 'application/json', 
	// 				'Authorization': `Bearer ${authtoken}`
	// 			},
	// 			data : data
	// 		};

	// 		axios.request(config)
	// 			.then((response) => {
	// 				if(response.status == 200 || response.status == 201) {
	// 					dispatch({ type : FIBER_SPLICE_ADD_SUCCESS });
	// 					loadFiberSplice(1);
	// 					Modal.success({
	// 						title: 'Fiber splice added successfully.',
	// 					});  
	// 				} else {
	// 					dispatch({ type : FIBER_SPLICE_ADD_ERROR });
	// 					Modal.warning({
	// 						title: 'Unable to added fiber splice.',
	// 					});	
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.log(error);
	// 				dispatch({ type : FIBER_SPLICE_ADD_ERROR });
	// 			});
	// 		setShowAddModal(false);
	// 	}
	// });

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleDate = (e) => {
		setDate(new Date(e && e.$d));
	};

	const handleDateChange = (date) => {
		setFilterDate(new Date(date && date.$d));
	};

	const deleteFiberSplice = () => {

		const id = parseInt(deleteId);
		
		dispatch({ type : FIBER_SPLICE_DELETE });

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/fibresplice/${id}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			}
		};

		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({type: FIBER_SPLICE_DELETE_SUCCESS, payload: response});
					setShowDeleteModal(false);   
					loadFiberSplice(1);     
					Modal.success({
						title: langdata && langdata.Fibersplicesuccessfullydeleted ? langdata.Fibersplicesuccessfullydeleted : 'Fiber splice successfully deleted.' ,
					});  
				} else {
					dispatch({type:FIBER_SPLICE_DELETE_ERROR});  
					Modal.warning({
						title: langdata && langdata.Unabletodeletefibersplice ? langdata.Unabletodeletefibersplice : 'Unable to delete fiber splice.' ,
					});	
				}
			})
			.catch((error) => {
				dispatch({type:FIBER_SPLICE_DELETE_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	// const handleFormValuesChange = (changedValues) => {
	// 	const formFieldName = Object.keys(changedValues)[0];
	// 	if (formFieldName === 'pidsinfoId') {
	// 		//   setSelectedCompanyId(changedValues[formFieldName]);  // perform setState here
	// 		form.setFieldsValue({channelId: undefined});
	// 		form.setFieldsValue({startChainage: undefined});
	// 		form.setFieldsValue({endChainage: undefined}); 
	// 		form.setFieldValue({odmeter : undefined });
	// 	}
	
	// 	if (formFieldName === 'channelId') {
	// 		//   setSelectedCompanyId(changedValues[formFieldName]);  // perform setState here
	// 		form.setFieldsValue({startChainage: undefined});
	// 		form.setFieldsValue({endChainage: undefined}); 
	// 		form.setFieldValue({odmeter : null });
	// 	}
	// };

	const handleSave = () => {
		if (!apiPidsId) {
			Modal.warning({ title: langdata && langdata.Pleaseselectthepids ? langdata.Pleaseselectthepids : 'Please select the pids' });
			return false;
		}

		if (!channelId) {
			Modal.warning({ title:  langdata && langdata.Pleaseselectthechannel ? langdata.Pleaseselectthechannel : 'Please select the channel' });
			return false;
		}

		if (!odMeter) {
			Modal.warning({ title: langdata && langdata.Pleaseenterthefiber ? langdata.Pleaseenterthefiber : 'Please enter the fiber' });
			return false;
		}

		if (!date) {
			Modal.warning({ title: langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime : 'Please select the date and time' });
			return false;
		}

		if (!fiberLength) {
			Modal.warning({ title: langdata && langdata.Pleaseenterthefiberlength ? langdata.Pleaseenterthefiberlength : 'Please enter the fiber length' });
			return false;
		}

		if (startChainage == null) {
			Modal.warning({ title: langdata && langdata.Pleaseselectthestartchainage ? langdata.Pleaseselectthestartchainage : 'Please select the start chainage' });
			return false;
		}

		if (endChainage == null) {
			Modal.warning({ title: langdata && langdata.Pleaseselecttheendchainage ? langdata.Pleaseselecttheendchainage : 'Please select the end chainage' });
			return false;
		}

		if (startChainage > endChainage) {
			Modal.warning({title :  langdata && langdata.Endchainageshouldbegreaterthanstartchainage ? langdata.Endchainageshouldbegreaterthanstartchainage : 'End chainage should be greater than start chainage'});
			return false;
		}

		if(parseFloat(startODMeter) > parseFloat(odMeter) || parseFloat(endODMeter) < parseFloat(odMeter) ) {
			Modal.warning({title : langdata && langdata.PleaseenteravalidODmetervalue ? langdata.PleaseenteravalidODmetervalue : 'Please enter a valid OD meter value.'});
			return false;
		}

		dispatch({ type : FIBER_SPLICE_ADD });

		let data = JSON.stringify({
			'pidsinfoId': apiPidsId,
			'channelId': channelId,
			'ODMeter': parseFloat(odMeter),
			'lengthDifferenceInMetre': parseFloat(fiberLength),
			'spliceDate': moment.utc(date).toISOString(),
			'status': 'New',
			'pipelineChainageFrom' : startChainage,
			'pipelineChainageTo' : endChainage
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/fibresplice`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 || response.status == 201) {
					dispatch({ type : FIBER_SPLICE_ADD_SUCCESS });
					loadFiberSplice(1);
					Modal.success({
						title: langdata && langdata.Fiberspliceaddedsuccessfully ? langdata.Fiberspliceaddedsuccessfully : 'Fiber splice added successfully.',
					});  
				} else {
					dispatch({ type : FIBER_SPLICE_ADD_ERROR });
					Modal.warning({
						title: langdata && langdata.Unabletoaddedfibersplice ? langdata.Unabletoaddedfibersplice : 'Unable to added fiber splice.',
					});	
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : FIBER_SPLICE_ADD_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		cloaseModal();
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.FiberSpliceInfo ? langdata.FiberSpliceInfo : 'This module helps to add fibre cut and maintenance informations, like location, chainage details, length of the fibre added etc to the pipeline configuration.'}</p>
		</Card>
	);

	return (

		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
				}}
				>

					<Col span={6}>
						<Row>
							<Space>
								<NodeIndexOutlined style={{fontSize:'30px'}}/>
								&nbsp;
								<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.FiberSplicing ? langdata.FiberSplicing : 'Fiber Splicing'}</Title>
							</Space>
						</Row>
					</Col>
					
					<Col span={18}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbypids ? langdata.Filterbypids : 'Filter by pids'}>
									<Space>
										<Select Item
											showSearch
											filterOption={filterOption}
											style={{ width: '150px', margin:'5px'}}
											placeholder={langdata && langdata.SelectPids ? langdata.SelectPids : 'Select Pids'}
											optionFilterProp="children"
											// value={regionId}
											defaultValue={'all'}
											options={pidsListFilter}
											onSelect={(e)=>setPidsId(e)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbystatus ? langdata.Filterbystatus : 'Filter by status'}>
									<Space>
										<Select
											style={{ width: '150px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus : 'Select Status'}
											optionFilterProp="children"
											defaultValue={null}
											options={statusOptions}
											onSelect={(e, options)=>handleStatusFilter(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbycreateddate ? langdata.Filterbycreateddate : 'Filter by created date'}>
									<Space>
										<DatePicker
											allowClear={false} format={settingsdateformat}
											style={{minWidth: '200px', maxWidth:'200px', margin:'5px'}}
											defaultValue={dayjs(moment().format('YYYY-MM-DD'), dateFormat)}
											onChange={handleDateChange}
											disabledDate={disabledDate}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title="Add">
									<Space>
										<Button type="primary" onClick={openAddModal} style={{margin:'5px'}} ><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.FiberSplicing ? langdata.FiberSplicing : 'Fiber Splicing'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data && data.fiber_splice_data.length > 0 ? (
							<Table
								scroll={{
									x: 900,
								}} 
								columns={columns} 
								dataSource={data && data.fiber_splice_data} 
								pagination = { data.fiber_splice_data.length > 0 ? data.fiber_splice_data_pagination  : 0}
								onChange={loadFiberSplice}
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>
			</Content>

			<Spin fullscreen spinning={data.pending} />
      
			{/* Add Fiber Management Starts */}
			<Modal
				title={langdata && langdata.AddFiberSpliceDetails ? langdata.AddFiberSpliceDetails : 'Add Fiber Splice Details'}
				centered
				open={showAddModal}
				onOk={{cloaseModal}}
				onCancel={cloaseModal}
				destroyOnClose= {true}
				maskClosable= {false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={cloaseModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={handleSave}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={800}
			>
				<Form layout="vertical">
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.PIDS ? langdata.PIDS : 'PIDS'} name ='pids' rules={[{ required: true }]}> 
								<Select
									// name="pidsinfoId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.PIDS ? langdata.PIDS : 'PIDS'}
									optionFilterProp="children"
									options={pidsList}
									onSelect={handlePids}
									// onChange={(value) => {formik.setFieldValue('pidsinfoId', value);}}
									value={apiPidsId}
								/>												
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.Channeldetails ? langdata.Channeldetails : 'Channel details'} name='channel' rules={[{ required: true }]}>
								<Select
									// name="channelId" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.Channeldetails ? langdata.Channeldetails : 'Channel details'}
									optionFilterProp="children"
									onSelect={handleChannel}
									options={channelList}
									// onChange={(value) => {formik.setFieldValue('channelId', value);}}
									value={channelId}
								/>											
							</Form.Item>
						</Col>
					</Row>
					
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.StartPipelineChainage ? langdata.StartPipelineChainage : 'Start Pipeline Chainage'} name='startChainage' rules={[{ required: true, message: langdata && langdata.StartPipelineChainage ? langdata.StartPipelineChainage : 'Start pipeline chainage' }]} >
								{/* <Input type="number" name="odmeter" onChange={formik.handleChange} placeholder="Please enter the fiber length" /> */}
								<Select
									// name="startChainage" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.StartPipelineChainage ? langdata.StartPipelineChainage : 'Start pipeline chainage'}
									optionFilterProp="children"
									options={pipelineChainage}
									// onChange={(value, item) => {formik.setFieldValue('startChainage', value); formik.setFieldValue('startODMeter', item.odMeter);}}
									onSelect={(value, item) => handleStartChainage(item)}
									value={startChainage}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.EndPipelineChainage ? langdata.EndPipelineChainage : 'End Pipeline Chainage'} name='endChainage' rules={[{ required: true, message: langdata && langdata.EndPipelineChainage ? langdata.EndPipelineChainage : 'End pipeline chainage' }]} >
								{/* <Input type="number" name="odmeter" onChange={formik.handleChange} placeholder="Please enter the fiber length" /> */}
								<Select
									// name="endChainage" 
									showSearch
									filterOption={filterOption}
									placeholder={langdata && langdata.EndPipelineChainage ? langdata.EndPipelineChainage : 'End pipeline chainage'}
									optionFilterProp="children"
									options={pipelineChainage}
									// onChange={(value, item) => {formik.setFieldValue('endChainage', value); formik.setFieldValue('endODMeter', item.odMeter);}}
									onSelect={(value, item) => handleEndChainage(item)}
									value={endChainage}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.FiberlengthODinMeter ? langdata.FiberlengthODinMeter : 'Fiber length (OD in Meter)'} name='odmeter' rules={[{ required: true, message: 'Please enter the fiber length' }]} >
								<Input type="number" onChange={(value) => handleODMeter(value)}  placeholder={langdata && langdata.PleaseenterthefiberlengthODinmeter ? langdata.PleaseenterthefiberlengthODinmeter : 'Please enter the fiber length (OD in meter)'} value={odMeter} />
								{/* <Select
									name="odmeter" 
									showSearch
									filterOption={filterOption}
									placeholder="OD Meter"
									optionFilterProp="children"
									options={odMeterList}
									onChange={(value, item) => {formik.setFieldValue('odmeter', value); formik.setFieldValue('odmeter', item.label);}}
								/> */}
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.DateandTime ? langdata.DateandTime : 'Date and Time'} name='dateTime' rules={[{ required: true, message: langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time' }]} >
								<DatePicker
									allowClear={false}
									// format="YYYY-MM-DD HH:mm:ss" 
									format={settingsdateformat + ' ' + settingstimeformat}
									disabledDate={disabledDate}
									style={{
										width: '100%',
									}}
									name="dateTime"
									placeholder={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}
									showTime={{
										hideDisabledOptions: true,
										defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
										disabledHours: () => [],
										disabledMinutes: () => [],
										disabledSeconds: () => [],
									}}
									onChange={handleDate}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item label={langdata && langdata.LengthoffiberaddedinMeter ? langdata.LengthoffiberaddedinMeter :'Length of fiber added (in Meter)'} name='length' rules={[{ required: true, message: langdata && langdata.Pleaseenterthelengthoffiberadded ? langdata.Pleaseenterthelengthoffiberadded :'Please enter the length of fiber added' }]} >
								<Input type="number" onChange={handleFiberLength} placeholder={langdata && langdata.Pleaseenterthelengthoffiberadded ? langdata.Pleaseenterthelengthoffiberadded :'Please enter the length of fiber added'}/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete :'Confirm delete'} open={showDeleteModal} onOk={deleteFiberSplice} onCancel={cloaseModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={cloaseModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={deleteFiberSplice}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>
						{langdata && langdata.Areyousureyouwanttodeletethisfibersplice ? langdata.Areyousureyouwanttodeletethisfibersplice : 'Are you sure you want to delete this fiber splice?'}
					</Col>
				</Row>
			</Modal>
		</>
	);
}