import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../Context';

import { Empty, Row, Col, Select, DatePicker, Typography, Space, Table, Spin, Tabs, Divider, Button, Modal, Tooltip, Badge, message, Popover, Avatar, Card} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { EyeOutlined, RedoOutlined, InfoOutlined } from '@ant-design/icons';

import { MARKED_FOR_RETRAINING_LIST, MARKED_FOR_RETRAINING_LIST_SUCCESS, MARKED_FOR_RETRAINING_LIST_ERROR, RETAINED_LIST, RETAINED_LIST_SUCCESS, RETAINED_LIST_ERROR, RETRAINED_DETAILS_SUCCESS, INPROGRESS_LIST, INPROGRESS_LIST_SUCCESS, INPROGRESS_LIST_ERROR, RELATED_ALARMS, RELATED_ALARMS_ERROR, RELATED_ALARMS_SUCCESS} from './constants';
import { APPROVED_LIST, APPROVED_LIST_SUCCESS, APPROVED_LIST_ERROR  } from './constants';
import { REJECTED_LIST, REJECTED_LIST_SUCCESS, REJECTED_LIST_ERROR   } from './constants';
import { MARKED_FOR_RETRAINING_DETAILS, MARKED_FOR_RETRAINING_DETAILS_SUCCESS } from './constants';
import { APPROVED_DETAILS_SUCCESS } from './constants';
import { REJECTED_DETAILS_SUCCESS } from './constants';

import retrainingDataReducer from './reducer';

import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import activityicons from '../activitytypes';

const { Title } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

export default function RetrainingData() {
	let start = dayjs().subtract(1, 'days').format('YYYY-MM-DD');
	let end = dayjs().format('YYYY-MM-DD');

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData,  getPids, pidsData} = useAuth();
	const { state } = useLocation();
	const [regionId, setRegionId] = useState(null);
	const [modelOpen, setModelOpen] = useState(false);
	const [retrainStatusId, setRetrainStatusId] = useState(state && state.data.retrainStatus ? state && state.data.retrainStatus : 0);
	const [retrainComment, setRetrainComment ] = useState('');
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];

	const navigate = useNavigate();

	const [dates, setDates] = useState({ start: start, end: end });

	const initalState = { markedForRetraining: [], approved: [], rejected: [], related_alarm :[], 
		markedForRetrainingPagination: { total: 0, limit: 10, skip: 0, current:1 }, approvedPagination: { total: 0, limit: 10, skip: 0, current:1 }, 
		rejectedPagination: { total: 0, limit: 10, skip: 0, current:1 }, details: null, loading: false, error: false, markedForRetrainingDetails : null,
		retraingData : null, approvedData : null, rejectedData : null, retrainedPagination: { total: 0, limit: 10, skip: 0, current:1 }, retrained : null,
		inProgress : [], inProgressPagination: { total: 0, limit: 10, skip: 0, current:1 }
	};
	const [data, dispatch] = useReducer(retrainingDataReducer, initalState);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);

	const [allocationId, setAllocationId] = useState(null);

	let pidsList = [];
	const [pids, setPids] = useState(null);
	const [tabValue, setTabValue] = useState(state && state.data.retrainStatus ? state && state.data.retrainStatus : 0);
	const [filterStartDate, setStartFilterDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 7));
	const [filterEndDate, setEndFilterDate] = useState(new Date());
	const [alarmdetails, setAlarmDetails] = useState({});

	const [related_alarms_mark_retrain, setRelatedMarkRetrainAlarms] = useState([]);
	const [related_alarms_approved_retrain, setRelatedApprovedRetrainAlarms] = useState([]);
	const [related_alarms_rejected_retrain, setRelatedRejectedRetrainAlarms] = useState([]);
	const [related_alarms_retrained_retrain, setRelatedRetrainedRetrainAlarms] = useState([]);
	const [activeExpRow, setActiveExpRow] = useState([]);

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	let related_alarms_datas = [];

	if(tabValue==0){
		if(related_alarms_mark_retrain.length > 0){
			related_alarms_mark_retrain.map( (item,index) => {
				let data = {};
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}
	
				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;
	
				related_alarms_datas.push(data);
			});
		}else{
			related_alarms_datas = [];
		}
	}else if (tabValue==1){
		if(related_alarms_approved_retrain.length > 0){
			related_alarms_approved_retrain.map( (item,index) => {
				let data = {};
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;
	
				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';
	
				related_alarms_datas.push(data);
			});
		}else{
			related_alarms_datas = [];
		}
	}else if (tabValue==2){
		if(related_alarms_rejected_retrain.length > 0){
			related_alarms_rejected_retrain.map( (item,index) => {
				let data = {};
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;

				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				related_alarms_datas.push(data);
			});
		}else{
			related_alarms_datas = [];
		}
	}else if (tabValue==3 || tabValue ==4){
		if(related_alarms_retrained_retrain.length > 0){
			related_alarms_retrained_retrain.map( (item,index) => {
				let data = {};
				const date = moment.utc(item.datetime).toDate();
				date.toString();
				const newdate = moment(date, 'YYYY-MM-DD').format(settingsdateformat);
				const newTime = moment(date, 'HH:mm:ss').format(settingstimeformat);
				const resolutiontime = moment.utc(item.resolutiontime).toDate();
				date.toString();
				const newResolutiontime = moment(resolutiontime, 'YYYY-MM-DD').format(settingsdateformat);
				data.key= index.toString();
				data.id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.alarm_prm_id = item.alarm_prm_id ? item.alarm_prm_id : 0;
				data.eventdate = item.datetime ? newdate + ' ' + newTime : null;
				data.resolutiontime = item.resolutiontime ? newResolutiontime : null;
				data.actualevent = item.actualEvent ? item.actualEvent : '<Not Set>';
				data.typeofEvent = item.resolutionDetails.predictedEvent ? item.resolutionDetails.predictedEvent : null;
				data.resolutionId = item.resolutionId ? item.resolutionId : null;
				data.resolutionStatus = item.status ? item.status : null;
				data.remarks = item.resolutionDetails.remarks ? item.resolutionDetails.remarks : null;
				data.pidname = item.pidname ? item.pidname : null;
				data.alarmSeverity = item.alarmSeverity;
				data.resolutionuser = item.resolutionuser;
				data.pidsinfoId = item.pidsinfoId;
				data.inProgressStatus = item.inProgressStatus;
				if (data.typeofEvent == null) {
					data.typeofEvent = data.actualevent;
				}
				data.eventType = item.eventType ? item.eventType : '-';
				data.locationDetails = item.locationDetails ? item.locationDetails : '-';
				data.regioninfoId = item.regioninfoId;
				data.channel_name = item.channel_name;
				data.Chainage = item.locationDetails && item.locationDetails.info ? item.locationDetails.info : '-';
				data.odMeter = item.odMeter;
				data.duration = item.duration;
				data.eventprobablity = item.eventprobablity;

				data.createdAt = item.createdAt ? item.createdAt : '';
				data.updatedAt = item.updatedAt ? item.updatedAt : '';

				related_alarms_datas.push(data);
			});
		}else{
			related_alarms_datas = [];
		}
	}

	useEffect(()=>{

		if (Object.keys(alarmdetails).length !== 0){

			if(alarmdetails && alarmdetails.id != undefined || alarmdetails && alarmdetails.id !== null || alarmdetails && alarmdetails.id != 'undefined'){
				
				// data.loading =  true;
				dispatch({ type: RELATED_ALARMS });
				let data = JSON.stringify({
					id: alarmdetails.id,
					pidsinfoId: alarmdetails.pidsinfoId,
					regioninfoId:null,
					allocationId: null,
				
				});
		
				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${api}/relatedalarm`,
					timeout: timeout,
					headers: { 
						'Content-Type': 'application/json', 
						'Authorization': `Bearer ${authtoken}`
					},
					data : data
				};
			
				axios.request(config)
					.then((response) => {
						if (response.status === 200) {
							dispatch({ type : RELATED_ALARMS_SUCCESS});
	
							if(tabValue==0){
								setRelatedMarkRetrainAlarms(response.data);
							}else if (tabValue==1){
								setRelatedApprovedRetrainAlarms(response.data);
							}else if (tabValue==2){
								setRelatedRejectedRetrainAlarms(response.data);
							}else if (tabValue==3 || tabValue ==4){
								setRelatedRetrainedRetrainAlarms(response.data);
							}
							
						}else {
							dispatch({ type : RELATED_ALARMS_ERROR});
						}
					})
					.catch((error) => {
						console.log(error);
						dispatch({ type : RELATED_ALARMS_ERROR});
						if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
							message.config({
								duration : 5,
								maxCount : 1,
							});
							message.open({
								type: 'error',
								content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
							});
						}
					});
	
			}
		}
		
		

	},[alarmdetails]);

	const columns = [
		{
			title: `${langdata && langdata.AlarmId ? langdata.AlarmId:'Alarm Id'}`,
			dataIndex: 'alarm_prm_id',
			key: 'alarm_prm_id',
			render: (text, record) => (
				<Space size="middle">
					{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
					record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>
					}
					<text>{text}</text>
				</Space>
			),			
		},
		{
			title: `${langdata && langdata.PIDSName ? langdata.PIDSName:'PIDS Name'}`,
			dataIndex: 'pidname',
			key: 'pidname',
		},
		{
			title: `${langdata && langdata.AlarmDate ? langdata.AlarmDate:'Alarm Date'}`,
			dataIndex: 'eventdate',
			key: 'eventdate',
		},
		{
			title: `${tabValue == 0 ? `${langdata && langdata.MarkedForRetrainDate ? langdata.MarkedForRetrainDate:'Marked For Retrain Date'}` : tabValue == 1 ? `${langdata && langdata.ApprovedDate ? langdata.ApprovedDate:'Approved Date'}` : tabValue == 2 ? `${langdata && langdata.RejectedDate ? langdata.RejectedDate:'Rejected Date'}` : tabValue == 4 ? `${langdata && langdata.InProgressDate ? langdata.InProgressDate:'In Progress Date'}` : `${langdata && langdata.RetrainedDate ? langdata.RetrainedDate:'Retrained Date'}`}`,
			dataIndex: 'resolutiontime',
			key: 'resolutiontime',
		},
		{
			// Display actual event if null
			title: `${langdata && langdata.ReportedEvent ? langdata.ReportedEvent:'Reported Event'}`,
			dataIndex: 'typeofEvent',
			key: 'typeofEvent',
		},
		{
			title: `${langdata && langdata.ActualEvent ? langdata.ActualEvent:'Actual Event'}`,
			dataIndex: 'actualevent',
			key: 'actualevent',
		},
		tabValue==4 ?
			{
				title: `${langdata && langdata.RetrainingStatus ? langdata.RetrainingStatus:'Retraining Status'}`,
				dataIndex: 'inProgressStatus',
				key: 'inProgressStatus',
				render: (record) =>  (
					record == 0 ? <Badge color="yellow" text={langdata && langdata.BackupInitiated ? langdata.BackupInitiated:'Backup Initiated'} /> :
						record == 1 ? <Badge color="blue" text={langdata && langdata.BackupStarted ? langdata.BackupStarted:'Backup Started'} /> :
							record == 2 ? <Badge color="green" text={langdata && langdata.BackupCompleted ? langdata.BackupCompleted:'Backup Completed'} /> :
								record == 3 ? <Badge color="red" text={langdata && langdata.BackupFailed ? langdata.BackupFailed:'Backup Failed'} /> :
									record == 4 ? <Badge color="purple" text={langdata && langdata.NotEnoughDataSamples ? langdata.NotEnoughDataSamples:'Not Enough Data Samples'} /> :
										record == 5 ? <Badge color="yellow" text={langdata && langdata.DataCopyingInitiated ? langdata.DataCopyingInitiated:'Data Copying Initiated'} /> :
											record == 6 ? <Badge color="blue" text={langdata && langdata.DataCopyingStarted ? langdata.DataCopyingStarted:'Data Copying Started'} /> :
												record == 7 ? <Badge color="green" text={langdata && langdata.DataCopyingCompleted ? langdata.DataCopyingCompleted:'Data Copying Completed'} /> :
													record == 8 ? <Badge color="red" text={langdata && langdata.DataCopyingFailed ? langdata.DataCopyingFailed:'Data Copying Failed'} /> :
														<Badge color="red" text={langdata && langdata.Failed ? langdata.Failed:'Failed'} />
				),
				filters: [
					{
						text: `${langdata && langdata.BackupInitiated ? langdata.BackupInitiated:'Backup Initiated'}`,
						value: '0',
					},
					{
						text: `${langdata && langdata.BackupStarted ? langdata.BackupStarted:'Backup Started'}`,
						value: '1',
					},
					{
						text: `${langdata && langdata.BackupCompleted ? langdata.BackupCompleted:'Backup Completed'}`,
						value: '2',
					},
					{
						text: `${langdata && langdata.BackupFailed ? langdata.BackupFailed:'Backup Failed'}`,
						value: '3',
					},
					{
						text: `${langdata && langdata.NotEnoughDataSamples ? langdata.NotEnoughDataSamples:'Not Enough Data Samples'}`,
						value: '4',
					},
					{
						text: `${langdata && langdata.DataCopyingInitiated ? langdata.DataCopyingInitiated:'Data Copying Initiated'}`,
						value: '5',
					},
					{
						text: `${langdata && langdata.DataCopyingStarted ? langdata.DataCopyingStarted:'Data Copying Started'}`,
						value: '6',
					},
					{
						text: `${langdata && langdata.DataCopyingCompleted ? langdata.DataCopyingCompleted:'Data Copying Completed'}`,
						value: '7',
					},
					{
						text: `${langdata && langdata.DataCopyingFailed ? langdata.DataCopyingFailed:'Data Copying Failed'}`,
						value: '8',
					},
				],
				onFilter: (value, record) => record.inProgressStatus == value,
			} : 
			{ width : '1%'},
		{
			title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
			key: 'action',
			align:'center',
			render: (_,record) => (
				<Space size="middle">
					<a title={langdata && langdata.ViewDetails ? langdata.ViewDetails:'View Details'} onClick={()=>{tabValue == 0 ? loadMarkedForRetrainingDataDetails(record) : tabValue == 1 ? 
						loadApprovedDataDetails(record) : tabValue == 2 ? loadRejectedDataDetails(record) : tabValue == 3 ? loadRetrainedDataDetails(record) : tabValue == 4 ? loadRetrainedDataDetails(record) : '' ;}} ><EyeOutlined /></a>
				</Space>
			)
		}
		
	];

	const retrainStatus = [ {
		value : 0,
		label : `${langdata && langdata.Retrain ? langdata.Retrain:'Retrain'}`
	},
	{
		value : 1,
		label : `${langdata && langdata.Approved ? langdata.Approved:'Approved'}`
	},
	{
		value : 2,
		label : `${langdata && langdata.Rejected ? langdata.Rejected:'Rejected'}`
	}
	];

	let regions = [{ value: null, label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	const handleChangeRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`, e);
		if(admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setRegionId(options.region);
			setAllocationId(e);
		}
		
	};

	const handleDateChange = (date, dateString) => {

		setStartFilterDate(new Date(date && date[0] && date[0].$d));
		setEndFilterDate(new Date(date && date[1] && date[1].$d));

		let start = dayjs(dateString[0]).format('YYYY-MM-DD');
		let end = dayjs(dateString[1]).format('YYYY-MM-DD');

		setDates({ start: start, end: end});

	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	useEffect(() => {
		if(!authtoken){
			navigate('/');
		}
		getPids(authtoken);
	},[authtoken]);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 1131);
	}, []);

	useEffect(() => {
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}

		if(tabValue==0){
			loadMarkedForRetrainingData(1);
		}
		else if(tabValue==1){
			loadApprovedData(1);

		}
		else if(tabValue==2){
			loadRejectedData(1);

		}
		else if(tabValue==3){
			loadRetrainedData(1);

		}
		else if(tabValue==4){
			loadInprogressData(1);

		}

		// loadMarkedForRetrainingData(1);
		// loadApprovedData(1);
		// loadRejectedData(1);
		// loadRetrainedData(1);
		// loadInprogressData(1);
	}, [regionId,allocationId,tabValue]);

	useEffect(() => {

		if(tabValue==0){
			loadMarkedForRetrainingData(1);
		}
		else if(tabValue==1){
			loadApprovedData(1);

		}
		else if(tabValue==2){
			loadRejectedData(1);

		}
		else if(tabValue==3){
			loadRetrainedData(1);

		}
		else if(tabValue==4){
			loadInprogressData(1);

		}

		// loadMarkedForRetrainingData(1);
		// loadApprovedData(1);
		// loadRejectedData(1);
		// loadRetrainedData(1);
		// loadInprogressData(1);
	}, [dates, pids, tabValue]);

	pidsList.push({ value : null, label : `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`});

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});

	pidsList.sort((a,b) => { return a.value - b.value; });
  
	const loadMarkedForRetrainingData = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.markedForRetrainingPagination.total ? data.markedForRetrainingPagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		let url = `${api}/alarm?$limit=${_pagination.limit}&$skip=${_pagination.skip}&sort[createdAt]=-1&sort[eventdate]=-1&sort[eventtime]=-1&isparent=true`;

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: url,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data: {
				'isEnabledForReTraining': true,
				'eventchannel': 'all',
				'eventstartdate': state && state.data.utc_startdate ? state && state.data.utc_startdate : moment.utc(filterStartDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				'eventenddate': state && state.data.utc_enddate ? state && state.data.utc_enddate : moment.utc(filterEndDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				'starteventtime': state && state.data.utc_starttime ? state && state.data.utc_starttime+':00.000000' : `${moment.utc(filterStartDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				'endeventtime': state && state.data.utc_endtime ? state && state.data.utc_endtime+':00.000000' : `${moment.utc(filterEndDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				'retrainStatus': 0,
				'region_id': regionId,
				'allocationId': allocationId,
				'pidsId': pids
			}
		};

		dispatch({ type: MARKED_FOR_RETRAINING_LIST });

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: MARKED_FOR_RETRAINING_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize });
			} else {
				dispatch( { type: MARKED_FOR_RETRAINING_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: MARKED_FOR_RETRAINING_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
				});
			}
		});
	};

	const loadApprovedData = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.approvedPagination.total ? data.approvedPagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		let url = `${api}/alarm?$limit=${_pagination.limit}&$skip=${_pagination.skip}&sort[createdAt]=-1&sort[eventdate]=-1&sort[eventtime]=-1&isparent=true`;

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: url,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data: {
				'isEnabledForReTraining': true,
				'eventchannel': 'all',
				'eventstartdate': state && state.data.utc_startdate ? state && state.data.utc_startdate : moment.utc(filterStartDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				'eventenddate': state && state.data.utc_enddate ? state && state.data.utc_enddate : moment.utc(filterEndDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				'starteventtime': state && state.data.utc_starttime ? state && state.data.utc_starttime+':00.000000' : `${moment.utc(filterStartDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				'endeventtime': state && state.data.utc_endtime ? state && state.data.utc_endtime+':00.000000' : `${moment.utc(filterEndDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				'retrainStatus': 1,
				'region_id': regionId,
				'allocationId': allocationId,
				'pidsId': pids
			}
		};

		dispatch({ type: APPROVED_LIST });

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: APPROVED_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: APPROVED_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: APPROVED_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
				});
			}
		});
	};

	const loadRejectedData = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.rejectedPagination.total ? data.rejectedPagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		let url = `${api}/alarm?$limit=${_pagination.limit}&$skip=${_pagination.skip}&sort[createdAt]=-1&sort[eventdate]=-1&sort[eventtime]=-1&isparent=true`;

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: url,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data: {
				'isEnabledForReTraining': true,
				'eventchannel': 'all',
				'eventstartdate': state && state.data.utc_startdate ? state && state.data.utc_startdate : moment.utc(filterStartDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				'eventenddate': state && state.data.utc_enddate ? state && state.data.utc_enddate : moment.utc(filterEndDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				'starteventtime': state && state.data.utc_starttime ? state && state.data.utc_starttime+':00.000000' : `${moment.utc(filterStartDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				'endeventtime': state && state.data.utc_endtime ? state && state.data.utc_endtime+':00.000000' : `${moment.utc(filterEndDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				'retrainStatus': 2,
				'region_id': regionId,
				'allocationId': allocationId,
				'pidsId': pids
			}
		};

		dispatch({ type: REJECTED_LIST });

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: REJECTED_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize });
			} else {
				dispatch( { type: REJECTED_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: REJECTED_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
				});
			}
		}); 
	};

	const loadRetrainedData = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.retrainedPagination.total ? data.retrainedPagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		let url = `${api}/alarm?$limit=${_pagination.limit}&$skip=${_pagination.skip}&sort[createdAt]=-1&sort[eventdate]=-1&sort[eventtime]=-1&isparent=true`;

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: url,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data: {
				'isEnabledForReTraining': true,
				'eventchannel': 'all',
				'eventstartdate': state && state.data.utc_startdate ? state && state.data.utc_startdate : moment.utc(filterStartDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				'eventenddate': state && state.data.utc_enddate ? state && state.data.utc_enddate : moment.utc(filterEndDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				'starteventtime': state && state.data.utc_starttime ? state && state.data.utc_starttime+':00.000000' : `${moment.utc(filterStartDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				'endeventtime': state && state.data.utc_endtime ? state && state.data.utc_endtime+':00.000000' : `${moment.utc(filterEndDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				'retrainStatus': 3,
				'region_id': regionId,
				'allocationId': allocationId,
				'pidsId': pids
			}
		};

		dispatch({ type: RETAINED_LIST });

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: RETAINED_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: RETAINED_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: RETAINED_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
				});
			}
		}); 
	};

	const loadInprogressData = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.retrainedPagination.total ? data.retrainedPagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		let url = `${api}/alarm?$limit=${_pagination.limit}&$skip=${_pagination.skip}&sort[createdAt]=-1&sort[eventdate]=-1&sort[eventtime]=-1&isparent=true`;

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: url,
			timeout: extendTimeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data: {
				'isEnabledForReTraining': true,
				'eventchannel': 'all',
				'eventstartdate': state && state.data.utc_startdate ? state && state.data.utc_startdate : moment.utc(filterStartDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				'eventenddate': state && state.data.utc_enddate ? state && state.data.utc_enddate : moment.utc(filterEndDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				'starteventtime': state && state.data.utc_starttime ? state && state.data.utc_starttime+':00.000000' : `${moment.utc(filterStartDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				'endeventtime': state && state.data.utc_endtime ? state && state.data.utc_endtime+':00.000000' : `${moment.utc(filterEndDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				'retrainStatus': 4,
				'region_id': regionId,
				'allocationId': allocationId,
				'pidsId': pids
			}
		};

		dispatch({ type: INPROGRESS_LIST });

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: INPROGRESS_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize });
			} else {
				dispatch( { type: INPROGRESS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: INPROGRESS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
				});
			}
		}); 
	};

	const loadMarkedForRetrainingDataDetails = ((record) =>  {
		setModelOpen(true);
		// let details = null;
		activityicons && activityicons.map((eventactivity)=>{
			if(record.eventType==eventactivity.type){
				// data = record;
				record.activityUrl= eventactivity.type && record.eventType==eventactivity.type ? eventactivity.severity[record.alarmSeverity] : '-';
			}});
		dispatch({ type: MARKED_FOR_RETRAINING_DETAILS });

		// if (data && data.markedForRetrainingPagination) {
		// 	data.markedForRetraining && data.markedForRetraining.map((item) => {
		// 		if (item.id === id) {
		// 			details = item;
		// 		}
		// 	});
		// 	data.retraingData && data.retraingData.map((item) => {
		// 		if (item.id === id) {
		// 			setRetraingNavData(item);
		// 		}
		// 	});
		dispatch({ type: MARKED_FOR_RETRAINING_DETAILS_SUCCESS, payload: record});
		// } else {
		// 	dispatch({ type: MARKED_FOR_RETRAINING_DETAILS_ERROR });
		// }
	});

	const loadApprovedDataDetails = ((record) =>  {
		setModelOpen(true);
		// let details = null;

		// dispatch({ type: APPROVED_DETAILS });

		// if (data && data.approved) {
		// 	data.approved && data.approved.map((item) => {
		// 		if (item.id === id) {
		// 			details = item;
		// 		}
		// 	});
		// 	data.approvedData && data.approvedData.map((item) => {
		// 		if (item.id === id) {
		// 			setRetraingNavData(item);
		// 		}
		// 	});
		activityicons && activityicons.map((eventactivity)=>{
			if(record.eventType==eventactivity.type){
				// data = record;
				record.activityUrl= eventactivity.type && record.eventType==eventactivity.type ? eventactivity.severity[record.alarmSeverity] : '-';
			}});
		dispatch({ type: APPROVED_DETAILS_SUCCESS, payload: record });
		// } else {
		// 	dispatch({ type: APPROVED_DETAILS_ERROR });
		// }
	});

	const loadRejectedDataDetails = ((record) =>  {
		setModelOpen(true);
		// let details = null;

		// dispatch({ type: REJECTED_DETAILS });

		// if (data && data.rejected) {
		// 	data.rejected && data.rejected.map((item) => {
		// 		if (item.id === id) {
		// 			details = item;
		// 		}
		// 	});
		// 	data.rejectedData && data.rejectedData.map((item) => {
		// 		if (item.id === id) {
		// 			setRetraingNavData(item);
		// 		}
		// 	});
		activityicons && activityicons.map((eventactivity)=>{
			if(record.eventType==eventactivity.type){
				// data = record;
				record.activityUrl= eventactivity.type && record.eventType==eventactivity.type ? eventactivity.severity[record.alarmSeverity] : '-';
			}});
		dispatch({ type: REJECTED_DETAILS_SUCCESS, payload: record });
		// } else {
		// 	dispatch({ type: REJECTED_DETAILS_ERROR });
		// }
	});

	const loadRetrainedDataDetails = ((record) =>  {
		setModelOpen(true);
		// let details = null;

		// dispatch({ type: REJECTED_DETAILS });

		// if (data && data.rejected) {
		// 	data.rejected && data.rejected.map((item) => {
		// 		if (item.id === id) {
		// 			details = item;
		// 		}
		// 	});
		// 	data.rejectedData && data.rejectedData.map((item) => {
		// 		if (item.id === id) {
		// 			setRetraingNavData(item);
		// 		}
		// 	});
		activityicons && activityicons.map((eventactivity)=>{
			if(record.eventType==eventactivity.type){
				// data = record;
				record.activityUrl= eventactivity.type && record.eventType==eventactivity.type ? eventactivity.severity[record.alarmSeverity] : '-';
			}});
		dispatch({ type: RETRAINED_DETAILS_SUCCESS, payload: record });
		// } else {
		// 	dispatch({ type: REJECTED_DETAILS_ERROR });
		// }
	});

	

	const handleSelectRetrainStatus = (value) => {
		setRetrainStatusId(value);
	};

	const handleTab = (value) => {

		setActiveExpRow([]);
		related_alarms_datas = [];
		setRetrainStatusId(value);
		setRetrainComment('');
		setTabValue(value);
	};

	const handleRejectReason = (e) => {
		setRetrainComment(e.target.value);
	};

	const handleUpdate = (value) => {
		setModelOpen(false);

		let data = JSON.stringify({
			'retrainStatus': parseInt(retrainStatusId),
			'retrainComment': retrainComment,
			'isSynced': false
		});

		let config = {
			method: 'patch',
			maxBodyLength: Infinity,
			url: `${api}/resolution/${value}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					Modal.success({
						title: `${langdata && langdata.Successfullyupdated ? langdata.Successfullyupdated:'Successfully updated'}`,
					});
					loadMarkedForRetrainingData(1);
					loadApprovedData(1);
					loadRejectedData(1);
				} 
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});

	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handlePids = (value) => {
		setPids(value);
	};


	const expandedRowRender = () => {
		if(related_alarms_datas.length > 0){

			const columns = [
				{
					title: `${langdata && langdata.AlarmId ? langdata.AlarmId:'Alarm Id'}`,
					dataIndex: 'alarm_prm_id',
					key: 'alarm_prm_id',
					render: (text, record) => (
						<Space size="middle">
							{ record  && 
					<span>
						<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
							record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
						width: '3px', height: '20px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
					</span>
							}
							<text>{text}</text>
						</Space>
					),
				},
				{
					title: `${langdata && langdata.AlarmDate ? langdata.AlarmDate:'Alarm Date'}`,
					dataIndex: 'eventdate',
					key: 'eventdate',
				},
				{
				// Display actual event if null
					title: `${langdata && langdata.ReportedEvent ? langdata.ReportedEvent:'Reported Event'}`,
					dataIndex: 'typeofEvent',
					key: 'typeofEvent',
				},
				{
					title: `${langdata && langdata.ActualEvent ? langdata.ActualEvent:'Actual Event'}`,
					dataIndex: 'actualevent',
					key: 'actualevent',
				},
				{
					title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
					key: 'action',
					align:'center',
					render: (_,record) => (
						<Space size="middle">
							<a title={langdata && langdata.ViewDetails ? langdata.ViewDetails:'View Details'} onClick={()=>{tabValue == 0 ? loadMarkedForRetrainingDataDetails(record) : tabValue == 1 ? 
								loadApprovedDataDetails(record) : tabValue == 2 ? loadRejectedDataDetails(record) : tabValue == 3 ? loadRetrainedDataDetails(record) : tabValue == 4 ? loadRetrainedDataDetails(record) :'' ;}} ><EyeOutlined /></a>
						</Space>
					)
				}
				
				
			];

			return <Table onRow={() => ({
				style: {
					background: '#faf7f0',
				}
			})} columns={columns} dataSource={related_alarms_datas} pagination={false} 
			scroll={{
				y: 240,
			}} />;
		}else{
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

		}
		
	};

	const handleRealtedAlarm = (e,options) =>{
		setRelatedMarkRetrainAlarms([]);
		setRelatedApprovedRetrainAlarms([]);
		setRelatedRejectedRetrainAlarms([]);
		setRelatedRetrainedRetrainAlarms([]);
		activeExpRow === options.key ? setActiveExpRow() : setActiveExpRow(options.key);
		if(e==true){
			setAlarmDetails({id : options.alarm_prm_id, pidsinfoId: options.pidsinfoId});
		}
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.retraindatacontent1 ? langdata.retraindatacontent1:'This module helps the user  to manage resolved alarms that are marked for retraining. Retrain data can be managed as Approved and Rejected before the system consider it for retraining the machine learning models.Module also shows the status of alarm data, whether in progress or retrained.'}</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >

					<Col span={6}>
						<Row>

							<Space>
								<RedoOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.RetrainingData ? langdata.RetrainingData:'Retraining Data'}</Title>
						</Row>
					</Col>

					<Col style={{ display : 'flex', justifyContent : 'right'}} span={18} >
						<Row>
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion:'Filter by region'}>
									<Space>

										<Select
											style={{ minWidth: '180px', maxWidth:'180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion:'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											// value={regionId}
											options={regions}
											onSelect={(e, options)=>handleChangeRegion(e, options)}
										/>

									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}>
									<Space>
										<Select 
											style={{ minWidth: '180px', maxWidth:'180px', margin:'5px'}}
											defaultValue={null}
											options={pidsList}
											onSelect={handlePids}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Selectdate ? langdata.Selectdate:'Select date'}>
									<Space>
										<RangePicker 
											allowClear={false}
											style={{ margin:'5px'}} disabledDate={disabledDate} format={settingsdateformat}
											defaultValue={[dayjs(state && state.data.utc_startdate ? state && state.data.utc_startdate : new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 7)),dayjs(state && state.data.utc_enddate ? state && state.data.utc_enddate : new Date())]}
											onChange={handleDateChange}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Space>
									<Typography>
										<Popover title={langdata && langdata.RetrainingData ? langdata.RetrainingData:'Retraining Data'}  content={infoContent} trigger="click">
											<Avatar size="small"><InfoOutlined /></Avatar>
										</Popover>
									</Typography>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Tabs 
					onChange={handleTab}
					defaultActiveKey = {retrainStatusId.toString()}
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
					}} >
					<TabPane tab={langdata && langdata.MarkedforRetraining ? langdata.MarkedforRetraining:'Marked for Retraining'} key="0" >
						<Row>
							<Col span={24}>
								{ data.markedForRetraining && data.markedForRetraining.length > 0 ? (
									<Table 
										scroll={{
											x: 900,
										}}
										columns = { columns }
										dataSource = { data.markedForRetraining }
										pagination = { data.markedForRetraining.length > 0 ? data.markedForRetrainingPagination  : 0}
										onChange = { loadMarkedForRetrainingData }
										expandable={{
											expandedRowKeys: activeExpRow,
											expandedRowRender,
											onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
											// onClick:handleRealtedAlarm
										}}
										// onRow={(record) => {
										// 	return {
										// 		onClick: () => { 
										// 			loadMarkedForRetrainingDataDetails(record.id);
										// 		}
										// 	};
										// }} 
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
					</TabPane>
					<TabPane tab={langdata && langdata.Approved ? langdata.Approved:'Approved'} key="1">
						<Row>
							<Col span={24}>
								{ data.approved && data.approved.length > 0 ? (
									<Table 
										scroll={{
											x: 900,
										}}
										columns = { columns }
										dataSource = { data.approved } 
										pagination = { data.approved.length > 0 ? data.approvedPagination : 0}
										onChange = { loadApprovedData }
										expandable={{
											expandedRowKeys: activeExpRow,
											expandedRowRender,
											onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
											// onClick:handleRealtedAlarm
										}}
										// onRow={(record) => {
										// 	return {
										// 		onClick: () => { 
										// 			loadApprovedDataDetails(record.id);
										// 		}
										// 	};
										// }} 
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
					</TabPane>
					<TabPane tab={langdata && langdata.Rejected ? langdata.Rejected:'Rejected'} key="2">
						<Row>
							<Col span={24}>
								{ data.rejected && data.rejected.length > 0 ? (
									<Table 
										scroll={{
											x: 900,
										}}
										columns={columns} 
										dataSource={data.rejected} 
										pagination = {data.rejected.length > 0 ? data.rejectedPagination : 0}
										onChange = { loadRejectedData }
										expandable={{
											expandedRowKeys: activeExpRow,
											expandedRowRender,
											onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
											// onClick:handleRealtedAlarm
										}}
										// onRow = {(record) => {
										// 	return {
										// 		onClick: () => { 
										// 			loadRejectedDataDetails(record.id);
										// 		}
										// 	};
										// }} 
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
					</TabPane>
					<TabPane tab={langdata && langdata.InProgress ? langdata.InProgress:'In Progress'} key="4">
						<Row>
							<Col span={24}>
								{ data.inProgress && data.inProgress.length > 0 ? (
									<Table 
										scroll={{
											x: 900,
										}}
										columns={columns} 
										dataSource={data.inProgress} 
										// pagination = {data.inProgress.length > 0 ? data.inProgressPagination : 0}
										onChange = { loadInprogressData } 
										expandable={{
											expandedRowKeys: activeExpRow,
											expandedRowRender,
											onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
											// onClick:handleRealtedAlarm
										}}
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
					</TabPane>
					<TabPane tab={langdata && langdata.Retrained ? langdata.Retrained:'Retrained'} key="3">
						<Row>
							<Col span={24}>
								{ data.retrained && data.retrained.length > 0 ? (
									<Table 
										scroll={{
											x: 900,
										}}
										columns={columns} 
										dataSource={data.retrained} 
										pagination = {data.retrained.length > 0 ? data.retrainedPagination : 0}
										onChange = { loadRetrainedData } 
										expandable={{
											expandedRowKeys: activeExpRow,
											expandedRowRender,
											onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
											// onClick:handleRealtedAlarm
										}}
									/>
								)
									:
									(
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
							</Col>
						</Row>
					</TabPane>
					
				</Tabs>
			</Content>
			<Modal
				open={modelOpen}
				title={langdata && langdata.Details ? langdata.Details:'Details'}
				// onOk={handleModelOk}
				onCancel={() => {setModelOpen(false);}}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Button type="primary" onClick={() => {navigate(( '/alarm-details'),{ state: { data: data.markedForRetrainingDetails && data.markedForRetrainingDetails, path: 'Retraining Data' } });}}>
						{langdata && langdata.Moreinfo ? langdata.Moreinfo:'More Info'}
					</Button>,
					
					// eslint-disable-next-line react/jsx-key
					tabValue === retrainStatusId || tabValue == 3 ? null :
						<Button type="primary" onClick={() => {handleUpdate(data.markedForRetrainingDetails && data.markedForRetrainingDetails.resolutionId);}}>
							{langdata && langdata.Update ? langdata.Update:'Update'}
						</Button>
				]}
			>
				<Divider/>
				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}>
					<Col span={24}>          
						<Typography>{langdata && langdata.Id ? langdata.Id:'Id'}: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.id}</Typography>
						<Typography>{langdata && langdata.AlarmDate ? langdata.AlarmDate:'Alarm date'}: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.eventdate}</Typography>
						{/* <Typography>Time: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.eventtime  }</Typography> */}
						<Typography>{langdata && langdata.MarkedForRetrainingDate ? langdata.MarkedForRetrainingDate:'Marked for retraining date'}: <b>{data.markedForRetrainingDetails && data.markedForRetrainingDetails.resolutiontime}</b></Typography>
						<Typography>{langdata && langdata.ReportedEvent ? langdata.ReportedEvent:'Reported event'}: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.typeofEvent}</Typography>
						<Typography>{langdata && langdata.ActualEvent ? langdata.ActualEvent:'Actual event'}: <b>{data.markedForRetrainingDetails && data.markedForRetrainingDetails.actualevent}</b></Typography>
						<Typography>{langdata && langdata.ResolutionID ? langdata.ResolutionID:'Resolution ID'}: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.resolutionId}</Typography>
						<Typography>{langdata && langdata.Resolutionstatus ? langdata.Resolutionstatus:'Resolution status'}: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.resolutionStatus}</Typography>
						<Typography>{langdata && langdata.Resolutionaddedby ? langdata.Resolutionaddedby:'Resolution added by'}: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.resolutionuser} </Typography>
						<Typography>{langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}: {data.markedForRetrainingDetails && data.markedForRetrainingDetails.remarks}</Typography>
						<br/>
						{tabValue == 3 || tabValue == 4 ? null :  
							<Typography>{langdata && langdata.Action ? langdata.Action:'Action'}: <Select style={{ width : '50%' }} options={retrainStatus} value={parseInt(retrainStatusId)} onSelect={handleSelectRetrainStatus}></Select></Typography>
						}
						{parseInt(retrainStatusId) === 2 ?
							<>
								<br/>
								<Typography>{langdata && langdata.Rejectreason ? langdata.Rejectreason:'Reject reason'}:</Typography>
								<TextArea rows={4} style={{ width : '100' }} onChange={handleRejectReason}/>
							</>
							: null }
					</Col>
        
				</Row>
			</Modal>

			<Spin spinning={data.loading} fullscreen tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg:'This process will take 2-3 minutes to complete. Please wait a moment.'}/>
		</>
	);
}