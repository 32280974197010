/* eslint-disable react/react-in-jsx-scope */
import { createContext, useContext, useState } from 'react';
import axios from 'axios';

export const LoginContext = createContext();


const LoginContextProvider = (props) => {

	const [regiondata, setRegionData] =useState();
	const [mapdata, setMapData] =useState();
	const [eventsData, setEventsData] = useState();
	const [pidsData, setPidsData] = useState();
	const [channelData, setChannelData] = useState();
	const [userTypeData, setUserTypeData] = useState();
	const [optionalpipedata, setMapOptionalPipelineData] = useState();
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const [getUserAllocationData, setUserAllocationData] = useState();
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const [pipelineConfig, setPipielineConfig] = useState();
	const [pidsRegionData, setPidsRegionData] = useState();
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	
	const handleGetMapdata = (token,regioninfoId,start,end) =>{
		const regionparams = `&regioninfoId=${regioninfoId}`;
		const pipelineChainageparams=`&pipelineChainage[$gte]=${start}&pipelineChainage[$lte]=${end}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipelineconfig?$limit=10000&$sort[pipelineChainage]=1${ regioninfoId && regioninfoId ? regionparams:''}${ end && end || end==0 ? pipelineChainageparams:''}`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setMapData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	const getRegion =(token) =>{
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/regioninfo?$limit=1000`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: {
      
			}
		};
      
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setRegionData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getAllEvents =(token) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/allevents?$sort[eventType]=1`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					setEventsData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getPids =(token) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pidsinfo-list?$limit=1000&pipelineId=${pipelineId}`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					setPidsData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getPidsRegion =(token,regionId) => {
		
		// let params = regionId ? `&regioninfoId=${regionId}` : '';
		let allocationParams = '';
		if(admin_roles.includes(permission)){
			// getRegion(authtoken);
			allocationParams = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			// getUserAllocation(authtoken);
			allocationParams = regionId ? `&allocationId=${regionId}` : '';
		}
		let url = '';
		if(admin_roles.includes(permission)){
			url = `/pidsinfo-regionfilter?$limit=1000&pipelineId=${pipelineId}${allocationParams}`;
		} else {
			url = `/pidsinfo-list?$limit=1000&pipelineId=${pipelineId}${allocationParams}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}${url}`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					if(admin_roles.includes(permission)){
						setPidsRegionData(response.data);
					} else {
						setPidsRegionData(response.data.data);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getChannels =(token, pidsId) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/channelinfo?pidsinfoId=${pidsId}`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
  
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					setChannelData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
  
	};

	const getUserTypes = (token) => {
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/usertype?id[$ne]=1`,
			headers: { 
				'Authorization': `Bearer ${token}`
			}
		};
      
		axios.request(config)
			.then((response) => {
				setUserTypeData(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
      
	};

	const handleOptionalPipelinedata = (token,pipelineId,regioninfoId,start,end) =>{

		const pipelineChainageparams=`&pipelineChainage[$gte]=${start}&pipelineChainage[$lte]=${end}`;
		const regionparams = `&regioninfoId=${regioninfoId}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/patchpipelineconfig?$limit=100000&$sort[pipelineChainage]=1${ regioninfoId && regioninfoId ? regionparams:''}&pipelineId=${pipelineId}${ end && end || end == 0 ? pipelineChainageparams:''}`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: {
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setMapOptionalPipelineData(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	const getUserAllocation = (token) => {

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url:  `${api}/userallocation?userId=${localStorage.getItem(`${dashboardtype.toUpperCase()}_USER_ID`)}`,
			headers: { 
				Authorization:`Bearer ${token}`  
			}
		};

		axios.request(config)
			.then((response) => {
				setUserAllocationData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});

	};


	const getPipelineConfig = (token, pidsinfoId, channelId) =>{
		const pidsinfoIdparams=`&pidsinfoId=${pidsinfoId}`;
		const channelIdparams = `&channelId=${channelId}`;

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/pipelineconfig?$limit=10000&$sort[pipelineChainage]=1${ pidsinfoId && pidsinfoId ? pidsinfoIdparams : ''}${ channelId && channelId  ? channelIdparams : ''}`,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${token}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					setPipielineConfig(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	return (
		<LoginContext.Provider value={{getRegion, regiondata, getAllEvents, eventsData, getPids, pidsData, getChannels, channelData,
			// eslint-disable-next-line react/prop-types
			handleGetMapdata, mapdata, getUserTypes, userTypeData, optionalpipedata,handleOptionalPipelinedata, getUserAllocation ,getUserAllocationData, getPipelineConfig, pipelineConfig, pidsRegionData, getPidsRegion}}>{props.children}</LoginContext.Provider>
	);
};

export const useAuth = () => useContext(LoginContext);

export default LoginContextProvider;