import React, { useEffect, useReducer, useState } from 'react';


import { Empty, Row, Col, Select, Typography, Space, Table, DatePicker, Tag, Tooltip, message, Spin, Popover, Avatar, Card} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { EyeOutlined, FundViewOutlined, InfoOutlined} from '@ant-design/icons';
import { useAuth } from '../Context';
import inspectiondata from './reducer';
import { INSPECTION_LIST, INSPECTION_LIST_ERROR, INSPECTION_LIST_SUCCESS, USERS_LIST, USERS_LIST_ERROR, USERS_LIST_SUCCESS } from './constants';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import { datetimeCovertertolocal } from '../datetime-converter';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';


const { Title } = Typography;


const { RangePicker } = DatePicker;


let inspection_data_pagination = {};

export default function Inspection() {

	const { state } = useLocation();
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const initalState = {inspection_data:[],users_data:[], details: null, pending: false, error: false };
	const [inspection_data, dispatch] = useReducer(inspectiondata, initalState);
	const [startDate, setStartDate] = useState(state && state.data.startdatetime ? state && state.data.startdatetime : new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30));
	const [endDate, setEndDate] = useState(state && state.data.enddatetime ? state && state.data.enddatetime : new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate()));
	const navigate = useNavigate();
	const [regionId, setRegionId] = useState(state && state.data.regionId ? state && state.data.regionId : null);
	const [usertypeId, setUserTypeId] = useState('All');
	const [user, setUser] = useState(null);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [allocationId, setAllocationId] = useState(state && state.data.regionId ? state && state.data.regionId : null);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;


	const usertype =[
		{
			value: 'All',
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`,
		},
		{
			value: 'Linewalker',
			label: `${langdata && langdata.LINEWALKER ? langdata.LINEWALKER:'LINEWALKER'}`,
		},
		{
			value: 'NPV',
			label: `${langdata && langdata.NPV ? langdata.NPV:'NPV'}`,
		}
	];



	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(new Date(dates[0] && dates[0].$d));
			setEndDate(new Date(dates[1] && dates[1].$d));
		} else {
			console.log('Clear');
		}
	};	

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const columns = [

		{
			title: `${langdata && langdata.Name ? langdata.Name:'Name'}`,
			dataIndex: 'name',
			key: 'name',
			//   render: (_,text) => <a onClick={showMap}>{text}</a>)

			render: (_, text) => (
				<Space size="middle">
					<a title={langdata && langdata.ViewInspection ? langdata.ViewInspection:'View Inspection'} onClick={()=>{showMap(text);}}>{text.name}</a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)

		},
		{
			title: `${langdata && langdata.Type ? langdata.Type:'Type'}`,
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date:'Date'}`,
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: `${langdata && langdata.StartTime ? langdata.StartTime:'Start Time'}`,
			dataIndex: 'starttime',
			key: 'duration',
		},
		{
			title: `${langdata && langdata.EndTime ? langdata.EndTime:'End Time'}`,
			dataIndex: 'endtime',
			key: 'endtime',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_, { status }) => (
				<>
					<Tag color={status === 'Completed' ?'green' :'red'} key={status}>
						{status.toUpperCase()}
					</Tag>
				</>
			),
		},

		{
			title: `${langdata && langdata.Actions ? langdata.Actions:'Actions'}`,
			key: 'action',
			align:'center',
			render: (_, record) => (
				<Space style={{justifyContent:'center',display:'flex'}} size="middle">
					<a title={langdata && langdata.ViewInspection ? langdata.ViewInspection:'View Inspection'} onClick={()=>{showMap(record);}} ><EyeOutlined/></a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 66);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
			if(admin_roles.includes(permission)){
				getRegion(authtoken);
			}
			else{
				getUserAllocation(authtoken);
			}
			getInspectiondata(1);
			usersList();
		}

	},[authtoken, regionId,usertypeId, user, startDate,endDate, allocationId]);

  

  

	let Inspectiondata = [];

	inspection_data.inspection_data && inspection_data.inspection_data.map((item) => {
		const datetime = datetimeCovertertolocal(item.startTimestamp);
		const endtime = datetimeCovertertolocal(item.endTimestamp);
		// const newdate = datetime.localdate + ' ' +  datetime.localtime;
		Inspectiondata.push({
			key: item.id,
			id: item.id,
			name: item.user.firstName + item.user.lastName,
			type:item.user.permissions,
			date:datetime.localdate,
			starttime: datetime.localtime,
			endtime: endtime.localtime,
			status: item.status,
			regioninfoId:item.regioninfoId,
			userId:item.userId

		});
	});

	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let users=[{value:null, label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}` }];

	inspection_data.users_data && inspection_data.users_data.map((user)=>{
		users.push({
			value:user.id,
			label:user.firstName.toUpperCase()+ ' ' +user.lastName.toUpperCase()

		});
	});


	const showMap = (inspection) => {
		navigate(( '/inspection-details'),{ state: { data: inspection, path: 'inspections' } });
	};



	const getInspectiondata = (pagination) => {
		let _pagination = { };
		_pagination.total = inspection_data_pagination.total ? inspection_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		dispatch({ type : INSPECTION_LIST});

		//for start end date
		let startdate=`${ moment.utc(startDate.setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss')}`;
		let enddate=`${moment.utc(endDate.setHours(23,59,59,999)).format('YYYY-MM-DD HH:mm:ss')}`;

		//for allocation or region
		let allocationParams = '';
		if(admin_roles.includes(permission)){
			allocationParams = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			allocationParams = allocationId ? `&allocationId=${allocationId}` : '';
		}

		//for usertype

		let inspectiontypeParams = '';

		if(usertypeId=='Linewalker'){
			inspectiontypeParams =`&isNightPatrol=${false}`;
		}
		else if (usertypeId=='NPV'){
			inspectiontypeParams = `&isNightPatrol=${true}`;
		}
		else{
			inspectiontypeParams='';
		}

		//for userid

		let userParams = '';
		if(user){
			userParams =`&$and[0][userId]=${user}`;
		}
		else{
			userParams = '';
		}
		let statusParams = state && state.data.params ? state.data.params : '';

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/inspection-list?$limit=${_pagination.limit}&$skip=${_pagination.skip}&startTimestamp[$gte]=${startdate}&endTimestamp[$lte]=${enddate}&$sort[startTimestamp]=-1${allocationParams}${inspectiontypeParams}${userParams}${statusParams}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					inspection_data_pagination.total = response.data.total ? response.data.total : 0;
					inspection_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					inspection_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					inspection_data_pagination.current = pagination.current ? pagination.current : 1;
					dispatch({ type : INSPECTION_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : INSPECTION_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : INSPECTION_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	const usersList = () => {

		dispatch({ type : USERS_LIST});

		const usertypeparams = usertypeId && usertypeId == 'All' ? '&usertypeId[$in][]=4&usertypeId[$in][]=7' : usertypeId=='Linewalker' ? 'usertypeId=4' : usertypeId=='NPV' ? 'usertypeId=7' : '';

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/userallocation-list?${usertypeparams}&isDeleted=false`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : USERS_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : USERS_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : USERS_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	const handleSetregion = (e,options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setRegionId(options.region);
			setAllocationId(e);
			
		}	};

	const handleSetUserType = (e) =>{
		setUserTypeId(e);
	};

	const handleSetUser = (e) =>{
		setUser(e);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.inspectioncontent1 ? langdata.inspectioncontent1 :'Inspections performed using the Security Tracking System (STS) application will be displayed after the inspection has been started.'}</p>
			<br />
			<p>{langdata && langdata.inspectioncontent2 ? langdata.inspectioncontent2 :'By default, details from last 1 month till date are displayed. Filter options available to filter by allocation, user, type and date.'}</p>
		</Card>
	);

	return (
		<>
			<Content>
				<Row
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>
					<Col span={5}>
						<Row>
							<Space>
								<FundViewOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.STSInspections ? langdata.STSInspections :'STS Inspections'}</Title>
						</Row>
					</Col>

					{/* <Col className="gutter-row" span={4}>
						<Title level={3} style={{ lineHeight: '2'}}></Title>
					</Col> */}
					<Col style ={{justifyContent:'right', display:'flex'}} span={19} >
						<Row >

							<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion :'Filter by region'}>
								<Space>
									<Select Item
										style={{ minWidth: '200px', margin:'5px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion :'Select Region'}
										optionFilterProp="children"
										options={regions}
										defaultValue={'ALL'}
										value={ admin_roles.includes(permission) ? regionId : allocationId }
										onSelect={(e, options)=>handleSetregion(e, options)}
									/>
								</Space>
							</Tooltip>
							<Space>&nbsp;&nbsp;</Space>


							<Tooltip placement="bottom" title={langdata && langdata.FilterbyUser ? langdata.FilterbyUser :'Filter by User'}>

								<Space>
									<Select Item
										style={{ minWidth: '200px', margin:'5px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectUsers ? langdata.SelectUsers :'Select Users'}
										optionFilterProp="children"
										// onChange={onChange}
										// onSearch={onSearch}
										// filterOption={filterOption}
										defaultValue={null}
										options={users}
										onSelect={handleSetUser}

									/>  
								</Space>
							</Tooltip>  
							<Space>&nbsp;&nbsp;</Space> 

							<Tooltip placement="bottom" title={langdata && langdata.FilterbyUserType ? langdata.FilterbyUserType :'Filter by User Type'}>
								<Space>

									<Select Item
										style={{ minWidth: '120px', margin:'5px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectUserType ? langdata.SelectUserType :'Select User Type'}
										optionFilterProp="children"
										value={usertypeId}
										onSelect={handleSetUserType}
										options={usertype}
									/>
								</Space>
							</Tooltip>
							<Space>&nbsp;&nbsp;</Space> 

							<Space>


								<RangePicker style={{ width: '220px', margin:'5px'}} onChange={onRangeChange} allowClear={false} format={settingsdateformat}
									defaultValue={[dayjs(state && state.data.startdatetime ? state && state.data.startdatetime : new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30)),dayjs(state && state.data.enddatetime ? state && state.data.enddatetime : new Date())]}

									// defaultValue={[dayjs(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate() - 30)),dayjs(new Date())]}
									disabledDate={disabledDate}
								/>
							</Space>

							<Space>&nbsp;&nbsp;</Space>
							<Space>
								<Typography>
									<Popover title={langdata && langdata.STSInspections ? langdata.STSInspections :'STS Inspections'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Row>
					</Col>
				</Row>

				{/* </Space> */}

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ Inspectiondata.length > 0 ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={Inspectiondata}
								pagination = { Inspectiondata.length > 0 ? inspection_data_pagination  : 0}
								onChange = { getInspectiondata }
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>


			</Content>

			<Spin spinning={inspection_data.pending} fullscreen />

		</>
	);
}