import React, { useEffect, useReducer, useState } from 'react';


import { Empty, Row, Col, Typography, Table, Tag, Space, message, Spin, Form, Modal, Button, Input, Tooltip, Select, Popover, Avatar, Card} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import edgedata from './reducer';
import { EDGE_INFO_LIST, EDGE_INFO_LIST_DETAILS, EDGE_INFO_LIST_ERROR, EDGE_INFO_LIST_SUCCESS } from './constants';
import axios from 'axios';
import { ToolOutlined, EditOutlined, LockOutlined, PlusOutlined, InfoOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import { useAuth } from '../Context';
import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Title } = Typography;


let data_pagination = {};

export default function EdgeManagement() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const initalState = {edge_data:[], details: null, pending: false, error: false };
	const [data, dispatch] = useReducer(edgedata, initalState);
	const navigate = useNavigate();

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [passwordVisible, setPasswordVisible] = React.useState(false);
	const [showAddEdgeManagementModal, setShowAddEdgeManagementModal] = useState(false);
	const {pidsRegionData, getPidsRegion} = useAuth();

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const columns = [

		{
			title: langdata && langdata.EdgeUserName ? langdata.EdgeUserName: 'Edge User Name',
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: langdata && langdata.PIDSName ? langdata.PIDSName:  'PIDS Name',
			dataIndex: 'pidsName',
			key: 'pidsName',
		},
		{
			title: langdata && langdata.IPAddress ? langdata.IPAddress: 'IP Address',
			dataIndex: 'ip',
			key: 'ip',
			//   render: (_,text) => <a onClick={showMap}>{text}</a>)

		},
		permission==='superadmin' ? 

			{
				title: langdata && langdata.Password ? langdata.Password:  'Password',
				dataIndex: 'password',
				key: 'password',
			}: {},
		// {
		// 	title: 'Version',
		// 	dataIndex: 'version',
		// 	key: 'version',
		// },
		{
			title: langdata && langdata.SyncTime ? langdata.SyncTime: 'Sync Time',
			dataIndex: 'synctime',
			key: 'synctime',
		},
		{
			title: langdata && langdata.Status ? langdata.Status: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<>
					<Tag color={status === 'Up' ? 'green' :  'red'} key={status}>
						{status}
					</Tag>
				</>
			),
		},
		permission==='superadmin' ? 
			{
				title: langdata && langdata.Actions ? langdata.Actions: 'Actions',
				key: 'action',
				align: 'center' ,
				render: (_,record) => (
					permission === 'superadmin' ? (
						<Space size="middle">
							<a title={langdata && langdata.Editedgemanagement ? langdata.Editedgemanagement: 'Edit edge management'} onClick={()=>{openEditEdgeManagement(record);}}><EditOutlined/></a>
						</Space>
					) : null
				)
			} : {}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 115);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		else{
			edgemanagementdata(1);
			getPidsRegion(authtoken, null);

		}

	},[authtoken]);

	const pidsList=[];

	pidsRegionData && pidsRegionData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name
		});

	});

	pidsList.sort((a,b) => { return a.value - b.value; });



	const edgemanagementdata = (pagination) => {
		let _pagination = { };
		_pagination.total = data_pagination.total ? data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		dispatch({ type : EDGE_INFO_LIST});

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/systemsinfo-list?pidsinfoId[$ne]=0&$limit=${_pagination.limit}&$skip=${_pagination.skip}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					data_pagination.total = response.data.total ? response.data.total : 0;
					data_pagination.limit = response.data.limit ? response.data.limit : 10;
					data_pagination.skip = response.data.skip ? response.data.skip : 0;
					data_pagination.current = pagination.current ? pagination.current : 1;
					
					dispatch({ type : EDGE_INFO_LIST_SUCCESS, payload : response.data.data});
				}else {
					dispatch({ type : EDGE_INFO_LIST_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : EDGE_INFO_LIST_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};

	const openEditEdgeManagement = (record)=>{
		setIsModalOpen(true);
		dispatch({type:EDGE_INFO_LIST_DETAILS, payload:record});

	};

	const handleOk = () => {
		setIsModalOpen(false);
		setPasswordVisible(false);

	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setPasswordVisible(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());
	
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: data.details && data.details.id,
			userName: data.details && data.details.userName ? data.details.userName : '-' ,
			ip: data.details && data.details.ip ? data.details.ip : '-',
			password: data.details && data.details.password ? data.details.password : '-',
			pidsid: data.details && data.details.pidsid ? data.details.pidsid : '-',
		},
		onSubmit: (values) => {
			const {userName, ip, password, pidsid} = values;
			if(userName == null || ip == null || password == null|| pidsid ==null|| userName == '' || ip == '' || password == '' || pidsid == '') {
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields: 'Please fill all the mandatory fields'});
				return true;
			}
			
			const username = userName;
			const ipaddress = ip;
			const syspassword = password;
			const pidsinfoid = pidsid;

			let data = JSON.stringify({
				userName:username,
				ip: ipaddress,
				password:syspassword,
				pidsinfoId:pidsinfoid,

			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/systemsinfo/${values.id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 ){
						Modal.success({ title: langdata && langdata.Edgemanagementupdatedsuccessfully ? langdata.Edgemanagementupdatedsuccessfully: 'Edge management updated successfully' });
						edgemanagementdata(1);
					} else {
						Modal.warning({ title: langdata && langdata.Edgemanagementconvertionnotupdated ? langdata.Edgemanagementconvertionnotupdated: 'Edge management convertion not updated' });
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});
			setIsModalOpen(false);
		}
	});

	const openAddEdgeManagementModal = () => {
		setShowAddEdgeManagementModal(true);
	};

	const cloaseAddEdgeManagementModal = () => {
		setShowAddEdgeManagementModal(false);
	};


	const addEdgeManagementFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			userName: null,
			ip: null,
			password: null,
			pidsid: null
		},
		onSubmit: (values) => {
			const {userName, ip, password, pidsid} = values;

			if(userName == null || ip == null || password == null|| pidsid ==null|| userName == '' || ip == '' || password == '' || pidsid == '') {
				Modal.warning({title : langdata && langdata.Pleasefillallthemandatoryfields ? langdata.Pleasefillallthemandatoryfields: 'Please fill all the mandatory fields'});
				return true;
			}
			const username = userName;
			const ipaddress = ip;
			const syspassword = password;
			const pidsinfoid = pidsid;

			let data = JSON.stringify({
				userName:username,
				ip:ipaddress,
				password:syspassword,
				pidsinfoId:pidsinfoid,
				isSynced:false,
				pipelineId:parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`))
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/systemsinfo`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 201 ){
						Modal.success({ title: langdata && langdata.Edgemanagementaddedsuccessfully ? langdata.Edgemanagementaddedsuccessfully: 'Edge management added successfully' });
						edgemanagementdata(1);
					} else {
						Modal.warning({ title: langdata && langdata.Edgemanagementnotadded ? langdata.Edgemanagementnotadded: 'Edge management not added' });
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});
			setShowAddEdgeManagementModal(false);
		}
	});

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p> { langdata && langdata.EdgeManagementInfo1 ? langdata.EdgeManagementInfo1: 'Edge Management enables users to view and manage various details, including usernames, passwords, IP addresses, and synchronization times of the edge system.'} </p>
			{ permission === 'superadmin' || permission === 'admin' ?
				<>
					< br/>
					<p> { langdata && langdata.EdgeManagementInfo2 ? langdata.EdgeManagementInfo2: 'Users can add edge details using the "Add" button, with the ability to add details only up to the number of edges available.'}</p> </> : null }
		</Card>
	);

	return (
		<>
			<Content>
				<Row 
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
				>

					<Col span={12}>
						<Row>
							<Space>
								<ToolOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
							<Title level={4} style={{ paddingTop:'10px'}}> { langdata && langdata.EdgeManagement ? langdata.EdgeManagement: 'Edge Management'}</Title>


						</Row>
					
					</Col>

					<Col span={12}>
						<Row justify="end">
							{data && data.edge_data.length < pidsList.length  ?

								<Col>
									<Tooltip placement="bottom" title={ langdata && langdata.Add ? langdata.Add: 'Add'}>
										<Space>
											{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={openAddEdgeManagementModal} ><PlusOutlined/>{ langdata && langdata.AddNew ? langdata.AddNew: 'Add New'}</Button> : null }
										</Space>
									</Tooltip>
								</Col>
								: null }
							<Space>&nbsp;&nbsp;</Space>
							<Space>
								<Typography>
									<Popover title={ langdata && langdata.EdgeManagement ? langdata.EdgeManagement: 'Edge Management'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Row>
					</Col>

					{/* </Space> */}
				</Row>

				<Row span={24} style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24}>
						{ data.edge_data.length > 0 ? (
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={data.edge_data}
								pagination = { data.edge_data.length > 0 ? data_pagination : 0}
								onChange = { edgemanagementdata }
							/>
						)
							:
							(
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
					</Col>
				</Row>

				<Modal title={ langdata && langdata.EditEdgeManagement ? langdata.EditEdgeManagement: 'Edit Edge Management'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose= {true}
					footer={[
						// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={handleCancel}>{ langdata && langdata.CLOSE ? langdata.CLOSE: 'CLOSE'}</Button>
							<Button type="primary" onClick={formik.handleSubmit}>{ langdata && langdata.SAVE ? langdata.SAVE: 'SAVE'}</Button>
						</Space>
					]}
					width={700}
				>
					<Form layout="vertical" 
						onSubmit={formik.handleSubmit} 
					// form={form} 
						// onValuesChange={handleFormValuesChange}
					>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.Username ? langdata.Username: 'User name'} name="userName" rules={[{ required: true }]}>
									<Input
									// name="activity" 
										showSearch
										filterOption={filterOption}
										placeholder={ langdata && langdata.Username ? langdata.Username: 'User name'}
										optionFilterProp="children"
										// options={eventData}
										defaultValue={data.details && data.details.userName}
										onChange={formik.handleChange}
									/>												
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.Pidsname ? langdata.Pidsname: 'Pids name'} name="pidsid" rules={[{ required: true }]}>
									<Select
									// name="activity" 
										showSearch
										filterOption={filterOption}
										placeholder={ langdata && langdata.Pidsname ? langdata.Pidsname: 'Pids name'}
										optionFilterProp="children"
										options={pidsList}
										onChange={(value)=>formik.setFieldValue('pidsid',value)}
										defaultValue={data.details && data.details.pidsid}

									/>												
								</Form.Item>
							</Col>
						</Row>
					
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.IP ? langdata.IP : 'IP'} name="ip" rules={[{ required: true }]}>
									<Input
									// name="activity" 
										showSearch
										filterOption={filterOption}
										placeholder={ langdata && langdata.IP ? langdata.IP : 'IP'}
										optionFilterProp="children"
										// options={eventData}
										defaultValue={data.details && data.details.ip}
										onChange={formik.handleChange}

									/>												
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.Password ? langdata.Password : 'Password'} name="password" rules={[{ required: true }]}>
									<Input.Password
										defaultValue={data.details && data.details.password}
										placeholder={ langdata && langdata.Password ? langdata.Password : 'Password' }
										visibilityToggle={{
											visible: passwordVisible,
											onVisibleChange: setPasswordVisible,
										}} prefix={<LockOutlined /> } 
										onChange={formik.handleChange}


									/>												
								</Form.Item>
							</Col>
						</Row>
						
					</Form>
				</Modal>

				{/* Add edge management modal */}

				<Modal
					title={ langdata && langdata.AddGradeManagement ? langdata.AddGradeManagement : 'Add Grade Management'}
					centered
					destroyOnClose={true}
					maskClosable={false}
					open={showAddEdgeManagementModal}
					onOk={{cloaseAddEdgeManagementModal}}
					onCancel={cloaseAddEdgeManagementModal}
					footer={[
					// eslint-disable-next-line react/jsx-key
						<Space>
							<Button onClick={cloaseAddEdgeManagementModal}>{ langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
							{ permission === 'superadmin' || permission === 'admin' ? <Button type="primary" onClick={addEdgeManagementFormik.handleSubmit}>{ langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button> : null }
						</Space>
					]}
					width={800}
				>
					<Form layout="vertical" onSubmit={addEdgeManagementFormik.handleSubmit}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.Username ? langdata.Username : 'User name'} name="userName" rules={[{ required: true }]}>
									<Input
									// name="activity" 
										showSearch
										filterOption={filterOption}
										placeholder={ langdata && langdata.Username ? langdata.Username : 'User name'}
										optionFilterProp="children"
										// options={eventData}
										onChange={addEdgeManagementFormik.handleChange}
									/>												
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.Pidsname ? langdata.Pidsname : 'Pids name'} name="pidsid" rules={[{ required: true }]}>
									<Select
									// name="activity" 
										showSearch
										filterOption={filterOption}
										placeholder={ langdata && langdata.Pidsname ? langdata.Pidsname : 'Pids name'}
										optionFilterProp="children"
										options={pidsList}
										onChange={(value)=>addEdgeManagementFormik.setFieldValue('pidsid',value)}
									/>												
								</Form.Item>
							</Col>
						</Row>
					
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.IP ? langdata.IP : 'IP'} name="ip" rules={[{ required: true }]}>
									<Input
									// name="activity" 
										showSearch
										filterOption={filterOption}
										placeholder={ langdata && langdata.IP ? langdata.IP : 'IP'}
										optionFilterProp="children"
										// options={eventData}
										onChange={addEdgeManagementFormik.handleChange}

									/>												
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24}>
								<Form.Item label={ langdata && langdata.Password ? langdata.Password : 'Password'} name="password" rules={[{ required: true }]}>
									<Input.Password
										placeholder={ langdata && langdata.Password ? langdata.Password : 'Password'}
										visibilityToggle={{
											visible: passwordVisible,
											onVisibleChange: setPasswordVisible,
										}} prefix={<LockOutlined /> } 
										onChange={addEdgeManagementFormik.handleChange}


									/>												
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>


			</Content>
            
			<Spin spinning={data.pending} fullscreen />
		</>
	);
}