import { FULL_CPS_LIST, FULL_CPS_LIST_SUCCESS, FULL_CPS_LIST_ERROR }  from './constants';

export default function fullCPSReducer(state, action) {
	let cps = [];
  
	switch (action.type) {
	case FULL_CPS_LIST:
		return { ...state, loading: true };

	case FULL_CPS_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? parseInt(item.id) : 0;
				data.pipelineId = item.pipelineId ? item.pipelineId : 0;
				data.pipelineChainage = item.pipelineChainage ?  parseFloat(item.pipelineChainage)/1000 : 0;
				data.deviceId = item.deviceId ?  item.deviceId : 0;
				data.tlpId = item.tlpId ?  item.tlpId : 0;
				data.datetime = item.datetime ? item.datetime : null;
            
				data.vac1 = item.vac1 ? parseFloat(item.vac1).toFixed(2) : 0;
				data.vac2 = item.vac2 ? parseFloat(item.vac2).toFixed(2) : 0;
				data.vdc1 = item.vdc1 ? parseFloat(item.vdc1).toFixed(2) : 0;
				data.vdc2 = item.vdc2 ? parseFloat(item.vdc2).toFixed(2) : 0;

				data.vac1Normal = item.vac1Normal ?(item.vac1Normal) : 0;
				data.vac2Normal = item.vac2Normal ? (item.vac2Normal) : 0;
				data.vdc1Normal = item.vdc1Normal ? (item.vdc1Normal) : 0;
				data.vdc2Normal = item.vdc2Normal ? (item.vdc2Normal): 0;
            
				data.vac1Min = data.vac1Normal ? parseFloat(data.vac1Normal.split(':')[0]).toFixed(2) : 0;
				data.vac1Max = data.vac1Normal ? parseFloat(data.vac1Normal.split(':')[1]).toFixed(2) : 0;
				data.vdc1Min = data.vdc1Normal ? parseFloat(data.vdc1Normal.split(':')[0]).toFixed(2) : 0;
				data.vdc1Max = data.vdc1Normal ? parseFloat(data.vdc1Normal.split(':')[1]).toFixed(2) : 0;
				data.vac2Min = data.vac2Normal ? parseFloat(data.vac2Normal.split(':')[0]).toFixed(2) : 0;
				data.vac2Max = data.vac2Normal ? parseFloat(data.vac2Normal.split(':')[1]).toFixed(2) : 0;
				data.vdc2Min = data.vdc2Normal ? parseFloat(data.vdc2Normal.split(':')[0]).toFixed(2) : 0;
				data.vdc2Max = data.vdc2Normal ? parseFloat(data.vdc2Normal.split(':')[1]).toFixed(2) : 0;

				data.vbat = item.vbat ? parseFloat(item.vbat).toFixed(2) : 0;
				data.temp = item.temp ? parseFloat(item.temp).toFixed(2) : 0;
				data.current = item.current ? parseFloat(item.current).toFixed(2) : 0;

				cps.push(data);
			});

			let sortedCPS = cps.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});
          
			return { ...state, cps: sortedCPS, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, cps: cps, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case FULL_CPS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}