import { datetimeCovertertolocal } from '../datetime-converter';
import { EDGE_INFO_LIST, EDGE_INFO_LIST_DETAILS, EDGE_INFO_LIST_ERROR, EDGE_INFO_LIST_SUCCESS } from './constants';

export default function edgedata(state, action) {

	let edge_data =[];

	switch (action.type) {
      
	case EDGE_INFO_LIST:
		return { ...state, pending: true };
	case EDGE_INFO_LIST_SUCCESS:
		action.payload && action.payload.map((item)=>{

			const datetime = datetimeCovertertolocal(item.syncTime);
			const newdate = datetime.localdate + ' ' +  datetime.localtime;

			edge_data.push({
				id: item.id,
				pidsName:item.pidsinfo.name.toUpperCase(),
				ip:item.ip ? item.ip : '-',
				userName:item.userName ? item.userName : '-',
				version:item.version,
				synctime: item.syncTime ? newdate : '-',
				status:item.status ? 'Up' : item.status == null ? 'Down' : 'Down',
				password:item.password ? item.password : '-',
				pidsid:item.pidsinfo.id,


			});

		});
		return { ...state, edge_data: edge_data, pending: false };
	case EDGE_INFO_LIST_ERROR:
		return { ...state, error: true, pending: false  };
	case EDGE_INFO_LIST_DETAILS:
		return { ...state, details:action.payload, error: true, pending: false  };
      
	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}