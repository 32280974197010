import React, { useEffect, useReducer, useState } from 'react';

import { Avatar, Popover, Card, Row, Col, Select, Typography, Button, Space, Table, Drawer, Form, Input, DatePicker, Tag, Radio, Checkbox, TimePicker, Modal, Spin, Divider, notification, message, Empty } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InfoOutlined, EditOutlined, FilterOutlined} from '@ant-design/icons';


import { Icon } from 'leaflet';
import '../../App.css';
import 'leaflet/dist/leaflet.css';

import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useAuth } from '../Context';
import axios from 'axios';
import { REALTIME_ALARM_DETAILS, REALTIME_ALARM_ERROR, REALTIME_ALARM_LIST, REALTIME_ALARM_MAP_DATA, REALTIME_ALARM_MAP_DATA_ERROR, REALTIME_ALARM_MAP_DATA_SUCCESS, REALTIME_ALARM_SUCCESS, REALTIME_ALARM_UPDATE_DETAILS, REALTIME_PATCH_ALARM, REALTIME_PATCH_ALARM_ERROR, REALTIME_PATCH_ALARM_SUCCESS, RELATED_ALARMS, RELATED_ALARMS_ERROR, RELATED_ALARMS_SUCCESS, RESOLUTION_ALARM, RESOLUTION_ALARM_ERROR, RESOLUTION_ALARM_SUCCESS } from './constants';
import realtimealarmdatareducer from './reducer';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

// import VehicleModal from '../../images/vehicleModal.png';
// import DrumModal from '../../images/drumModal.png';
// import ClampingModal from '../../images/clampingModal.png';
// import mapImg from '../../images/mapImg.png';
// import mapImgDeep from '../../images/ManualDeep.png';
// import mapImgMachine from '../../images/JCBMap.png';
// import mapImgTunneling from '../../images/TunnelingMap.png';
// import modalDefault from '../../images/modalDefault.png';
// import modalMan from '../../images/ManWalking.png';
import eventsicons from '../eventstypes';
import { enddatetimeCoverter, startdatetimeCoverter, datetimeCovertertolocal } from '../datetime-converter';
import { Option } from 'antd/es/mentions';
import TextArea from 'antd/es/input/TextArea';
import Googlemap from '../Map/map';
import dayjs from 'dayjs';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';

const { Search } = Input;

// const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};

// const pidsicon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	// iconUrl:require('../../images/refinery.png'),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]
// });
  
const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]  });

const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});

const defaulticon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/markerimages/default-white.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});

const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});


// const terrain = [
// 	{
// 		value: 'all',
// 		label: 'All'
// 	},
// 	{
// 		value: 'Canal',
// 		label: 'Canal'
// 	},
// 	{
// 		value: 'Forest area',
// 		label: 'Forest area '
// 	},
// 	{
// 		value: 'Drainage',
// 		label: 'Drainage'
// 	},
// 	{
// 		value: 'Railway crossing',
// 		label: 'Railway crossing'
// 	},
// 	{
// 		value: 'Dirt Road',
// 		label: 'Dirt Road'
// 	},
// 	{
// 		value: 'Open Place',
// 		label: 'Open Place'
// 	},
// 	{
// 		value: 'Bricks factory',
// 		label: 'Bricks factory '
// 	},
// 	{
// 		value: 'Footpath',
// 		label: 'Footpath'
// 	},
// 	{
// 		value: 'Residential area',
// 		label: 'Residential area'
// 	},
// 	{
// 		value: 'BARC Society',
// 		label: 'BARC Society'
// 	},
// 	{
// 		value: 'Farm land',
// 		label: 'Farm land'
// 	},
// 	{
// 		value: 'River side',
// 		label: 'River side '
// 	},
// 	{
// 		value: 'Boundry wall',
// 		label: 'Boundry wall'
// 	},
// 	{
// 		value: 'School Ground',
// 		label: 'School Ground'
// 	},
// 	{
// 		value: 'Parking',
// 		label: 'Parking'
// 	},
// 	{
// 		value: 'Building',
// 		label: 'Building'
// 	},
// 	{
// 		value: 'Hilly Area',
// 		label: 'Hilly Area '
// 	},
// 	{
// 		value: 'Road side',
// 		label: 'Road side'
// 	},
// 	{
// 		value: 'Bridge',
// 		label: 'Bridge'
// 	},
// 	{
// 		value: 'Barren Land',
// 		label: 'Barren Land'
// 	},
// 	{
// 		value: 'Barc jungle',
// 		label: 'Barc jungle'
// 	}
// ];

let alarm_data_pagination = {};

export default function AlarmReport() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const { state } = useLocation();
	const [openEditDrawer, setOpenEditDrawer] = useState(false);
	const [getstatus, setStatus] = useState([]);
	const [view, setSize] = useState('List');
	const [filterDrawer, setFilterdrawer] = useState(false);
	const {getRegion, regiondata, handleGetMapdata, mapdata, getAllEvents, eventsData, optionalpipedata,handleOptionalPipelinedata, getUserAllocation ,getUserAllocationData, getPids, pidsData} = useAuth();
	const [regionId, setRegionId] = useState(state && state.data.regionId ? state && state.data.regionId : null);
	const initalState = {relatimealarmdata : [], relatimealarmdata_details:[],status_patch_details:[], details: null, pending: false, error: false, mapdatas : [] };
	const [alarmdata, dispatch] = useReducer(realtimealarmdatareducer, initalState);
	// const [date, setDate] = useState(new Date().toLocaleDateString('en-UK', {
	// 	year: 'numeric',
	// 	month: '2-digit',
	// 	day: '2-digit',
	// }));

	// console.log(date);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const [filterEvent, setEventType] = useState(state && state.data.eventtype ? [state.data.eventtype] : ['all']);
	const [filterRealted, setFilterRelated] = useState('No');
	const [filterStatus, setFilterStatus] = useState(null);
	const [filterSeverity, setFilterSeverity] = useState(state && state.data.severity ||  (state && state.data.severity == '0') ? state.data.severity : 'all');
	// const [filterTerrain, setFilterTerrain] = useState('all');
	const [filterDate, setFilterDate] = useState(state && state.data.date ? state && state.data.date : new Date());
	const [filterStartTime, setFilterStartTIme] = useState(null);
	const [filterEndTime, setFilterEndTIme] = useState(null);
	const [allocationId, setAllocationId] = useState(state && state.data.regionId ? state && state.data.regionId : null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);

	const [resRemarks, setResRemarks] = useState('');
	const [retraincheck, setRetrainCheck] = useState(false);
	const [resvalue, setResValue] = useState(1);
	const [eventname, setEventName] = useState(null);
	const [modelResolutionOpen, setResolution] = useState(false);

	const [sort, setSort] = useState('-1');
	const [modelOpen, setModelOpen] = useState(false);
	const navigate = useNavigate();
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const [checkresids, setCheckResIds] = useState([]);
	const [check, setCheckValue] =useState(false);
	const [allcheck, setAllCheckValue] =useState(false);

	const [bulkmodelResolutionOpen, setBulkResolution] = useState(false);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const [resolutionCompareDate, setResolutionCompareDate] = useState(state && state.data.date ? state && state.data.date : new Date());
	const [date, setDate] = useState(state && state.data.date ? state.data.date : new Date());
	const [startTime, setStartTime] = useState(dayjs('00:00:00', 'HH:mm:ss'));
	const [endTime, setEndTime] = useState(dayjs('23:59:59', 'HH:mm:ss'));
	const [apiBody, setApiBody] = useState(true);
	const [filterPids, setFilterPids] = useState(null);

	const [alarmdetails, setAlarmDetails] = useState({});
	const [relatedAlarms, setRelatedAlarms] = useState([]);
	const [activeExpRow, setActiveExpRow] = useState([]);
	const [showRelatedAlarm, setShowRelatedAlarm] = useState(false);
	const [timeSelection, setTimeSelection] = useState(state && state.data.date ? true : false);
	const [filterLastHour, setFiltetLastHour] = useState(1);
	const [searchValue, setSearchValue] = useState(null);
	const [showSearchValue, setShowSearchValue] = useState(null);
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envRefreshTime = process.env.REACT_APP_REFRESH_TIME == undefined || process.env.REACT_APP_REFRESH_TIME == 'undefined' ? 180000 : process.env.REACT_APP_REFRESH_TIME;
	const refreshTime = localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`) == 'null' ? parseInt(envRefreshTime) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_REFRESH_TIME`));

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;


	const options = [
		{
			value: '-1',
			label: `${langdata && langdata.SortRecentAlarmFirst ? langdata.SortRecentAlarmFirst:'Sort: Recent Alarm First'}`,
		},
		{
			value: '1',
			label: `${langdata && langdata.SortOldAlarmFirst ? langdata.SortOldAlarmFirst:'Sort: Old Alarm First'}`,
		},
	];
	
	const status_options = [
		{
			value: 'New',
			label: `${langdata && langdata.New ? langdata.New:'New'}`,
		},
		{
			value: 'Resolved',
			label: `${langdata && langdata.Resolved ? langdata.Resolved:'Resolved'}`,
		},
		{
			value: 'In Inspection',
			label: `${langdata && langdata.InInspection ? langdata.InInspection:'In Inspection'}`,
		},
	];

	const includeRelatedAlarms = [
		{
			value: 'Yes',
			label: `${langdata && langdata.Yes ? langdata.Yes:'Yes'}`
		},
		{
			value: 'No',
			label: `${langdata && langdata.No ? langdata.No:'No'}`
		},	
	];
	
	
	const status = [
		{
			value: null,
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
		},
		{
			value: 'Acknowledged',
			label: `${langdata && langdata.Acknowledged ? langdata.Acknowledged:'Acknowledged'}`
		},
		{
			value: 'In Inspection',
			label: `${langdata && langdata.InInspection ? langdata.InInspection:'In Inspection'}`,
		},
		{
			value: 'New',
			label: `${langdata && langdata.New ? langdata.New:'New'}`
		},
		{
			value: 'Resolved',
			label: `${langdata && langdata.Resolved ? langdata.Resolved:'Resolved'}`
		},
	];
	
	const severity = [
		{
			value: 'all',
			label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
		},
		{
			value: '2',
			label: `${langdata && langdata.Amber ? langdata.Amber:'Amber'}`
		},
		{
			value: '0',
			label: `${langdata && langdata.Clear ? langdata.Clear:'Clear'}`
		},
		{
			value: '1',
			label: `${langdata && langdata.Green ? langdata.Green:'Green'}`
		},
		{
			value: '3',
			label: `${langdata && langdata.Red ? langdata.Red:'Red'}`
		}
	];

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.realtimealarminfo1 ? langdata.realtimealarminfo1:'Details of alarms generated in the OneView system. Displays the alarms in the last 1 hour by default. Click on filter icon to use alarm filter options.'}</p>
			<br/>
			<p>{langdata && langdata.realtimealarminfo1 ? langdata.realtimealarminfo2:'Toggle to Map view for viewing location of alarms plotted on pipeline.'}</p>
			<br/>
		</Card>
	);

	let realtimeAlarmList = [];
	let alarmIds =[];
	let bulkresolutiondata = [];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	alarmdata.relatimealarmdata && alarmdata.relatimealarmdata.map((item, index) => {
		
		// const date = moment.utc(item.datetime).toDate();
		const datetime = datetimeCovertertolocal(item.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		// date.toString();
		// const newdate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm a');
		realtimeAlarmList.push({
			key: index.toString(),
			id: item.alarm_prm_id,
			activity: item.eventType,
			description:item.ruleDesc,
			date:newdate,
			duration:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
			channel: item.channel_name,
			eventDate: newdate,
			chainage: item.locationDetails ? item.locationDetails.info : null,
			fibrelength: item.odMeter,
			status:item.status,
			pidsinfoId:item.pidsinfoId,
			ackdetDetails:item.ackdetDetails,
			alarmSeverity:item.alarmSeverity,
			pidsname:item.pidname.toUpperCase(),
			region : regiondata && regiondata.map((region) => { if (item.regioninfoId === region.id){ return region.regionName.toUpperCase();} }),
			newid : item.newid,
		});
		alarmIds.push(item.alarm_prm_id);
		bulkresolutiondata.push({
			id: item.alarm_prm_id,
			activity: item.eventType,
			pidsinfoId:item.pidsinfoId,
		});
	});

	let contenttext = '';
	if(alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details){

		const datetime = datetimeCovertertolocal(alarmdata.relatimealarmdata_details.datetime);
		const newdate = datetime.localdate + ' ' +  datetime.localtime;
		let regionObject = regiondata && regiondata.find((region) => region.id == alarmdata.relatimealarmdata_details.regioninfoId);
		let regionName;
		if(regionObject !== undefined){
			regionName= regionObject.regionName.toUpperCase();
		}
		contenttext = `${langdata && langdata.Id ? langdata.Id:'Id'}: ${alarmdata.relatimealarmdata_details.alarm_prm_id}\n${langdata && langdata.Activity ? langdata.Activity:'Activity'}: ${alarmdata.relatimealarmdata_details.eventType}\n${langdata && langdata.Date ? langdata.Date:'Date'}: ${newdate}\n${langdata && langdata.Region ? langdata.Region:'Region'}: ${regionName}\n${langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: ${alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.pidname ? alarmdata.relatimealarmdata_details.pidname.toUpperCase() : ''}\n${langdata && langdata.Channel ? langdata.Channel:'Channel'}: ${alarmdata.relatimealarmdata_details.channel_name}\n${langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: ${alarmdata.relatimealarmdata_details.locationDetails ? alarmdata.relatimealarmdata_details.locationDetails.info : null}\n${langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: ${alarmdata.relatimealarmdata_details.odMeter}\n${langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}:${alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Lat}\n${langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: ${alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Long}`;
	}

	const pipeline = [];
	const center=[];
	const odmeter=[];
	const optionalpath = [];
	const markers = [];
	// const points = [];
	// const pidsmarker=[];
	// const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type=  localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const alarm_markers= [];

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	});
  
	optionalpipedata &&  optionalpipedata.map((item)=>{

		optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	});

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.CH ? langdata.CH:'CH'}`
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.WB ? langdata.WB:'WB'}`
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TLP ? langdata.TLP:'TLP'}`
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.KMP ? langdata.KMP:'KMP'}`
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TP ? langdata.TP:'TP'}`
			},);
		}


	});

	// const alarmicon = new Icon({
	//   // iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	//   iconUrl:require(`${eventsicons[0].severity[0]}`),
	//   iconSize:[38,38]
	// })
	eventsicons && eventsicons.map((icondata)=>{
		alarmdata.mapdatas && alarmdata.mapdatas.map((item)=>{
			if(item.eventType==icondata.type){
				alarm_markers.push({
					geocode:[item.locationDetails.Lat,item.locationDetails.Long],
					pop:item.eventType,
					alarmSeverity:item.alarmSeverity,
					id:item.newid,
					// eslint-disable-next-line no-undef
					ic: new L.icon({
						iconUrl: icondata.severity[item.alarmSeverity],
						iconSize: [30, 40],
						iconAnchor:[18, 36]            
					})
				},);
			}
		});
	});
  


	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let allevents=[{value:'all',
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	let pidsList = [{
		value : null,
		label: `${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	eventsData && eventsData.map((allevent)=>{
		allevents.push({
			value:allevent.eventType,
			label:allevent.eventType});
	});

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
		
	}

	pidsData && pidsData.map((item) => {
		pidsList.push({
			value : item.id,
			label : item.name.toUpperCase()
		});
	});

	pidsList.sort((a,b) => { return a.value - b.value; });

	const lastHours = [
		{
			value : 1,
			label : `1 ${langdata && langdata.Hour ? langdata.Hour :'Hour'}`
		},
		{
			value : 2,
			label : `2  ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 3,
			label : `3 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 6,
			label : `6 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 12,
			label : `12 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		{
			value : 24,
			label : `24 ${langdata && langdata.Hours ? langdata.Hours :'Hours'}`
		},
		// {
		// 	value : 0,
		// 	label : 'Custom'
		// }
	];

	const columns = [
		{
			title: <Checkbox checked={allcheck} onClick={()=>{handleSetAllCheckIds(allcheck == true ? false : true);}}></Checkbox>,
			dataIndex: 'id',
			key: 'id',
			render: (_, record) => (

				<>	
					{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
					record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>
					}
					
					<Checkbox checked ={checkresids.includes(record.id) ? true : (!checkresids.includes(record.id) && check == false) ? false : (!checkresids.includes(record.id) && check == true) ? false : false} 
						onClick={()=>{handleSetCheckIds(
							(checkresids.includes(record.id)) ? false : true, record.id)
						
						;}} style={{paddingTop:'10px'}} />
				</>
			)

			
		},
		{
			title: `${langdata && langdata.Id ? langdata.Id :'Id'}`,
			dataIndex: 'id',
			key: 'id',
			
		},
		
		{
			title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
			dataIndex: 'activity',
			key: 'activity',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo :'More info'} onClick={()=>{key(record.newid);}}>{record.activity}</a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Region ? langdata.Region :'Region'}`,
			dataIndex: 'region',
			key: 'region',
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}`,
			dataIndex: 'pidsname',
			key: 'pidsname',
		},
		{
			title: `${langdata && langdata.Channel ? langdata.Channel :'Channel'}`,
			dataIndex: 'channel',
			key: 'channel',
		},
		{
			title: `${langdata && langdata.Description ? langdata.Description :'Description'}`,
			dataIndex: 'description',
			key: 'description',
			render: (text) => (
				<Popover title={langdata && langdata.Description ? langdata.Description :'Description'} content={text} trigger="hover">
					<Typography.Paragraph
						ellipsis={
							{
								rows: 2,
								expandable: false,
								symbol: ''
							}
						}
					>
						{text}
					</Typography.Paragraph>
				</Popover>
			)
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: `${langdata && langdata.Duration ? langdata.Duration :'Duration'}`,
			dataIndex: 'duration',
			key: 'duration',
		},
		
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
			render: (text) => (
				<Popover title={langdata && langdata.Chainage ? langdata.Chainage :'Chainage'} content={text} trigger="hover">
					<Typography.Paragraph
						ellipsis={
							{
								rows: 2,
								expandable: false,
								symbol: ''
							}
						}
					>
						{text}
					</Typography.Paragraph>
				</Popover>
			)
		},
		{
			title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
			dataIndex: 'fibrelength',
			key: 'fibrelength',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status :'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_, status) => (
				<>
					{status &&
						<Tag color={status.alarmSeverity==3 ? 'red' :
							status.alarmSeverity==2 ? 'gold' : status.alarmSeverity==1 ? 'green' : status.alarmSeverity==0 ? 'violet' : ''}>
							{status.status}
						</Tag>}
				</>
			),
		},

		{
			title: `${langdata && langdata.Actions ? langdata.Actions :'Actions'}`,
			key: 'action',
			render: (_, record) => (
				<Space style={{justifyContent:'center',display:'flex'}} size="middle">
					<Button style={{ display : 'contents'}} title="Update Alarm" disabled ={record && record.status=='Resolved' ? true : false} onClick={()=>{showEditDrawer(record);}}><EditOutlined style={{color:record && record.status=='Resolved' ? '' : '#1677FF'}}/></Button>
				</Space>
			)
		}
	];

	const from_datetimeobj=[];

	if(filterDate && filterDate !=='1970-01-01' && filterStartTime){
		var resultArray = filterStartTime.split(',').map(function(filterStartTime){return Number(filterStartTime);});
		if(resultArray.length > 0) {
			filterDate.setHours(resultArray[0],resultArray[1],resultArray[2]);
			let startdatetime=startdatetimeCoverter(filterDate);
			from_datetimeobj.push({startdatetime});

		}

	}

	const to_datetimeobj=[];

	if(filterDate && filterDate !=='1970-01-01' && filterEndTime ){


		var resultEndArray = filterEndTime.split(',').map(function(filterEndTime){return Number(filterEndTime);});
		if(resultEndArray.length > 0){
			filterDate.setHours(resultEndArray[0],resultEndArray[1],resultEndArray[2]);
			let enddatetime=enddatetimeCoverter(filterDate);
			to_datetimeobj.push({enddatetime});

		}

	}

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 8);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
			getRegion(authtoken);
		}
		if (map_type && map_type === 'leaflet') {
			handleGetMapdata(authtoken,regionId,startCh,endCh);
			handleOptionalPipelinedata(authtoken,pipelineId,regionId,startCh,endCh);
		}      

	},[authtoken, sort, regionId, allocationId]);

	useEffect(() => {
		getAlarm(1);
		getMapAlarm();
		onSearch(searchValue);
	},[apiBody, authtoken, sort, regionId, allocationId, searchValue]);

	useEffect(() => {
		const interval = setInterval(() => {
			// getAlarm(1);
			navigate(0);
		}, refreshTime ); 
		return () => clearInterval(interval);
	}, []);

	const getMapAlarm = () =>{

		if(searchValue) {
			return false;
		}

		setActiveExpRow([]);

		setCheckResIds([]);
		setAllCheckValue(false);

		const related_alarm = filterRealted == 'No' ? '&isparent=true' : '';

		// const ack = state && state.data.status == 'Acknowledged' ?  'ack' :  null;


		let data;

		if(apiBody == true && state) {

			data=JSON.stringify({
				endeventtime: state && state.data.endeventtime ? `${moment.utc(state.data.endeventtime.setHours(23,59,59,999)).format('HH:mm:ss')}.000000` :  `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				eventenddate: state && state.data.eventenddate ? moment.utc(state.data.eventenddate.setHours(23,59,59,999)).format('YYYY-MM-DD') :  moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				eventstartdate: state && state.data.eventstartdate ? moment.utc(state.data.eventstartdate.setHours(0,0,0,0)).format('YYYY-MM-DD') :  moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				region_id: admin_roles.includes(permission) ? regionId : null,
				starteventtime: state && state.data.starteventtime ? `${moment.utc(state.data.starteventtime.setHours(0,0,0,0)).format('HH:mm:ss')}.000000` : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				allocationId : !admin_roles.includes(permission) ? allocationId : null,
				alarmSeverity: state && state.data.severity || state && state.data.severity == '0' ? state.data.severity : 'all',
				status: state && state.data.status ? state.data.status : null,
				eventtype : state && state.data.eventtype ? [state.data.eventtype] : ['all'],
				resolutionAdded: state && state.data.resolutionAdded == false ? state.data.resolutionAdded : null,
			});
		}else {

			let startFilterTime;
			let endFilterTime;
			let startFilterDate;
			let endFilterDate;

			if(timeSelection == false) {
				startFilterTime = `${moment.utc().subtract(filterLastHour, 'hours').format('HH:mm:ss')}.000000`;
				endFilterTime = `${moment.utc().format('HH:mm:ss')}.000000`;
		
				startFilterDate = `${moment.utc().subtract(filterLastHour, 'hours').format('YYYY-MM-DD')}`;
				endFilterDate = `${moment.utc().format('YYYY-MM-DD')}`;
			} else {
				startFilterTime = null;
				endFilterTime = null;
				startFilterDate = null;
				endFilterDate = null;
			}

			data = JSON.stringify({
				alarmSeverity: filterSeverity && filterSeverity,
				allocationId: !admin_roles.includes(permission) ? allocationId : null,
				endeventtime: to_datetimeobj && to_datetimeobj[0] ? `${to_datetimeobj[0].enddatetime.utctime}.000000` : endFilterTime ? endFilterTime : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				eventenddate: to_datetimeobj && to_datetimeobj[0] ? to_datetimeobj[0].enddatetime.utcdate : endFilterDate ? endFilterDate : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				eventstartdate: from_datetimeobj && from_datetimeobj[0] ? from_datetimeobj[0].startdatetime.utcdate : startFilterDate ? startFilterDate : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				eventtype: filterEvent && filterEvent.length > 0 ? filterEvent : ['all'] ,
				pidsId: filterPids,
				region_id: admin_roles.includes(permission) ? regionId : null,
				starteventtime: from_datetimeobj && from_datetimeobj[0] ? `${from_datetimeobj[0].startdatetime.utctime}.000000` : startFilterTime ? startFilterTime : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				status: filterStatus,
				// terrain:filterTerrain,
			});
		}	

		dispatch({ type : REALTIME_ALARM_MAP_DATA});
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/alarm?sort[eventdate]=${sort}&sort[eventtime]=${sort}${related_alarm}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : REALTIME_ALARM_MAP_DATA_SUCCESS, payload : response.data.data});
					data = response.data;
				}else {
					dispatch({ type : REALTIME_ALARM_MAP_DATA_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
				dispatch({ type : REALTIME_ALARM_MAP_DATA_ERROR});
			});
	};
	
	const getAlarm = (pagination) =>{

		if(searchValue) {
			return false;
		}

		setActiveExpRow([]);

		let _pagination = { };
		_pagination.total = alarm_data_pagination.total ? alarm_data_pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		setCheckResIds([]);
		setAllCheckValue(false);

		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		const related_alarm = filterRealted == 'No' ? '&isparent=true' : '';

		// const ack = state && state.data.status == 'Acknowledged' ?  'ack' :  null;

		let data;

		if(apiBody == true && state) {

			data=JSON.stringify({
				endeventtime: state && state.data.endeventtime ? `${moment.utc(state.data.endeventtime.setHours(23,59,59,999)).format('HH:mm:ss')}.000000` : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				eventenddate: state && state.data.eventenddate ? moment.utc(state.data.eventenddate.setHours(23,59,59,999)).format('YYYY-MM-DD') : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				eventstartdate: state && state.data.eventstartdate ? moment.utc(state.data.eventstartdate.setHours(0,0,0,0)).format('YYYY-MM-DD') : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				region_id: admin_roles.includes(permission) ? regionId : null,
				starteventtime: state && state.data.starteventtime ? `${moment.utc(state.data.starteventtime.setHours(0,0,0,0)).format('HH:mm:ss')}.000000` : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				allocationId : !admin_roles.includes(permission) ? allocationId : null,
				alarmSeverity: state && state.data.severity || state && state.data.severity == '0' ? state.data.severity : 'all',
				status: state && state.data.status ? state.data.status : null,
				eventtype : state && state.data.eventtype ? [state.data.eventtype] : ['all'],
				resolutionAdded: state && state.data.resolutionAdded == false ? state.data.resolutionAdded : null,
			});
		}else {

			let startFilterTime;
			let endFilterTime;
			let startFilterDate;
			let endFilterDate;

			if(timeSelection == false) {
				startFilterTime = `${moment.utc().subtract(filterLastHour, 'hours').format('HH:mm:ss')}.000000`;
				endFilterTime = `${moment.utc().format('HH:mm:ss')}.000000`;
		
				startFilterDate = `${moment.utc().subtract(filterLastHour, 'hours').format('YYYY-MM-DD')}`;
				endFilterDate = `${moment.utc().format('YYYY-MM-DD')}`;
			} else {
				startFilterTime = null;
				endFilterTime = null;
				startFilterDate = null;
				endFilterDate = null;
			}

			data = JSON.stringify({
				alarmSeverity: filterSeverity && filterSeverity,
				allocationId: !admin_roles.includes(permission) ? allocationId : null,
				endeventtime: to_datetimeobj && to_datetimeobj[0] ? `${to_datetimeobj[0].enddatetime.utctime}.000000` : endFilterTime ? endFilterTime : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
				eventenddate: to_datetimeobj && to_datetimeobj[0] ? to_datetimeobj[0].enddatetime.utcdate : endFilterDate ? endFilterDate : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
				eventstartdate: from_datetimeobj && from_datetimeobj[0] ? from_datetimeobj[0].startdatetime.utcdate : startFilterDate ? startFilterDate : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
				eventtype: filterEvent && filterEvent.length > 0 ? filterEvent : ['all'] ,
				pidsId: filterPids,
				region_id: admin_roles.includes(permission) ? regionId : null,
				starteventtime: from_datetimeobj && from_datetimeobj[0] ? `${from_datetimeobj[0].startdatetime.utctime}.000000` : startFilterTime ? startFilterTime : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
				status: filterStatus,
				// terrain:filterTerrain,
			});
		}	

		dispatch({ type : REALTIME_ALARM_LIST});
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/alarm?sort[eventdate]=${sort}&sort[eventtime]=${sort}&limit=${_pagination.limit}&skip=${_pagination.skip}${related_alarm}`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					alarm_data_pagination.total = response.data.total ? response.data.total : 0;
					alarm_data_pagination.limit = response.data.limit ? response.data.limit : 10;
					alarm_data_pagination.skip = response.data.skip ? response.data.skip : 0;
					alarm_data_pagination.current = pagination.current ? pagination.current : 1;
					alarm_data_pagination.pageSize =  _pagination.pageSize;
					dispatch({ type : REALTIME_ALARM_SUCCESS, payload : response.data.data});
					data = response.data;
				}else {
					dispatch({ type : REALTIME_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
				dispatch({ type : REALTIME_ALARM_ERROR});
			});
	};

	const showEditDrawer = (record) => {
		dispatch({type:REALTIME_ALARM_UPDATE_DETAILS, payload:record});
		setStatus({
			status:record.status,
			id:record.id,
			pidsinfoId:record.pidsinfoId
		});
		setOpenEditDrawer(true);
	};

	const onCloseEditDrawer = () => {
		setOpenEditDrawer(false);
	};

	const handleSelectStatus = (e) =>{
		if(e == 'Resolved'){
			getAllEvents(authtoken);
			setResolution(true);
			setOpenEditDrawer(false);

		}
		setStatus({...getstatus,status:e});
	};

	const onSaveEditDrawer = () => {

		let data=JSON.stringify({
			status: getstatus.status,
			id:getstatus.id,
			// table: pidsname,
			pidsinfoId:getstatus.pidsinfoId
		});

		dispatch({ type : REALTIME_PATCH_ALARM});
		let config = {
			method: 'patch',
			maxBodyLength: Infinity,
			url: `${api}/alarm`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : REALTIME_PATCH_ALARM_SUCCESS, payload : response.data.data});
					Modal.success({ title: `${langdata && langdata.Successfullyupdated ? langdata.Successfullyupdated :'Successfully updated.'}` });
					getAlarm({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});
					getMapAlarm();
					data = response.data;
				}else {
					dispatch({ type : REALTIME_PATCH_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_PATCH_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
		// }


		setOpenEditDrawer(false);
	};
  
	const handleFilterDrawer = () => {
		getAllEvents(authtoken);
		setFilterdrawer(true);
		getPids(authtoken);
	};

	const onCloseFilterDrawer=() => {
		setFilterdrawer(false);
	};

	const handleSetregion = (e, options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(admin_roles.includes(permission)) {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	// const handleSetDate = (e) =>{
	// 	setDate(new Date(e && e.$d).toLocaleDateString('en-UK', {
	// 		day: '2-digit',
	// 		month: '2-digit',
	// 		year: 'numeric'
	// 	}));
	// };


	const handleSetSort = (value) => {
		setSort(value);
	};

	const key = (value) => {
		setModelOpen(true);
		dispatch({ type : REALTIME_ALARM_DETAILS, payload : value});
	};

	const handleModelCancel = () => {
		setModelOpen(false);
	};

	const handleModelOk = (value) => { 
		navigate(`alarmDetails/${value}`);
	};

	// filter starts
	const handleFilterAlarm = () =>{
		setSearchValue(null);
		setShowSearchValue(null);
		if(filterRealted == 'No') {
			setShowRelatedAlarm(false);
		} else {
			setShowRelatedAlarm(true);
		}
		setApiBody(false);
		setResolutionCompareDate(filterDate);
		if(apiBody == false) {
			getAlarm(1);
			getMapAlarm();
		}


		// dispatch({type : REALTIME_ALARM_SUCCESS, payload : []});

		// let _pagination = { };
		// _pagination.total = alarm_data_pagination.total ? alarm_data_pagination.total : 0;
		// _pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		// _pagination.skip =  pagination && pagination.current ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		// localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE`, pagination && pagination.current ? pagination.current : 1);
		// localStorage.setItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`, pagination && pagination.pageSize ? pagination.pageSize : 10);

		// const related_alarm = filterRealted == 'No' ? 'isparent=true' : '';

		// dispatch({ type : REALTIME_ALARM_LIST});
		// let data = JSON.stringify({
		// 	alarmSeverity: filterSeverity && filterSeverity,
		// 	allocationId:allocationId,
		// 	endeventtime: to_datetimeobj && to_datetimeobj[0] ? `${to_datetimeobj[0].enddatetime.utctime}.000000` : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
		// 	eventenddate: to_datetimeobj && to_datetimeobj[0] ? to_datetimeobj[0].enddatetime.utcdate : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
		// 	eventstartdate: from_datetimeobj && from_datetimeobj[0] ? from_datetimeobj[0].startdatetime.utcdate : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
		// 	eventtype: filterEvent && filterEvent.length > 0 ? [filterEvent] : ['all'] ,
		// 	pidsId: null,
		// 	region_id:regionId ? regionId : null,
		// 	starteventtime: from_datetimeobj && from_datetimeobj[0] ? `${from_datetimeobj[0].startdatetime.utctime}.000000` : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
		// 	status: filterStatus,
		// 	terrain:filterTerrain,
		// });

		// let config = {
		// 	method: 'post',
		// 	maxBodyLength: Infinity,
		// 	url: `${api}/alarm?${related_alarm}`,
		// 	headers: { 
		// 		'Content-Type': 'application/json', 
		// 		'Authorization': `Bearer ${authtoken}`
		// 	},
		// 	data : data
		// };
      
		// axios.request(config)
		// 	.then((response) => {
		// 		if (response.status === 200) {
		// 			alarm_data_pagination.total = response.data.total ? response.data.total : 0;
		// 			alarm_data_pagination.limit = response.data.limit ? response.data.limit : 10;
		// 			alarm_data_pagination.skip = response.data.skip ? response.data.skip : 0;
		// 			alarm_data_pagination.current = pagination.current ? pagination.current : 1;
		// 			dispatch({ type : REALTIME_ALARM_SUCCESS, payload : response.data.data});
		// 		}else {
		// 			dispatch({ type : REALTIME_ALARM_ERROR});
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 		dispatch({ type : REALTIME_ALARM_ERROR});
		// 	});
    
		setFilterdrawer(false);

	};

	const handleSelectEvent = (e)=>{

		setEventType(e);
	};

	const handleRelated = (e)=>{

		setFilterRelated(e);
	};

	const handleStatus = (e)=>{

		setFilterStatus(e);
	};

	const handleSeverity = (e)=>{

		setFilterSeverity(e);
	};

	const handlePids = (event) => {
		setFilterPids(event);
	};

	const changeTimeSelection = (e) => {
		setTimeSelection(e.target.checked);
	};
	// const handleTerrain = (e)=>{

	// 	setFilterTerrain(e);
	// };
	const handleLastHour = (event) => {
		setFiltetLastHour(event);
	};

	const handleDate = (e)=>{
		setDate(new Date(date && date.$d));
		setFilterDate(new Date(e && e.$d));
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleStartTime = (e)=>{
		setFilterStartTIme(moment(e && e.$d).format('HH,mm,ss'));
		setStartTime(moment(e && e.$d).format('HH,mm,ss'));
	};

	const handleEndTime = (e)=>{
		setFilterEndTIme(moment(e && e.$d).format('HH,mm,ss'));
		setEndTime(moment(e && e.$d).format('HH,mm,ss'));
	};


	// filter Ends

	const handleSearchValue = (e) => {
		setShowSearchValue(e.target.value);
	};

	const onSearch = (value) => {
		setSearchValue(value);

		if(value){
			dispatch({ type : REALTIME_ALARM_LIST});
			let data = JSON.stringify({
				allocationId:allocationId,
				pidsinfoId: null,
				id:value,
				region_id: admin_roles.includes(permission) ? regionId : null,
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/alarmsearch?sort[eventdate]=${sort}&sort[eventtime]=${sort}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
      
			axios.request(config)
				.then((response) => {
					if (response.status === 201) {
						dispatch({ type : REALTIME_ALARM_SUCCESS, payload : response.data.data});
						dispatch({ type : REALTIME_ALARM_MAP_DATA_SUCCESS, payload : response.data.data});
						alarm_data_pagination.total = response.data ? response.data.data.length : 0;
						// alarm_data_pagination.limit = response.data.limit ? response.data.limit : 10;
						// alarm_data_pagination.skip = response.data.skip ? response.data.skip : 0;
						// alarm_data_pagination.current = pagination.current ? pagination.current : 1;
						// alarm_data_pagination.pageSize =  _pagination.pageSize;
					}else {
						dispatch({ type : REALTIME_ALARM_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : REALTIME_ALARM_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}
      
	};

	


	const handleResComment= (e)=>{
		setResRemarks(e.target.value);
	};

	const onCheck = (e) => {
		setRetrainCheck(e.target.checked); 
	};

	const radioStyle = {
		display: 'flex',
		height: '30px',
		lineHeight: '30px'
	};

	let showOtherOpt = false;

	if (eventsData !== null) {
		var events =eventsData && eventsData.map((item) => (
			<Radio style={radioStyle} key={item.id} value={item.eventType}>
				{item.eventType}
			</Radio>
		));
	}

	const onChangeEvent = (e) => {
		setEventName(e.target.value);
	};

	const onResChange = (e) => {
		setResValue(e.target.value);
	};

	const handleResModelSave = ()=>{
		dispatch({ type : RESOLUTION_ALARM});

		let data={
			alarmId:alarmdata.details.id,
			predictedEvent: resvalue===1 ? alarmdata.details.activity : eventname,
			// comments: alarmdata.details.comment,
			isEnabledForRetraining: retraincheck,
			isReTrainingCompleted: null,
			reTrainingDate: moment()
				.utc()
				.format(),
			pidsinfoId: alarmdata.details.pidsinfoId,
			remarks: resRemarks.replace(/'/g, '"'),
			actualEvent: alarmdata.details.activity,
			userId: localStorage.getItem(`${dashboardtype.toUpperCase()}_USER_ID`),
			isSynced: retraincheck
		};

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/resolution`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 201) {
					dispatch({ type : RESOLUTION_ALARM_SUCCESS, payload : response.data});
					Modal.success({ title: `${langdata && langdata.Resolutionaddedsuccessfully ? langdata.Resolutionaddedsuccessfully :'Resolution added successfully'}` });
					getAlarm({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});    
					getMapAlarm();
					setResolution(false);
					setResValue(1);
					setEventName(null);
					setRetrainCheck(false);
					setResRemarks('');

				}else {
					dispatch({ type : RESOLUTION_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : RESOLUTION_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});


	};

	const handleResModelCancel = ()=>{
		setResolution(false);
	};


	const handleSetCheckIds = (e,options)=>{
		if(e==true){
			// setCheckValue(e);
			setCheckResIds(checkresids => [...checkresids,options]);
		}else {
			const arrayremovedid = checkresids.filter(function (letter) {
				return letter !== options;
			});
			// setCheckValue(e);
			setCheckResIds(arrayremovedid);
		}
	};

	const handleResModelOpen = ()=>{
		getAllEvents(authtoken);
		setBulkResolution(true);
	};

	const handleSetAllCheckIds = (e) =>{
		setAllCheckValue(e);
		if(e==false){
			setCheckResIds([]);
		}else{

			if(check === true) {
				setCheckValue(false);
			} else {
				setCheckValue(true);
			}
			setCheckResIds([]);
			alarmIds && alarmIds.map((item)=>{
				setCheckResIds(checkresids => [...checkresids,item]);
			});

		}
		
	};



	const handlebulkResModelSave = ()=>{
		dispatch({ type : RESOLUTION_ALARM});

		bulkresolutiondata && bulkresolutiondata.map((item)=>{
			checkresids && checkresids.map((inneritem)=>{
				if(inneritem==item.id){
					let data={
						alarmId:item.id,
						predictedEvent: resvalue===1 ? item.activity : eventname,
						// comments: alarmdata.details.comment,
						isEnabledForRetraining: retraincheck,
						isReTrainingCompleted: null,
						reTrainingDate: moment()
							.utc()
							.format(),
						pidsinfoId: item.pidsinfoId,
						remarks: resRemarks.replace(/'/g, '"'),
						actualEvent: item.activity,
						userId: localStorage.getItem(`${dashboardtype.toUpperCase()}_USER_ID`),
						isSynced: retraincheck
					};
			
					let config = {
						method: 'post',
						maxBodyLength: Infinity,
						url: `${api}/resolution`,
						timeout: timeout,
						headers: { 
							'Content-Type': 'application/json', 
							'Authorization': `Bearer ${authtoken}`
						},
						data : data
					};
				
					axios.request(config)
						.then((response) => {
							if (response.status === 201) {
								dispatch({ type : RESOLUTION_ALARM_SUCCESS, payload : response.data});
								
								if(checkresids.slice(-1) == item.id){
									Modal.success({ title: `${langdata && langdata.Resolutionaddedsuccessfully ? langdata.Resolutionaddedsuccessfully :'Resolution added successfully'}` });
									getAlarm({current : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)), skip : (parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE`)) - 1) * 10, pageSize : parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_PAGE_SIZE`))});    
									getMapAlarm();
									setBulkResolution(false);
									setResValue(1);
									setEventName(null);
									setCheckResIds([]);
									setRetrainCheck(false);
									setResRemarks('');
								}
			
							}else {
								dispatch({ type : RESOLUTION_ALARM_ERROR});
							}
						})
						.catch((error) => {
							console.log(error);
							dispatch({ type : RESOLUTION_ALARM_ERROR});
							if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
								message.config({
									duration : 5,
									maxCount : 1,
								});
								message.open({
									type: 'error',
									content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
								});
							}
						});
			
				}
			});
		});

		

	};

	const bulkhandleResModelCancel = ()=>{
		setBulkResolution(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const daysDifference = (new Date(
		new Date().getUTCFullYear(),
		new Date().getUTCMonth(),
		new Date().getUTCDate(),
		new Date().getUTCHours(),
		new Date().getUTCMinutes(),
		new Date().getUTCSeconds(),
	).getTime() - new Date(moment.utc(resolutionCompareDate.setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss')).getTime())/ (1000 * 60 * 60 * 24);

	const expandedRowRender = () => {
		if(related_alarms_datas.length > 0){

			const columns = [
				{
					title: `${langdata && langdata.AlarmId ? langdata.AlarmId :'Alarm Id'}`,
					dataIndex: 'alarmId',
					key: 'alarmId',
					render: (text, record) => (
						<Space size="middle">
							{ record  && 
					<span>
						<span style={{ float: 'left', backgroundColor: record.alarmSeverity==3 ? 'red' :
							record.alarmSeverity==2 ? 'gold' : record.alarmSeverity==1 ? 'green' : record.alarmSeverity==0 ? 'violet' : '', 
						width: '3px', height: '20px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
					</span>
							}
							<text>{text}</text>
						</Space>
					),
				},
				{
					title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
					dataIndex: 'activity',
					key: 'activity',
				},
				{
					title: `${langdata && langdata.Description ? langdata.Description :'Description'}`,
					dataIndex: 'description',
					key: 'description',
					render: (text) => (
						<Popover title={langdata && langdata.Description ? langdata.Description :'Description'} content={text} trigger="hover">
							<Typography.Paragraph
								ellipsis={
									{
										rows: 2,
										expandable: false,
										symbol: ''
									}
								}
							>
								{text}
							</Typography.Paragraph>
						</Popover>
					)
				},
				
				{
					title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
					dataIndex: 'date',
					key: 'date',
					// render: (_, date) => (
					// 	<Space size="middle">
					// 		<p title="Edit Alarm">{ new Date(date.date).toLocaleDateString('en-UK', {
					// 			day: '2-digit',
					// 			month: '2-digit',
					// 			year: 'numeric'
					// 		})}</p>
					// 	</Space>
					// )
				},
				
				{
					title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
					dataIndex: 'chainage',
					key: 'chainage',
					render: (text) => (
						<Popover title={langdata && langdata.Chainage ? langdata.Chainage :'Chainage'} content={text} trigger="hover">
							<Typography.Paragraph
								ellipsis={
									{
										rows: 2,
										expandable: false,
										symbol: ''
									}
								}
							>
								{text}
							</Typography.Paragraph>
						</Popover>
					)
				},
				{
					title: `${langdata && langdata.Duration ? langdata.Duration :'Duration'}`,
					dataIndex: 'duration',
					key: 'duration',
				},
				{
					title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
					dataIndex: 'fibrelength',
					key: 'fibrelength',
				},
				
				
			];

			return <Table onRow={() => ({
				style: {
					background: '#faf7f0',
				}
			})} columns={columns} dataSource={related_alarms_datas} pagination={false} 
			scroll={{
				y: 240,
			}} />;
		}else{
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

		}
		
	};

	const handleRealtedAlarm = (e,options) =>{
		setRelatedAlarms([]);
		activeExpRow === options.key ? setActiveExpRow() : setActiveExpRow(options.key.toString());
		if(e==true){
			setAlarmDetails({id : options.id.toString(), pidsinfoId: options.pidsinfoId});
		}
	};

	useEffect(()=>{
		
		if((alarmdetails && alarmdetails.id != undefined) && (alarmdetails && alarmdetails.id !== null) && (alarmdetails && alarmdetails.id != 'undefined')){

			// data.loading =  true;
			dispatch({ type: RELATED_ALARMS });
			let data = JSON.stringify({
				id: alarmdetails.id,
				pidsinfoId: alarmdetails.pidsinfoId,
				regioninfoId:null,
				allocationId: null,
			
			});
	
			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/relatedalarm?sort[eventdate]=${sort}&sort[eventtime]=${sort}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					'Authorization': `Bearer ${authtoken}`
				},
				data : data
			};
		
			axios.request(config)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type : RELATED_ALARMS_SUCCESS});
						setRelatedAlarms(response.data);
						
					}else {
						dispatch({ type : RELATED_ALARMS_ERROR});
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : RELATED_ALARMS_ERROR});
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						});
					}
				});

		}

	},[alarmdetails]);

	
	let related_alarms_datas = [];
	
	if(relatedAlarms.length > 0){
		relatedAlarms.map( (item, index) => {
			const datetime = datetimeCovertertolocal(item.datetime);
			const newdate = datetime.localdate + ' ' +  datetime.localtime;
			related_alarms_datas.push({
				key: index.toString(),
				alarmId: item.alarm_prm_id,
				severity:item.alarmSeverity && item.alarmSeverity==3 ? 'RED' :  item.alarmSeverity==2 ? 'AMBER' : item.alarmSeverity==1 ? 'GREEN' : item.alarmSeverity==0 ? 'CLEAR' : '',
				activity: item.eventType,
				description:item.ruleDesc,
				date:newdate,
				duration:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
				channel: item.channel_name,
				chainage: item.locationDetails ? item.locationDetails.info : null,
				locationDetails:item.locationDetails && item.locationDetails,
				alarmSeverity:item.alarmSeverity,
				// pidsname:pidsData && pidsData.map((pids) => { if (item.pidsinfoId === pids.id){ return pids.name.toUpperCase();} }),
				region : regiondata && regiondata.map((region) => { if (item.regioninfoId[0] === region.id){ return region.regionName.toUpperCase();} }),
				fibrelength: item.odMeter,
			});

		});
	}else{
		related_alarms_datas = [];
	}

	return (
		<>
			{view && view === 'List' ?
				<Content>
					<Row style={{
						margin: '10px',
						marginBottom: '0px',
						paddingTop: '10px',
						paddingBottom: '10px',
						background: '#FFFFFF',
						alignItems:'right'
					}} >
						<Col span={4}>
							<Row style={{alignItems:'center', textAlign: 'center'}}>
								<Col span={24} style={{textAlign: 'left', paddingLeft: '10px'}}>
									<Radio.Group style={{margin:'5px'}} value={view} onChange={(e) => setSize(e.target.value)}>
										<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
										<Radio.Button value="Map">{langdata && langdata.Map ? langdata.Map:'Map'}</Radio.Button>
									</Radio.Group>
								</Col>
							</Row>
						</Col>
						<Col span={20} style={{paddingRight: '10px'}}>
							<Row style={{alignItems:'center', textAlign: 'right'}}>
								<Col span={24}>
									<Space>
										<Search style={{margin:'5px', verticalAlign:'middle', marginTop:'2px', paddingRight:'10px'}} type="number" value={showSearchValue} onChange={handleSearchValue} allowClear placeholder={langdata && langdata.SearchAlarmid ? langdata.SearchAlarmid:'Search Alarm id'} onSearch={onSearch} enterButton />
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Select Item
											style={{ minWidth: '200px', margin:'5px', textAlign: 'left'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.Sort ? langdata.Sort:'Sort'}
											optionFilterProp="children"
											onSelect={handleSetSort}
											value={sort ? sort : '-1'}
											options={options}
										/>
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Select
											style={{ minWidth: '240px', textAlign: 'left', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion:'Select Region'}
											optionFilterProp="children"
											// defaultValue={'ALL'}
											value={ admin_roles.includes(permission) ? regionId : allocationId }
											options={regions}
											onSelect={(e, options)=>handleSetregion(e, options)}
										/>
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Space>
										<Button style ={{margin:'5px'}} type='primary' onClick={handleFilterDrawer}><FilterOutlined /></Button>
									</Space>
									<Space>&nbsp;&nbsp;</Space>
									<Popover title={langdata && langdata.RealtimeAlarms ? langdata.RealtimeAlarms:'Realtime Alarms'} content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
									<Space>&nbsp;&nbsp;</Space>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row span={24} style={{
						margin: '10px',
						padding: '10px',
						// marginBottom:'0px',
						// paddingBottom:'0px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >

						<Col style={{justifyContent:'right', display:'flex'}} span={24} className="gutter-row">
							<p style={{marginTop:'7px'}}>{checkresids.length > 0 ?
								`${langdata && langdata.Selected ? langdata.Selected:'Selected'} ${checkresids.length} ${langdata && langdata.items ? langdata.items:'items'} `
								: null}  &nbsp;    </p>    
							<Button 
								disabled={checkresids.length > 0 ? false : true}
								onClick={handleResModelOpen} 
								type='primary'>{langdata && langdata.ADDRESOLUTION ? langdata.ADDRESOLUTION:'ADD RESOLUTION'}</Button>
						</Col>

					</Row>

					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						// marginTop:'0px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24} style={{ margin : '10px'}}>
							{showRelatedAlarm == false && dashboardtype == 'oneview' ?
								<Table 
									scroll={{
										x: 900,
									}}
									columns={columns} dataSource={realtimeAlarmList} pagination = { realtimeAlarmList.length > 0 ? alarm_data_pagination : 0 }
									onChange = { getAlarm }
									expandable={{
										expandedRowKeys: activeExpRow,
										expandedRowRender,
										onExpand:(record,event)=>{handleRealtedAlarm(record,event);},
									}} />
								: 
								<Table 
									scroll={{
										x: 900,
									}}
									columns={columns} dataSource={realtimeAlarmList} pagination = { realtimeAlarmList.length > 0 ? alarm_data_pagination : 0 }
									onChange = { getAlarm }
								/>
							}
						</Col>
					</Row>

					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
						justifyContent:'center',
						display:'flex'
					}} 
					>

					</Row>

					{/* Alarm Edit Starts*/}

					<Modal
						title={langdata && langdata.EditAlarmStatus ? langdata.EditAlarmStatus:'Edit Alarm Status'}
						destroyOnClose
						onCancel={onCloseEditDrawer}
						open={openEditDrawer}
						onOk={onSaveEditDrawer}

						styles={{
							body: {
							},
						}}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Space>
								<Button onClick={onCloseEditDrawer}>
									{langdata && langdata.CANCEL ? langdata.CANCEL:'CANCEL'}
								</Button>
								<Button onClick={onSaveEditDrawer} type="primary">
									{langdata && langdata.SAVE ? langdata.SAVE:'SAVE'}
								</Button>
							</Space>
						]}
					>
						<Form layout="vertical">
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="update status" label={langdata && langdata.UpdateStatus ? langdata.UpdateStatus:'Update Status'} rules={[{ required: true, message: `${langdata && langdata.Pleaseselectthestatus ? langdata.Pleaseselectthestatus:'Please select the status'}` }]} >
										<Select defaultValue={getstatus.status} options={status_options} onChange={handleSelectStatus} />
									</Form.Item>
								</Col>
							</Row>

						</Form>
					</Modal>

					<Drawer
						title={langdata && langdata.Filteroptions ? langdata.Filteroptions:'Filter options'}
						// width={260}
						onClose={onCloseFilterDrawer}
						open={filterDrawer}>
						<Form layout="vertical">
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="activity" label={langdata && langdata.Activity ? langdata.Activity:'Activity'} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectActivity ? langdata.SelectActivity:'Select Activity'}
											optionFilterProp="children"
											options={allevents}
											onChange={handleSelectEvent}
											defaultValue={filterEvent}
											mode="multiple"
										/>
									</Form.Item>
								</Col>
							</Row>
							{dashboardtype == 'oneview' ? 
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="includeRelatedAlarms" label={langdata && langdata.Includerelatedalarms ? langdata.Includerelatedalarms:'Include related alarms'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectIncluderelatedalarms ? langdata.SelectIncluderelatedalarms:'Select Include related alarms'}
												optionFilterProp="children"
												options={includeRelatedAlarms}
												onSelect={handleRelated}
												defaultValue={filterRealted}
											/>
										</Form.Item>
									</Col>
								</Row>
								: null }
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="status" label={langdata && langdata.Status ? langdata.Status:'Status'} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus:'Select Status'}
											optionFilterProp="children"
											options={status}
											onSelect={handleStatus}
											defaultValue={filterStatus}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="severity" label={langdata && langdata.Severity ? langdata.Severity:'Severity'} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectSeverity ? langdata.SelectSeverity:'Select Severity'}
											optionFilterProp="children"
											options={severity}
											onSelect={handleSeverity}
											defaultValue={`${filterSeverity}`}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="pids" label={langdata && langdata.PIDS ? langdata.PIDS:'PIDS'} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
											optionFilterProp="children"
											onSelect={handlePids}
											options={pidsList}
											defaultValue={filterPids}
											value={filterPids}
										/>
									</Form.Item>
								</Col>
							</Row>
							{/* <Row gutter={24}>
								<Col span={24}>
									<Form.Item name="terrain" label="Terrain:"  >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder="Select Terrain"
											optionFilterProp="children"
											options={terrain}
											onSelect={handleTerrain}
											defaultValue={filterTerrain}
										/>
									</Form.Item>
								</Col>
							</Row> */}
							<Row gutter={24}>
								<Col span={24}>
									<Form.Item name="lastHour" label={langdata && langdata.AlarmsinLast ? langdata.AlarmsinLast:'Alarms in Last'} >
										<Select Item
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectTime ? langdata.SelectTime:'Select Time'}
											optionFilterProp="children"
											onSelect={handleLastHour}
											options={lastHours}
											defaultValue={filterLastHour}
											value={filterLastHour}
											disabled={timeSelection}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={24}>
									<Checkbox onChange={changeTimeSelection} checked={timeSelection}>{langdata && langdata.Advancedoptions ? langdata.Advancedoptions:'Advanced options'}</Checkbox>
								</Col>
							</Row>
							{timeSelection == true ?
								<>
									<Row gutter={24} style={{marginTop : '20px'}}>
										<Col span={24}>
											<Form.Item name="date" label={langdata && langdata.Date ? langdata.Date:'Date'}  >
												<DatePicker allowClear={false} onChange={handleDate} style={{ minWidth: '100%'}} defaultValue={dayjs(date)} format={settingsdateformat} disabledDate={disabledDate}/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={24}>
											<Form.Item name="startTime" label={langdata && langdata.SelectTime ? langdata.SelectTime:'Start Time'}  >
												<TimePicker allowClear={false} onChange={handleStartTime} style={{ minWidth: '100%'}} defaultValue={dayjs(startTime, 'HH:mm:ss')}/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={24}>
											<Form.Item name="endTime" label={langdata && langdata.EndTime ? langdata.EndTime:'End Time'}  >
												<TimePicker allowClear={false} onChange={handleEndTime} style={{ minWidth: '100%'}} defaultValue={dayjs(endTime, 'HH:mm:ss')}/>
											</Form.Item>
										</Col>
									</Row>
								</>
								: null }
							<Row gutter={24}>
								<Col span={12} style={{ display:'flex', justifyContent: 'flex-end', marginTop : timeSelection == false ? '20px' : ''}}>
									<Button type='primary' onClick={handleFilterAlarm}>{langdata && langdata.Apply ? langdata.Apply:'Apply'}</Button>
								</Col>
								<Col span={12} style={{ display:'flex', marginTop : timeSelection == false ? '20px' : ''}}>
									<Button onClick={onCloseFilterDrawer}>{langdata && langdata.Close ? langdata.Close:'Close'}</Button>
								</Col>
							</Row>
						</Form>
					</Drawer>

					{/* Alarm Edit Ends */}
					<Modal
						open={modelOpen}
						title={langdata && langdata.AlarmDetails ? langdata.AlarmDetails:'Alarm Details'}
						onOk={handleModelOk}
						onCancel={handleModelCancel}
						footer={[
							<Row style={{marginTop:'25px'}} key={'id'} >
								<Col style={{justifyContent:'left', display:'flex'}} span={6}>
									<Button  key={'id'}
										onClick={() => {
											navigator.clipboard.writeText(contenttext);
											const args = {
												message: `${langdata && langdata.Alarmdetailscopiedtoclipboard ? langdata.Alarmdetailscopiedtoclipboard:'Alarm details copied to clipboard'}`,
												description: '',
												duration: 1,
											};
											notification.open(args);
										}}
									
									>
										{langdata && langdata.CopyToClipboard ? langdata.CopyToClipboard:'Copy To Clipboard'}
									</Button>
								</Col>
								{
									// eslint-disable-next-line react/jsx-key
								}
								<Col  span={18}>

									<Button onClick={handleModelCancel}>
										{langdata && langdata.Close ? langdata.Close:'Close'}
									</Button>
									{
									// eslint-disable-next-line react/jsx-key
									}
									<Button  style={{marginLeft:'10px'}} type="primary" onClick={() => {navigate(( '/alarm-details'),{ state: { data: alarmdata.relatimealarmdata_details, path: 'realtime-alarm' } });}}>
										{langdata && langdata.Moreinfo ? langdata.Moreinfo:'More info'}
									</Button>
								</Col>

							</Row>,
						]}
					>
						<Divider/>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}>

							<Col span={12}>          
								<Typography>{langdata && langdata.Id ? langdata.Id:'Id'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.alarm_prm_id}</Typography>
								<Typography>{langdata && langdata.Activity ? langdata.Activity:'Activity'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType}</b></Typography>
								<Typography>{langdata && langdata.Date ? langdata.Date:'Date'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && 
								moment.utc(alarmdata.relatimealarmdata_details.datetime).local().format(settingsdateformat + ' ' + settingstimeformat)
								}</b>
								</Typography>
								<Typography>{langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Lat}</Typography>
								<Typography>{langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Long}</Typography>
								<Typography>{langdata && langdata.Region ? langdata.Region:'Region'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.regioninfoId && regiondata && regiondata.map((region) => { if (alarmdata.relatimealarmdata_details.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
								<Typography>{langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (alarmdata.relatimealarmdata_details.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
								<Typography>{langdata && langdata.Channel ? langdata.Channel:'Channel'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.channel_name}</Typography>
								<Typography>{langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.info}</Typography>
								<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.odMeter}{' m'}</Typography>
								<Typography>{langdata && langdata.Duration ? langdata.Duration:'Duration'}: {alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.duration / 60).toFixed(2)} {' '}Min</Typography>
								<Typography style={{color:alarmdata.relatimealarmdata_details.alarmSeverity === 0 ? 'CLEAR':
									alarmdata.relatimealarmdata_details.alarmSeverity === 1 ? 'green':
										alarmdata.relatimealarmdata_details.alarmSeverity === 2 ? '#fa9e0a' :
											alarmdata.relatimealarmdata_details.alarmSeverity === 3 ? 'red' : 
												'black'}}>{langdata && langdata.Severity ? langdata.Severity:'Severity'}: {alarmdata && alarmdata.relatimealarmdata_details && 
          (alarmdata.relatimealarmdata_details.alarmSeverity === 0 ? 'CLEAR':alarmdata.relatimealarmdata_details.alarmSeverity === 1 ? 'GREEN':alarmdata.relatimealarmdata_details.alarmSeverity === 2 ? 'AMBER' :alarmdata.relatimealarmdata_details.alarmSeverity === 3 ? 'RED' : '' )
									}</Typography>
								<Typography><b>{langdata && langdata.EventProbability ? langdata.EventProbability:'Event Probability'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventprobablity ? ((alarmdata.relatimealarmdata_details.eventprobablity)*100).toFixed(2) : 0}%</b></Typography>
								<Typography>{langdata && langdata.Resolutionstatus ? langdata.Resolutionstatus:'Resolution status'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.status)}</b></Typography>
								<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.remarks)}</Typography>
							</Col>
							<Col span={12}> 
								<img src={alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.activityUrl} alt={`${alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType}`} align="right" />
							</Col>
						</Row>
					</Modal>

					<Modal
						open={modelResolutionOpen}
						title={langdata && langdata.AddResolution ? langdata.AddResolution:'Add Resolution'}
						onOk={handleResModelSave}
						onCancel={handleResModelCancel}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Button onClick={handleResModelCancel}>
								{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
							</Button>,
							// eslint-disable-next-line react/jsx-key
							<Button onClick={handleResModelSave} type="primary">
								{langdata && langdata.Save ? langdata.Save:'Save'}
							</Button>,
						]}
					>
						<Divider/>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}
						>

							<Col style={{margin:'10px'}} span={24}>         


								<Radio.Group onChange={onResChange} value={resvalue}>
									<Space direction="vertical">
										<Radio value={1}>{langdata && langdata.Inspectedactivityissameasreported ? langdata.Inspectedactivityissameasreported:'Inspected activity is same as reported'}</Radio>
										<Radio value={2}>{langdata && langdata.Inspectedactivityisdifferentfromreported ? langdata.Inspectedactivityisdifferentfromreported:'Inspected activity is different from reported'}</Radio>
									</Space>
								</Radio.Group>
							</Col>

							<Col span={24}>
								{resvalue === 2 ? (
									<div style={{ marginTop: '10px' }}>
										<div style={{ textAlign: 'left', marginLeft: '22%', width: '100%' }}>
											<Radio.Group onChange={onChangeEvent}>
												{events}
												<Radio style={radioStyle} value={'others'}>
													{langdata && langdata.Others ? langdata.Others:'Others'}
													{showOtherOpt && (
														<Select
															// onChange={this.commentChange}
															style={{ width: '80%', marginLeft: 10 }}
														>
															<Option value="Sheep/Cattle Grazing">{langdata && langdata.SheepCattleGrazing ? langdata.SheepCattleGrazing:'Sheep/Cattle Grazing'}</Option>
															<Option value="Horse/Cow/Buffalo/Goat tied inside ROU">
																{langdata && langdata.HorseCowBuffaloGoattiedinsideROU ? langdata.HorseCowBuffaloGoattiedinsideROU:'Horse/Cow/Buffalo/Goat tied inside ROU'}
															</Option>
															<Option value="Nala flowing inside ROU">
																{langdata && langdata.NalaflowinginsideROU ? langdata.NalaflowinginsideROU:'Nala flowing inside ROU'}
															</Option>
															<Option value="Ploughing activity">{langdata && langdata.Ploughingactivity ? langdata.Ploughingactivity:'Ploughing activity'}</Option>
															<Option value="Grass cutting">{langdata && langdata.Grasscutting ? langdata.Grasscutting:'Grass cutting'}</Option>
															<Option value="No activity found">{langdata && langdata.Noactivityfound ? langdata.Noactivityfound:'No activity found'}</Option>
														</Select>
													)}
												</Radio>
											</Radio.Group>
										</div>
									</div>
								) : null}
								<br />
							</Col>


							<Col span={24}> 
								{langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}:

								<br />

								<TextArea style={{marginTop:'10px'}} value={resRemarks}  onChange={handleResComment} rows={4} placeholder={langdata && langdata.MaxLengthis ? langdata.MaxLengthis:'Max Length is 500'} maxLength={500} />

							</Col>
							<Col title={daysDifference < 2 ? '' : langdata && langdata.Resolutionisavailableonlyforlast48hours ? langdata.Resolutionisavailableonlyforlast48hours: 'Resolution is available only for last 48 hours'} style={{margin:'10px'}} span={24}>  
								<Checkbox disabled={daysDifference < 2 ? false : true} checked={retraincheck} onChange={onCheck}>{langdata && langdata.Savetheactivityforretraining ? langdata.Savetheactivityforretraining:'Save the activity for retraining'}</Checkbox>
							</Col>
						</Row>
					</Modal>

					<Modal
						open={bulkmodelResolutionOpen}
						title={langdata && langdata.Addbulkresolution ? langdata.Addbulkresolution:'Add bulk resolution'}
						onOk={handlebulkResModelSave}
						onCancel={bulkhandleResModelCancel}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Button onClick={handlebulkResModelSave} type="primary">
								{langdata && langdata.Save ? langdata.Save:'Save'}
							</Button>,
							// eslint-disable-next-line react/jsx-key
							<Button onClick={bulkhandleResModelCancel}>
								{langdata && langdata.Cancel ? langdata.Cancel:'Cancel'}
							</Button>,
						]}
					>
						<Divider/>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}
						>

							<Col style={{margin:'10px'}} span={24}>         


								<Radio.Group onChange={onResChange} value={resvalue}>
									<Space direction="vertical">
										<Radio value={1}>{langdata && langdata.Inspectedactivityissameasreported ? langdata.Inspectedactivityissameasreported:'Inspected activity is same as reported'}</Radio>
										<Radio value={2}>{langdata && langdata.Inspectedactivityisdifferentfromreported ? langdata.Inspectedactivityisdifferentfromreported:'Inspected activity is different from reported'}</Radio>
									</Space>
								</Radio.Group>
							</Col>

							<Col span={24}>
								{resvalue === 2 ? (
									<div style={{ marginTop: '10px' }}>
										<div style={{ textAlign: 'left', marginLeft: '22%', width: '100%' }}>
											<Radio.Group onChange={onChangeEvent}>
												{events}
												<Radio style={radioStyle} value={'others'}>
													{langdata && langdata.Others ? langdata.Others:'Others'}
													{showOtherOpt && (
														<Select
															// onChange={this.commentChange}
															style={{ width: '80%', marginLeft: 10 }}
														>
															<Option value="Sheep/Cattle Grazing">{langdata && langdata.SheepCattleGrazing ? langdata.SheepCattleGrazing:'Sheep/Cattle Grazing'}</Option>
															<Option value="Horse/Cow/Buffalo/Goat tied inside ROU">
																{langdata && langdata.HorseCowBuffaloGoattiedinsideROU ? langdata.HorseCowBuffaloGoattiedinsideROU:'Horse/Cow/Buffalo/Goat tied inside ROU'}
															</Option>
															<Option value="Nala flowing inside ROU">
																{langdata && langdata.NalaflowinginsideROU ? langdata.NalaflowinginsideROU:'Nala flowing inside ROU'}
															</Option>
															<Option value="Ploughing activity">{langdata && langdata.Ploughingactivity ? langdata.Ploughingactivity:'Ploughing activity'}</Option>
															<Option value="Grass cutting">{langdata && langdata.Grasscutting ? langdata.Grasscutting:'Grass cutting'}</Option>
															<Option value="No activity found">{langdata && langdata.Noactivityfound ? langdata.Noactivityfound:'No activity found'}</Option>
														</Select>
													)}
												</Radio>
											</Radio.Group>
										</div>
									</div>
								) : null}
								<br />
							</Col>


							<Col span={24}> 
								{langdata && langdata.Remarks ? langdata.Remarks:'Remarks'}

								<br />

								<TextArea style={{marginTop:'10px'}} value={resRemarks}  onChange={handleResComment} rows={4} placeholder={langdata && langdata.MaxLengthis ? langdata.MaxLengthis:'Max Length is 500'} maxLength={500} />

							</Col>
							<Col title={daysDifference < 2 ? '' : langdata && langdata.Resolutionisavailableonlyforlast48hours ? langdata.Resolutionisavailableonlyforlast48hours: 'Resolution is available only for last 48 hours'}  style={{margin:'10px'}} span={24}>  
								<Checkbox disabled={daysDifference < 2 ? false : true} checked={retraincheck} onChange={onCheck} >{langdata && langdata.Savetheactivityforretraining ? langdata.Savetheactivityforretraining:'Save the activity for retraining'}</Checkbox>
							</Col>
						</Row>
					</Modal>

					<Spin fullscreen spinning={alarmdata.pending} />

				</Content>
  
				:


			//   Map View Starts

				<>
					<Content>

						<Row style={{
							margin: '10px',
							marginBottom: '0px',
							paddingTop: '10px',
							paddingBottom: '10px',
							background: '#FFFFFF',
							alignItems:'right'
						}} >
							<Col span={4}>
								<Row style={{alignItems:'center', textAlign: 'center'}}>
									<Col span={24} style={{textAlign: 'left', paddingLeft: '10px'}}>
										<Radio.Group  style={{margin:'5px'}} value={view} onChange={(e) => setSize(e.target.value)}>
											<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
											<Radio.Button value="Map">{langdata && langdata.Map ? langdata.Map:'Map'}</Radio.Button>
										</Radio.Group>
									</Col>
								</Row>
							</Col>
							<Col span={20} style={{paddingRight: '10px'}}>
								<Row style={{alignItems:'center', textAlign: 'right'}}>
									<Col span={24}>
										<Space>
											<Select
												style={{ minWidth: '240px', textAlign: 'left', margin:'5px'}}
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion:'Select Region'}
												optionFilterProp="children"
												// defaultValue={'ALL'}
												value={ admin_roles.includes(permission) ? regionId : allocationId }
												options={regions}
												onSelect={(e, options)=>handleSetregion(e, options)}
											/>
										</Space>
										<Space>&nbsp;&nbsp;</Space>
										<Space>
											<Button type='primary' onClick={handleFilterDrawer}><FilterOutlined /></Button>
										</Space>
									</Col>
								</Row>
							</Col>
						</Row>

						{/* map filter starts  */}
						{map_type && map_type !== 'leaflet' ?

							<Googlemap alarmData = {alarmdata.mapdatas} onClick = {key} regionId={regionId} startCh={startCh} endCh={endCh} alarmfilterDrawer = {filterDrawer} />

							:
							<>

								{mapdata && mapdata ? 

									<div className="App" style={{textAlign: 'left', margin : '10px'}}>
										<MapContainer center={center} zoom={14} scrollWheelZoom={false}>
											<TileLayer
												attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
												url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
											/>
											{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

											{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

											<LayersControl position="topright" >

												{/* <LayersControl.Overlay  checked name="PIDS"> */}

												<MarkerClusterGroup>
													{allPidsDatas && allPidsDatas.map((marker,index)=>
														<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
															<Popup>
																{marker.pop}
															</Popup>

														</Marker>

														

													)}       
												</MarkerClusterGroup>
												{/* </LayersControl.Overlay> */}


												<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP:'KMP'}>
													<MarkerClusterGroup>

														{KMP && KMP.map((marker,index)=>
															<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>

    
												<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB:'WB'}>
													<MarkerClusterGroup>

														{WB && WB.map((marker,index)=>
															<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>




												<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP:'TP'}>
													<MarkerClusterGroup>

														{Turn && Turn.map((marker,index)=>
															<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>

  
												<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP:'TLP'}>
													<MarkerClusterGroup>

														{TLP && TLP.map((marker,index)=>
															<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>

												</LayersControl.Overlay>


												{/* <LayersControl.Overlay  name="POINTS">
										<MarkerClusterGroup>

											{points && points.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
													<Popup>
														{marker.pop}
													</Popup>
													<Tooltip>{marker.pop}</Tooltip>
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay> */}



												<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH:'CH'}>
													<MarkerClusterGroup>
														{markers && markers.map((marker,index)=>
															<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}

													</MarkerClusterGroup>
												</LayersControl.Overlay>

												<LayersControl.Overlay checked name={langdata && langdata.Alarms ? langdata.Alarms:'Alarms'}>
													<MarkerClusterGroup>

														{alarm_markers && alarm_markers.map((marker,index)=>
															<Marker   
																eventHandlers={{
																	click: () => {
																		key(marker.id);
																	},
																}}
																key={`alarm-${index}`} position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
																<Popup>
																	{marker.pop}
																</Popup>
																{/* <Tooltip>{marker.pop}</Tooltip> */}
															</Marker>

														)}       
													</MarkerClusterGroup>
												</LayersControl.Overlay>


       
												{/* <LayersControl.Overlay  checked name="LINE">
										<Polyline pathOptions={linecolor} weight={7} positions={line} />
									</LayersControl.Overlay> */}

      
												<LayersControl.Overlay  checked name={langdata && langdata.Pipeline ? langdata.Pipeline:'Pipeline'}>
													<MarkerClusterGroup>
														<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
															{tempMapArray && Object.values(tempMapArray).map((item) => {
																let pipeline = [];
																item.map((data) => {
																	pipeline.push({lat : data.latitude,lng : data.longitude});
																});
																return (
																	<>
																		<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																	</>
																);
															})}
														</Pane>
													</MarkerClusterGroup>
												</LayersControl.Overlay>


												<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder:'Pipeline Border'}>
													<MarkerClusterGroup>
														{tempMapArray && Object.values(tempMapArray).map((item) => {
															let pipelineBorder = [];
															item.map((data) => {
																pipelineBorder.push({lat : data.latitude,lng : data.longitude});
															});
															return (
																<>
																	<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																</>
															);
														})}
													</MarkerClusterGroup>
												</LayersControl.Overlay>


												<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber:'Fiber'}>
													<MarkerClusterGroup>
														{tempMapArray && Object.values(tempMapArray).map((item) => {
															let odmeter = [];
															item.map((data) => {
																odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
															});
															return (
																<>
																	<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
																</>
															);
														})}
													</MarkerClusterGroup>
												</LayersControl.Overlay>


												<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath:'Optional Path'}>
													<MarkerClusterGroup>
														{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
															let optionalpath = [];
															item.map((data) => {
																optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
															});
															return (
																<>
																	<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
																</>
															);
														})}
													</MarkerClusterGroup>
												</LayersControl.Overlay>


											</LayersControl>
										</MapContainer>

									</div> : null}
							</> }
						{/* map ends */}

						{/* Model Starts */}

						<Modal
							open={modelOpen}
							title={langdata && langdata.AlarmDetails ? langdata.AlarmDetails:'Alarm Details'}
							onOk={handleModelOk}
							onCancel={handleModelCancel}
							footer={[
							// eslint-disable-next-line react/jsx-key
								<Button onClick={handleModelCancel}>
									{langdata && langdata.Close ? langdata.Close:'Close'}
								</Button>,
								// <Button type="primary" onClick={()=>handleModelOk(data.realtimeAlarmList.id)}>
								//   More info
								// </Button>,
								// eslint-disable-next-line react/jsx-key
								<Button type="primary" onClick={() => {navigate(( '/alarm-details'),{ state: { data: alarmdata.relatimealarmdata_details, path: 'realtime-alarm' } });}}>
									{langdata && langdata.Moreinfo ? langdata.Moreinfo:'More info'}
								</Button>,
							]}
						>
							<Divider/>
							<Row
								gutter={{
									xs: 8,
									sm: 16,
									md: 24,
									lg: 32,
								}}>

								<Col span={12}>          
									<Typography>{langdata && langdata.Id ? langdata.Id:'Id'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.alarm_prm_id}</Typography>
									<Typography>{langdata && langdata.Activity ? langdata.Activity:'Activity'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType}</b></Typography>
									<Typography>{langdata && langdata.Date ? langdata.Date:'Date'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && 
								moment.utc(alarmdata.relatimealarmdata_details.datetime).local().format(settingsdateformat + ' ' + settingstimeformat)
									}</b>
									</Typography>
									<Typography>{langdata && langdata.Latitude ? langdata.Latitude:'Latitude'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Lat}</Typography>
									<Typography>{langdata && langdata.Longitude ? langdata.Longitude:'Longitude'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.Long}</Typography>
									<Typography>{langdata && langdata.Region ? langdata.Region:'Region'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.regioninfoId && regiondata && regiondata.map((region) => { if (alarmdata.relatimealarmdata_details.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
									<Typography>{langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (alarmdata.relatimealarmdata_details.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
									<Typography>{langdata && langdata.Channel ? langdata.Channel:'Channel'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.channel_name}</Typography>
									<Typography>{langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}: {alarmdata && alarmdata.relatimealarmdata_details.locationDetails && alarmdata.relatimealarmdata_details.locationDetails.info}</Typography>
									<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength:'Fiber Length'}: {alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.odMeter}{' m'}</Typography>
									<Typography>{langdata && langdata.Duration ? langdata.Duration:'Duration'}: {alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.duration / 60).toFixed(2)} {' '}Min</Typography>
									<Typography style={{color:alarmdata.relatimealarmdata_details.alarmSeverity === 0 ? 'CLEAR':
										alarmdata.relatimealarmdata_details.alarmSeverity === 1 ? 'green':
											alarmdata.relatimealarmdata_details.alarmSeverity === 2 ? '#fa9e0a' :
												alarmdata.relatimealarmdata_details.alarmSeverity === 3 ? 'red' : 
													'black'}}>{langdata && langdata.Severity ? langdata.Severity:'Severity'}: {alarmdata && alarmdata.relatimealarmdata_details && 
          (alarmdata.relatimealarmdata_details.alarmSeverity === 0 ? 'CLEAR':alarmdata.relatimealarmdata_details.alarmSeverity === 1 ? 'GREEN':alarmdata.relatimealarmdata_details.alarmSeverity === 2 ? 'AMBER' :alarmdata.relatimealarmdata_details.alarmSeverity === 3 ? 'RED' : '' )
										}</Typography>
									<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability:'Event Probability'}: <b>{alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventprobablity ? ((alarmdata.relatimealarmdata_details.eventprobablity)*100).toFixed(2) : 0}%</b></Typography>
									<Typography>{langdata && langdata.Resolutionstatus ? langdata.Resolutionstatus:'Resolution status'}: {alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.status)}</Typography>
									<Typography>{langdata && langdata.Remark ? langdata.Remark:'Remark'}: {alarmdata && alarmdata.relatimealarmdata_details && (alarmdata.relatimealarmdata_details.remarks)}</Typography>
								</Col>
								<Col span={12}> 
									<img src={alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.activityUrl} alt={`${alarmdata && alarmdata.relatimealarmdata_details && alarmdata.relatimealarmdata_details.eventType}`} align="right" />
								</Col>
							</Row>
						</Modal>
					

						<Drawer
							title={langdata && langdata.Filteroptions ? langdata.Filteroptions:'Filter options'}
							// width={260}
							onClose={onCloseFilterDrawer}
							open={filterDrawer}>
							<Form layout="vertical">
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="activity" label={langdata && langdata.Activity ? langdata.Activity:'Activity'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectActivity ? langdata.SelectActivity:'Select Activity'}
												optionFilterProp="children"
												options={allevents}
												onChange={handleSelectEvent}
												defaultValue={filterEvent}
												mode="multiple"
											/>
										</Form.Item>
									</Col>
								</Row>
								{dashboardtype == 'oneview' ? 
									<Row gutter={24}>
										<Col span={24}>
											<Form.Item name="includeRelatedAlarms" label={langdata && langdata.Includerelatedalarms ? langdata.Includerelatedalarms:'Include related alarms'} >
												<Select Item
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.SelectIncluderelatedalarms ? langdata.SelectIncluderelatedalarms:'Select Include related alarms'}
													optionFilterProp="children"
													options={includeRelatedAlarms}
													onSelect={handleRelated}
													defaultValue={filterRealted}
												/>
											</Form.Item>
										</Col>
									</Row>
									: null }
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="status" label={langdata && langdata.Status ? langdata.Status:'Status'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus:'Select Status'}
												optionFilterProp="children"
												options={status}
												onSelect={handleStatus}
												defaultValue={filterStatus}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="severity" label={langdata && langdata.Severity ? langdata.Severity:'Severity'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectSeverity ? langdata.SelectSeverity:'Select Severity'}
												optionFilterProp="children"
												options={severity}
												onSelect={handleSeverity}
												defaultValue={`${filterSeverity}`}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="pids" label={langdata && langdata.PIDS ? langdata.PIDS:'PIDS'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectPIDS ? langdata.SelectPIDS:'Select PIDS'}
												optionFilterProp="children"
												onSelect={handlePids}
												options={pidsList}
												defaultValue={filterPids}
												value={filterPids}
											/>
										</Form.Item>
									</Col>
								</Row>
								{/* <Row gutter={24}>
									<Col span={24}>
										<Form.Item name="terrain" label="Terrain:"  >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder="Select Terrain"
												optionFilterProp="children"
												options={terrain}
												onSelect={handleTerrain}
												defaultValue={filterTerrain}
											/>
										</Form.Item>
									</Col>
								</Row> */}
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item name="lastHour" label={langdata && langdata.AlarmsinLast ? langdata.AlarmsinLast:'Alarms in Last'} >
											<Select Item
												showSearch
												filterOption={filterOption}
												placeholder={langdata && langdata.SelectTime ? langdata.SelectTime:'Select Time'}
												optionFilterProp="children"
												onSelect={handleLastHour}
												options={lastHours}
												defaultValue={filterLastHour}
												value={filterLastHour}
												disabled={timeSelection}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span={24}>
										<Checkbox onChange={changeTimeSelection} checked={timeSelection}>{langdata && langdata.Advancedoptions ? langdata.Advancedoptions:'Advanced options'}</Checkbox>
									</Col>
								</Row>
								{timeSelection == true ?
									<>
										<Row gutter={24} style={{marginTop : '20px'}}>
											<Col span={24}>
												<Form.Item name="date" label={langdata && langdata.Date ? langdata.Date:'Date'} >
													<DatePicker allowClear={false} onChange={handleDate} style={{ minWidth: '100%'}} defaultValue={dayjs(date)} format={settingsdateformat} disabledDate={disabledDate}/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={24}>
											<Col span={24}>
												<Form.Item name="startTime" label={langdata && langdata.StartTime ? langdata.StartTime:'Start Time'}  >
													<TimePicker allowClear={false} onChange={handleStartTime} style={{ minWidth: '100%'}} defaultValue={dayjs(startTime, 'HH:mm:ss')}/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={24}>
											<Col span={24}>
												<Form.Item name="endTime" label={langdata && langdata.EndTime ? langdata.EndTime:'End Time'}  >
													<TimePicker allowClear={false} onChange={handleEndTime} style={{ minWidth: '100%'}} defaultValue={dayjs(endTime, 'HH:mm:ss')}/>
												</Form.Item>
											</Col>
										</Row>
									</>
									: null }
								<Row gutter={24}>
									<Col span={12} style={{ display:'flex', justifyContent: 'flex-end', marginTop : timeSelection == false ? '20px' : ''}}>
										<Button type='primary' onClick={handleFilterAlarm}>{langdata && langdata.Apply ? langdata.Apply:'Apply'}</Button>
									</Col>
									<Col span={12} style={{ display:'flex', marginTop : timeSelection == false ? '20px' : ''}}>
										<Button onClick={onCloseFilterDrawer}>{langdata && langdata.Close ? langdata.Close:'Close'}</Button>
									</Col>
								</Row>
							</Form>
						</Drawer>

						
    
						<Spin fullscreen spinning={alarmdata.pending} />

					</Content>

				</>
    

      
			}
            


		</>
	);
}