import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Avatar, Card, Popover, Empty, Row, Col, Select, Typography, Tooltip, Space, Checkbox, Spin, message } from 'antd';
import { Content } from 'antd/es/layout/layout';

import Chart from 'react-apexcharts';
import useResizeObserver from 'use-resize-observer';

import { FULL_CPS_LIST, FULL_CPS_LIST_SUCCESS, FULL_CPS_LIST_ERROR }  from './constants';

import fullCPSReducer from './reducer';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

import axios from 'axios';
import { InfoOutlined, LineChartOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export default function FullCPSReport() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const tlp_feature = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES` , null));
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const [regionId, setRegionId] = useState(null);
  
	const { ref, width } = useResizeObserver();
	
	const [filterVDC1, setFilterVDC1] = useState(false);
	const [filterVAC1, setFilterVAC1] = useState(false);
	const [filterVDC2, setFilterVDC2] = useState(false);
	const [filterVAC2, setFilterVAC2] = useState(false);
	const [filterBattery, setFilterBattery] = useState(false);
	const [filterCurrent, setFilterCurrent] = useState(false);
	const [filterTemp, setFilterTemp] = useState(false);

	const [filterVDC1Min, setFilterVDC1Min] = useState(false);
	const [filterVDC1Max, setFilterVDC1Max] = useState(false);
	const [filterVAC1Min, setFilterVAC1Min] = useState(false);
	const [filterVAC1Max, setFilterVAC1Max] = useState(false);
  
	const [filterVDC2Min, setFilterVDC2Min] = useState(false);
	const [filterVDC2Max, setFilterVDC2Max] = useState(false);
	const [filterVAC2Min, setFilterVAC2Min] = useState(false);
	const [filterVAC2Max, setFilterVAC2Max] = useState(false);

	const [referenceCellValue, setReferenceCellValue] = useState(tlp_feature==null ? false : tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType==2 ? false : tlp_feature && tlp_feature.refElectrodeType==2 ? false : true);
  
	const [series, setSeries] = useState([]);
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const initalState = { cps: [], tlps: [], loading: false, error: false };
	const [data, dispatch] = useReducer(fullCPSReducer, initalState);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
  
	let regions = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}];
  
	let pipelineChainages = [];
	let vac1s = [];
	let vdc2NoRefs = [];
	let vdc2Refs = [];
	let vdc1NoRefs = [];
	let vdc1Refs = [];
	let vac2s = [];
	let vbats = [];
	let temps = [];
	let currents = [];
	let vac1Mins = [];
	let vac1Maxs = [];
	let vdc1Mins = [];
	let vdc1Maxs = [];
	let vac2Mins = [];
	let vac2Maxs = [];
	let vdc2Mins = [];
	let vdc2Maxs = [];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	let options = {
		dataLabels: {
			enabled: false
		},
		title: {
			text: langdata && langdata.FullCPSReport ? langdata.FullCPSReport : 'FULL CPS CHART',
			align: 'left'
		},
		chart: {
			toolbar: {
				show: false
			}
		},
		legend: {
			show: true,
			position: 'top',
			horizontalAlign: 'left',
			onItemClick: {
				toggleDataSeries: false
			},
			onItemHover: {
				highlightDataSeries: false
			}
		},
		xaxis: {
			title: {
				text: langdata && langdata.CHAINAGE ? langdata.CHAINAGE : 'CHAINAGE'
			},
			categories: pipelineChainages,
			labels: {
				show: true,
				showDuplicates: true,
				formatter: function (val) {
					if (val) {
						return parseFloat(val).toFixed(2);
					} else {
						return val;
					}
				}
			}
		},
		markers: {
			size: 5,
			shape: 'square'
		},
		stroke: {
			curve: 'smooth',
			width: 2
		}
	};

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	const handleChangeRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(permission == 'superadmin' || permission == 'admin') {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
		}
	};

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.FullCPSContent1 ? langdata.FullCPSContent1 : 'This module offers a real-time view of the Smart TLPs installed on the pipeline, displaying all the Smart TLPs and their most recently updated values in a graphical format. It also enables users to compare various parameters across different Smart TLPs.'}</p>
			<br/>
		</Card>
	);

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 22);
	}, []);
  
	useEffect(() => {
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		loadCPs();
	}, [regionId, allocationId]);

	const loadCPs = () => {
		dispatch({ type: FULL_CPS_LIST });
    
		let url = `${api}/tlplogsbulkcps?$limit=100000&$sort[pipelineChainage]=1`;

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regionId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}

		if ((regionId && regionId !== null) || (allocationId && allocationId !== null)) {
			url = `${api}/tlplogsbulkcps?$limit=100000&$sort[pipelineChainage]=1${filterByregionIdPart}`;
		}

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config).then((response) => {
			if (response && response.data) {
				dispatch( { type: FULL_CPS_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: FULL_CPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: FULL_CPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const onChangeVDC2 = () => {
		let newVal = !filterVDC2;
		let fields = series;
    
		setFilterVDC2(newVal);
		onChangeVDC2Min(newVal);
		onChangeVDC2Max(newVal);

		if (newVal === true) {
			fields.push({name: 'PSP (V)', data: referenceCellValue === true ? vdc2Refs : vdc2NoRefs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'PSP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
    
		setSeries(fields);
	};

	const onChangeVAC2 = () => {
		let newVal = !filterVAC2;
		let fields = series;

		setFilterVAC2(newVal);
		onChangeVAC2Min(newVal);
		onChangeVAC2Max(newVal);

		if (newVal === true) {
			fields.push({name: 'AC PICKUP (V)', data: vac2s});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVDC1 = () => {
		let newVal = !filterVDC1;
		let fields = series;

		setFilterVDC1(newVal);
		onChangeVDC1Min(newVal);
		onChangeVDC1Max(newVal);

		if (newVal === true) {
			fields.push({name: 'CSP (V)', data: referenceCellValue === true ? vdc1Refs : vdc1NoRefs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'CSP (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);  
	};

	const onChangeVAC1 = () => {      
		let newVal = !filterVAC1;
		let fields = series;

		setFilterVAC1(newVal);
		onChangeVAC1Min(newVal);
		onChangeVAC1Max(newVal);

		if (newVal === true) {
			fields.push({name: 'CASING AC PICKUP (V)', data: vac1s});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields); 
	};

	const onChangeBattery = () => {
		let newVal = !filterBattery;
		let fields = series;

		setFilterBattery(newVal);

		if (newVal === true) {
			fields.push({name: 'BATTERY (V)', data: vbats});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'BATTERY (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeCurrent = () => {  
		let newVal = !filterCurrent;
		let fields = series;

		setFilterCurrent(newVal);

		if (newVal === true) {
			fields.push({name: 'CURRENT', data: currents});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'CURRENT';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);  
	};

	const onChangeTemp = () => {
		let newVal = !filterTemp;
		let fields = series;

		setFilterTemp(newVal);

		if (newVal === true) {
			fields.push({name: 'TEMPERATURE', data: temps});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'TEMPERATURE';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVAC1Min = (e) => { 
		let newVal = !filterVAC1Min;
		let fields = series;

		if(e !=='CASING AC PICKUP MIN'){
			setFilterVAC1Min(e);

		}else{
			setFilterVAC1Min(newVal);

		}

		if (newVal === true && e ==='CASING AC PICKUP MIN') {
			fields.push({name: 'CASING AC PICKUP MIN (V)', data: vac1Mins});
		} else if (newVal === false && e ==='CASING AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		
		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: 'CASING AC PICKUP MIN (V)', data: vac1Mins});
		}

		setSeries(fields);
	};

	const onChangeVAC1Max = (e) => { 
		let newVal = !filterVAC1Max;
		let fields = series;

		if(e !=='CASING AC PICKUP MAX'){
			setFilterVAC1Max(e);

		}else{

			setFilterVAC1Max(newVal);
		}


		if (newVal === true && e =='CASING AC PICKUP MAX') {
			fields.push({name: 'CASING AC PICKUP MAX (V)', data: vac1Maxs});
		} else if(newVal === false && e =='CASING AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if (e==false && newVal==false){
			let index = fields.findIndex(function(item) { return item.name === 'CASING AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e==true && newVal==true){
			fields.push({name: 'CASING AC PICKUP MAX (V)', data: vac1Maxs});

		}

		setSeries(fields);
	};

	const onChangeVDC1Min = (e) => {
		let newVal = !filterVDC1Min;
		let fields = series;

		if(e !== 'CSP MIN'){
			setFilterVDC1Min(e);
		}else{
			setFilterVDC1Min(newVal);
		}


		if (newVal === true && e == 'CSP MIN') {
			fields.push({name: 'CSP MIN (V)', data: vdc1Mins});
		} else if (newVal === false && e == 'CSP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		if(e==false && newVal == false){
			let index = fields.findIndex(function(item) { return item.name === 'CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}else if (e==true && newVal == true){
			fields.push({name: 'CSP MIN (V)', data: vdc1Mins});
		}

		setSeries(fields);
	};

	const onChangeVDC1Max = (e) => { 
		let newVal = !filterVDC1Max;
		let fields = series;

		if(e !== 'CSP MAX'){
			setFilterVDC1Max(e);
		}
		else{
			setFilterVDC1Max(newVal);
		}

		if (newVal === true && e == 'CSP MAX') {
			fields.push({name: 'CSP MAX (V)', data: vdc1Maxs});
		} else if(newVal === false && e == 'CSP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e ==false && newVal == false){
			let index = fields.findIndex(function(item) { return item.name === 'CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}else if (e == true && newVal == true){
			fields.push({name: 'CSP MAX (V)', data: vdc1Maxs});

		}
		

		setSeries(fields);
	};

	const onChangeVAC2Min = (e) => { 
		let newVal = !filterVAC2Min;
		let fields = series;


		if(e !== 'AC PICKUP MIN'){
			setFilterVAC2Min(e);

		}else{
			setFilterVAC2Min(newVal);
		}

		if (newVal === true && e === 'AC PICKUP MIN') {
			fields.push({name: 'AC PICKUP MIN (V)', data: vac2Mins});
		} else if (newVal === false && e === 'AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e ==false && newVal == false){
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e ==true && newVal == true){
			fields.push({name: 'AC PICKUP MIN (V)', data: vac2Mins});

		}

		setSeries(fields);
	};

	const onChangeVAC2Max = (e) => { 
		let newVal = !filterVAC2Max;
		let fields = series;

		if(e!== 'AC PICKUP MAX'){
			setFilterVAC2Max(e);
		}else{
			setFilterVAC2Max(newVal);
		}

		if (newVal === true && e==='AC PICKUP MAX') {
			fields.push({name: 'AC PICKUP MAX (V)', data: vac2Maxs});
		} else if(newVal === false && e==='AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e ==false && newVal == false){
			let index = fields.findIndex(function(item) { return item.name === 'AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}else if(e == true && newVal == true){
			fields.push({name: 'AC PICKUP MAX (V)', data: vac2Maxs});
		}

		setSeries(fields);
	};

	const onChangeVDC2Min = (e) => {
		let newVal = !filterVDC2Min;
		let fields = series;

		if(e !== 'PSP MIN'){
			setFilterVDC2Min(e);
		}
		else{
			setFilterVDC2Min(newVal);
		}

		if (newVal === true && e == 'PSP MIN') {
			fields.push({name: 'PSP MIN (V)', data: vdc2Mins});
		} else if (newVal === false && e == 'PSP MIN') {
			let index = fields.findIndex(function(item) { return item.name === 'PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e=== false && newVal=== false){
			let index = fields.findIndex(function(item) { return item.name === 'PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e=== true && newVal=== true){
			fields.push({name: 'PSP MIN (V)', data: vdc2Mins});

		}

		setSeries(fields);
	};

	const onChangeVDC2Max = (e) => { 
		let newVal = !filterVDC2Max;
		let fields = series;

		if(e !== 'PSP MAX'){
			setFilterVDC2Max(e);
		}else{
			setFilterVDC2Max(newVal);
		}


		if (newVal === true && e =='PSP MAX' ) {
			fields.push({name: 'PSP MAX (V)', data: vdc2Maxs});
		} else if (newVal == false && e == 'PSP MAX') {
			let index = fields.findIndex(function(item) { return item.name === 'PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e == false && newVal == false){
			let index = fields.findIndex(function(item) { return item.name === 'PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e === true && newVal === true){
			fields.push({name: 'PSP MAX (V)', data: vdc2Maxs});

		}

		setSeries(fields);
	};

	const handleReferenceCellValue = () => {
		let newVal  = referenceCellValue === true ? false : true;
		let fields = series;
		
		let cspIndex;
		fields.map((item, index) => {
			if(item.name === 'CSP (V)') {
				cspIndex = index;
			}
		});

		let pspIndex;
		fields.map((item, index) => {
			if(item.name === 'PSP (V)') {
				pspIndex = index;
			}
		});

		setReferenceCellValue(newVal);
    
		if (newVal === true) {
			if (cspIndex >= 0) {
				fields[cspIndex] = {name: 'CSP (V)', data: vdc1Refs};
			}

			if (pspIndex >= 0) {
				fields[pspIndex] = {name: 'PSP (V)', data: vdc2Refs};
			} 
		} else {
			if (cspIndex >= 0) {
				fields[cspIndex] = {name: 'CSP (V)', data: vdc1NoRefs};

			}

			if (pspIndex >= 0) {
				fields[pspIndex] = {name: 'PSP (V)', data: vdc2NoRefs};
			}
		}

		setSeries(fields);
	};

	if (data && data.cps) {
		data.cps.map((item) => {
			pipelineChainages.push(item.pipelineChainage);
      
			vdc1NoRefs.push(item.vdc1);
			vdc1Refs.push((parseFloat(item.vdc1) + -1.1).toFixed(4));
      
			vdc2NoRefs.push(item.vdc2);
			vdc2Refs.push((parseFloat(item.vdc2) + -1.1).toFixed(4));

			vac1s.push(item.vac1);
			vac2s.push(item.vac2);
      
			vbats.push(item.vbat);
			temps.push(item.temp);
			currents.push(item.current);

			// Limit filters
			vac1Mins.push(item.vac1Min);
			vac1Maxs.push(item.vac1Max);
			vdc1Mins.push(item.vdc1Min);
			vdc1Maxs.push(item.vdc1Max);
			vac2Mins.push(item.vac2Min);
			vac2Maxs.push(item.vac2Max);
			vdc2Mins.push(item.vdc2Min);
			vdc2Maxs.push(item.vdc2Max);
		});
	}

	let minChartWidth = parseInt(data.cps.length/20).toFixed(2);

	minChartWidth = (minChartWidth < 1) ? 1 : minChartWidth;

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={12}>
						<Row>
							<Space>
								<LineChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.FullCPSReport ? langdata.FullCPSReport : 'Full CPS Report'}</Title>
						</Row>
					</Col>

					
					<Col span={12}>
						<Row justify="end">
							<Col>
								<Space>
									<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion : 'Filter by region'}>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder= {langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											options={regions}
											onSelect={(e, options)=>handleChangeRegion(e, options)}
										/>
									</Tooltip>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Popover title={langdata && langdata.FullCPSReport ? langdata.FullCPSReport : 'Full CPS Report'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
								<Space>&nbsp;&nbsp;</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ marginTop: '10px' }} >
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeVDC2} checked={filterVDC2}>{langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC2Min('PSP MIN');}} checked={filterVDC2Min}>{langdata && langdata.PSPMINV ? langdata.PSPMINV : 'PSP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC2Max('PSP MAX');}} checked={filterVDC2Max}>{langdata && langdata.PSPMAXV ? langdata.PSPMAXV : 'PSP MAX (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={onChangeVAC2} checked={filterVAC2}>{langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}</Checkbox>  
							</Col>
							
							<Col>
								<Checkbox onChange={()=>{onChangeVAC2Min('AC PICKUP MIN');}} checked={filterVAC2Min}>{langdata && langdata.ACPICKUPMINV ? langdata.ACPICKUPMINV : 'AC PICKUP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC2Max('AC PICKUP MAX');}} checked={filterVAC2Max}>{langdata && langdata.ACPICKUPMAXV ? langdata.ACPICKUPMAXV : 'AC PICKUP MAX (V)'}</Checkbox>
							</Col>
							
							
						</Row>
						<br/>
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							
							<Col>
								<Checkbox onChange={onChangeVDC1} checked={filterVDC1}>{langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC1Min('CSP MIN');}} checked={filterVDC1Min}>{langdata && langdata.CSPMINV ? langdata.CSPMINV : 'CSP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC1Max('CSP MAX');}} checked={filterVDC1Max}>{langdata && langdata.CSPMAXV ? langdata.CSPMAXV : 'CSP MAX (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={onChangeVAC1} checked={filterVAC1}>{langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}</Checkbox>
							</Col>
							
							<Col>
								<Checkbox onChange={()=>{onChangeVAC1Min('CASING AC PICKUP MIN');}} checked={filterVAC1Min}>{langdata && langdata.CASINGACPICKUPMINV ? langdata.CASINGACPICKUPMINV : 'CASING AC PICKUP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC1Max('CASING AC PICKUP MAX');}} checked={filterVAC1Max}>{langdata && langdata.CASINGACPICKUPMAXV ? langdata.CASINGACPICKUPMAXV : 'CASING AC PICKUP MAX (V)'}</Checkbox>
							</Col>
						</Row>
						<br/>
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeBattery} checked={filterBattery}>{langdata && langdata.BATTERY ? langdata.BATTERY : 'BATTERY'}</Checkbox>
							</Col>
							{tlp_feature.current ? 
								<Col>
									<Checkbox onChange={onChangeCurrent} checked={filterCurrent}>{langdata && langdata.CURRENT ? langdata.CURRENT : 'CURRENT'}</Checkbox>
								</Col> : null }
							<Col>
								<Checkbox onChange={onChangeTemp} checked={filterTemp}>{langdata && langdata.TEMPERATURE ? langdata.TEMPERATURE : 'TEMPERATURE'}</Checkbox>
							</Col>
						</Row>
						{tlp_feature && tlp_feature.refElectrodeType!==2 ?
							<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
								<Col span={24}>
									<Text type="secondary">
										<Checkbox checked={referenceCellValue} onChange={handleReferenceCellValue}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
									</Text>
								</Col>
							</Row> : null}
						<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
							<Col span={24}>
								<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
								<br />
								<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
							</Col>
						</Row>
						<div ref={ref}>
							<Card className="card-Shadow-dashboard" style={{overflow: 'scroll'}}>
								{ data.cps && data.cps.length > 0 ? (
									<Chart height={500} width={(width - 40) * minChartWidth} options={ options } series={ series } type="line" />
								) : (
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
							</Card>
						</div>
					</Col>
				</Row>
			</Content>
			<Spin fullscreen spinning={data.loading} />
		</>
	);
}