import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col, Typography, Table, Tag,  Breadcrumb, Card, Space, message, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Icon } from 'leaflet';
import '../../App.css';
import 'leaflet/dist/leaflet.css';

import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context';
import inspectiondata from './reducer';
import { INSPECTION_DATA, INSPECTION_DATA_ERROR, INSPECTION_DATA_SUCCESS, INSPECTON_ALARM_DATA, INSPECTON_ALARM_ERROR, INSPECTON_ALARM_SUCCESS } from './constants';
import axios from 'axios';
import { FullscreenOutlined,FullscreenExitOutlined, HomeOutlined } from '@ant-design/icons';
import GoogleMap from '../Map/map';
import moment from 'moment';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';



const { Title } = Typography;

const linecolor = { color: 'green' };
const pipecolor = { color: 'black' };
const odmetercolor = { color: '#036CD6' };
const optionalcolor ={color:'orange'};
const bordercolor = { color: '#fc8383'};
// const pidsicon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	// iconUrl:require('../../images/refinery.png'),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]});


// const markericon = new Icon({
// 	iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
// 	// iconUrl:require("./location-pin.png"),
// 	iconSize:[45,50],
// 	iconAnchor:  [25, 50]});
const CHicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/ch.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const WBicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/wb.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const KMPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/kmp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TLPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tlp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});
const TPicon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/tp.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]});

const PatchPoint = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/red-pin.png'),
	iconSize: [20, 30],
	iconAnchor:[18, 36]
});

const StartIcon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/start.png'),
	iconSize: [20, 30],
	iconAnchor:[18, 36]
});

const EndIcon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../images/stop.png'),
	iconSize: [20, 30],
	iconAnchor:[18, 36]
});

const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});

export default function InspectionDetails() {

	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const { state } = useLocation();
	const navigate = useNavigate();
	const [size, setSize] = useState('200px');
	const {handleGetMapdata, mapdata, optionalpipedata,handleOptionalPipelinedata, getRegion,regiondata, getPids, pidsData} = useAuth();
	const initalState = {inspection_data:[],patch_data:[],inspection_alarm:[], details: null, pending: false, error: false };
	const [inspection_data, dispatch] = useReducer(inspectiondata, initalState);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(!state) {
			navigate(-1);
		}
	},[authtoken]);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}else{
			if(map_type && map_type === 'leaflet' && state) {
				handleGetMapdata(authtoken,state.data.regioninfoId,null,null);
				handleOptionalPipelinedata(authtoken,null,state.data.regioninfoId,null,null);
			}
			getRegion(authtoken);
			getPids(authtoken);
			handleInspection();
			// handleInspectionAlarm()
		}
 

	},[authtoken]);

	const pipeline = [];
	const center=[];
	const odmeter=[];
	const optionalpath = [];
	const markers = [];
	const points = [];
	const pidsmarker=[];
	const line = [];
	const KMP = [];
	const WB = [];
	const Turn = [];
	const TLP = [];
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const startAllocation = [];
	const endAllocation = [];

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
		pipeline.push([item.latitude,item.longitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push([item.fibreLatitude,item.fibreLongitude]);
	});
  
	optionalpipedata &&  optionalpipedata.map((item)=>{

		optionalpath.push([item.walkingLatitude,item.walkingLongitude]);
	});

	mapdata &&  mapdata.map((item)=>{

		if(item.rouMarker=='CH'){
			markers.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.CH ? langdata.CH:'CH'}`
			},);
		}
		else if(item.rouMarker=='WB'){
			WB.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.WB ? langdata.WB:'WB'}`
			},);
		}

		else if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TLP ? langdata.TLP:'TLP'}`
			},);
		}

		else if(item.rouMarker=='KMP'){
			KMP.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.KMP ? langdata.KMP:'KMP'}`
			},);
		}

		else if(item.rouMarker=='TP'){
			Turn.push({
				geocode:[item.latitude,item.longitude],
				pop:`${langdata && langdata.TP ? langdata.TP:'TP'}`
			},);
		}


	});
  
	mapdata &&  mapdata.map((item,index)=>{

		if(index==0){
			pidsmarker.push({
				geocode:[item.latitude,item.longitude],
				pop:'PID 1'
			},);
		}

	});

	const tempMapArray = {};
	const teampOptionalMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	optionalpipedata && optionalpipedata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!teampOptionalMapArray[fieldValue]) {
			teampOptionalMapArray[fieldValue] = [];
		}
		teampOptionalMapArray[fieldValue].push(item);
	});

	inspection_data.patch_data && inspection_data.patch_data.map((item)=>{
		item.liveLocation.map((inneritem,index)=>{
			line.push([inneritem.latitude,inneritem.longitude]);
			if(index==0){
				center.length=0;
				center.push(parseFloat(inneritem.latitude), parseFloat(inneritem.longitude));
			}
		});
	});

	inspection_data.patch_data && inspection_data.patch_data.map((item)=>{
		startAllocation.push( {geocode : [parseFloat(item.startLocation.latitude), parseFloat(item.startLocation.longitude)],pop:'START'});
		endAllocation.push({geocode : [item.endLocation.latitude && item.endLocation.latitude, item.endLocation.longitude && item.endLocation.longitude],pop:'END'});
		item.liveLocation.map((inneritem, index)=>{
			points.push({
				geocode:[inneritem.latitude,inneritem.longitude],
				pop:`${langdata && langdata.COVERED ? langdata.COVERED:'COVERED'}`
			},);
			if(index==0){
				center.length=0;
				center.push(parseFloat(inneritem.latitude), parseFloat(inneritem.longitude));
			}
		});
	});


	const alarm_data=[];


	inspection_data.inspection_alarm && inspection_data.inspection_alarm.map((item) => {
		const date = moment.utc(item.datetime).toDate();
		date.toString();
		const newdate = moment(date, 'YYYY-MM-DD HH:mm:ss').format(settingsdateformat + ' ' + settingstimeformat);
		alarm_data.push({
			key: item.alarm_prm_id,
			alarmId: item.alarm_prm_id,
			severity:item.alarmSeverity && item.alarmSeverity==3 ? 'RED' :  item.alarmSeverity==2 ? 'AMBER' : item.alarmSeverity==1 ? 'GREEN' : item.alarmSeverity==0 ? 'CLEAR' : '',
			activity: item.eventType,
			description:item.ruleDesc,
			date:item.datetime ? newdate : '-',
			duration:item.duration ? (item.duration/60).toFixed(2) + ` ${langdata && langdata.Min ? langdata.Min:' Min'}` : 0 + ` ${langdata && langdata.Min ? langdata.Min:' Min'}`,
			channel: item.channelId,
			chainage: item.locationDetails ? item.locationDetails.info : null,
			locationDetails:item.locationDetails && item.locationDetails,
			alarmSeverity:item.alarmSeverity,
			status:item.status,
			channel_name:item.channel_name,
			pidsinfoId:item.pidsinfoId,
			region : regiondata && regiondata.map((region) => { if (item.regioninfoId === region.id){ return region.regionName.toUpperCase();} }),


		});
	});

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item)=>{
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
	
	}


	const columns = [

		{
			title: `${langdata && langdata.AlarmId ? langdata.AlarmId:'Alarm Id'}`,
			dataIndex: 'alarmId',
			key: 'alarmId',
		},
        
		{
			title: `${langdata && langdata.AlarmSeverity ? langdata.AlarmSeverity:'Alarm Severity'}`,
			dataIndex: 'severity',
			key: 'severity',
  
			render: (_, { severity }) => (
				<>
					<Tag color={severity === 'RED' ?'red' :  severity === 'GREEN' ?'green' : severity === 'AMBER' ? 'gold' : severity === 'CLEAR' ? 'violet' :'' } key={severity}>
						{severity}
					</Tag>
				</>
			),
		},
        
        
		{
			title: `${langdata && langdata.Activity ? langdata.Activity:'Activity'}`,
			dataIndex: 'activity',
			key: 'activity',
  
		},
		{
			title: `${langdata && langdata.Region ? langdata.Region:'Region'}`,
			dataIndex: 'region',
			key: 'region',
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS:'PIDS'}`,
			dataIndex: 'pidsname',
			key: 'pidsname',
			render: (_, record) => (
				<>
					{pidsData && pidsData.map((pids) => { if (record.pidsinfoId === pids.id){ return pids.name.toUpperCase();} })}
				</>
			)
		},
		{
			title: `${langdata && langdata.Channel ? langdata.Channel:'Channel'}`,
			dataIndex: 'channel_name',
			key: 'channel_name',
		},
		{
			title: `${langdata && langdata.Description ? langdata.Description:'Description'}`,
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date:'Date'}`,
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage:'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
		},
		{
			title: `${langdata && langdata.Status ? langdata.Status:'Status'}`,
			dataIndex: 'status',
			key: 'status',
			render: (_, status) => (
				<>
					{status &&
						<Tag color={status.alarmSeverity==3 ? 'red' :
							status.alarmSeverity==2 ? 'gold' : status.alarmSeverity==1 ? 'green' : status.alarmSeverity==0 ? 'violet' : ''}>
							{status.status}
						</Tag>}
				</>
			),
		}
	];

	const handleInspection=()=>{
		dispatch({ type : INSPECTION_DATA});
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/inspection?id=${state && state.data.id}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : ''
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {

					dispatch({ type : INSPECTION_DATA_SUCCESS, payload : response.data.data});
					handleInspectionAlarm(response.data.data[0].allocationId, response.data.data[0].allocation && response.data.data[0].allocation.pipelineConfigFrom ? response.data.data[0].allocation.pipelineConfigFrom : null,
						response.data.data[0].allocation && response.data.data[0].allocation.pipelineConfigTo ? response.data.data[0].allocation.pipelineConfigTo : null, response.data.data[0].resolvedAlarms);

				}else {
					dispatch({ type : INSPECTION_DATA_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : INSPECTION_DATA_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};


	const handleInspectionAlarm=(allocationId,from,to,alarm)=>{
		dispatch({ type : INSPECTON_ALARM_DATA});

		let data ={
			allocationId : allocationId,
			from: from,
			to: to,
			alarm: alarm,
		};
    
		let config = {
			method: 'POST',
			maxBodyLength: Infinity,
			url: `${api}/inspectionalarm`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
      
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : INSPECTON_ALARM_SUCCESS, payload : response.data});
				}else {
					dispatch({ type : INSPECTON_ALARM_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : INSPECTON_ALARM_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
					});
				}
			});
	};



	return (
		<>
			{state ? 
				<>
					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '20px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
						marginTop:'10px',
						marginBottom: '0px'
				
					}}
					>
						<Col style={{marginTop : '10px'}}span={12}>
							<Row>
								<Space>
									<Title level={5} style={{ lineHeight: '2', fontWeight:'1'}}> {langdata && langdata.LineWalkerNPV ? langdata.LineWalkerNPV:'Linewalker/NPV'}: <b>{state.data.name}</b> </Title>
								</Space>
								<Space>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Space>
								<Space>
									<Title level={5} style={{ lineHeight: '2', fontWeight:'1'}}> {langdata && langdata.Date ? langdata.Date:'Date'}: <b> {state.data.date}</b> </Title>
								</Space>
					
								{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							</Row>
						</Col>
						{/* <Col className="gutter-row" span={6}>
				</Col>
				<Col className="gutter-row" span={12}>
				</Col> */}

						{/* <Col className="gutter-row" style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={6}>
					<Button type='primary' onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} style={{marginRight:'20px'}} >View Map</Button>
				</Col> */}
						{/* </Space> */}
					</Row>
					{size && size === '200px' ? 
						<Row 
							style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								// background: '#FFFFFF',
								alignItems:'center',
								marginTop:'10px',
								marginBottom: '0px'
							}}            
						>
							<Col className="gutter-row" span={24}>
      

								<Card>
									<Breadcrumb
										items={[
											{
												href: '/dashboard',
												title: <HomeOutlined />,
											},
											{
												title: (state.path === 'inspections' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.Inspection ? langdata.Inspection:'Inspection'}</a> : <a onClick={() => {navigate(-1);}}>{langdata && langdata.LineWalkerInspections ? langdata.LineWalkerInspections:'Line Walker Inspections'}</a> )
											},
											{
												title: `${langdata && langdata.InspectionView ? langdata.InspectionView:'Inspection View'}`,
											},
										]}
										style={{ marginBottom : '10px'}}
									/>
        
									{/* <Row style={{justifyContent:'right', display:'flex'}}>

<Button onClick={()=>{setSize(size === 'auto' ? '200px':'auto')}} style={{marginRight:'20px'}} >Full Size</Button>
    </Row> */}
									<Content>

										{/* map filter starts  */}
										{map_type && map_type !== 'leaflet' ?

											< GoogleMap inspectionData = {inspection_data.patch_data} height = {'30vh'} />
									
											: 
									
											mapdata && mapdata ? 
												<div className="App" style={{textAlign: 'left'}}>
        
													<MapContainer center={center} zoom={17} scrollWheelZoom={false} style={{height:size}}>
														<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenOutlined /></button>
														<TileLayer
															attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
															url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
														/>
														{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

														{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

														<LayersControl position="topright" >

															<MarkerClusterGroup>
																{allPidsDatas && allPidsDatas.map((marker,index)=>
																	<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
																		<Popup>
																			{marker.pop}
																		</Popup>

																	</Marker>

	

																)}       
															</MarkerClusterGroup>


															<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP:'KMP'}>
																<MarkerClusterGroup>

																	{KMP && KMP.map((marker,index)=>
																		<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>


															<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB:'WB'}>
																<MarkerClusterGroup>

																	{WB && WB.map((marker,index)=>
																		<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>




															<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP:'TP'}>
																<MarkerClusterGroup>

																	{Turn && Turn.map((marker,index)=>
																		<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>


															<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP:'TLP'}>
																<MarkerClusterGroup>

																	{TLP && TLP.map((marker,index)=>
																		<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>


															<LayersControl.Overlay  name={langdata && langdata.POINTS ? langdata.POINTS:'POINTS'}>
																<MarkerClusterGroup>

																	{points && points.map((marker,index)=>
																		<Marker key={`points-${index}`} position={marker.geocode} icon={PatchPoint}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																	{startAllocation && startAllocation.map((marker,index)=>
																		<Marker key={`points-${index}`} position={marker.geocode} icon={StartIcon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																	{endAllocation && endAllocation.map((marker,index)=>
																		<Marker key={`points-${index}`} position={marker.geocode} icon={EndIcon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}       
																</MarkerClusterGroup>

															</LayersControl.Overlay>



															<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH:'CH'}>
																<MarkerClusterGroup>
																	{markers && markers.map((marker,index)=>
																		<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
																			<Popup>
																				{marker.pop}
																			</Popup>
																			{/* <Tooltip>{marker.pop}</Tooltip> */}
																		</Marker>

																	)}

																</MarkerClusterGroup>
															</LayersControl.Overlay>


															<LayersControl.Overlay  checked name={langdata && langdata.LINE ? langdata.LINE:'LINE'}>
																<Polyline pathOptions={linecolor} weight={7} positions={line} />
															</LayersControl.Overlay>


															<LayersControl.Overlay  checked name={langdata && langdata.Pipeline ? langdata.Pipeline:'Pipeline'}>
																<MarkerClusterGroup>
																	<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
																		{tempMapArray && Object.values(tempMapArray).map((item) => {
																			let pipeline = [];
																			item.map((data) => {
																				pipeline.push({lat : data.latitude,lng : data.longitude});
																			});
																			return (
																				<>
																					<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																				</>
																			);
																		})}
																	</Pane>
																</MarkerClusterGroup>
															</LayersControl.Overlay>

															<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder:'Pipeline Border'}>
																<MarkerClusterGroup>
																	{tempMapArray && Object.values(tempMapArray).map((item) => {
																		let pipelineBorder = [];
																		item.map((data) => {
																			pipelineBorder.push({lat : data.latitude,lng : data.longitude});
																		});
																		return (
																			<>
																				<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
																			</>
																		);
																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>

															<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber:'Fiber'}>
																<MarkerClusterGroup>
																	{tempMapArray && Object.values(tempMapArray).map((item) => {
																		let odmeter = [];
																		item.map((data) => {
																			odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
																		});
																		return (
																			<>
																				<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
																			</>
																		);
																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>

															<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath:'Optional Path'}>
																<MarkerClusterGroup>
																	{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
																		let optionalpath = [];
																		item.map((data) => {
																			optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
																		});
																		return (
																			<>
																				<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
																			</>
																		);
																	})}
																</MarkerClusterGroup>
															</LayersControl.Overlay>

														</LayersControl>
													</MapContainer>

												</div>
												:null}

										{/* <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
      style={{
        margin: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        background: '#FFFFFF',
        alignItems:'center',
      }}
      >
  
  <Col className="gutter-row" span={24}>

          <Select
                  style={{ minWidth: '240px', maxWidth:'240px', margin:'20px'}}
                  value={patchsort} defaultValue={patchsort} 
                  onSelect={handlePatchSort} options={options} />

        </Col>
        </Row> */}

										<Row  style={{
											margin: '10px',
											paddingLeft: '10px',
											paddingRight: '10px',
											background: '#FFFFFF',
											alignItems:'center',
										}} span={24} 
										>
											<Col span={24}>
												<Table 
													scroll={{
														x: 900,
													}}
													columns={columns} dataSource={alarm_data} />

											</Col>
										</Row>
						
									</Content>
					
								</Card>
							</Col>
						</Row>
                    
						: 
						<div className="App" style={{textAlign: 'left', margin : '10px'}}>
							<MapContainer center={center} zoom={17} scrollWheelZoom={false}>
								<button onClick={()=>{setSize(size === 'auto' ? '200px':'auto');}} className="zoomButton"><FullscreenExitOutlined /></button>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{/* {view && view==='view' ? 
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    :

    <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
          
          />
          } */}

								{/* <TileLayer
      attribution='Stamen Watercolor'
      url="https://tiles.stadiamaps.com/tiles/stamen_watercolor/{z}/{x}/{y}.jpg"
    /> */}

								<LayersControl position="topright" >

									<MarkerClusterGroup>
										{allPidsDatas && allPidsDatas.map((marker,index)=>
											<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
												<Popup>
													{marker.pop}
												</Popup>

											</Marker>

	

										)}       
									</MarkerClusterGroup>


									<LayersControl.Overlay  name={langdata && langdata.KMP ? langdata.KMP:'KMP'}>
										<MarkerClusterGroup>

											{KMP && KMP.map((marker,index)=>
												<Marker key={`kmp-${index}`} position={marker.geocode} icon={KMPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

    
									<LayersControl.Overlay  name={langdata && langdata.WB ? langdata.WB:'WB'}>
										<MarkerClusterGroup>

											{WB && WB.map((marker,index)=>
												<Marker key={`wb-${index}`} position={marker.geocode} icon={WBicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>




									<LayersControl.Overlay  name={langdata && langdata.TP ? langdata.TP:'TP'}>
										<MarkerClusterGroup>

											{Turn && Turn.map((marker,index)=>
												<Marker key={`turn-${index}`} position={marker.geocode} icon={TPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>

  
									<LayersControl.Overlay  name={langdata && langdata.TLP ? langdata.TLP:'TLP'}>
										<MarkerClusterGroup>

											{TLP && TLP.map((marker,index)=>
												<Marker key={`tlp-${index}`} position={marker.geocode} icon={TLPicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
										</MarkerClusterGroup>

									</LayersControl.Overlay>


									<LayersControl.Overlay  name={langdata && langdata.POINTS ? langdata.POINTS:'POINTS'}>
										<MarkerClusterGroup>

											{points && points.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={PatchPoint}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}
											{startAllocation && startAllocation.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={StartIcon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}       
											{endAllocation && endAllocation.map((marker,index)=>
												<Marker key={`points-${index}`} position={marker.geocode} icon={EndIcon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}              
										</MarkerClusterGroup>

									</LayersControl.Overlay>



									<LayersControl.Overlay name={langdata && langdata.CH ? langdata.CH:'CH'}>
										<MarkerClusterGroup>
											{markers && markers.map((marker,index)=>
												<Marker key={`marker-${index}`} position={marker.geocode} icon={CHicon}>
													<Popup>
														{marker.pop}
													</Popup>
													{/* <Tooltip>{marker.pop}</Tooltip> */}
												</Marker>

											)}

										</MarkerClusterGroup>
									</LayersControl.Overlay>

       
									<LayersControl.Overlay  checked name={langdata && langdata.LINE ? langdata.LINE:'LINE'}>
										<Polyline pathOptions={linecolor} weight={7} positions={line} />
									</LayersControl.Overlay>

									<LayersControl.Overlay  checked name={langdata && langdata.Pipeline ? langdata.Pipeline:'Pipeline'}>
										<MarkerClusterGroup>
											<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
												{tempMapArray && Object.values(tempMapArray).map((item) => {
													let pipeline = [];
													item.map((data) => {
														pipeline.push({lat : data.latitude,lng : data.longitude});
													});
													return (
														<>
															<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
														</>
													);
												})}
											</Pane>
										</MarkerClusterGroup>
									</LayersControl.Overlay>

									<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder:'Pipeline Border'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let pipelineBorder = [];
												item.map((data) => {
													pipelineBorder.push({lat : data.latitude,lng : data.longitude});
												});
												return (
													<>
														<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>

									<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber:'Fiber'}>
										<MarkerClusterGroup>
											{tempMapArray && Object.values(tempMapArray).map((item) => {
												let odmeter = [];
												item.map((data) => {
													odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
												});
												return (
													<>
														<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>

									<LayersControl.Overlay name={langdata && langdata.OptionalPath ? langdata.OptionalPath:'Optional Path'}>
										<MarkerClusterGroup>
											{teampOptionalMapArray && Object.values(teampOptionalMapArray).map((item) => {
												let optionalpath = [];
												item.map((data) => {
													optionalpath.push({lat : data.walkingLatitude, lng : data.walkingLongitude});
												});
												return (
													<>
														<Polyline pathOptions={optionalcolor} weight={10} positions={optionalpath} />
													</>
												);
											})}
										</MarkerClusterGroup>
									</LayersControl.Overlay>


								</LayersControl>
							</MapContainer>

						</div>
        
        
					}

        

					<Spin spinning={inspection_data.pending} fullscreen />
					{/* Edit grade ends */}

				</>
				: '' }
		</>
	);
}