/* eslint-disable react/jsx-key */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useReducer, useState } from 'react';

import { Row, Col,  Typography, Button, Table, Radio,  Modal, Spin, Space, Divider, Select, DatePicker, Card, Empty, message, Popover, Avatar} from 'antd';
import { Content } from 'antd/es/layout/layout';
import '../../../App.css';

import { useAuth } from '../../Context';
import axios from 'axios';
import { GRAPH_DATA, GRAPH_DATA_ERROR, GRAPH_DATA_SUCCESS, REALTIME_EVENTS_DETAILS, REALTIME_EVENTS_ERROR, REALTIME_EVENTS_LIST, REALTIME_EVENTS_SUCCESS } from './constants';
import realtimeEventDataReducer from './reducer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// import VehicleModal from '../../../images/vehicleModal.png';
// import DrumModal from '../../../images/drumModal.png';
// import ClampingModal from '../../../images/clampingModal.png';
// import mapImg from '../../../images/mapImg.png';
// import mapImgDeep from '../../../images/ManualDeep.png';
// import mapImgMachine from '../../../images/JCBMap.png';
// import mapImgTunneling from '../../../images/TunnelingMap.png';
// import modalDefault from '../../../images/modalDefault.png';
// import modalMan from '../../../images/ManWalking.png';
import dayjs from 'dayjs';
import { BatchDownloader } from './downloader';
import { SearchOutlined, DownloadOutlined, FileTextOutlined, InfoOutlined } from '@ant-design/icons';
import useResizeObserver from 'use-resize-observer';
import Chart from 'react-apexcharts';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';


const { Title } = Typography;


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function EventAnalytics() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const [view, setSize] = useState('List');
	const {getRegion, regiondata, getAllEvents, eventsData, handleGetMapdata, mapdata,getUserAllocation ,getUserAllocationData, getPids, pidsData} = useAuth();
	const initalState = {relatimeEventdata : [], pending: false, error: false, realtime_events_details : [], event_view:[], pagination: { total: 0, limit: 10, skip: 0, current:1 }, graph1PipelineChainage : [], graph1EventsCount : [], graph2Date : [], graph2EventsCount : [] };
	const [eventData, dispatch] = useReducer(realtimeEventDataReducer, initalState);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [url, setURL] = useState(null);
	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);
	const [modelOpen, setModelOpen] = useState(false);
	const navigate = useNavigate();
	const [reportFilter, setFilter] = useState({regioninfoId:null, event:['all'], frequency : 'Random', status:null, searchby:'chainage', startchainage:null, endchainage:null, startODMeter:null, endODMeter:null
	}); 
	const { ref, width } = useResizeObserver();
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	const envExtendTimeout = process.env.REACT_APP_EXTEND_TIMEOUT == undefined || process.env.REACT_APP_EXTEND_TIMEOUT == 'undefined' ? 180000 : process.env.REACT_APP_EXTEND_TIMEOUT;
	const extendTimeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`) == 'null' ? parseInt(envExtendTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_EXTEND_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;


	const searchValue = [

		{
			value: 'chainage',
			label: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
		},
		{
			value: 'od',
			label: `${langdata && langdata.ODMeter ? langdata.ODMeter :'ODMeter'}`,
		}
	];
	
	const Frequency = [
		{
			value: 'Continous',
			label: `${langdata && langdata.Continous ? langdata.Continous :'Continous'}`,
		},
		{
			value: 'Random',
			label: `${langdata && langdata.Random ? langdata.Random :'Random'}`,
		},
	];

	let regions=[{value:null,
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	let allevents = [{value:'all',
		label:`${langdata && langdata.ALL ? langdata.ALL:'ALL'}`
	}];

	eventsData && eventsData.map((allevent)=>{
		allevents.push({
			value:allevent.eventType,
			label:allevent.eventType});
	});

	const columns = [
		{
			title: `${langdata && langdata.Id ? langdata.Id :'Id'}`,
			dataIndex: 'id',
			key: 'id',
			render: (_, record) => (
				<>	
					<Space size="middle">

						{ record  && 
			<span>
				<span style={{ float: 'left', backgroundColor:record.eventConfidence >= 0.9 && record.eventConfidence <= 1 ? 'red' :
					record.eventConfidence >= 0.8 && record.eventConfidence < 0.9 ? 'gold' : record.eventConfidence >= 0.5 && record.eventConfidence < 0.8 ? 'green' : 'violet' , 
				width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} />
			</span>

						}
						<text >
							<a title={langdata && langdata.Moreinfo ? langdata.Moreinfo:'More info'} style={{ color : 'black'}} onClick={()=>{key(record.id);}}>{record.id}</a>
							{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
						</text>
					</Space>
				</>
				// <Space size="middle">
				// 	<a title="More Info" style={{ color : 'black'}} onClick={()=>{key(record.id);}}>{record.id}</a>
				// 	{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				// </Space>
			)
		},
		{
			title: `${langdata && langdata.Activity ? langdata.Activity :'Activity'}`,
			dataIndex: 'eventType',
			key: 'eventType',
			render: (_, record) => (
				<Space size="middle">
					<a title="More Info" onClick={()=>{key(record.id);}}>{record.eventType}</a>
					{/* <a title="Delete TLP Rule" onClick={showDeleteTLPRuleModal}><CloseOutlined /></a> */}
				</Space>
			)
		},
		{
			title: `${langdata && langdata.Region ? langdata.Region :'Region'}`,
			dataIndex: 'region',
			key: 'region',
			render: (_, record) => (
				<>
					{regiondata && regiondata.map((region) => { if (record.regioninfoId === region.id){ return region.regionName.toUpperCase();} })}
				</>
			)
		},
		{
			title: `${langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}`,
			dataIndex: 'pids',
			key: 'pids',
			render: (_, record) => (
				<>
					{pidsData && pidsData.map((pids) => { if (record.pidsinfoId === pids.id){ return pids.name.toUpperCase();} })}
				</>
			)
		},

		{
			title: `${langdata && langdata.Channel ? langdata.Channel :'Channel'}`,
			dataIndex: 'eventChannel',
			key: 'eventChannel',
		},
		{
			title: `${langdata && langdata.Date ? langdata.Date :'Date'}`,
			dataIndex: 'eventDate',
			key: 'eventDate',   
		},
		{
			title: `${langdata && langdata.FiberLength ? langdata.FiberLength :'Fiber Length (m)'}`,
			dataIndex: 'odInMeter',
			key: 'odInMeter',
		},
		
		{
			title: `${langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}`,
			dataIndex: 'chainage',
			key: 'chainage',
		},
	];
  
	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 152);
	}, []);

	useEffect(()=>{
		if(!authtoken){
			navigate('/');
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
			getRegion(authtoken);

		}
		getAllEvents(authtoken);
		handleGetMapdata(authtoken,reportFilter.regioninfoId,startCh,endCh);
		getPids(authtoken);
		
	},[authtoken,reportFilter.regioninfoId,allocationId]);

	useEffect(()=>{
		getEvent(1);
		loadGraph();
	},[authtoken]);

	
	let pipelineChainage = [];
	
	let odmeter = [];

	mapdata &&  mapdata.map((item)=>{

		pipelineChainage.push({
			value:item.pipelineChainage,
			label:item.pipelineChainage.toString()
		});
	});

	mapdata &&  mapdata.map((item)=>{

		odmeter.push({
			value:item.ODMeter,
			label:item.ODMeter.toString()
		});
	});


	const getEvent = (pagination)=>{

		let _pagination = { };
    
		_pagination.total = eventData.pagination.total ? eventData.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		dispatch({ type : REALTIME_EVENTS_LIST});

		let data ={
			'eventtype': reportFilter.event && reportFilter.event.length > 0 ? reportFilter.event : ['all'],
			'eventchannel': 'all',
			'eventstartdate': moment.utc(startDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
			'starteventtime': `${moment.utc(startDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			'endeventtime': `${moment.utc(endDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			'eventenddate': moment.utc(endDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
			'frequency': reportFilter.frequency,
			'startChainage': reportFilter.startchainage,
			'endChainage': reportFilter.endchainage,
			'searchBy': reportFilter.searchby,
			'firstOD': reportFilter.startODMeter,
			'secondOD': reportFilter.endODMeter,
			'region_id': reportFilter.regioninfoId,
			'allocationId':allocationId
		};

		setURL({base: `${api}/events`, params: `&sort=${-1}`, data:data});
		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `${api}/events?limit=${_pagination.limit}&skip=${_pagination.skip}&sort=-1`,
			timeout: extendTimeout,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			},
			data : data
		};
    
		axios.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : REALTIME_EVENTS_SUCCESS, payload : response.data, page:pagination.current,  pageSize : pagination.pageSize });
					data = response.data;
				}else {
					dispatch({ type : REALTIME_EVENTS_ERROR});
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : REALTIME_EVENTS_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						  });
				}
			});
	};



	const key = (value) => {
		setModelOpen(true);
		dispatch({ type : REALTIME_EVENTS_DETAILS, payload : value});
	};

	const handleModelCancel = () => {
		setModelOpen(false);
	};

	// Event Filter Starts

	const handleSearchBy = (e)=>{
		// if(e=='chainage'){
		// 	setFilter({...reportFilter,startODMeter:null});
		// 	setFilter({...reportFilter,endODMeter:null});
		// }
		// else{
		// 	setFilter({...reportFilter,startchainage:null});
		// 	setFilter({...reportFilter,endchainage:null});
		// }
		// setFilter({...reportFilter,searchby:e});
		setFilter({...reportFilter, searchby:e,startchainage:null,endchainage:null, startODMeter:null, endODMeter:null});

	};

	const handleSetregion = (e, options) =>{
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(admin_roles.includes(permission)) {
			setAllocationId(null);
			// setFilter({...reportFilter,regioninfoId:e});
			setFilter({...reportFilter,regioninfoId:e,startchainage:null,endchainage:null, startODMeter:null, endODMeter:null});

		} else {
			// setFilter({...reportFilter,regioninfoId:options.region});
			setFilter({...reportFilter,regioninfoId:options.region, startchainage:null,endchainage:null, startODMeter:null, endODMeter:null});
			setAllocationId(e);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
	};

	const handleSelectEvent = (e)=>{
		setFilter({...reportFilter,event:e});
	};
	

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(new Date(dates[0] && dates[0].$d));
			setEndDate(new Date(dates[1] && dates[1].$d));
		} else {
			console.log('Clear');
		}
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleSetFrequency = (e)=>{
		setFilter({...reportFilter,frequency:e});
	};

	const handleSetStartCH = (e)=>{
		setFilter({...reportFilter,startchainage:e});
	};

	const handleSetEndCH = (e)=>{
		setFilter({...reportFilter,endchainage:e});
	};

	const handleSetStartODMeter = (e)=>{
		setFilter({...reportFilter,startODMeter:e});
	};

	const handleSetEndODMeter = (e)=>{
		setFilter({...reportFilter,endODMeter:e});
	};

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};
  
	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};

	const HandleSearch = () =>{
		getEvent(1);
		loadGraph();
	};

	const loadGraph =()=> {
		dispatch({ type : GRAPH_DATA});
		let data ={
			'eventtype': reportFilter.event && reportFilter.event.length > 0 ? reportFilter.event : ['all'],
			'eventchannel': 'all',
			'eventstartdate': moment.utc(startDate.setHours(0,0,0,0)).format('YYYY-MM-DD'),
			'starteventtime': `${moment.utc(startDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`,
			'endeventtime': `${moment.utc(endDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`,
			'eventenddate': moment.utc(endDate.setHours(23,59,59,999)).format('YYYY-MM-DD'),
			'frequency': reportFilter.frequency,
			'startChainage': reportFilter.startchainage,
			'endChainage': reportFilter.endchainage,
			'searchBy': reportFilter.searchby,
			'firstOD': reportFilter.startODMeter,
			'secondOD': reportFilter.endODMeter,
			'region_id': reportFilter.regioninfoId,
			'allocationId':allocationId
		
		};

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url:  `${api}/event-analytics?limit=10000&isparent=true`,
			timeout: extendTimeout,
			headers: { 
				'Authorization': `Bearer ${authtoken}`,
				'Content-Type': 'application/json'
			},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status === 200) {
					dispatch({ type : GRAPH_DATA_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : GRAPH_DATA_ERROR });
				}
			})
			.catch((error) => {
				dispatch({ type : GRAPH_DATA_ERROR });
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content:  `${langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :'It seems the network is unreachable. Please refresh the application.'}`,
						  });
				}
			});
	};

	let minChartWidth = parseInt(eventData.graph1PipelineChainage && eventData.graph1PipelineChainage.length/20).toFixed(2);

	minChartWidth = (minChartWidth < 1) ? 1 : minChartWidth;

	let minChartWidth2 = parseInt(eventData.graph2Date && eventData.graph2Date.length/20).toFixed(2);

	minChartWidth2 = (minChartWidth2 < 1) ? 1 : minChartWidth2;

	let series = [{
		name: `${langdata && langdata.EventCount ? langdata.EventCount :'Event Count'}`,
		data: eventData.graph1EventsCount
	}];
	
	let options = {
		dataLabels: {
			enabled: true
		},
		title: {
			text: `${langdata && langdata.Eventcountbychainage ? langdata.Eventcountbychainage :'Event count by chainage'}`,
			align: 'left'
		},
		chart: {
			toolbar: {
				show: false
			}
		},
		legend: {
			show: true,
			position: 'top',
			horizontalAlign: 'center',
			onItemClick: {
				toggleDataSeries: true
			},
			onItemHover: {
				highlightDataSeries: true
			}
		},
		xaxis: {
			title: {
				text: `${langdata && langdata.CHAINAGE ? langdata.CHAINAGE :'CHAINAGE'}`
			},
			categories: eventData.graph1PipelineChainage,
			labels: {
				show: true,
				showDuplicates: true,
				formatter: function (val) {
					return val;
				}
			}
		},
		yaxis: {
			title: {
				text: `${langdata && langdata.COUNT ? langdata.COUNT :'COUNT'}`
			}
		},
		markers: { size: 5, shape: 'square' },
	  	stroke: { curve: 'smooth', width: 2 },
	  
	};
	let series2 = [{
		name: `${langdata && langdata.EventCount ? langdata.EventCount :'Event Count'}`,
		data: eventData.graph2EventsCount
	}];
	let options2 = {

		title: {
			text: `${langdata && langdata.Eventscountbydate ? langdata.Eventscountbydate :'Events count by date'}`,
			align: 'left'
		},
		chart: {
			toolbar: {
				show: false
			}
		},

	  plotOptions: {
			bar: {
		  horizontal: false,
		  columnWidth: '20px',
		  endingShape: 'rounded'
			},
	  },
	  dataLabels: {
			enabled: false
	  },
	  markers: { size: 5, shape: 'square' },
	  stroke: { curve: 'smooth', width: 2 },
	  xaxis: {
			title: {
				text: `${langdata && langdata.DATE ? langdata.DATE :'DATE'}`
			},
			categories: eventData.graph2Date,
			labels: {
				show: true,
				showDuplicates: true,
				rotate: -60,
			}
		},
		yaxis: {
			title: {
				text: `${langdata && langdata.COUNT ? langdata.COUNT :'COUNT'}`
			}
		},
	  fill: {
			opacity: 1
	  }
	  };

	//   const handleGenerateView = () =>{

	// 	dispatch({ type : EVENT_VIEW});

	// 	let config = {
	// 		method: 'get',
	// 		maxBodyLength: Infinity,
	// 		url: `${api}/createeventview
	//     `,
	// 		headers: { 
	// 			'Content-Type': 'application/json', 
	// 			'Authorization': `Bearer ${authtoken}`
	// 		},
	// 		data : ''
	// 	};
      
	// 	axios.request(config)
	// 		.then((response) => {
	// 			if (response.status === 200 || response.status === 201 || response.status === 204) {
	// 				Modal.success({
	// 					title: 'Event view generated' ,
	// 			  });
	// 				dispatch({ type : EVENT_VIEW_SUCCESS, payload : response.data});
	// 			}else {
	// 				dispatch({ type : EVENT_VIEW_ERROR});
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			dispatch({ type : EVENT_VIEW_ERROR});
	// 		});
	// };

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.eventanalyticscontent1 ? langdata.eventanalyticscontent1:'This feature allow the user to sort and filter event details by chainage and fiber length wise for precise analysis. Also provide graphical representations showcasing event counts per chainage and day, providing valuable insights at a glance'}</p>
		</Card>
	);

	return (
		<>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >

				<Col span={12}>
					<Row>
						<Space>
							<FileTextOutlined style={{fontSize:'30px'}}/>
							{/* </Col> */}
						</Space>
						<Space>&nbsp;&nbsp;</Space>
					
						{/* <Col style={{marginLeft:'4px'}} span={19}> */}
						<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.EventAnalytics ? langdata.EventAnalytics:'Event Analytics'}</Title>
					</Row>
				</Col>
				<Col span={12}>
					<Row justify="end">
						<Col>
							{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>Region:</Text> */}
							<Space>
								<Typography>
									<Popover title={langdata && langdata.EventAnalytics ? langdata.EventAnalytics:'Event Analytics'}  content={infoContent} trigger="click">
										<Avatar size="small"><InfoOutlined /></Avatar>
									</Popover>
								</Typography>
							</Space>
						</Col>
					</Row>
				</Col>

				
			</Row>











			<Row style={{
				marginTop: '0px',
				marginBottom: '0px',
				marginLeft: '10px',
				marginRight: '10px',
				// paddingTop: '10px',
				paddingBottom: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				justifyContent:'left', display:'flex', alignItems:'left'
			}}>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.Searchby ? langdata.Searchby:'Search by'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.Select ? langdata.Select:'Select'}
										optionFilterProp="children"
										value={reportFilter.searchby}
										options={searchValue}
										onSelect={handleSearchBy}                  

									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>




				<Row style={{marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}} >


					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>

						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.Allocations ? langdata.Allocations:'Allocations'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion:'Select Region'}
										optionFilterProp="children"
										options={regions}
										defaultValue={null}
										onSelect={(e, options)=>handleSetregion(e, options)}
									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
						
					</Col>	

				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								{reportFilter && reportFilter.searchby == 'chainage' ? ( 
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.StartChainage ? langdata.StartChainage:'Start Chainage'}:</Space>
								) : (
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.StartODMeter ? langdata.StartODMeter:'Start ODMeter'}:</Space>
								)}
								<Space>&nbsp;</Space>

								{reportFilter && reportFilter.searchby == 'chainage' ? ( 
									<Space>
										<Select
											style={{ minWidth: '180px', maxWidth: '180px' }}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectStartChainage ? langdata.SelectStartChainage:'Select Start Chainage'}
											optionFilterProp="children"
											value={reportFilter.startchainage}
											options={pipelineChainage}
											onSelect={handleSetStartCH} />
									</Space>
								) : (
									<Space>
										<Select
											style={{ minWidth: '180px', maxWidth: '180px' }}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectStartODMeter ? langdata.SelectStartODMeter:'Select Start ODMeter'}
											optionFilterProp="children"
											value={reportFilter.startODMeter}
											options={odmeter}
											onSelect={handleSetStartODMeter} />
									</Space>
								)}
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								{reportFilter && reportFilter.searchby == 'chainage' ? ( 
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.EndChainage ? langdata.EndChainage:'End Chainage'}:</Space>
								) : (
									<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>{langdata && langdata.EndODMeter ? langdata.EndODMeter:'End ODMeter'}:</Space>
								)}
								<Space>&nbsp;</Space>

								<Space>
									{reportFilter && reportFilter.searchby == 'chainage' ? ( 
										<Select Item
											style={{ minWidth: '180px', maxWidth:'180px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectEndChainage ? langdata.SelectEndChainage:'Select End Chainage'}
											optionFilterProp="children"
											value={reportFilter.endchainage}
											options={pipelineChainage}
											onSelect={handleSetEndCH}                  

										/>
									) : (
										<Select Item
											style={{ minWidth: '180px', maxWidth:'180px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectEndODMeter ? langdata.SelectEndODMeter:'Select End ODMeter'}
											optionFilterProp="children"
											value={reportFilter.endODMeter}
											options={odmeter}
											onSelect={handleSetEndODMeter}                  

										/>
									)}
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>




				<Row style={{marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}} >


					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>

						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>
						
								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.Activity ? langdata.Activity:'Activity'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select
										style={{ minWidth: '180px', maxWidth:'180px'}}
										mode="multiple"
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectEvent ? langdata.SelectEvent:'Select Events'}
										optionFilterProp="children"
										options={allevents}
										onChange={handleSelectEvent}               
										defaultValue={['all']}
									/>	
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
						
					</Col>	

				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.DateRange ? langdata.DateRange:'Date Range'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<RangePicker onChange={onRangeChange} disabledDate={disabledDate} allowClear={false} format={settingsdateformat}
										defaultValue={[dayjs(moment().format('YYYY-MM-DD'), dateFormat), dayjs(moment().format('YYYY-MM-DD'), dateFormat)]} 
										style={{ minWidth: '180px', maxWidth:'180px'}} />
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>



				<Row style={{ marginTop:'20px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{textAlign:'right', width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									{langdata && langdata.AnomalyFrequency ? langdata.AnomalyFrequency:'Anomaly Frequency'}:
								</Space>
								<Space>&nbsp;</Space>

								<Space>
									<Select
										style={{ minWidth: '180px', maxWidth:'180px'}}
										showSearch
										filterOption={filterOption}
										placeholder={langdata && langdata.SelectAnomalyFrequency ? langdata.SelectAnomalyFrequency:'Select Anomaly Frequency'}
										optionFilterProp="children"
										value={reportFilter.frequency}
										onSelect={handleSetFrequency}                  
										options={Frequency}
									/>
								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row>


				{/* <Row style={{marginTop:'30px', justifyContent:'left', display:'flex', alignItems:'left'}} >


					<Col style={{justifyContent:'left', display:'flex', alignItems:'left'}} span={24}
					>

						<Space>
							<Row  style={{justifyContent:'left', display:'flex', alignItems:'left'}}>

								<Space style={{width:'160px', justifyContent:'left', display:'flex', alignItems:'left'}}>

									&nbsp;
								</Space>
								<Space>&nbsp;</Space>

								<Space style={{width:'360px', justifyContent:'left', display:'flex', alignItems:'left'}}>
									<Button type="primary" title="Search Report" onClick={HandleSearch}><SearchOutlined />Search</Button>

								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>

					</Col>	

				</Row> */}
				{/* <Row style={{ marginTop:'30px', justifyContent:'right', display:'flex', alignItems:'right'}}>
					<Col style={{justifyContent:'right', display:'flex', alignItems:'right'}} span={24}
					>
						<Space>
							<Row  style={{justifyContent:'right', display:'flex', alignItems:'right'}}>

								<Space style={{width:'160px', justifyContent:'right', display:'flex', alignItems:'right'}}>

									&nbsp;
								</Space>
								<Space>&nbsp;</Space>

								<Space style={{width:'360px'}}>
									<Button type="primary" title="Search Report" onClick={HandleSearch}><SearchOutlined />Search</Button>

								</Space>
								<Space>&nbsp;</Space>

							</Row>
						</Space>
					</Col>	
				</Row> */}


				<Col span={24}>

					

					<Row style={{ marginTop:'20px', justifyContent:'left', display:'flex', alignItems:'left'}}>
						<Col style={{justifyContent:'left', display:'flex', alignItems:'left'}} span={24}
						>
							<Space>
								<Row  style={{justifyContent:'left', display:'flex', alignItems:'left'}}>

									<Space style={{width:'160px', justifyContent:'left', display:'flex', alignItems:'left'}}>

									&nbsp;
									</Space>
									<Space>&nbsp;</Space>

									<Space style={{justifyContent:'left', display:'flex', alignItems:'left', width:'360px'}}>
										<Button type="primary" title={langdata && langdata.SearchReport ? langdata.SearchReport:'Search Report'} onClick={HandleSearch}><SearchOutlined />{langdata && langdata.Search ? langdata.Search:'Search'}</Button>


									</Space>
									<Space>&nbsp;</Space>

								</Row>
							</Space>
						</Col>	
					</Row>

				</Col>

			</Row>


			{view && view === 'List' ?
				<Content>
					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '10px',
						marginBottom:'0px',
						// marginTop:'0px',
						padding:'10px',
						background: '#FFFFFF',
						alignItems:'center',
					}}
					>
						<Col className="gutter-row" span={8}>
							<Radio.Group value={view} onChange={(e) => setSize(e.target.value)}>
								<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
								<Radio.Button value="Graph">{langdata && langdata.Graph ? langdata.Graph:'Graph'}</Radio.Button>
							</Radio.Group>
						</Col>
						<Col span={16}>&nbsp;</Col>
					</Row>

					<Row span={24} style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center'
					}} >
						<Col span={24} style={{ margin : '10px'}}>
							<Table 
								scroll={{
									x: 900,
								}}
								columns={columns} dataSource={eventData.relatimeEventdata}  pagination = { eventData.relatimeEventdata.length > 0 ? eventData.pagination : 0 } onChange={getEvent}  
								// onRow={(record) => {
								// 	return {
								// 		onClick: () => { 
								// 			key(record.id);
								// 		}
								// 	};
								// }}
							/>
						</Col>
					</Row>

					<Row gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{
						margin: '10px',
						paddingLeft: '10px',
						paddingRight: '10px',
						background: '#FFFFFF',
						alignItems:'center',
						justifyContent:'center',
						display:'flex'
					}} 
					>
						<Col style={{
							alignItems:'left',
							justifyContent:'left',
							display:'flex',
							padding:'10px'
						}}  className="gutter-row" span={24}>
							<Button disabled={eventData.relatimeEventdata && eventData.relatimeEventdata.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download:'Download'}</Button>
						</Col>

					</Row>
					{/* Fetch and Download Modal Starts */}
					<Modal
						title={langdata && langdata.DownloadReport ? langdata.DownloadReport:'Download Report'}
						centered
						destroyOnClose={true}
						open={showFetchAndDownloadModal}
						onOk={closeShowFetchAndDownloadModal}
						onCancel={closeShowFetchAndDownloadModal}
						footer={[

						]}
						width={540}
					>
						<Row gutter={16}>
							<Col span={24}>
								<Typography>
									{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords :'Total number of records'} : {eventData && eventData.pagination.total}
								</Typography>
							</Col>
						</Row>

						{eventData && eventData.pagination.total > 0 ? (
							<BatchDownloader total={eventData.pagination.total} url={url}/>
						) : (
							<>
								<Row gutter={16} style={{
									backgroundColor: '#d3d3d3',
									padding: '5px',
									marginTop: '10px'
								}}>
									<Col span={24}>
										<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound :'No records found.'}</Typography>
									</Col>
								</Row>
								<br/>
							</>
          
						)}
					</Modal>
					{/* Fetch and Download Modal Modal Ends */}


					<Modal
						open={modelOpen}
						title={langdata && langdata.EventDetails ? langdata.EventDetails :'Event Details'}
						// onOk={handleModelOk}
						onCancel={handleModelCancel}
						footer={[
							// eslint-disable-next-line react/jsx-key
							<Button onClick={handleModelCancel}>
                				{langdata && langdata.Close ? langdata.Close :'Close'}
							</Button>,
							<Button type="primary" onClick={() => {navigate(( '/eventDetails'),{ state: { data: eventData.realtime_events_details, path: 'event-analytics' } });}}>
                				{langdata && langdata.Moreinfo ? langdata.Moreinfo :'More info'}
							</Button>,
						]}
					>
						<Divider/>
						<Row
							gutter={{
								xs: 8,
								sm: 16,
								md: 24,
								lg: 32,
							}}>

							<Col span={12}>          
								<Typography>{langdata && langdata.Id ? langdata.Id :'Id'}: {eventData && eventData.realtime_events_details && eventData.realtime_events_details.id}</Typography>
								<Typography>{langdata && langdata.Activity ? langdata.Activity :'Activity'}: <b>{eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType}</b></Typography>
								<Typography>{langdata && langdata.Date ? langdata.Date :'Date'}: <b>{eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventDate}</b>
								</Typography>
								<Typography>{langdata && langdata.Region ? langdata.Region :'Region'}: {eventData && eventData.realtime_events_details && eventData.realtime_events_details.regioninfoId && regiondata && regiondata.map((region) => { if (eventData.realtime_events_details.regioninfoId === region.id) { return region.regionName.toUpperCase();} })}</Typography>
								<Typography>{langdata && langdata.PIDS ? langdata.PIDS :'PIDS'}: {eventData && eventData.realtime_events_details && eventData.realtime_events_details.pidsinfoId && allPidsData && allPidsData.map((pids) => { if (eventData.realtime_events_details.pidsinfoId === pids.value){ return pids.label.toUpperCase();} }) }</Typography>
								<Typography>{langdata && langdata.Channel ? langdata.Channel :'Channel'}: {eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventChannel}</Typography>
								<Typography>{langdata && langdata.Chainage ? langdata.Chainage :'Chainage'}: {eventData && eventData.realtime_events_details && eventData.realtime_events_details.chainage}</Typography>
								<Typography>{langdata && langdata.Fiberlength ? langdata.Fiberlength :'Fiber Length'}: {eventData && eventData.realtime_events_details && eventData.realtime_events_details.odInMeter}{' m'}</Typography>
								<Typography>{langdata && langdata.Duration ? langdata.Duration :'Duration'}: {eventData && eventData.realtime_events_details && (eventData.realtime_events_details.duration / 60).toFixed(2)} Min</Typography>
								<Typography>{langdata && langdata.EventProbability ? langdata.EventProbability :'Event Probability'}: <b>{(eventData && eventData.realtime_events_details && (eventData.realtime_events_details.eventConfidence)*100).toFixed(2)}%</b></Typography>
							</Col>
							<Col span={12}> 
								<img src={eventData && eventData.realtime_events_details && eventData.realtime_events_details.activityUrl} alt={`${eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType}`} align="right" />
							</Col>
							{/* <Col span={12}> 
								{eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Manual Digging' ? 
									<img src={mapImg} alt="manual digging" align="right" /> :
									eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Machine Digging' ? 
										<img src={mapImgMachine} alt="Machine" align="right" /> : 
										eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Tunneling' ? 
											<img src={mapImgTunneling} alt="Tunneling" align="right" /> : 
											eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Vehicle Movement' ? 
												<img src={VehicleModal} alt="Tunneling" align="right" /> : 
												eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Drums Beating' ? 
													<img src={DrumModal} alt="Tunneling" align="right" /> : 
													eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Clamping' ? 
														<img src={ClampingModal} alt="Tunneling" align="right" /> : 
														eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Manual Digging - Deeper (1.5 to 3 feets)' ? 
															<img width="300px" src={mapImgDeep} alt="Manual Digging - Deeper (1.5 to 3 feets)" align="right" /> : 
															eventData && eventData.realtime_events_details && eventData.realtime_events_details.eventType === 'Man Movement' ? 
																<img src={modalMan} alt="Man Movement" align="right" /> :
																<img src={modalDefault} alt="Unclassified" align="right" /> }
							</Col>						 */}
						</Row>
					</Modal>
					<Spin fullscreen spinning={eventData.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg :'This process will take 2-3 minutes to complete. Please wait a moment.'}/>


				</Content>  
				:
			//   Map View Starts
				<>
					<Content>
						<Row gutter={{
							xs: 8,
							sm: 16,
							md: 24,
							lg: 32,
						}}
						style={{
							margin: '10px',
							//   paddingLeft: '10px',
							//   paddingRight: '10px',
							padding:'10px',
							background: '#FFFFFF',
							alignItems:'center',
						}}
						>
							<Col className="gutter-row" span={12}>

								<Radio.Group value={view} onChange={(e) => setSize(e.target.value)}>
									<Radio.Button value="List">{langdata && langdata.List ? langdata.List:'List'}</Radio.Button>
									<Radio.Button value="Graph">{langdata && langdata.Graph ? langdata.Graph:'Graph'}</Radio.Button>
								</Radio.Group>
							</Col>
        
							<Col className="gutter-row" span={12}>
        					&nbsp;
							</Col>
							{/* </Space> */}
						</Row>

						<div className="App" style={{textAlign: 'left', margin : '10px'}}>
							<div ref={ref}>
								<Card className="card-Shadow-dashboard" style={{overflow: 'scroll'}}>
									{ eventData && eventData.pagination.total > 0 ? (
										<Chart height={500} width={(width - 40) * minChartWidth} options={options} series={series} type="line" />
									) : (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
								</Card>
								<Card className="card-Shadow-dashboard" style={{overflow: 'scroll'}}>
									{ eventData && eventData.pagination.total > 0 ? (
										<Chart height={500}  width={(width - 40) * minChartWidth2} options={options2} series={series2} type='bar'/>
									) : (
										<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)}
								</Card>
							</div>
						</div>						
						<Spin fullscreen spinning={eventData.pending} tip={langdata && langdata.largeloadingmsg ? langdata.largeloadingmsg :'This process will take 2-3 minutes to complete. Please wait a moment.'}/>
					</Content>
				</>  
			}
		</>
	);
}